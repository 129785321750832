import React, { FC, SVGProps } from 'react'

interface FrameScaleProps extends SVGProps<any> {
	image: string
}

const FrameScale: FC<FrameScaleProps> = ({ image, ...otherProps }) => (
	<svg
		id="Layer_1"
		data-name="Layer 1"
		xmlns="http://www.w3.org/2000/svg"
		version="1.1"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 682.1 195.6"
		width="195.6px"
		height="195.6px"
		{...otherProps}
	>
		<defs>
			<style>{'.cls-4{stroke-width:0;fill:#232323}'}</style>
		</defs>
		<path
			d="M677.9 35c0-11.9-6.9-18-18.8-18h-30.2v.4h-153V7.7c0-3.1-2.6-5.7-5.7-5.7H35.4c-3.1 0-5.7 2.6-5.7 5.7v8.5l-16.2.3-9.3 9.3-1.6 81.4h27.1v79.6c0 3.1 2.6 5.7 5.7 5.7h434.8c.6 0 1.2 0 1.7-.3h168v-2.9 2.5l10.3-8.6 1.9-76.7h27.5l-1.7-71.7v.2Z"
			style={{ fill: '#010101', strokeWidth: 0 }}
		/>

		<path
			className="cls-4"
			d="M83.6 78.6H56.2c-2 0-3.6 1.5-3.6 3.4v80.7h2.5V82c0-.4.4-.9 1.1-.9h27.4c.6 0 1 .4 1 .9v80.7h2.5V82c0-1.9-1.6-3.4-3.5-3.4Z"
		/>
		<path
			className="cls-4"
			d="M69.9 93.7c-6.4 0-11.6 5.2-11.6 11.6s5.2 11.6 11.6 11.6 11.6-5.2 11.6-11.6-5.2-11.6-11.6-11.6Zm0 20.7c-5 0-9.1-4.1-9.1-9.1s4.1-9.1 9.1-9.1 9.1 4.1 9.1 9.1-4.1 9.1-9.1 9.1Z"
		/>
		<path
			className="cls-4"
			d="M681 107.3 678.9 26c0-.3-.2-.7-.4-.9l-9.4-9s-.2-.2-.3-.2H477.6V7.7c0-3.8-3.1-6.9-6.9-6.9h-435c-3.8 0-6.9 3.1-6.9 6.9v8.1H13.2c-.1 0-.2.1-.3.2l-9.5 9.1c0 .1-.2.2-.3.4v.4L.9 107.3v.4l.1.2.1.2h27.5V187c0 3.8 3.1 6.9 6.9 6.9h605.6c.3 0 .6-.1.8-.3l9.2-9c0-.1.2-.2.2-.3v-.4l2.1-75.6h26.7v-.9h.7Zm-666.2-89h14v6.5H14.5l.3-6.5ZM9.9 106H3.4l2-78.7h6.5l-2 78.8ZM12 24.8H7.3l4.9-4.7-.2 4.7Zm.4 81.2 2-78.8h14.4V106H12.4Zm654.7-87.7.3 6.5H477.6v-6.5h189.6ZM31.3 187.5V7.7c0-2.4 2-4.4 4.4-4.4h434.9c2.4 0 4.4 2 4.4 4.4v179.8c0 2.4-2 4.4-4.4 4.4H35.7c-2.4 0-4.4-2-4.4-4.4Zm608.8 4.4H475.9c1-1.2 1.6-2.7 1.6-4.4v-2.1h162.7v6.5h-.1Zm.2-9H477.5v-74.4h164.6l-1.8 74.4Zm2.3 7.2v-4.7h4.8l-4.8 4.7Zm6.7-7.2h-6.5l1.8-74.4h6.7l-2 74.4ZM477.5 106V27.3h189.9l2 78.8h-192Zm192.2-85.9 4.9 4.7h-4.7l-.2-4.7ZM672 106l-2-78.8h6.5l2 78.8H672Z"
		/>
		<path
			className="cls-4"
			d="M55.5 40.6h72.6v21.9H55.5c-1.6 0-2.8-1.3-2.8-2.8V43.5c0-1.6 1.3-2.8 2.8-2.8Z"
		/>
		<image
			width="296"
			height="128"
			transform="translate(148 33.4)"
			xlinkHref={image}
			style={{ isolation: 'isolate' }}
		/>
	</svg>
)

export default FrameScale
