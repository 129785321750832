import React, { FC, SVGProps } from 'react'

interface FrameSmallProps extends SVGProps<any> {
	image: string
}

const Frame290: FC<FrameSmallProps> = ({ image, ...otherProps }) => (
	<svg
		id="2-9-img_svg__smart-label-2-9"
		data-name="smart-label-2-9"
		viewBox="0 0 465 203"
		width="166.4px"
		height="166.4px"
		{...otherProps}
	>
		<defs>
			<style>
				{'.2-9-img_svg__cls-3{fill-rule:evenodd;fill:#3f3f3f}'}
			</style>
		</defs>
		<rect
			x={1.5}
			y={1.5}
			width={462}
			height={200}
			rx={7}
			stroke="#979797"
			strokeWidth={3}
		/>
		<path
			d="M84 31.5h363v158H84z"
			transform="translate(-5 -8.5)"
			fillRule="evenodd"
			fill="#f0efef"
		/>
		<path
			style={{ fill: 'grey' }}
			className="2-9-img_svg__cls-3"
			d="M39 167a1.49 1.49 0 10-1.49 1.47A1.48 1.48 0 0039 167zm6.57 8.46H32.73a1.47 1.47 0 100 2.94h12.83a1.47 1.47 0 100-2.94m-17.07 0A1.48 1.48 0 0027 176.9a1.49 1.49 0 101.49-1.47m9 5.23a1.47 1.47 0 100 2.94h8.37a1.48 1.48 0 001.48-1.47 1.47 1.47 0 00-1.48-1.47z"
			transform="translate(-5 -8.5)"
		/>
		<path
			style={{ fill: 'grey' }}
			className="2-9-img_svg__cls-3"
			d="M50.19 165.51h-8.36a1.48 1.48 0 00-1.49 1.49 1.48 1.48 0 001.49 1.47h8.33a1 1 0 110 2H28.49a1.47 1.47 0 100 2.94h21.67a1 1 0 110 2 1.47 1.47 0 100 2.94 1 1 0 110 2 1.47 1.47 0 100 2.94 1 1 0 110 2H32.94a1.47 1.47 0 100 2.94h17.22a11.43 11.43 0 100-22.85"
			transform="translate(-5 -8.5)"
		/>
		<path
			style={{ fill: 'grey' }}
			className="2-9-img_svg__cls-3"
			d="M32.73 183.6a1.48 1.48 0 001.49-1.47 1.49 1.49 0 10-1.49 1.47"
			transform="translate(-5 -8.5)"
		/>
		<image
			width={296}
			height={128}
			transform="matrix(1.22 0 0 1.22 80 24)"
			xlinkHref={image}
		/>
	</svg>
)

export default Frame290
