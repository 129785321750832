import { SelectOption } from '@app/@types'
import { Box } from '@mui/material'
import { useField } from 'formik'
import React, { ChangeEvent, FC } from 'react'
import { CustomCheckbox } from '../CustomCheckbox'

//added Domen
import ListItem from '@mui/material/ListItem'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'

interface CustomFormCheckboxGroupProps {
	className?: string
	name: string
	onChange?: (item: SelectOption, checked: boolean) => void
	options: SelectOption[]
	type?: 'checkbox' | 'radio'
	flexDirection?: 'row' | 'column'
	labelWidth?: number
}

export const CustomFormCheckboxGroup: FC<CustomFormCheckboxGroupProps> = ({
	className,
	name,
	onChange,
	options,
	type = 'checkbox',
	flexDirection = 'column',
	labelWidth = 70,
}) => {
	const [field, , helpers] = useField<any[]>({
		name,
		type: 'checkbox',
	})

	const value = field.value || []

	const onCheckboxChange =
		(item: SelectOption) =>
		(evt: ChangeEvent<HTMLInputElement>, checked: boolean) => {
			if (type === 'checkbox') {
				if (checked) {
					helpers.setValue([...value, item.value])
				} else {
					helpers.setValue(value.filter((q) => q !== item.value))
				}
			} else {
				if (checked) {
					helpers.setValue(item.value)
				}
			}

			if (onChange) {
				onChange(item, checked)
			}
		}

	const useStyles = makeStyles(() =>
		createStyles({
			root: {
				margin: 0,
				padding: 0,
			},
		})
	)
	const classes = useStyles()
	if (flexDirection === 'row')
		return (
			<Box
				className={className}
				display="flex"
				flexWrap="wrap"
				flexDirection={'row'}
				ml="3px"
			>
				{options?.map((q) => (
					<Box key={q.value} width={labelWidth}>
						<CustomCheckbox
							key={q.value}
							label={q.label}
							onChange={onCheckboxChange(q)}
							value={
								type === 'checkbox'
									? field.value?.includes(q.value)
									: field.value === q.value
							}
						/>
					</Box>
				))}
			</Box>
		)

	return (
		<Box className={className} display="flex" flexWrap="wrap">
			{options?.map((q) => {
				return (
					<ListItem key={q.value} classes={{ root: classes.root }}>
						<CustomCheckbox
							key={q.value}
							label={q.label}
							onChange={onCheckboxChange(q)}
							value={
								type === 'checkbox'
									? field.value?.includes(q.value)
									: field.value === q.value
							}
						/>
					</ListItem>
				)
			})}
		</Box>
	)
}
