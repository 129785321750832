import React, { FC } from 'react'

import { Maybe } from '@app/lib/graphql'
import { isNumber } from '@app/lib/utils'
import { BatteryPercentage } from './BatteryPercentage'

const BATTERY_LOWER_LIMIT = 2100

interface BatteryStatusProps {
	healthPercentage?: Maybe<number>
	batteryCharging?: boolean
}

export const energyCalc = (val: number): number => {
	let energyLevel = 0

	if (val > 3000) {
		energyLevel = 100
	} else {
		energyLevel =
			((val - BATTERY_LOWER_LIMIT) / (3000 - BATTERY_LOWER_LIMIT)) * 100.0
	}

	return Math.round(energyLevel)
}

export const BatteryStatus: FC<BatteryStatusProps> = ({
	healthPercentage,
	batteryCharging = false,
}) => {
	const batteryPercentage = isNumber(healthPercentage)
		? healthPercentage
		: undefined

	console.log('batteryCharging', batteryCharging)
	console.log('healthPercentage', healthPercentage)

	return (
		<BatteryPercentage
			batteryPercentage={batteryPercentage}
			batteryCharging={batteryCharging}
		/>
	)
}
