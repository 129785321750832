import React, { FC } from 'react'
import Typography from '@mui/material/Typography'

import { CustomIconFromValue } from './CustomIconFromValue'
import { Maybe } from '@app/lib/graphql'
import { Box } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) =>
	createStyles({
		icon: {
			fontSize: theme.spacing(3),
		},
	})
)

interface BatteryPercentageProps {
	batteryPercentage?: Maybe<number>
	batteryCharging?: Maybe<boolean>
}

export const BatteryPercentage: FC<BatteryPercentageProps> = ({
	batteryPercentage,
	batteryCharging = false,
}) => {
	const classes = useStyles()

	let iconPostfix: string

	if (!batteryPercentage || batteryPercentage < 5) {
		iconPostfix = '0'
	} else if (batteryPercentage < 25) {
		iconPostfix = '25'
	} else if (batteryPercentage < 50) {
		iconPostfix = '50'
	} else if (batteryPercentage < 90) {
		iconPostfix = '75'
	} else {
		iconPostfix = '100'
	}

	return (
		<Box display="flex" alignItems="center">
			{batteryCharging ? (
				<Typography>Charging</Typography>
			) : (
				<Typography>
					{batteryPercentage === undefined ? '/' : batteryPercentage}
					{' %'}
				</Typography>
			)}
			<Box ml={1}>
				<CustomIconFromValue
					className={classes.icon}
					icon={`svg battery-health-${iconPostfix}`}
				/>
			</Box>
		</Box>
	)
}
