import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AccountNumber: any;
  BigInt: any;
  Byte: any;
  CountryCode: any;
  Cuid: any;
  Currency: any;
  DID: any;
  Date: any;
  DateTime: any;
  DateTimeISO: any;
  DeweyDecimal: any;
  Duration: any;
  EmailAddress: any;
  GUID: any;
  HSL: any;
  HSLA: any;
  HexColorCode: any;
  Hexadecimal: any;
  IBAN: any;
  IP: any;
  IPCPatent: any;
  IPv4: any;
  IPv6: any;
  ISBN: any;
  ISO8601Duration: any;
  JSON: any;
  JSONObject: any;
  JWT: any;
  LCCSubclass: any;
  Latitude: any;
  LocalDate: any;
  LocalDateTime: any;
  LocalEndTime: any;
  LocalTime: any;
  Locale: any;
  Long: any;
  Longitude: any;
  MAC: any;
  NegativeFloat: any;
  NegativeInt: any;
  NonEmptyString: any;
  NonNegativeFloat: any;
  NonNegativeInt: any;
  NonPositiveFloat: any;
  NonPositiveInt: any;
  ObjectID: any;
  PhoneNumber: any;
  Port: any;
  PositiveFloat: any;
  PositiveInt: any;
  PostalCode: any;
  RGB: any;
  RGBA: any;
  RoutingNumber: any;
  SESSN: any;
  SafeInt: any;
  SemVer: any;
  Time: any;
  TimeZone: any;
  Timestamp: any;
  URL: any;
  USCurrency: any;
  UUID: any;
  UnsignedFloat: any;
  UnsignedInt: any;
  Upload: any;
  UtcOffset: any;
  Void: any;
};

export enum Action_Type {
  Create = 'CREATE',
  Delete = 'DELETE',
  Read = 'READ',
  Update = 'UPDATE'
}

export type ActivateBarResponse = {
  __typename?: 'ActivateBarResponse';
  bar?: Maybe<Bar>;
  errors?: Maybe<Array<Error>>;
  storageToken?: Maybe<Scalars['String']>;
};

export type AddChannelInput = {
  channelItems: Array<ChannelItemInput>;
  displayType?: InputMaybe<DisplayType>;
  name?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};

export type AddFlowInput = {
  flowStatements: Array<FlowStatementInput>;
  name?: InputMaybe<Scalars['String']>;
};

export type AddFlowRunnerInput = {
  activeDays?: InputMaybe<Array<InputMaybe<WeekDay>>>;
  flowId: Scalars['Int'];
  linkedItems?: InputMaybe<Array<InputMaybe<AddFlowRunnerLink>>>;
  name?: InputMaybe<Scalars['String']>;
  timeFrom?: InputMaybe<Scalars['Time']>;
  timeTo?: InputMaybe<Scalars['Time']>;
  validFrom: Scalars['DateTime'];
  validTo?: InputMaybe<Scalars['DateTime']>;
};

export type AddFlowRunnerLink = {
  linkedItemId: Scalars['Int'];
  type: FlowRunnerLink;
};

export type AddIntegrationInput = {
  cronUpdateUrl: Scalars['String'];
  name: Scalars['String'];
  secretKey: Scalars['String'];
  syncTime: Scalars['String'];
  token: Scalars['String'];
  triggerUrl: Scalars['String'];
  weekDays: Array<WeekDay>;
};

export type AddIntegrationResponse = {
  __typename?: 'AddIntegrationResponse';
  errors?: Maybe<Array<Maybe<Error>>>;
  integration?: Maybe<Integration>;
};

export type AddOrderInput = {
  costumerId?: InputMaybe<Scalars['Int']>;
  mode?: InputMaybe<OrderMode>;
  orderItems?: InputMaybe<Array<AddOrderItemInput>>;
  orderNumber: Scalars['String'];
  orderType: OrderType;
  rootId?: InputMaybe<Scalars['Int']>;
  supplierId?: InputMaybe<Scalars['Int']>;
};

export type AddOrderItemInput = {
  completedDate?: InputMaybe<Scalars['DateTime']>;
  deliveryDate: Scalars['DateTime'];
  productId: Scalars['Int'];
  quantity: Scalars['Int'];
  status: OrderStatus;
};

export type AddOrderResponse = {
  __typename?: 'AddOrderResponse';
  errors?: Maybe<Array<Maybe<Error>>>;
  order?: Maybe<Order>;
};

export type AddPlaylistInput = {
  name: Scalars['String'];
  playlistItems: Array<PlaylistItemInput>;
};

export type AddProductResponse = {
  __typename?: 'AddProductResponse';
  errors?: Maybe<Array<Error>>;
  /** product added by the mutation */
  product?: Maybe<Product>;
  success?: Maybe<Scalars['Boolean']>;
};

export type AddRoleInput = {
  permissions: Array<Scalars['String']>;
  rank?: InputMaybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type AddRootInput = {
  key?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
};

export type AddRouterInput = {
  name: Scalars['String'];
  rootId: Scalars['Int'];
};

export type AddSensorInput = {
  name: Scalars['String'];
  rootId: Scalars['Int'];
  serialNumber: Scalars['MAC'];
  tags?: InputMaybe<Array<Scalars['String']>>;
};

export type AddSupplier = {
  address: Scalars['String'];
  addressNumber: Scalars['String'];
  city: Scalars['String'];
  companyName: Scalars['String'];
  country: Country;
  email: Scalars['String'];
  postCode: Scalars['String'];
  supplierId: Scalars['String'];
  vat: Scalars['String'];
};

export type AddSupplierResponse = {
  __typename?: 'AddSupplierResponse';
  errors?: Maybe<Array<Error>>;
  supplier?: Maybe<Supplier>;
};

export type AddSynchronizationInput = {
  integrationId?: InputMaybe<Scalars['Int']>;
  integrationToken?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  status: SynchronizationStatus;
  time?: InputMaybe<Scalars['DateTime']>;
  updatedProducts: Array<Scalars['String']>;
};

export type AddSynchronizationResponse = {
  __typename?: 'AddSynchronizationResponse';
  errors?: Maybe<Array<Maybe<Error>>>;
  synchronization?: Maybe<Synchronization>;
};

export type AddTenantInput = {
  address: Scalars['String'];
  addressNumber: Scalars['String'];
  city: Scalars['String'];
  companyName: Scalars['String'];
  contractLength: ContractLength;
  contractorName: Scalars['String'];
  contractorsMail?: InputMaybe<Scalars['String']>;
  country: Country;
  firstUserEmail: Scalars['String'];
  firstUserName: Scalars['String'];
  firstUserPassword: Scalars['String'];
  loginLogo?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  maxRoots: Scalars['Int'];
  maxUsers: Scalars['Int'];
  name: Scalars['String'];
  postCode: Scalars['String'];
  primaryColor?: InputMaybe<Scalars['String']>;
  purchasedModules: Array<PurchasedModules>;
  textColor?: InputMaybe<Scalars['String']>;
  vat: Scalars['String'];
};

export type AddTenantResponse = {
  __typename?: 'AddTenantResponse';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
  tenant?: Maybe<Tenant>;
};

export type AddUserInput = {
  allowedIPs?: InputMaybe<Array<Scalars['IPv4']>>;
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  ledConfig?: InputMaybe<LedConfigInput>;
  password: Scalars['String'];
  roleIDs: Array<Scalars['Int']>;
  rootIDs: Array<InputMaybe<Scalars['Int']>>;
  username: Scalars['String'];
};

export type AddVirtualCategoryPointInput = {
  name?: InputMaybe<Scalars['String']>;
  virtualRackIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type AddVirtualCategoryPointPosition = {
  virtualCategoryPointId: Scalars['Int'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type AddVirtualCategoryScreenInput = {
  backgroundFileId?: InputMaybe<Scalars['Int']>;
  catalogMode?: InputMaybe<Scalars['Boolean']>;
  channelId?: InputMaybe<Scalars['Int']>;
  fileId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  timeout?: InputMaybe<Scalars['Int']>;
  virtualCategoryPointPositions?: InputMaybe<Array<InputMaybe<AddVirtualCategoryPointPosition>>>;
};

export type AddVirtualMultiScreenInput = {
  name?: InputMaybe<Scalars['String']>;
  virtualMultiScreenPositions?: InputMaybe<Array<InputMaybe<AddVirtualMultiScreenPositionInput>>>;
};

export type AddVirtualMultiScreenPositionInput = {
  channelId?: InputMaybe<Scalars['Int']>;
  virtualCategoryPointId?: InputMaybe<Scalars['Int']>;
  virtualRackId?: InputMaybe<Scalars['Int']>;
};

export type AddVirtualProductPositionInput = {
  itemCount?: InputMaybe<Scalars['Int']>;
  productId: Scalars['Int'];
  rotation?: InputMaybe<Scalars['Int']>;
  zoom?: InputMaybe<Scalars['Int']>;
};

export type AddVirtualRackInput = {
  fileId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  virtualShelfPositions: Array<InputMaybe<AddVirtualShelfPositionInput>>;
  virtualStoreDeviceIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type AddVirtualShelfInput = {
  fileId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  spacing?: InputMaybe<Scalars['Float']>;
  variant: VirtualShelfVariant;
  virtualProductPositions?: InputMaybe<Array<AddVirtualProductPositionInput>>;
};

export type AddVirtualShelfPositionInput = {
  virtualShelfId: Scalars['Int'];
};

export type AddVirtualStoreDeviceInput = {
  channelId?: InputMaybe<Scalars['Int']>;
  ip?: InputMaybe<Scalars['IPv4']>;
  name?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  rootId: Scalars['Int'];
  tags?: InputMaybe<Array<Scalars['String']>>;
  token: Scalars['String'];
  virtualCategoryPointId?: InputMaybe<Scalars['Int']>;
  virtualCategoryScreenId?: InputMaybe<Scalars['Int']>;
  virtualMultiScreenId?: InputMaybe<Scalars['Int']>;
};

export type Aisle = {
  __typename?: 'Aisle';
  /** bays connected to a aisle */
  bays?: Maybe<Array<Bay>>;
  id: Scalars['Int'];
  /** aisle key */
  key: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  orientation?: Maybe<Orientation>;
  root?: Maybe<Root>;
  /** @deprecated Use `bays`. */
  shelves?: Maybe<Array<Shelf>>;
};

export type AisleResponse = {
  __typename?: 'AisleResponse';
  aisle?: Maybe<Aisle>;
  errors?: Maybe<Array<Maybe<Error>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export enum AlarmType {
  Expiration = 'expiration',
  Express = 'express',
  Normal = 'normal'
}

export type AssetDownload = {
  __typename?: 'AssetDownload';
  token?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Bar = {
  __typename?: 'Bar';
  backgroundFile?: Maybe<File>;
  devices?: Maybe<Array<Device>>;
  fileId?: Maybe<Scalars['Int']>;
  group?: Maybe<Group>;
  groupId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  macAddress?: Maybe<Scalars['MAC']>;
  name?: Maybe<Scalars['String']>;
  root?: Maybe<Root>;
  rootId?: Maybe<Scalars['Int']>;
  shelf?: Maybe<Shelf>;
  shelfId?: Maybe<Scalars['Int']>;
  size: DeviceSize;
  x?: Maybe<Scalars['Int']>;
  y?: Maybe<Scalars['Int']>;
  z?: Maybe<Scalars['Int']>;
};

export type BarRegisterInput = {
  deviceIds?: InputMaybe<Array<Scalars['Int']>>;
  rootId: Scalars['Int'];
  shelfId?: InputMaybe<Scalars['Int']>;
  size: DeviceSize;
  token: Scalars['String'];
  x?: InputMaybe<Scalars['Int']>;
};

export type BarResponse = {
  __typename?: 'BarResponse';
  bar?: Maybe<Bar>;
  errors?: Maybe<Array<Error>>;
};

export type BarsFilter = {
  barMacAddress?: InputMaybe<Scalars['String']>;
  rootId?: InputMaybe<Scalars['Int']>;
};

export type BarsPagination = {
  __typename?: 'BarsPagination';
  nodes: Array<Maybe<Bar>>;
  totalCount: Scalars['Int'];
};

export type Bay = {
  __typename?: 'Bay';
  aisle?: Maybe<Aisle>;
  id: Scalars['Int'];
  /** bay key */
  key: Scalars['String'];
  /** @deprecated Use `key`. */
  name?: Maybe<Scalars['String']>;
  /** @deprecated Never used */
  orientation?: Maybe<Orientation>;
  root?: Maybe<Root>;
  /** shelves connected to a bay */
  shelves?: Maybe<Array<Shelf>>;
};

export type BayInput = {
  aisleId: Scalars['Int'];
  key: Scalars['String'];
};

export type BayResponse = {
  __typename?: 'BayResponse';
  bay?: Maybe<Bay>;
  errors?: Maybe<Array<Maybe<Error>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export enum BlinkSpeed {
  Fast = 'FAST',
  Slow = 'SLOW',
  Standard = 'STANDARD'
}

export enum BlinkTime {
  Long = 'LONG',
  Short = 'SHORT',
  Standard = 'STANDARD',
  VeryLong = 'VERY_LONG'
}

export enum ButtonActions {
  ExpressOrder = 'EXPRESS_ORDER'
}

export type Channel = {
  __typename?: 'Channel';
  channelItems?: Maybe<Array<ChannelItem>>;
  displays?: Maybe<Array<Display>>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  scales?: Maybe<Array<Maybe<Scale>>>;
  tags?: Maybe<Array<Scalars['String']>>;
  tvDevices?: Maybe<Array<Maybe<TvDevice>>>;
  type: SignageDeviceType;
};

export type ChannelItem = {
  __typename?: 'ChannelItem';
  display?: Maybe<Display>;
  duration?: Maybe<Scalars['Int']>;
  file?: Maybe<File>;
  id: Scalars['Int'];
  playlist?: Maybe<Playlist>;
  priority?: Maybe<Scalars['Boolean']>;
  schedule: Schedule;
  sequence: Scalars['Int'];
};

export type ChannelItemInput = {
  displayId?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['Int']>;
  fileId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  playlistId?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Boolean']>;
  schedule: ScheduleInput;
};

export type ChannelResponse = {
  __typename?: 'ChannelResponse';
  channel?: Maybe<Channel>;
  errors?: Maybe<Array<Error>>;
};

export type ChannelsFilter = {
  displayType?: InputMaybe<DisplayType>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SignageDeviceType>;
};

export type ChannelsPagination = {
  __typename?: 'ChannelsPagination';
  nodes?: Maybe<Array<Channel>>;
  totalCount: Scalars['Int'];
};

export type Component = {
  __typename?: 'Component';
  h?: Maybe<Scalars['Int']>;
  htmlContent?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  styleType?: Maybe<Scalars['String']>;
  type?: Maybe<ComponentType>;
  w?: Maybe<Scalars['Int']>;
  x?: Maybe<Scalars['Int']>;
  y?: Maybe<Scalars['Int']>;
};

export enum ComponentType {
  Barcode = 'BARCODE',
  Image = 'IMAGE',
  Itemlist = 'ITEMLIST',
  Price = 'PRICE',
  Text = 'TEXT'
}

export type ComponentVar = {
  __typename?: 'ComponentVar';
  id: Scalars['Int'];
  maxLength?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  templateKey?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceValueType>;
};

export type ComponentVarsFilter = {
  name?: InputMaybe<Scalars['String']>;
  templateKey?: InputMaybe<Scalars['String']>;
};

export type ComponentVarsPagination = {
  __typename?: 'ComponentVarsPagination';
  nodes?: Maybe<Array<ComponentVar>>;
  totalCount: Scalars['Int'];
};

export type ConfigTransaction = {
  __typename?: 'ConfigTransaction';
  deviceserialnumber?: Maybe<Scalars['String']>;
  receivedate?: Maybe<Scalars['String']>;
  senddate?: Maybe<Scalars['String']>;
  stage?: Maybe<TransactionStage>;
  transactionid?: Maybe<Scalars['String']>;
};

export type ConfigTransactionResponse = {
  __typename?: 'ConfigTransactionResponse';
  errors?: Maybe<Array<Error>>;
  transaction?: Maybe<Transaction>;
};

export enum Connection {
  /** RSSI < -90 */
  Bad = 'BAD',
  /** RSSI > -70 */
  Perfect = 'PERFECT',
  /** RSSI -70 <=> -90 */
  Standard = 'STANDARD'
}

export enum ContractLength {
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY'
}

export enum Country {
  Austria = 'AUSTRIA',
  Belgium = 'BELGIUM',
  Bulgaria = 'BULGARIA',
  Croatia = 'CROATIA',
  Cyprus = 'CYPRUS',
  CzechRepublic = 'CZECH_REPUBLIC',
  Denmark = 'DENMARK',
  Estonia = 'ESTONIA',
  Finland = 'FINLAND',
  France = 'FRANCE',
  Germany = 'GERMANY',
  Greece = 'GREECE',
  Hungary = 'HUNGARY',
  Iceland = 'ICELAND',
  Ireland = 'IRELAND',
  Italy = 'ITALY',
  Latvia = 'LATVIA',
  Liechtenstein = 'LIECHTENSTEIN',
  Lithuania = 'LITHUANIA',
  Luxembourg = 'LUXEMBOURG',
  Malta = 'MALTA',
  Netherlands = 'NETHERLANDS',
  Norway = 'NORWAY',
  Poland = 'POLAND',
  Portugal = 'PORTUGAL',
  Romania = 'ROMANIA',
  Slovakia = 'SLOVAKIA',
  Slovenia = 'SLOVENIA',
  Spain = 'SPAIN',
  Sweden = 'SWEDEN',
  Switzerland = 'SWITZERLAND',
  UnitedKingdom = 'UNITED_KINGDOM'
}

export type CustomerInfoResponse = {
  __typename?: 'CustomerInfoResponse';
  purchasedModules?: Maybe<Array<PurchasedModules>>;
};

export type DashSettings = {
  __typename?: 'DashSettings';
  customer: Scalars['String'];
  loginLogo?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

export type DeleteProductInput = {
  internalIds: Array<Scalars['String']>;
};

export type DeleteProductResponse = {
  __typename?: 'DeleteProductResponse';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteResponse = {
  __typename?: 'DeleteResponse';
  affected?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteShelfInput = {
  bayId?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['String']>;
  shelfId?: InputMaybe<Scalars['Int']>;
};

export type DeleteTemplateInput = {
  id: Scalars['Int'];
};

export type DeleteTemplateVariantInput = {
  id: Scalars['Int'];
};

export type DeleteTemplatesInput = {
  ids: Array<Scalars['Int']>;
};

export type DemoScreenResponse = {
  __typename?: 'DemoScreenResponse';
  filename?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type Device = {
  __typename?: 'Device';
  active?: Maybe<Scalars['Boolean']>;
  batteryCharging?: Maybe<Scalars['Boolean']>;
  batteryError?: Maybe<Scalars['Boolean']>;
  /** batteryLevel: 0-100% */
  batteryLevel?: Maybe<Scalars['Int']>;
  config?: Maybe<DeviceConfig>;
  configId?: Maybe<Scalars['Int']>;
  deviceMode?: Maybe<DeviceMode>;
  deviceType?: Maybe<DeviceType>;
  firmwareVersion?: Maybe<Scalars['String']>;
  group?: Maybe<Group>;
  groupId?: Maybe<Scalars['Int']>;
  /** @deprecated Use `batteryLevel` instead */
  health?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  lastConfigTransaction?: Maybe<Transaction>;
  lastSync?: Maybe<Scalars['String']>;
  lastTransaction?: Maybe<Transaction>;
  longPressAction?: Maybe<ButtonActions>;
  longPressEnabled: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Position>;
  positionId?: Maybe<Scalars['Int']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['Int']>;
  root?: Maybe<Root>;
  rootId?: Maybe<Scalars['Int']>;
  rotated?: Maybe<Scalars['Boolean']>;
  rssi?: Maybe<Scalars['Int']>;
  serialNumber: Scalars['String'];
  shelf?: Maybe<Shelf>;
  shelfId?: Maybe<Scalars['Int']>;
  shortPressAction?: Maybe<ButtonActions>;
  shortPressEnabled: Scalars['Boolean'];
  size?: Maybe<DeviceSize>;
  template?: Maybe<Template>;
  templateId?: Maybe<Scalars['Int']>;
  x?: Maybe<Scalars['Int']>;
  y?: Maybe<Scalars['Int']>;
  z?: Maybe<Scalars['Int']>;
};

export type DeviceConfig = {
  __typename?: 'DeviceConfig';
  device: Device;
  deviceId: Scalars['Int'];
  highPowerMode: Scalars['Boolean'];
  id: Scalars['Int'];
  sleepTime: Scalars['Int'];
};

export type DeviceConfigInput = {
  deviceId: Scalars['Int'];
  highPowerMode?: InputMaybe<Scalars['Boolean']>;
  sleepTime: Scalars['Int'];
};

export type DeviceConfigResponse = {
  __typename?: 'DeviceConfigResponse';
  deviceConfig?: Maybe<DeviceConfig>;
  errors?: Maybe<Array<Error>>;
};

export type DeviceConfiguration = {
  ledColor?: InputMaybe<Scalars['String']>;
  ledMode?: InputMaybe<Led_Mode>;
  rootId?: InputMaybe<Scalars['Int']>;
  templateVariantKey?: InputMaybe<Scalars['String']>;
};

export enum DeviceMode {
  Bluetooth = 'BLUETOOTH',
  Wifi = 'WIFI'
}

export type DevicePlanogramInput = {
  deletePositions: Array<Scalars['Int']>;
};

export type DevicePreviewResponse = {
  __typename?: 'DevicePreviewResponse';
  current?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
};

export type DeviceRegisterInput = {
  deviceMode?: InputMaybe<DeviceMode>;
  deviceType?: InputMaybe<DeviceType>;
  groupId?: InputMaybe<Scalars['Int']>;
  internalId?: InputMaybe<Scalars['String']>;
  longPressAction?: InputMaybe<ButtonActions>;
  longPressEnabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  positionId?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['Int']>;
  rootId: Scalars['Int'];
  rotated?: InputMaybe<Scalars['Boolean']>;
  screenType: Scalars['String'];
  serialNumber: Scalars['MAC'];
  shelfId?: InputMaybe<Scalars['Int']>;
  shortPressAction?: InputMaybe<ButtonActions>;
  shortPressEnabled?: InputMaybe<Scalars['Boolean']>;
  templateVariantId?: InputMaybe<Scalars['Int']>;
};

export type DeviceResponse = {
  __typename?: 'DeviceResponse';
  device?: Maybe<Device>;
  errors?: Maybe<Array<Error>>;
};

export enum DeviceSize {
  Epaper_152x152 = 'EPAPER_152x152',
  Epaper_200X200 = 'EPAPER_200X200',
  Epaper_296X128R = 'EPAPER_296X128_R',
  Epaper_296X128Y = 'EPAPER_296X128_Y',
  Epaper_400X300R = 'EPAPER_400X300_R',
  Epaper_400X300Y = 'EPAPER_400X300_Y',
  Epaper_800x480R = 'EPAPER_800x480_R',
  Epaper_800x480S6 = 'EPAPER_800x480_S6',
  EpaperNone = 'EPAPER_NONE',
  Lcd_1920X158 = 'LCD_1920X158',
  Lcd_1920X1080 = 'LCD_1920X1080',
  Lcd_2180X180 = 'LCD_2180X180'
}

export enum DeviceType {
  SmartLabel = 'SMART_LABEL',
  SmartScale = 'SMART_SCALE'
}

export type DeviceValueInput = {
  /** value name (only for display purposes) */
  name?: InputMaybe<Scalars['String']>;
  /** value can be assigned to a single root (e.g. the same product can have a different price in a specific root)) */
  rootId?: InputMaybe<Scalars['Int']>;
  /** placeholder replaced with value in template (e.g. {{PRICE}}) */
  templateKey: Scalars['String'];
  /** value type (formats value) */
  type: DeviceValueType;
  /** product value */
  value: Scalars['String'];
};

export enum DeviceValueType {
  Base64 = 'BASE64',
  Ean = 'EAN',
  Image = 'IMAGE',
  Number = 'NUMBER',
  Price = 'PRICE',
  Qr = 'QR',
  Text = 'TEXT'
}

export type DevicesFilter = {
  activated?: InputMaybe<Scalars['Boolean']>;
  active?: InputMaybe<Scalars['Boolean']>;
  /** search by device serialnumber or product name or product internal id */
  any?: InputMaybe<Scalars['String']>;
  connection?: InputMaybe<Connection>;
  deviceSerialNumber?: InputMaybe<Scalars['String']>;
  deviceType?: InputMaybe<DeviceType>;
  health?: InputMaybe<Health>;
  rootId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<TransactionStage>;
  templateVariantId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<DeviceSize>;
  virtual?: InputMaybe<Scalars['Boolean']>;
};

export type DevicesPagination = {
  __typename?: 'DevicesPagination';
  nodes?: Maybe<Array<Device>>;
  totalCount: Scalars['Int'];
};

export type Disable2FaResponse = {
  __typename?: 'Disable2FAResponse';
  delta?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean'];
};

export type Display = {
  __typename?: 'Display';
  height: Scalars['Int'];
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  type: DisplayType;
  width: Scalars['Int'];
};

export enum DisplayType {
  BizerbaKhiiPro_800 = 'BIZERBA_KHIIPro_800',
  DibalD900 = 'DIBAL_D900',
  DibalS500 = 'DIBAL_S500',
  DigiSm5300L = 'DIGI_SM5300L',
  DigiSm6000Ssp = 'DIGI_SM6000SSP',
  VirtualStore = 'VIRTUAL_STORE'
}

export type EditAisleInput = {
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  orientation?: InputMaybe<Orientation>;
};

export type EditBarInput = {
  devices?: InputMaybe<Array<EditDeviceInput>>;
  fileId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  shelfId?: InputMaybe<Scalars['Int']>;
  x?: InputMaybe<Scalars['Int']>;
};

export type EditBayInput = {
  key?: InputMaybe<Scalars['String']>;
};

export type EditChannelInput = {
  channelItems?: InputMaybe<Array<ChannelItemInput>>;
  name?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type EditDemoScreenInput = {
  fileId: Scalars['Int'];
};

export type EditDeviceConfigInput = {
  highPowerMode?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  sleepTime?: InputMaybe<Scalars['Int']>;
};

export type EditDeviceInput = {
  longPressAction?: InputMaybe<ButtonActions>;
  longPressEnabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  positionId?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['Int']>;
  rotated?: InputMaybe<Scalars['Boolean']>;
  shelfId?: InputMaybe<Scalars['Int']>;
  shortPressAction?: InputMaybe<ButtonActions>;
  shortPressEnabled?: InputMaybe<Scalars['Boolean']>;
  templateVariantId?: InputMaybe<Scalars['Int']>;
  x?: InputMaybe<Scalars['Int']>;
  y?: InputMaybe<Scalars['Int']>;
  z?: InputMaybe<Scalars['Int']>;
};

export type EditDeviceResponse = {
  __typename?: 'EditDeviceResponse';
  device?: Maybe<Device>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean'];
};

export type EditFileInput = {
  originalname?: InputMaybe<Scalars['String']>;
  parentFolderId?: InputMaybe<Scalars['Int']>;
};

export type EditFlowComponentInput = {
  type: FlowComponentType;
  value: Scalars['String'];
};

export type EditFlowInput = {
  flowStatements: Array<FlowStatementInput>;
  name?: InputMaybe<Scalars['String']>;
};

export type EditFlowRunnerInput = {
  activeDays?: InputMaybe<Array<InputMaybe<WeekDay>>>;
  flowId?: InputMaybe<Scalars['Int']>;
  linkedItems?: InputMaybe<Array<InputMaybe<AddFlowRunnerLink>>>;
  name?: InputMaybe<Scalars['String']>;
  timeFrom?: InputMaybe<Scalars['Time']>;
  timeTo?: InputMaybe<Scalars['Time']>;
  validFrom?: InputMaybe<Scalars['DateTime']>;
  validTo?: InputMaybe<Scalars['DateTime']>;
};

export type EditFlowRunnerLink = {
  linkedItemId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<FlowRunnerLink>;
};

export type EditFlowStatementInput = {
  flowComponents: Array<FlowComponentInput>;
  type: FlowStatementType;
};

export type EditGroupInput = {
  level?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  parentGroupId?: InputMaybe<Scalars['Int']>;
};

export type EditIntegrationInput = {
  cronUpdateUrl?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  syncTime?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  triggerUrl?: InputMaybe<Scalars['String']>;
  weekDays?: InputMaybe<Array<WeekDay>>;
};

export type EditOrderInput = {
  archive?: InputMaybe<Scalars['Boolean']>;
  costumerId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  mode?: InputMaybe<OrderMode>;
  orderItems?: InputMaybe<Array<AddOrderItemInput>>;
  orderNumber?: InputMaybe<Scalars['String']>;
  orderType?: InputMaybe<OrderType>;
  rootId?: InputMaybe<Scalars['Int']>;
  supplierId?: InputMaybe<Scalars['Int']>;
};

export type EditOrderItemInput = {
  deliveryDate?: InputMaybe<Scalars['DateTime']>;
  quantity?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<OrderStatus>;
};

export type EditOrderItemResponse = {
  __typename?: 'EditOrderItemResponse';
  archivedOrder?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type EditPlaylistInput = {
  name?: InputMaybe<Scalars['String']>;
  playlistItems?: InputMaybe<Array<PlaylistItemInput>>;
};

export type EditPositionInput = {
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
  templateVariantId?: InputMaybe<Scalars['Int']>;
  x?: InputMaybe<Scalars['Int']>;
  y?: InputMaybe<Scalars['Int']>;
  z?: InputMaybe<Scalars['Int']>;
};

export type EditPostComponent = {
  h: Scalars['Int'];
  htmlContent: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  styleType?: InputMaybe<Scalars['String']>;
  type: ComponentType;
  w: Scalars['Int'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type EditPostComponentVar = {
  defaultValue?: InputMaybe<Scalars['String']>;
  maxLength?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  templateKey: Scalars['String'];
  type?: InputMaybe<DeviceValueType>;
};

export type EditProductInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  deviceConfig?: InputMaybe<DeviceConfiguration>;
  eanCodes?: InputMaybe<Array<Scalars['String']>>;
  expirationDate?: InputMaybe<Scalars['Time']>;
  id: Scalars['Int'];
  internalId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parentProductId?: InputMaybe<Scalars['Int']>;
  productType?: InputMaybe<ProductType>;
  rootId?: InputMaybe<Scalars['Int']>;
  rootKeys?: InputMaybe<Array<Scalars['String']>>;
  supplierId?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  templateVariantId?: InputMaybe<Scalars['Int']>;
  valueEntities?: InputMaybe<Array<DeviceValueInput>>;
};

export type EditProductResponse = {
  __typename?: 'EditProductResponse';
  errors?: Maybe<Array<Error>>;
  isAssignedToShelves?: Maybe<Scalars['Boolean']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type EditRoleInput = {
  permissions?: InputMaybe<Array<Scalars['String']>>;
  rank?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

export type EditRootInput = {
  key?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
};

export type EditRouterInput = {
  dhcp?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  routerNetwork?: InputMaybe<RouterNetworkInput>;
};

export type EditScaleInput = {
  channelId?: InputMaybe<Scalars['Int']>;
  ip?: InputMaybe<Scalars['IPv4']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  username?: InputMaybe<Scalars['String']>;
};

export type EditScheduleInput = {
  dateRange?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  timeFrames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  weekdays?: InputMaybe<Array<WeekDay>>;
};

export type EditSensorInput = {
  name?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type EditShelfInput = {
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  orientation?: InputMaybe<Orientation>;
};

export type EditSupplier = {
  address?: InputMaybe<Scalars['String']>;
  addressNumber?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Country>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  postCode?: InputMaybe<Scalars['String']>;
  supplierId?: InputMaybe<Scalars['String']>;
  vat?: InputMaybe<Scalars['String']>;
};

export type EditSynchronizationInput = {
  id: Scalars['Int'];
  note?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<SynchronizationStatus>;
  time?: InputMaybe<Scalars['DateTime']>;
  updatedProducts?: InputMaybe<Array<Scalars['String']>>;
};

export type EditTemplateInput = {
  components?: InputMaybe<Array<EditPostComponent>>;
  height?: InputMaybe<Scalars['Int']>;
  isCustom?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  templateVariables?: InputMaybe<Array<EditPostComponentVar>>;
  templateVariantId?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type EditTemplateVariantInput = {
  isDefault?: InputMaybe<Scalars['Boolean']>;
  key: Scalars['String'];
};

export type EditTenantInput = {
  address?: InputMaybe<Scalars['String']>;
  addressNumber?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contractLength?: InputMaybe<ContractLength>;
  contractorName?: InputMaybe<Scalars['String']>;
  contractorsMail?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Country>;
  id: Scalars['Int'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  loginLogo?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  maxRoots?: InputMaybe<Scalars['Int']>;
  maxUsers?: InputMaybe<Scalars['Int']>;
  postCode?: InputMaybe<Scalars['String']>;
  primaryColor?: InputMaybe<Scalars['String']>;
  purchasedModules?: InputMaybe<Array<PurchasedModules>>;
  textColor?: InputMaybe<Scalars['String']>;
  vat?: InputMaybe<Scalars['String']>;
};

export type EditTvDeviceInput = {
  channelId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};

export type EditUserInput = {
  allowedIPs?: InputMaybe<Array<Scalars['IPv4']>>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  ledConfig?: InputMaybe<LedConfigInput>;
  password?: InputMaybe<Scalars['String']>;
  roleIDs?: InputMaybe<Array<Scalars['Int']>>;
  rootIds?: InputMaybe<Array<Scalars['Int']>>;
  username?: InputMaybe<Scalars['String']>;
};

export type EditVirtualCategoryPointInput = {
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  virtualRackIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type EditVirtualCategoryScreenInput = {
  backgroundFileId?: InputMaybe<Scalars['Int']>;
  catalogMode?: InputMaybe<Scalars['Boolean']>;
  channelId?: InputMaybe<Scalars['Int']>;
  fileId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  timeout?: InputMaybe<Scalars['Int']>;
  virtualCategoryPointPositions?: InputMaybe<Array<InputMaybe<AddVirtualCategoryPointPosition>>>;
};

export type EditVirtualMultiScreenInput = {
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  virtualMultiScreenPositions?: InputMaybe<Array<InputMaybe<AddVirtualMultiScreenPositionInput>>>;
};

export type EditVirtualRackInput = {
  fileId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  virtualShelfPositions: Array<InputMaybe<AddVirtualShelfPositionInput>>;
  virtualStoreDeviceIds: Array<InputMaybe<Scalars['Int']>>;
};

export type EditVirtualShelfInput = {
  fileId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  spacing?: InputMaybe<Scalars['Float']>;
  variant: VirtualShelfVariant;
  virtualProductPositions: Array<InputMaybe<AddVirtualProductPositionInput>>;
  virtualRackId?: InputMaybe<Scalars['Int']>;
};

export type EditVirtualShelfPositionInput = {
  virtualShelfId: Scalars['Int'];
};

export type EditVirtualStoreDeviceInput = {
  channelId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  ip?: InputMaybe<Scalars['IPv4']>;
  name?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  virtualCategoryPointId?: InputMaybe<Scalars['Int']>;
  virtualCategoryScreenId?: InputMaybe<Scalars['Int']>;
  virtualMultiScreenId?: InputMaybe<Scalars['Int']>;
};

export type Enable2FaResponse = {
  __typename?: 'Enable2FAResponse';
  delta?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean'];
};

export type Error = {
  __typename?: 'Error';
  message: Scalars['String'];
  path: Scalars['String'];
};

export type File = {
  __typename?: 'File';
  duration?: Maybe<Scalars['Int']>;
  encoding?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  isFolder: Scalars['Boolean'];
  mimetype?: Maybe<Scalars['String']>;
  originalname?: Maybe<Scalars['String']>;
  parentFolder?: Maybe<File>;
  thumbnail?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type FileFilter = {
  height?: InputMaybe<Scalars['Int']>;
  mimeTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  originalname?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type FileStructureFilter = {
  isFolder?: InputMaybe<Scalars['Boolean']>;
  originalname?: InputMaybe<Scalars['String']>;
  parentFolderId?: InputMaybe<Scalars['Int']>;
};

export type FilesResponsePagination = {
  __typename?: 'FilesResponsePagination';
  nodes: Array<File>;
  totalCount: Scalars['Int'];
};

export type Flow = {
  __typename?: 'Flow';
  flowStatements: Array<FlowStatement>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type FlowComponent = {
  __typename?: 'FlowComponent';
  id: Scalars['Int'];
  type: FlowComponentType;
  value: Scalars['String'];
};

export type FlowComponentInput = {
  type: FlowComponentType;
  value: Scalars['String'];
};

export enum FlowComponentType {
  ChangeTemplate = 'CHANGE_TEMPLATE',
  DataService = 'DATA_SERVICE',
  DecreaseBy = 'DECREASE_BY',
  Equals = 'EQUALS',
  Exist = 'EXIST',
  GreaterThan = 'GREATER_THAN',
  IncreaseBy = 'INCREASE_BY',
  LowerThan = 'LOWER_THAN',
  Sensor = 'SENSOR',
  ValueNumber = 'VALUE_NUMBER',
  ValueProcentage = 'VALUE_PROCENTAGE',
  Variable = 'VARIABLE'
}

export type FlowResponse = {
  __typename?: 'FlowResponse';
  errors?: Maybe<Array<Error>>;
  flow?: Maybe<Flow>;
};

export type FlowRunner = {
  __typename?: 'FlowRunner';
  flow: Flow;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<Product>>>;
  timeFrom?: Maybe<Scalars['Time']>;
  timeTo?: Maybe<Scalars['Time']>;
  validFrom?: Maybe<Scalars['DateTime']>;
  validTo?: Maybe<Scalars['DateTime']>;
};

export enum FlowRunnerLink {
  Group = 'GROUP',
  Product = 'PRODUCT',
  Root = 'ROOT',
  Tag = 'TAG'
}

export type FlowRunnerResponse = {
  __typename?: 'FlowRunnerResponse';
  errors?: Maybe<Array<Error>>;
  flowRunner?: Maybe<FlowRunner>;
};

export type FlowRunnersFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type FlowRunnersPaginations = {
  __typename?: 'FlowRunnersPaginations';
  nodes: Array<FlowRunner>;
  totalCount: Scalars['Int'];
};

export type FlowStatement = {
  __typename?: 'FlowStatement';
  flowComponents: Array<FlowComponent>;
  id: Scalars['Int'];
  type: FlowStatementType;
};

export type FlowStatementInput = {
  flowComponents: Array<FlowComponentInput>;
  type: FlowStatementType;
};

export enum FlowStatementType {
  If = 'IF',
  Then = 'THEN'
}

export type FlowsFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type FlowsPaginations = {
  __typename?: 'FlowsPaginations';
  nodes: Array<Flow>;
  totalCount: Scalars['Int'];
};

export type FolderInput = {
  name: Scalars['String'];
  parentFolderId?: InputMaybe<Scalars['Int']>;
};

export type Generate2FaSecretResponse = {
  __typename?: 'Generate2FASecretResponse';
  secret: Scalars['String'];
  uri: Scalars['String'];
};

export type Group = {
  __typename?: 'Group';
  id: Scalars['Int'];
  level?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  root?: Maybe<Root>;
  rootId?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['DateTime']>;
};

export type GroupsFilter = {
  name?: InputMaybe<Scalars['String']>;
  rootId?: InputMaybe<Scalars['Int']>;
};

export type GroupsPaginations = {
  __typename?: 'GroupsPaginations';
  nodes: Array<Group>;
  totalCount: Scalars['Int'];
};

export enum Health {
  Bat_20_0 = 'BAT_20_0',
  Bat_50_20 = 'BAT_50_20',
  Bat_75_50 = 'BAT_75_50',
  Bat_100_75 = 'BAT_100_75'
}

export type IncompleteTransactionsInput = {
  rootId?: InputMaybe<Scalars['Int']>;
};

export type InfoResponse = {
  __typename?: 'InfoResponse';
  apiErrCount?: Maybe<Scalars['Int']>;
  batteryUnderCount?: Maybe<Scalars['Int']>;
  deviceErrCount?: Maybe<Scalars['Int']>;
  deviceOffline?: Maybe<Scalars['Int']>;
  industrialScaleCount?: Maybe<Scalars['Int']>;
  openWarehouseOrderCount?: Maybe<Scalars['Int']>;
  processingErrCount?: Maybe<Scalars['Int']>;
  rootCount?: Maybe<Scalars['Int']>;
  routerCount?: Maybe<Scalars['Int']>;
  routerOffline?: Maybe<Scalars['Int']>;
  sensorLabelCount?: Maybe<Scalars['Int']>;
  shelfBarCount?: Maybe<Scalars['Int']>;
  signageDeviceCount?: Maybe<Scalars['Int']>;
  smartLabelCount?: Maybe<Scalars['Int']>;
  tenant: Scalars['String'];
  totalWarehouseOrderCount?: Maybe<Scalars['Int']>;
  warehouseProductCount?: Maybe<Scalars['Int']>;
};

export type Integration = {
  __typename?: 'Integration';
  cronUpdateUrl?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  syncTime: Scalars['String'];
  synchronizations?: Maybe<Array<Synchronization>>;
  token: Scalars['String'];
  triggerUrl?: Maybe<Scalars['String']>;
  weekDays: Array<WeekDay>;
};

export type IntegrationResponse = {
  __typename?: 'IntegrationResponse';
  errors?: Maybe<Array<Maybe<Error>>>;
  integration?: Maybe<Integration>;
};

export type IntegrationsFilter = {
  filter?: InputMaybe<Scalars['String']>;
};

export type IntegrationsPagination = {
  __typename?: 'IntegrationsPagination';
  nodes?: Maybe<Array<Integration>>;
  totalCount: Scalars['Int'];
};

export type IsleInput = {
  key: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  orientation?: InputMaybe<Orientation>;
  rootId: Scalars['Int'];
};

export type IslesFilter = {
  key?: InputMaybe<Scalars['String']>;
  rootId?: InputMaybe<Scalars['Int']>;
};

export type IslesPaginations = {
  __typename?: 'IslesPaginations';
  nodes: Array<Aisle>;
  totalCount: Scalars['Int'];
};

export type KeyValuePair = {
  __typename?: 'KeyValuePair';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export enum Led_Mode {
  Blink = 'BLINK'
}

export enum LedColor {
  Blue = 'BLUE',
  Cyan = 'CYAN',
  Green = 'GREEN',
  Magenta = 'MAGENTA',
  Red = 'RED',
  White = 'WHITE',
  Yellow = 'YELLOW'
}

export type LedConfig = {
  __typename?: 'LedConfig';
  blinkSpeed?: Maybe<BlinkSpeed>;
  blinkTime?: Maybe<BlinkTime>;
  color?: Maybe<LedColor>;
};

export type LedConfigInput = {
  blinkSpeed: BlinkSpeed;
  blinkTime: BlinkTime;
  color: LedColor;
};

export type LicenseCount = {
  __typename?: 'LicenseCount';
  count: Scalars['Int'];
  module: PurchasedModules;
};

export type LocationRestriction = {
  __typename?: 'LocationRestriction';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export enum LogType {
  Create = 'CREATE',
  Update = 'UPDATE'
}

export type LoginResponse = {
  __typename?: 'LoginResponse';
  delta?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Error>>;
  needOTP?: Maybe<Scalars['Boolean']>;
  scopes?: Maybe<Array<Scalars['String']>>;
  tokens?: Maybe<Scalars['JSON']>;
};

export enum Module_Type {
  Admin = 'ADMIN',
  Analytics = 'ANALYTICS',
  Bar = 'BAR',
  DevTools = 'DEV_TOOLS',
  Flow = 'FLOW',
  Integration = 'INTEGRATION',
  Management = 'MANAGEMENT',
  Orbis = 'ORBIS',
  Order = 'ORDER',
  Planogram = 'PLANOGRAM',
  Product = 'PRODUCT',
  ProductHistory = 'PRODUCT_HISTORY',
  Root = 'ROOT',
  Router = 'ROUTER',
  Signage = 'SIGNAGE',
  SmartLabel = 'SMART_LABEL',
  SmartSensor = 'SMART_SENSOR',
  Storage = 'STORAGE',
  Template = 'TEMPLATE',
  User = 'USER',
  VirtualStore = 'VIRTUAL_STORE',
  Warehouse = 'WAREHOUSE',
  WarehouseReport = 'WAREHOUSE_REPORT'
}

export type ManagementDashInfo = {
  __typename?: 'ManagementDashInfo';
  numOfLicenses?: Maybe<Array<LicenseCount>>;
  numOfTotalDevices?: Maybe<Scalars['Int']>;
  numOfTotalRoots?: Maybe<Scalars['Int']>;
  numOfTotalRouters?: Maybe<Scalars['Int']>;
  numOfTotalScales?: Maybe<Scalars['Int']>;
  numOfTotalSmartLabels?: Maybe<Scalars['Int']>;
  numOfTotalUsers?: Maybe<Scalars['Int']>;
};

export type MeResponse = {
  __typename?: 'MeResponse';
  allowedIPs?: Maybe<Array<Maybe<Scalars['IPv4']>>>;
  email?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  ledConfig?: Maybe<LedConfig>;
  locationRestrictions?: Maybe<Array<LocationRestriction>>;
  permissions?: Maybe<Array<Scalars['String']>>;
  roles?: Maybe<Array<Role>>;
  rootIDs?: Maybe<Array<Scalars['Int']>>;
};

export type Measurement = {
  __typename?: 'Measurement';
  _time?: Maybe<Scalars['DateTime']>;
  _value?: Maybe<Scalars['Float']>;
};

export enum MeasurementType {
  Humidity = 'HUMIDITY',
  Temperature = 'TEMPERATURE'
}

export enum ModeEnum {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL'
}

export type MultiUploadResponse = {
  __typename?: 'MultiUploadResponse';
  errors?: Maybe<Array<Error>>;
  file?: Maybe<File>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Activate a Smart Bar (internal) */
  activateBar: ActivateBarResponse;
  /** Activate a AS100 device */
  activateTVDevice: TvDeviceResponse;
  /** Add a new Aisle */
  addAisle: AisleResponse;
  /** Add a new Shelf */
  addBay: BayResponse;
  /** Add a new Channel */
  addChannel: ChannelResponse;
  /** Add device configuration */
  addDeviceConfig: DeviceConfigResponse;
  /** Add or edit products. If internal IDs exist, products will be edited. (can change device image) */
  addEditProducts: AddEditProductsResponse;
  /** Add flow */
  addFlow: StandardResponse;
  /** Add flow runner */
  addFlowRunner: StandardResponse;
  /** Add group */
  addGroup: StandardResponse;
  /** Add integration */
  addIntegration: AddIntegrationResponse;
  /** Add order */
  addOrder: AddOrderResponse;
  /** Add a new Playlist */
  addPlaylist: StandardResponse;
  /** Add a new Position */
  addPosition: PositionResponse;
  /** Add product */
  addProduct: AddProductResponse;
  /** Add a new user role */
  addRole: StandardResponse;
  /** Add root (main location) */
  addRoot: RootResponse;
  /** Add a new router */
  addRouter?: Maybe<Router>;
  /** Add a smart sensor */
  addSensor: SensorResponse;
  /** Add a new Shelf */
  addShelf: ShelfResponse;
  /** Add a new Shelf Structure (AAA-BB-SS) */
  addShelfStruct: ShelfStructResponse;
  /** Add a new supplier */
  addSupplier: AddSupplierResponse;
  /** Add synchronization */
  addSynchronization: AddSynchronizationResponse;
  /** Add a new template */
  addTemplate: StandardResponse;
  /** Add a new template variant */
  addTemplateVariant: TemplateVariantResponse;
  addTenant: AddTenantResponse;
  /** Add a new user */
  addUser: StandardResponse;
  /** Add a new Virtual Shelf */
  addVirtualCategoryPoint: VirtualCategoryPointResponse;
  /** Add a new Virtual Category Screen */
  addVirtualCategoryScreen: VirtualCategoryScreenResponse;
  /** Register a Smart Bar */
  addVirtualDevice: DeviceResponse;
  /** Add a new Virtual Multi Screen */
  addVirtualMultiScreen: VirtualMultiScreenResponse;
  /** Add a new Virtual Rack */
  addVirtualRack: VirtualRackResponse;
  /** Add a new Virtual Shelf */
  addVirtualShelf: VirtualShelfResponse;
  /** Add a Virtual Store Device */
  addVirtualStoreDevice: VirtualStoreDeviceResponse;
  /** Support for admin to disable user's 2FA */
  adminDisable2FA: StandardResponse;
  /** Reset a Smart Label */
  clearDevice: StandardResponse;
  /** create a new folder for asset management */
  createFolder: StandardResponse;
  /** Delete a Channel */
  deleteChannel: StandardResponse;
  /** Delete a Smart Label */
  deleteDevice: StandardResponse;
  /** delete  file */
  deleteFile: StandardResponse;
  /** Delete a Integration */
  deleteIntegration: StandardResponse;
  /** Delete a Position */
  deletePosition: StandardResponse;
  /** Delete a Position and untie device */
  deletePositionAndUntieDevice: StandardResponse;
  /** Delete product */
  deleteProduct: DeleteProductResponse;
  /** delete  product image */
  deleteProductImage: StandardResponse;
  /** delete all productImages for proudcts */
  deleteProductImages: StandardResponse;
  /** Delete all products with internal IDs */
  deleteProducts: DeleteResponse;
  /** Delete a Role */
  deleteRole: StandardResponse;
  /** Delete a Root */
  deleteRoot: StandardResponse;
  /** Delete a Router */
  deleteRouter: StandardResponse;
  /** Delete a Scale */
  deleteScale: StandardResponse;
  /** Delete a Position */
  deleteShelf: StandardResponse;
  /** Delete supplier */
  deleteSupplier: StandardResponse;
  /** Delete a TV Device */
  deleteTVDevice: StandardResponse;
  /** Delete template by ID */
  deleteTemplate: DeleteResponse;
  /** Delete templateVariant by ID */
  deleteTemplateVariant: DeleteResponse;
  /** Delete all templates by IDs */
  deleteTemplates: DeleteResponse;
  deleteTenant: StandardResponse;
  /** Delete a User */
  deleteUser: StandardResponse;
  /** Delete value entity */
  deleteValueEntities: StandardResponse;
  /** Delete a VirtualCategoryPoint */
  deleteVirtualCategoryPoint: StandardResponse;
  /** Delete a VirtualCategoryScreen */
  deleteVirtualCategoryScreen: StandardResponse;
  /** delete virtualCategoryScreen image */
  deleteVirtualCategoryScreenImage: StandardResponse;
  /** Delete VirtualMultiScreen */
  deleteVirtualMultiScreen: StandardResponse;
  /** Delete a VirtualRack */
  deleteVirtualRack: StandardResponse;
  /** Delete a VirtualShelf */
  deleteVirtualShelf: StandardResponse;
  /** Delete a VirtualStoreDevice */
  deleteVirtualStoreDevice: StandardResponse;
  /** Disable 2FA */
  disable2FA: Disable2FaResponse;
  /** Edit Aisle */
  editAisle: StandardResponse;
  /** Edit a Bar (can change bar images) */
  editBar: StandardResponse;
  /** Edit Bay */
  editBay: StandardResponse;
  /** Edit a Channel */
  editChannel: StandardResponse;
  /** Edit demo screen */
  editDemoScreen: DemoScreenResponse;
  /** Edit a device (can change device image) */
  editDevice: EditDeviceResponse;
  /** Edit a device config */
  editDeviceConfig: StandardResponse;
  editFile: StandardResponse;
  /** Edit flow */
  editFlow: StandardResponse;
  /** Edit flow runner */
  editFlowRunner: StandardResponse;
  /** Edit group */
  editGroup: StandardResponse;
  /** Edit integration */
  editIntegration: StandardResponse;
  /** Edit Order */
  editOrder: StandardResponse;
  /** Edit Order Item */
  editOrderItem: EditOrderItemResponse;
  /** Edit a Playlist */
  editPlaylist: StandardResponse;
  /** Edit position (can change device image) */
  editPosition: StandardResponse;
  /** Edit product */
  editProduct: EditProductResponse;
  /** Edit a user role */
  editRole: StandardResponse;
  /** Edit root */
  editRoot: StandardResponse;
  /** Edit a router */
  editRouter: StandardResponse;
  /** Edit a scale */
  editScale: StandardResponse;
  /** Edit a sensor */
  editSensor: StandardResponse;
  /** Edit shelf */
  editShelf: StandardResponse;
  /** Edit an existing supplier */
  editSupplier: StandardResponse;
  /** Edit synchronization */
  editSynchronization: StandardResponse;
  /** Edit a template */
  editTemplate: StandardResponse;
  /** Edit a template variant */
  editTemplateVariant: StandardResponse;
  editTenant: StandardResponse;
  /** Edit a TV Device */
  editTvDevice: StandardResponse;
  /** Edit a user */
  editUser: StandardResponse;
  /** Edit a Virtual Category */
  editVirtualCategoryPoint: StandardResponse;
  /** Edit a Virtual Category Screen */
  editVirtualCategoryScreen: StandardResponse;
  /** Edit a Virtual Multi Screen */
  editVirtualMultiScreen: StandardResponse;
  /** Edit a Virtual Rack */
  editVirtualRack: StandardResponse;
  /** Edit a Virtual Shelf */
  editVirtualShelf: StandardResponse;
  /** Edit a Virtual Store Device */
  editVirtualStoreDevice: StandardResponse;
  /** Enable 2FA */
  enable2FA: Enable2FaResponse;
  /** Force Reset a Smart Label */
  forceResetDevice: StandardResponse;
  /** Generate secret for 2FA */
  generate2FASecret: Generate2FaSecretResponse;
  login: LoginResponse;
  logout?: Maybe<Scalars['Boolean']>;
  /** upload multiple files for use in template editor */
  multiUpload?: Maybe<Array<MultiUploadResponse>>;
  /** upload multiple files for use in template editor */
  multiUploadDibal?: Maybe<Array<File>>;
  /** Refresh all Virtual Store Devices */
  refreshVirtualStoreDevices: StandardResponse;
  /** Register a Smart Bar */
  registerBar: BarResponse;
  /** Register a Smart Label */
  registerDevice: DeviceResponse;
  /** Register a router with token */
  registerRouter?: Maybe<RegisterRouterResponse>;
  /** Register a Scale */
  registerScale: ScaleResponse;
  /** Set template to all devices containing a product */
  setProductTemplate: StandardResponse;
  /** Trigger device LED */
  triggerDeviceLed: StandardResponse;
  /** Trigger synchronization */
  triggerSync: StandardResponse;
  /** upload product image */
  uploadProductImage?: Maybe<ProductImage>;
  /** upload virtual store screen image */
  uploadVirtualStoreScreen?: Maybe<File>;
  /** Verify 2FA */
  verify2FA: StandardResponse;
};


export type MutationActivateBarArgs = {
  macAddress?: InputMaybe<Scalars['MAC']>;
  token: Scalars['String'];
};


export type MutationActivateTvDeviceArgs = {
  input: TvDeviceActivateInput;
  token?: InputMaybe<Scalars['String']>;
};


export type MutationAddAisleArgs = {
  input: IsleInput;
};


export type MutationAddBayArgs = {
  input: BayInput;
};


export type MutationAddChannelArgs = {
  input: AddChannelInput;
};


export type MutationAddDeviceConfigArgs = {
  input: DeviceConfigInput;
};


export type MutationAddEditProductsArgs = {
  input: Array<ProductInput>;
  overrideExisting?: InputMaybe<Scalars['Boolean']>;
};


export type MutationAddFlowArgs = {
  input: AddFlowInput;
};


export type MutationAddFlowRunnerArgs = {
  input: AddFlowRunnerInput;
};


export type MutationAddGroupArgs = {
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['Int']>;
  rootId: Scalars['Int'];
};


export type MutationAddIntegrationArgs = {
  input: AddIntegrationInput;
};


export type MutationAddOrderArgs = {
  input: AddOrderInput;
};


export type MutationAddPlaylistArgs = {
  input: AddPlaylistInput;
};


export type MutationAddPositionArgs = {
  input: PositionInput;
};


export type MutationAddProductArgs = {
  input: ProductInput;
};


export type MutationAddRoleArgs = {
  input: AddRoleInput;
};


export type MutationAddRootArgs = {
  input: AddRootInput;
};


export type MutationAddRouterArgs = {
  input: AddRouterInput;
};


export type MutationAddSensorArgs = {
  input: AddSensorInput;
};


export type MutationAddShelfArgs = {
  input: ShelfInput;
};


export type MutationAddShelfStructArgs = {
  input: ShelfStructInput;
};


export type MutationAddSupplierArgs = {
  input: AddSupplier;
};


export type MutationAddSynchronizationArgs = {
  input: AddSynchronizationInput;
};


export type MutationAddTemplateArgs = {
  input: TemplateInput;
};


export type MutationAddTemplateVariantArgs = {
  input: TemplateVariantInput;
};


export type MutationAddTenantArgs = {
  input: AddTenantInput;
};


export type MutationAddUserArgs = {
  input: AddUserInput;
};


export type MutationAddVirtualCategoryPointArgs = {
  input: AddVirtualCategoryPointInput;
};


export type MutationAddVirtualCategoryScreenArgs = {
  input: AddVirtualCategoryScreenInput;
};


export type MutationAddVirtualDeviceArgs = {
  input: VirtualDeviceInput;
};


export type MutationAddVirtualMultiScreenArgs = {
  input: AddVirtualMultiScreenInput;
};


export type MutationAddVirtualRackArgs = {
  input: AddVirtualRackInput;
};


export type MutationAddVirtualShelfArgs = {
  input: AddVirtualShelfInput;
};


export type MutationAddVirtualStoreDeviceArgs = {
  input: AddVirtualStoreDeviceInput;
};


export type MutationAdminDisable2FaArgs = {
  userId: Scalars['Int'];
};


export type MutationClearDeviceArgs = {
  deviceSerialNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateFolderArgs = {
  input: FolderInput;
};


export type MutationDeleteChannelArgs = {
  channelId: Scalars['Int'];
};


export type MutationDeleteDeviceArgs = {
  deviceSerialNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationDeleteFileArgs = {
  fileId: Scalars['Int'];
};


export type MutationDeleteIntegrationArgs = {
  id: Scalars['Int'];
};


export type MutationDeletePositionArgs = {
  positionId: Scalars['Int'];
};


export type MutationDeletePositionAndUntieDeviceArgs = {
  positionId: Scalars['Int'];
};


export type MutationDeleteProductArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteProductImageArgs = {
  productImageId: Scalars['Int'];
};


export type MutationDeleteProductImagesArgs = {
  internalIds: Array<Scalars['String']>;
};


export type MutationDeleteProductsArgs = {
  input: DeleteProductInput;
};


export type MutationDeleteRoleArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteRootArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteRouterArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteScaleArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteShelfArgs = {
  input: DeleteShelfInput;
};


export type MutationDeleteSupplierArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTvDeviceArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTemplateArgs = {
  input: DeleteTemplateInput;
};


export type MutationDeleteTemplateVariantArgs = {
  input: DeleteTemplateVariantInput;
};


export type MutationDeleteTemplatesArgs = {
  input: DeleteTemplatesInput;
};


export type MutationDeleteTenantArgs = {
  code: Scalars['String'];
  name: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteValueEntitiesArgs = {
  ids?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationDeleteVirtualCategoryPointArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteVirtualCategoryScreenArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteVirtualCategoryScreenImageArgs = {
  categoryId: Scalars['Int'];
};


export type MutationDeleteVirtualMultiScreenArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteVirtualRackArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteVirtualShelfArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteVirtualStoreDeviceArgs = {
  id: Scalars['Int'];
};


export type MutationDisable2FaArgs = {
  code: Scalars['String'];
};


export type MutationEditAisleArgs = {
  aisleId: Scalars['Int'];
  input: EditAisleInput;
};


export type MutationEditBarArgs = {
  barId: Scalars['Int'];
  input: EditBarInput;
};


export type MutationEditBayArgs = {
  bayId: Scalars['Int'];
  input: EditBayInput;
};


export type MutationEditChannelArgs = {
  channelId: Scalars['Int'];
  input: EditChannelInput;
};


export type MutationEditDemoScreenArgs = {
  input: EditDemoScreenInput;
};


export type MutationEditDeviceArgs = {
  deviceId: Scalars['Int'];
  input: EditDeviceInput;
};


export type MutationEditDeviceConfigArgs = {
  input: EditDeviceConfigInput;
};


export type MutationEditFileArgs = {
  fileId: Scalars['Int'];
  input: EditFileInput;
};


export type MutationEditFlowArgs = {
  flowId: Scalars['Int'];
  input: EditFlowInput;
};


export type MutationEditFlowRunnerArgs = {
  flowRunnerId: Scalars['Int'];
  input: EditFlowRunnerInput;
};


export type MutationEditGroupArgs = {
  groupId: Scalars['Int'];
  input: EditGroupInput;
};


export type MutationEditIntegrationArgs = {
  input: EditIntegrationInput;
};


export type MutationEditOrderArgs = {
  input: EditOrderInput;
};


export type MutationEditOrderItemArgs = {
  id: Scalars['Int'];
  input: EditOrderItemInput;
};


export type MutationEditPlaylistArgs = {
  input: EditPlaylistInput;
  playlistId: Scalars['Int'];
};


export type MutationEditPositionArgs = {
  input: EditPositionInput;
  positionId: Scalars['Int'];
};


export type MutationEditProductArgs = {
  input: EditProductInput;
};


export type MutationEditRoleArgs = {
  input: EditRoleInput;
  roleId: Scalars['Int'];
};


export type MutationEditRootArgs = {
  input: EditRootInput;
  rootId: Scalars['Int'];
};


export type MutationEditRouterArgs = {
  input: EditRouterInput;
  routerId: Scalars['Int'];
};


export type MutationEditScaleArgs = {
  input: EditScaleInput;
  scaleId: Scalars['Int'];
};


export type MutationEditSensorArgs = {
  input: EditSensorInput;
  sensorId: Scalars['Int'];
};


export type MutationEditShelfArgs = {
  input: EditShelfInput;
  shelfId: Scalars['Int'];
};


export type MutationEditSupplierArgs = {
  input: EditSupplier;
};


export type MutationEditSynchronizationArgs = {
  input: EditSynchronizationInput;
};


export type MutationEditTemplateArgs = {
  input: EditTemplateInput;
  templateId: Scalars['Int'];
};


export type MutationEditTemplateVariantArgs = {
  input: EditTemplateVariantInput;
  templateVariantId: Scalars['Int'];
};


export type MutationEditTenantArgs = {
  input: EditTenantInput;
};


export type MutationEditTvDeviceArgs = {
  input: EditTvDeviceInput;
  tvDeviceId: Scalars['Int'];
};


export type MutationEditUserArgs = {
  input: EditUserInput;
  userId: Scalars['Int'];
};


export type MutationEditVirtualCategoryPointArgs = {
  input: EditVirtualCategoryPointInput;
};


export type MutationEditVirtualCategoryScreenArgs = {
  input: EditVirtualCategoryScreenInput;
};


export type MutationEditVirtualMultiScreenArgs = {
  input: EditVirtualMultiScreenInput;
};


export type MutationEditVirtualRackArgs = {
  input: EditVirtualRackInput;
};


export type MutationEditVirtualShelfArgs = {
  input: EditVirtualShelfInput;
};


export type MutationEditVirtualStoreDeviceArgs = {
  input: EditVirtualStoreDeviceInput;
};


export type MutationEnable2FaArgs = {
  code: Scalars['String'];
};


export type MutationForceResetDeviceArgs = {
  id: Scalars['Int'];
};


export type MutationLoginArgs = {
  otp?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationMultiUploadArgs = {
  files: Array<Scalars['Upload']>;
  parentFolderId?: InputMaybe<Scalars['Int']>;
  productAssign?: InputMaybe<Scalars['Boolean']>;
};


export type MutationMultiUploadDibalArgs = {
  files: Array<Scalars['Upload']>;
  parentFolderId?: InputMaybe<Scalars['Int']>;
};


export type MutationRegisterBarArgs = {
  input: BarRegisterInput;
};


export type MutationRegisterDeviceArgs = {
  input: DeviceRegisterInput;
};


export type MutationRegisterRouterArgs = {
  token: Scalars['String'];
  uuid: Scalars['String'];
};


export type MutationRegisterScaleArgs = {
  input: ScaleRegisterInput;
};


export type MutationSetProductTemplateArgs = {
  input: SetProductTemplateInput;
};


export type MutationTriggerDeviceLedArgs = {
  input: TriggerDeviceLedInput;
};


export type MutationTriggerSyncArgs = {
  input: TriggerSyncInput;
};


export type MutationUploadProductImageArgs = {
  file: Scalars['Upload'];
  internalId: Scalars['String'];
  primary?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUploadVirtualStoreScreenArgs = {
  file: Scalars['Upload'];
  virtualCategoryScreenId: Scalars['Int'];
};


export type MutationVerify2FaArgs = {
  code: Scalars['String'];
  secret: Scalars['String'];
};

export type Option = {
  __typename?: 'Option';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Order = {
  __typename?: 'Order';
  costumerId?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  mode?: Maybe<OrderMode>;
  orderItems?: Maybe<Array<OrderItem>>;
  orderNumber?: Maybe<Scalars['String']>;
  orderType?: Maybe<OrderType>;
  rootId?: Maybe<Scalars['Int']>;
  supplier?: Maybe<Supplier>;
  supplierId?: Maybe<Scalars['Int']>;
};

export type OrderFilter = {
  any?: InputMaybe<Scalars['String']>;
  archive?: InputMaybe<Scalars['Boolean']>;
  costumerId?: InputMaybe<Scalars['Int']>;
  orderMode?: InputMaybe<OrderMode>;
  orderType?: InputMaybe<OrderType>;
  productId?: InputMaybe<Scalars['Int']>;
  rootId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<OrderStatus>;
  supplierId?: InputMaybe<Scalars['Int']>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  deliveryDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  internalId?: Maybe<Scalars['String']>;
  partnerId?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['Int']>;
  productName?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  status?: Maybe<OrderStatus>;
};

export type OrderItemLog = {
  __typename?: 'OrderItemLog';
  deliveryDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  orderItem?: Maybe<OrderItem>;
  orderItemId?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  status?: Maybe<OrderStatus>;
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<LogType>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
};

export type OrderItemLogsFilter = {
  orderItemId?: InputMaybe<Scalars['Int']>;
};

export type OrderItemLogsPagination = {
  __typename?: 'OrderItemLogsPagination';
  nodes: Array<Maybe<OrderItemLog>>;
  totalCount: Scalars['Int'];
};

export enum OrderMode {
  Automatic = 'AUTOMATIC',
  Express = 'EXPRESS',
  Manual = 'MANUAL'
}

export type OrderResponse = {
  __typename?: 'OrderResponse';
  errors?: Maybe<Array<Error>>;
  order?: Maybe<Order>;
};

export enum OrderStatus {
  Delivered = 'DELIVERED',
  Failed = 'FAILED',
  PartiallyDelivered = 'PARTIALLY_DELIVERED',
  Pending = 'PENDING',
  Processing = 'PROCESSING'
}

export enum OrderType {
  VirtualStore = 'VIRTUAL_STORE',
  Warehouse = 'WAREHOUSE'
}

export type OrdersPagination = {
  __typename?: 'OrdersPagination';
  nodes: Array<Maybe<Order>>;
  totalCount: Scalars['Int'];
};

export enum Orientation {
  Bottomleft = 'BOTTOMLEFT',
  Bottoright = 'BOTTORIGHT',
  Topleft = 'TOPLEFT',
  Topright = 'TOPRIGHT'
}

export type ParseTemplateResponse = {
  __typename?: 'ParseTemplateResponse';
  errors?: Maybe<Array<Error>>;
  template?: Maybe<Scalars['String']>;
};

export type Playlist = {
  __typename?: 'Playlist';
  id: Scalars['Int'];
  name: Scalars['String'];
  playlistItems: Array<PlaylistItem>;
};

export type PlaylistItem = {
  __typename?: 'PlaylistItem';
  duration: Scalars['Int'];
  file?: Maybe<File>;
  id: Scalars['Int'];
  playlist: Playlist;
  sequence?: Maybe<Scalars['Int']>;
};

export type PlaylistItemInput = {
  duration: Scalars['Int'];
  fileId: Scalars['Int'];
  sequence?: InputMaybe<Scalars['Int']>;
};

export type PlaylistResponse = {
  __typename?: 'PlaylistResponse';
  errors?: Maybe<Array<Error>>;
  playlist?: Maybe<Playlist>;
};

export type PlaylistsFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type PlaylistsPagination = {
  __typename?: 'PlaylistsPagination';
  nodes?: Maybe<Array<Playlist>>;
  totalCount: Scalars['Int'];
};

export type Position = {
  __typename?: 'Position';
  device?: Maybe<Device>;
  id: Scalars['Int'];
  /** @deprecated Never used */
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** @deprecated Never used */
  orientation?: Maybe<Orientation>;
  product?: Maybe<Product>;
  root?: Maybe<Root>;
  /** sequenca number on shelf (starts with 1) */
  sequence?: Maybe<Scalars['Int']>;
  /** conncted shelf */
  shelf?: Maybe<Shelf>;
  templateVariant?: Maybe<TemplateVariant>;
  /** offset for shelf edge */
  x?: Maybe<Scalars['Int']>;
  y?: Maybe<Scalars['Int']>;
  z?: Maybe<Scalars['Int']>;
};

export type PositionInput = {
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['Int']>;
  productInternalId?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  shelfId: Scalars['Int'];
  templateVariantId?: InputMaybe<Scalars['Int']>;
  x?: InputMaybe<Scalars['Int']>;
  y?: InputMaybe<Scalars['Int']>;
  z?: InputMaybe<Scalars['Int']>;
};

export type PositionResponse = {
  __typename?: 'PositionResponse';
  errors?: Maybe<Array<Maybe<Error>>>;
  position?: Maybe<Position>;
  success?: Maybe<Scalars['Boolean']>;
};

export type PositionStructInput = {
  name?: InputMaybe<Scalars['String']>;
  productInternalId?: InputMaybe<Scalars['String']>;
  templateVariantKey?: InputMaybe<Scalars['String']>;
  x: Scalars['Int'];
  y?: InputMaybe<Scalars['Int']>;
  z?: InputMaybe<Scalars['Int']>;
};

export type PositionsFilter = {
  aisleId?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['String']>;
  rootId?: InputMaybe<Scalars['Int']>;
  shelfId?: InputMaybe<Scalars['Int']>;
};

export type PositionsPaginations = {
  __typename?: 'PositionsPaginations';
  nodes: Array<Position>;
  totalCount: Scalars['Int'];
};

export type PostComponent = {
  h: Scalars['Int'];
  htmlContent: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  styleType?: InputMaybe<Scalars['String']>;
  type: ComponentType;
  w: Scalars['Int'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type PostComponentVar = {
  defaultValue?: InputMaybe<Scalars['String']>;
  maxLength?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  templateKey: Scalars['String'];
  type: DeviceValueType;
};

export type Product = {
  __typename?: 'Product';
  /** active product */
  active?: Maybe<Scalars['Boolean']>;
  /** devices assigned to a specific product (root, group, template) */
  devices?: Maybe<Array<Device>>;
  /** product can have multiple unique ean codes */
  eanCodes?: Maybe<Array<Scalars['String']>>;
  /** expiration date */
  expirationDate?: Maybe<Scalars['Time']>;
  id: Scalars['Int'];
  /** internal product identification (e.g. EAN code) */
  internalId: Scalars['String'];
  /** product name (only for display purposes) */
  name?: Maybe<Scalars['String']>;
  /** only variations have parent products */
  parentProduct?: Maybe<Product>;
  positions?: Maybe<Array<Maybe<Position>>>;
  productImages?: Maybe<Array<Maybe<ProductImage>>>;
  /** product type */
  productType?: Maybe<ProductType>;
  /** product variations */
  products?: Maybe<Array<Product>>;
  quantityHistory?: Maybe<Array<QuantityHistory>>;
  root?: Maybe<Root>;
  /** root id */
  rootId?: Maybe<Scalars['Int']>;
  /** limit product to specific roots */
  rootKeys?: Maybe<Array<Scalars['String']>>;
  supplier?: Maybe<Supplier>;
  /** supplier Id */
  supplierId?: Maybe<Scalars['Int']>;
  /** define/sort products with specific tags */
  tags?: Maybe<Array<Scalars['String']>>;
  /** product values (e.g. PRICE, EAN CODE, TITLE, ...) */
  valueEntities?: Maybe<Array<Value>>;
};


export type ProductDevicesArgs = {
  where?: InputMaybe<ProductDevicesFilter>;
};


export type ProductProductsArgs = {
  alarmType?: InputMaybe<AlarmType>;
  rootId?: InputMaybe<Scalars['Int']>;
  supplierId?: InputMaybe<Scalars['Int']>;
};


export type ProductValueEntitiesArgs = {
  rootId?: InputMaybe<Scalars['Int']>;
};

export type ProductDevicesFilter = {
  groupId?: InputMaybe<Scalars['Int']>;
  rootId?: InputMaybe<Scalars['Int']>;
  templateId?: InputMaybe<Scalars['Int']>;
};

export type ProductImage = {
  __typename?: 'ProductImage';
  file: File;
  id: Scalars['Int'];
  primary: Scalars['Boolean'];
};

export type ProductInput = {
  /** active */
  active?: InputMaybe<Scalars['Boolean']>;
  /** apply multiple device configurations - only for devices connected to this product */
  deviceConfig?: InputMaybe<DeviceConfiguration>;
  /** one product can have multiple unique ean codes */
  eanCodes?: InputMaybe<Array<Scalars['String']>>;
  /** expiration date */
  expirationDate?: InputMaybe<Scalars['Time']>;
  /** internal product identification (e.g. EAN code) */
  internalId: Scalars['String'];
  /** product name (only for display purposes) */
  name?: InputMaybe<Scalars['String']>;
  /** parent product internal id */
  parentInternalId?: InputMaybe<Scalars['String']>;
  /** by setting a parent product you're turning this in to a product variation */
  parentProductId?: InputMaybe<Scalars['Int']>;
  /** product type */
  productType?: InputMaybe<ProductType>;
  /** root id */
  rootId?: InputMaybe<Scalars['Int']>;
  /** limit product to specific roots */
  rootKeys?: InputMaybe<Array<Scalars['String']>>;
  /** supplier id */
  supplierId?: InputMaybe<Scalars['Int']>;
  /** define/sort products with specific tags */
  tags?: InputMaybe<Array<Scalars['String']>>;
  templateVariantId?: InputMaybe<Scalars['Int']>;
  /** product values (e.g. PRICE, EAN CODE, TITLE, ...) */
  valueEntities?: InputMaybe<Array<DeviceValueInput>>;
};

export type ProductLog = {
  __typename?: 'ProductLog';
  columnName: Scalars['String'];
  created: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  newValue: Scalars['String'];
  oldValue: Scalars['String'];
  position?: Maybe<Scalars['String']>;
  root?: Maybe<Root>;
  user?: Maybe<User>;
};

export type ProductLogFilter = {
  productId: Scalars['Int'];
};

export type ProductLogsPaginations = {
  __typename?: 'ProductLogsPaginations';
  nodes: Array<Maybe<ProductLog>>;
  totalCount: Scalars['Int'];
};

export type ProductResponse = {
  __typename?: 'ProductResponse';
  errors?: Maybe<Array<Error>>;
  product?: Maybe<Product>;
};

export enum ProductType {
  VirtualStore = 'VIRTUAL_STORE',
  Warehouse = 'WAREHOUSE'
}

export type ProductsFilter = {
  /** limit by active products */
  active?: InputMaybe<Scalars['Boolean']>;
  /** alarm type */
  alarmType?: InputMaybe<AlarmType>;
  /** limit search for products containing string in name, ean code or internal id */
  any?: InputMaybe<Scalars['String']>;
  /** internal product identification (e.g. EAN code) */
  internalId?: InputMaybe<Scalars['String']>;
  /** display variations of a product */
  parentProductId?: InputMaybe<Scalars['Int']>;
  /** limit search by product type */
  productType?: InputMaybe<ProductType>;
  /** limit search by rootId */
  rootId?: InputMaybe<Scalars['Int']>;
  /** limit search to a specific root */
  rootKey?: InputMaybe<Scalars['String']>;
  /** limit search by supplierId */
  supplierId?: InputMaybe<Scalars['Int']>;
  /** limit search for products containing specific tags */
  tags?: InputMaybe<Array<Scalars['String']>>;
  /** value entities filter */
  valueEntities?: InputMaybe<Array<ValueEntitiesFilter>>;
  /** display only products with children */
  withChild?: InputMaybe<Scalars['Boolean']>;
  /** products with parent */
  withParent?: InputMaybe<Scalars['Boolean']>;
  /** display only products not product variations */
  withoutChild?: InputMaybe<Scalars['Boolean']>;
  /** products without parent */
  withoutParent?: InputMaybe<Scalars['Boolean']>;
};

export type ProductsPaginations = {
  __typename?: 'ProductsPaginations';
  nodes: Array<Product>;
  totalCount: Scalars['Int'];
};

export enum PurchasedModules {
  Basic = 'BASIC',
  Orbis = 'ORBIS',
  Signage = 'SIGNAGE',
  VirtualStore = 'VIRTUAL_STORE',
  Warehouse = 'WAREHOUSE'
}

export type QuantityHistory = {
  __typename?: 'QuantityHistory';
  created: Scalars['DateTime'];
  id: Scalars['Int'];
  mode: ModeEnum;
  productId: Scalars['Int'];
  quantity?: Maybe<Scalars['Int']>;
  quantityChange?: Maybe<Scalars['Int']>;
};

export type QuantityHistoryFilter = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  mode?: InputMaybe<ModeEnum>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<Scalars['Int']>;
};

export type QuantityHistoryResponse = {
  __typename?: 'QuantityHistoryResponse';
  nodes: Array<Maybe<QuantityHistory>>;
  totalCount: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  /** Get aisle by id */
  aisle: AisleResponse;
  /** Get all aisles */
  aisles: IslesPaginations;
  /** Find Bar by token or id */
  bar: BarResponse;
  /** Get all bars */
  bars: BarsPagination;
  /** Get all transactions for a batch */
  batchTransactions?: Maybe<TransactionResponse>;
  /** Get bay by id */
  bay: BayResponse;
  /** Get all bays */
  bays: BaysPaginations;
  /** Get a Channel */
  channel: ChannelResponse;
  /** Get all Channels */
  channels: ChannelsPagination;
  checkAuth: Scalars['Boolean'];
  /** Get all used variables */
  componentVars: ComponentVarsPagination;
  /** Get customer's purchased modules */
  customerInfo: CustomerInfoResponse;
  /** Find device by exact MAC address */
  dashSettings?: Maybe<DashSettings>;
  /** Find device by exact MAC address */
  device: DeviceResponse;
  /** Get all config transactions for a specific device */
  deviceConfigTransactions?: Maybe<ConfigTransactionResponse>;
  /** Get device current image in base64 */
  devicePreview: DevicePreviewResponse;
  /** Get all transactions for a specific device */
  deviceTransactions?: Maybe<TransactionResponse>;
  /** Get all devices */
  deviceTypes: Array<DeviceSize>;
  /** Get all devices */
  devices: DevicesPagination;
  files?: Maybe<FilesResponsePagination>;
  /** Get a single flow */
  flow: FlowResponse;
  /** Get a single flow runner */
  flowRunner: FlowRunnerResponse;
  /** Get all flow runners */
  flowRunners: FlowRunnersPaginations;
  /** Get all flows */
  flows: FlowsPaginations;
  getAsset?: Maybe<AssetDownload>;
  getFileStructure?: Maybe<Array<File>>;
  /** Get all groups */
  groups: GroupsPaginations;
  /** Get all incomplete/unfinshed transactions */
  incompleteTransactions?: Maybe<TransactionResponse>;
  info?: Maybe<InfoResponse>;
  /** Get Integration by id or token */
  integration: IntegrationResponse;
  /** Get Integrations */
  integrations: IntegrationsPagination;
  managementDashInfo?: Maybe<ManagementDashInfo>;
  me?: Maybe<MeResponse>;
  /** Get order */
  order: OrderResponse;
  /** Get Orders */
  orderItemLogs: OrderItemLogsPagination;
  /** Get Orders */
  orders: OrdersPagination;
  parseTemplate: ParseTemplateResponse;
  /** Get a playlist */
  playlist: PlaylistResponse;
  /** Get all Playlists */
  playlists: PlaylistsPagination;
  /** Get position by id */
  position: PositionResponse;
  /** Get all positions */
  positions: PositionsPaginations;
  /** Get a product */
  product: ProductResponse;
  /** get all product logs */
  productLogs: ProductLogsPaginations;
  /** Get all products */
  products: ProductsPaginations;
  /** get product history */
  quantityHistory: QuantityHistoryResponse;
  /** Get user role */
  role: RoleResponse;
  /** Get all user roles */
  roles: RolesPaginations;
  /** Get root by id */
  root: RootResponse;
  /** Get all roots */
  roots?: Maybe<RootsPaginations>;
  /** Get the number of roots and max number of roots */
  rootsInfo: RootsInfoResponse;
  /** Get router details */
  routerDetails: RouterDetailsResponse;
  /** Get all routers without details */
  routers: RoutersPaginations;
  /** Find scale by id */
  scale: ScaleResponse;
  /** Get all scales */
  scales: ScalesPagination;
  /** Find sensor by serialnumber */
  sensor: SensorExtendedResponse;
  /** Get all sensors */
  sensors: SensorsPagination;
  /** Get shelf by id */
  shelf: ShelfResponse;
  /** Get all shelves */
  shelves: ShelvesPaginations;
  /** Get supplier */
  supplier: SupplierResponse;
  /** Get all suppliers */
  suppliers: SuppliersPaginations;
  /** Get Synchronization by id */
  synchronization: SynchronizationResponse;
  /** Get Synchronizations */
  synchronizations: SynchronizationsPagination;
  /** Get all tags */
  tags: TagsPagination;
  /** Get a template */
  template: TemplateResponse;
  /** Get a template variant */
  templateVariant: TemplateVariantResponse;
  /** Get all template variants */
  templateVariants: TemplateVariantsPagination;
  /** Get all templates */
  templates: TemplatesPagination;
  tenant: TenantResponse;
  tenants?: Maybe<TenantsPaginations>;
  /** Get transaction by ID */
  transaction?: Maybe<TransactionResponse>;
  /** Get all TV Devices */
  tvDevices: TvDevicesPagination;
  /** get unique valueEntity columns */
  uniqueValueEntityColumns: UniqueValueEntityColumnsResponse;
  /** Get user */
  user: UserResponse;
  /** Get all users */
  users: UsersPaginations;
  /** Get the number of users and max number of users */
  usersInfo: UsersInfoResponse;
  /** Get Virtual Category Point */
  virtualCategoryPoint: VirtualCategoryPointResponse;
  /** Get Virtual Categories */
  virtualCategoryPoints: VirtualCategoryPointsPagination;
  /** Get Virtual Category Screen */
  virtualCategoryScreen: VirtualCategoryScreenResponse;
  /** Get Virtual Category Screens */
  virtualCategoryScreens: VirutalCategoryScreensPagination;
  /** Get Virtual Multi Screen */
  virtualMultiScreen: VirtualMultiScreenResponse;
  /** Get Virtual Multi Screens */
  virtualMultiScreens: VirutalMultiScreensPagination;
  /** Get Virtual Rack */
  virtualRack: VirtualRackResponse;
  /** Get Virtual Racks */
  virtualRacks: VirtualRacksPagination;
  /** Get Virtual Shelf */
  virtualShelf: VirtualShelfResponse;
  /** Get Virtual Shelves */
  virtualShelves: VirtualShelvesPagination;
  /** Get Virtual Store Device */
  virtualStoreDevice: VirtualStoreDeviceResponse;
  /** Get Virtual Store Devices */
  virtualStoreDevices: VirtualStoreDevicesPagination;
  /** Get warehouse alarms count */
  warehouseAlarmsCount: WarehouseAlarmsCountResponse;
  /** Get warehouse children */
  warehouseChildren: ProductsPaginations;
  /** Get order report */
  warehouseOrderReport: WarehouseOrderReport;
  /** Get warehouse parent products with children - filtering is on children */
  warehouseParentsWithChildren: ProductsPaginations;
  /** warehouse product report */
  warehouseProductReport: Array<WarehouseProduct>;
  /** warehouse product value report */
  warehouseProductValueReport: WarehouseProductValueReports;
};


export type QueryAisleArgs = {
  aisleId: Scalars['Int'];
};


export type QueryAislesArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<IslesFilter>;
};


export type QueryBarArgs = {
  barId?: InputMaybe<Scalars['Int']>;
  token?: InputMaybe<Scalars['String']>;
};


export type QueryBarsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<BarsFilter>;
};


export type QueryBatchTransactionsArgs = {
  batchUUID: Scalars['String'];
};


export type QueryBayArgs = {
  bayId: Scalars['Int'];
};


export type QueryBaysArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<BaysFilter>;
};


export type QueryChannelArgs = {
  id: Scalars['Int'];
};


export type QueryChannelsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<ChannelsFilter>;
};


export type QueryComponentVarsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<ComponentVarsFilter>;
};


export type QueryDashSettingsArgs = {
  customer: Scalars['String'];
};


export type QueryDeviceArgs = {
  deviceSerialNumber: Scalars['String'];
};


export type QueryDeviceConfigTransactionsArgs = {
  deviceId: Scalars['Int'];
};


export type QueryDevicePreviewArgs = {
  deviceSerialNumber: Scalars['String'];
};


export type QueryDeviceTransactionsArgs = {
  deviceId: Scalars['Int'];
};


export type QueryDeviceTypesArgs = {
  virtual?: InputMaybe<Scalars['Boolean']>;
};


export type QueryDevicesArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<DevicesFilter>;
};


export type QueryFilesArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<FileFilter>;
};


export type QueryFlowArgs = {
  flowId: Scalars['Int'];
};


export type QueryFlowRunnerArgs = {
  flowRunnerId: Scalars['Int'];
};


export type QueryFlowRunnersArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<FlowRunnersFilter>;
};


export type QueryFlowsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<FlowsFilter>;
};


export type QueryGetAssetArgs = {
  fileId: Scalars['Int'];
};


export type QueryGetFileStructureArgs = {
  where?: InputMaybe<FileStructureFilter>;
};


export type QueryGroupsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<GroupsFilter>;
};


export type QueryIncompleteTransactionsArgs = {
  where?: InputMaybe<IncompleteTransactionsInput>;
};


export type QueryIntegrationArgs = {
  id?: InputMaybe<Scalars['Int']>;
  token?: InputMaybe<Scalars['String']>;
};


export type QueryIntegrationsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<IntegrationsFilter>;
};


export type QueryOrderArgs = {
  id: Scalars['Int'];
};


export type QueryOrderItemLogsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<OrderItemLogsFilter>;
};


export type QueryOrdersArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<OrderFilter>;
};


export type QueryParseTemplateArgs = {
  productId: Scalars['Int'];
  templateId: Scalars['Int'];
};


export type QueryPlaylistArgs = {
  id: Scalars['Int'];
};


export type QueryPlaylistsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<PlaylistsFilter>;
};


export type QueryPositionArgs = {
  positionId: Scalars['Int'];
};


export type QueryPositionsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<PositionsFilter>;
};


export type QueryProductArgs = {
  eanCode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  internalId?: InputMaybe<Scalars['String']>;
};


export type QueryProductLogsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where: ProductLogFilter;
};


export type QueryProductsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<ProductsFilter>;
};


export type QueryQuantityHistoryArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  productId: Scalars['Int'];
  where?: InputMaybe<QuantityHistoryFilter>;
};


export type QueryRoleArgs = {
  roleId: Scalars['Int'];
};


export type QueryRolesArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<RolesFilter>;
};


export type QueryRootArgs = {
  rootId: Scalars['Int'];
};


export type QueryRootsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<RootsFilter>;
};


export type QueryRouterDetailsArgs = {
  id?: InputMaybe<Scalars['Int']>;
  uuid?: InputMaybe<Scalars['String']>;
};


export type QueryRoutersArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<RoutersFilter>;
};


export type QueryScaleArgs = {
  scaleId: Scalars['Int'];
};


export type QueryScalesArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<ScalesFilter>;
};


export type QuerySensorArgs = {
  serialNumber: Scalars['String'];
};


export type QuerySensorsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<SensorsFilter>;
};


export type QueryShelfArgs = {
  aisleKey?: InputMaybe<Scalars['String']>;
  bayKey?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  shelfId?: InputMaybe<Scalars['Int']>;
};


export type QueryShelvesArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<ShelvesFilter>;
};


export type QuerySupplierArgs = {
  supplierId: Scalars['Int'];
};


export type QuerySuppliersArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<SuppliersFilter>;
};


export type QuerySynchronizationArgs = {
  id: Scalars['Int'];
};


export type QuerySynchronizationsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<SynchronizationsFilter>;
};


export type QueryTagsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<TagsFilter>;
};


export type QueryTemplateArgs = {
  id: Scalars['Int'];
};


export type QueryTemplateVariantArgs = {
  id: Scalars['Int'];
};


export type QueryTemplateVariantsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<TemplateVariantsFilter>;
};


export type QueryTemplatesArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<TemplatesFilter>;
};


export type QueryTenantArgs = {
  id: Scalars['Int'];
};


export type QueryTenantsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<SearchTenantInput>;
};


export type QueryTransactionArgs = {
  transactionId: Scalars['String'];
};


export type QueryTvDevicesArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<TvDevicesFilter>;
};


export type QueryUniqueValueEntityColumnsArgs = {
  templateKey: Scalars['String'];
};


export type QueryUserArgs = {
  userId: Scalars['Int'];
};


export type QueryUsersArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<UsersFilter>;
};


export type QueryVirtualCategoryPointArgs = {
  virtualCategoryPointId: Scalars['Int'];
};


export type QueryVirtualCategoryPointsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<VirtualCategoryPointsFilter>;
};


export type QueryVirtualCategoryScreenArgs = {
  virtualCategoryScreenId: Scalars['Int'];
};


export type QueryVirtualCategoryScreensArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<VirtualCategoryScreensFilter>;
};


export type QueryVirtualMultiScreenArgs = {
  virtualMultiScreenId: Scalars['Int'];
};


export type QueryVirtualMultiScreensArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<VirtualMultiScreensFilter>;
};


export type QueryVirtualRackArgs = {
  virtualRackId: Scalars['Int'];
};


export type QueryVirtualRacksArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<VirtualRacksFilter>;
};


export type QueryVirtualShelfArgs = {
  virtualShelfId: Scalars['Int'];
};


export type QueryVirtualShelvesArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<VirtualShelvesFilter>;
};


export type QueryVirtualStoreDeviceArgs = {
  virtualStoreDeviceId: Scalars['Int'];
};


export type QueryVirtualStoreDevicesArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<VirtualStoreDevicesFilter>;
};


export type QueryWarehouseChildrenArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<ProductsFilter>;
};


export type QueryWarehouseOrderReportArgs = {
  input: WarehouseOrderReportInput;
};


export type QueryWarehouseParentsWithChildrenArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: InputMaybe<WarehouseParentsWithChildrenFilter>;
};


export type QueryWarehouseProductReportArgs = {
  date: Scalars['DateTime'];
  rootId?: InputMaybe<Scalars['Int']>;
  supplierId?: InputMaybe<Scalars['Int']>;
};


export type QueryWarehouseProductValueReportArgs = {
  rootId?: InputMaybe<Scalars['Int']>;
  startDate: Scalars['DateTime'];
  supplierId?: InputMaybe<Scalars['Int']>;
};

export type RegisterRouterResponse = {
  __typename?: 'RegisterRouterResponse';
  configuration?: Maybe<Array<KeyValuePair>>;
  imageTopicPostfix?: Maybe<Scalars['String']>;
  machineName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Option>>;
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['Int'];
  permissions: Array<Scalars['String']>;
  rank?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

export type RoleResponse = {
  __typename?: 'RoleResponse';
  errors?: Maybe<Array<Error>>;
  role?: Maybe<Role>;
};

export type RolesFilter = {
  rank?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

export type RolesPaginations = {
  __typename?: 'RolesPaginations';
  nodes: Array<Role>;
  totalCount: Scalars['Int'];
};

export type Root = {
  __typename?: 'Root';
  deviceCount?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  key?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
};

export type RootResponse = {
  __typename?: 'RootResponse';
  errors?: Maybe<Array<Maybe<Error>>>;
  root?: Maybe<Root>;
  success?: Maybe<Scalars['Boolean']>;
};

export type RootsFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type RootsInfoResponse = {
  __typename?: 'RootsInfoResponse';
  maxRoots?: Maybe<Scalars['Int']>;
  numOfRoots?: Maybe<Scalars['Int']>;
};

export type RootsPaginations = {
  __typename?: 'RootsPaginations';
  nodes: Array<Root>;
  totalCount: Scalars['Int'];
};

export type Router = {
  __typename?: 'Router';
  dhcp?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  macAddress?: Maybe<Scalars['MAC']>;
  name?: Maybe<Scalars['String']>;
  online?: Maybe<Scalars['Boolean']>;
  root?: Maybe<Root>;
  rootId?: Maybe<Scalars['Int']>;
  routerNetwork?: Maybe<RouterNetwork>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<RouterType>;
  uuid?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type RouterDetails = {
  __typename?: 'RouterDetails';
  cpu_temp?: Maybe<Scalars['Int']>;
  cpu_usage?: Maybe<Scalars['Int']>;
  ip_address?: Maybe<Scalars['String']>;
  is_connected_to_vpn?: Maybe<Scalars['Boolean']>;
  is_online?: Maybe<Scalars['Boolean']>;
  is_undervolted?: Maybe<Scalars['Boolean']>;
  mac_address?: Maybe<Scalars['String']>;
  memory_total?: Maybe<Scalars['Int']>;
  memory_usage?: Maybe<Scalars['Int']>;
  router?: Maybe<Router>;
  status?: Maybe<Scalars['String']>;
  storage_total?: Maybe<Scalars['Int']>;
  storage_usage?: Maybe<Scalars['Int']>;
  uuid?: Maybe<Scalars['String']>;
};

export type RouterDetailsResponse = {
  __typename?: 'RouterDetailsResponse';
  errors?: Maybe<Array<Error>>;
  routerDetails?: Maybe<RouterDetails>;
};

export type RouterNetwork = {
  __typename?: 'RouterNetwork';
  dns1?: Maybe<Scalars['IPv4']>;
  dns2?: Maybe<Scalars['IPv4']>;
  gateway?: Maybe<Scalars['IPv4']>;
  ip?: Maybe<Scalars['IPv4']>;
  subnet?: Maybe<Scalars['IPv4']>;
  wifichannel?: Maybe<Scalars['Int']>;
  wifimode?: Maybe<WifiMode>;
};

export type RouterNetworkInput = {
  dns1?: InputMaybe<Scalars['IPv4']>;
  dns2?: InputMaybe<Scalars['IPv4']>;
  gateway?: InputMaybe<Scalars['IPv4']>;
  ip?: InputMaybe<Scalars['IPv4']>;
  subnet?: InputMaybe<Scalars['IPv4']>;
  wifichannel?: InputMaybe<Scalars['Int']>;
  wifimode?: InputMaybe<WifiMode>;
};

export enum RouterType {
  Ar100 = 'AR100',
  As100 = 'AS100'
}

export type RoutersFilter = {
  active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  rootId?: InputMaybe<Scalars['Int']>;
};

export type RoutersPaginations = {
  __typename?: 'RoutersPaginations';
  nodes: Array<Router>;
  totalCount: Scalars['Int'];
};

export type Scale = {
  __typename?: 'Scale';
  channel?: Maybe<Channel>;
  channelId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  ip: Scalars['IPv4'];
  name?: Maybe<Scalars['String']>;
  root?: Maybe<Root>;
  rootId?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Scalars['String']>>;
  type: DisplayType;
  username: Scalars['String'];
};

export type ScaleRegisterInput = {
  channelId?: InputMaybe<Scalars['Int']>;
  ip: Scalars['IPv4'];
  name?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  rootId: Scalars['Int'];
  tags: Array<Scalars['String']>;
  type: DisplayType;
  username: Scalars['String'];
};

export type ScaleResponse = {
  __typename?: 'ScaleResponse';
  errors?: Maybe<Array<Error>>;
  scale?: Maybe<Scale>;
};

export type ScalesFilter = {
  channelId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  rootId?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ScalesPagination = {
  __typename?: 'ScalesPagination';
  nodes: Array<Maybe<Scale>>;
  totalCount: Scalars['Int'];
};

export type Schedule = {
  __typename?: 'Schedule';
  dateRange?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  id: Scalars['Int'];
  timeFrames?: Maybe<Array<Maybe<Scalars['String']>>>;
  weekdays: Array<WeekDay>;
};

export type ScheduleInput = {
  dateRange?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  timeFrames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  weekdays: Array<WeekDay>;
};

export type SearchTenantInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type Sensor = {
  __typename?: 'Sensor';
  battery?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  root: Root;
  rootId: Scalars['Int'];
  serialNumber: Scalars['MAC'];
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SensorExtended = {
  __typename?: 'SensorExtended';
  active?: Maybe<Scalars['Boolean']>;
  battery?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  measurement?: Maybe<Array<Maybe<Measurement>>>;
  name?: Maybe<Scalars['String']>;
  root?: Maybe<Root>;
  rootId?: Maybe<Scalars['Int']>;
  serialNumber: Scalars['MAC'];
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type SensorExtendedMeasurementArgs = {
  from: Scalars['DateTime'];
  to?: InputMaybe<Scalars['DateTime']>;
  type: MeasurementType;
};

export type SensorExtendedResponse = {
  __typename?: 'SensorExtendedResponse';
  errors?: Maybe<Array<Error>>;
  sensor?: Maybe<SensorExtended>;
};

export type SensorResponse = {
  __typename?: 'SensorResponse';
  errors?: Maybe<Array<Error>>;
  sensor?: Maybe<Sensor>;
};

export type SensorsFilter = {
  rootId?: InputMaybe<Scalars['Int']>;
  serialNumber?: InputMaybe<Scalars['String']>;
};

export type SensorsPagination = {
  __typename?: 'SensorsPagination';
  nodes: Array<Maybe<SensorExtended>>;
  totalCount: Scalars['Int'];
};

export type SetProductTemplateInput = {
  productInternalId: Scalars['String'];
  rootId: Scalars['Int'];
  templateVariantId: Scalars['Int'];
};

export type Shelf = {
  __typename?: 'Shelf';
  /** @deprecated Use `bay`. */
  aisle?: Maybe<Aisle>;
  bay?: Maybe<Bay>;
  device?: Maybe<Device>;
  id: Scalars['Int'];
  /** shelf key */
  key: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  /** @deprecated Never used */
  orientation?: Maybe<Orientation>;
  /** positions connected to a shelf */
  positions?: Maybe<Array<Position>>;
  root?: Maybe<Root>;
};

export type ShelfInput = {
  aisleId?: InputMaybe<Scalars['Int']>;
  bayId: Scalars['Int'];
  key: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  orientation?: InputMaybe<Orientation>;
};

export type ShelfResponse = {
  __typename?: 'ShelfResponse';
  errors?: Maybe<Array<Maybe<Error>>>;
  shelf?: Maybe<Shelf>;
  success?: Maybe<Scalars['Boolean']>;
};

/** combined input for adding a new shelf (if bay or aisle don't exist, they wil be created) */
export type ShelfStructInput = {
  aisleKey: Scalars['String'];
  bayKey: Scalars['String'];
  positions?: InputMaybe<Array<PositionStructInput>>;
  rootId: Scalars['Int'];
  shelfKey: Scalars['String'];
  shelfName?: InputMaybe<Scalars['String']>;
};

export type ShelfStructResponse = {
  __typename?: 'ShelfStructResponse';
  errors?: Maybe<Array<Error>>;
  positions?: Maybe<Array<Position>>;
  shelf?: Maybe<Shelf>;
  success?: Maybe<Scalars['Boolean']>;
};

export enum SignageDeviceType {
  Hdmi = 'HDMI',
  Hf = 'HF',
  VirtualStore = 'VIRTUAL_STORE'
}

export type StandardResponse = {
  __typename?: 'StandardResponse';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Supplier = {
  __typename?: 'Supplier';
  address?: Maybe<Scalars['String']>;
  addressNumber?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  country?: Maybe<Country>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  postCode?: Maybe<Scalars['String']>;
  supplierId?: Maybe<Scalars['String']>;
  vat?: Maybe<Scalars['String']>;
};

export type SupplierResponse = {
  __typename?: 'SupplierResponse';
  errors?: Maybe<Array<Error>>;
  supplier?: Maybe<Supplier>;
};

export type SuppliersFilter = {
  any?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Country>;
};

export type SuppliersPaginations = {
  __typename?: 'SuppliersPaginations';
  nodes: Array<Maybe<Supplier>>;
  totalCount: Scalars['Int'];
};

export type Synchronization = {
  __typename?: 'Synchronization';
  id: Scalars['Int'];
  integration: Integration;
  note?: Maybe<Scalars['String']>;
  status: SynchronizationStatus;
  time: Scalars['DateTime'];
  updatedProducts: Array<Scalars['String']>;
};

export type SynchronizationResponse = {
  __typename?: 'SynchronizationResponse';
  errors?: Maybe<Array<Maybe<Error>>>;
  synchronization?: Maybe<Synchronization>;
};

export enum SynchronizationStatus {
  Complete = 'COMPLETE',
  Failed = 'FAILED',
  Processing = 'PROCESSING'
}

export type SynchronizationsFilter = {
  filter?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<SynchronizationStatus>;
};

export type SynchronizationsPagination = {
  __typename?: 'SynchronizationsPagination';
  nodes?: Maybe<Array<Synchronization>>;
  totalCount: Scalars['Int'];
};

export type TvDevice = {
  __typename?: 'TVDevice';
  channel?: Maybe<Channel>;
  channelId?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  root?: Maybe<Root>;
  rootId?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  token?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type TvDeviceActivateInput = {
  channelId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  rootId: Scalars['Int'];
  tags: Array<Scalars['String']>;
};

export type TvDeviceRegisterInput = {
  height?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  rootId?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  token: Scalars['String'];
  weight?: InputMaybe<Scalars['Int']>;
};

export type TvDeviceResponse = {
  __typename?: 'TVDeviceResponse';
  errors?: Maybe<Array<Error>>;
  tvDevice?: Maybe<TvDevice>;
};

export type TvDevicesFilter = {
  channelId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  rootId?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TvDevicesPagination = {
  __typename?: 'TVDevicesPagination';
  nodes: Array<Maybe<TvDevice>>;
  totalCount: Scalars['Int'];
};

export type TagsFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type TagsPagination = {
  __typename?: 'TagsPagination';
  nodes?: Maybe<Array<Scalars['String']>>;
  totalCount: Scalars['Int'];
};

export type Template = {
  __typename?: 'Template';
  components?: Maybe<Array<Component>>;
  deviceSize?: Maybe<TemplateType>;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  isCustom?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  root?: Maybe<Root>;
  templateVariables?: Maybe<Array<ComponentVar>>;
  templateVariant?: Maybe<TemplateVariant>;
  width?: Maybe<Scalars['Int']>;
};

export type TemplateInput = {
  components?: InputMaybe<Array<PostComponent>>;
  deviceSize: TemplateType;
  height: Scalars['Int'];
  isCustom: Scalars['Boolean'];
  name?: InputMaybe<Scalars['String']>;
  templateVariables?: InputMaybe<Array<PostComponentVar>>;
  templateVariantId: Scalars['Int'];
  width: Scalars['Int'];
};

export type TemplateResponse = {
  __typename?: 'TemplateResponse';
  errors?: Maybe<Array<Error>>;
  template?: Maybe<Template>;
};

export enum TemplateType {
  Epaper_152x152 = 'EPAPER_152x152',
  Epaper_200X200 = 'EPAPER_200X200',
  Epaper_296X128R = 'EPAPER_296X128_R',
  Epaper_296X128Y = 'EPAPER_296X128_Y',
  Epaper_400X300R = 'EPAPER_400X300_R',
  Epaper_400X300Y = 'EPAPER_400X300_Y',
  Epaper_800x480R = 'EPAPER_800x480_R',
  Epaper_800x480RRotated = 'EPAPER_800x480_R_ROTATED',
  Epaper_800x480S6 = 'EPAPER_800x480_S6',
  Epaper_800x480S6Rotated = 'EPAPER_800x480_S6_ROTATED',
  EpaperNone = 'EPAPER_NONE',
  Lcd_1920X158 = 'LCD_1920X158',
  Lcd_1920X1080 = 'LCD_1920X1080',
  Lcd_2180X180 = 'LCD_2180X180'
}

export type TemplateVariant = {
  __typename?: 'TemplateVariant';
  id: Scalars['Int'];
  inUse?: Maybe<Scalars['Boolean']>;
  isDefault: Scalars['Boolean'];
  key: Scalars['String'];
  templates?: Maybe<Array<Template>>;
};

export type TemplateVariantInput = {
  isDefault: Scalars['Boolean'];
  key: Scalars['String'];
};

export type TemplateVariantResponse = {
  __typename?: 'TemplateVariantResponse';
  errors?: Maybe<Array<Error>>;
  templateVariant?: Maybe<TemplateVariant>;
};

export type TemplateVariantsFilter = {
  deviceSize?: InputMaybe<TemplateType>;
  inUse?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
};

export type TemplateVariantsPagination = {
  __typename?: 'TemplateVariantsPagination';
  nodes?: Maybe<Array<TemplateVariant>>;
  totalCount: Scalars['Int'];
};

export type TemplatesFilter = {
  name?: InputMaybe<Scalars['String']>;
  rootId?: InputMaybe<Scalars['Int']>;
};

export type TemplatesPagination = {
  __typename?: 'TemplatesPagination';
  nodes?: Maybe<Array<Template>>;
  totalCount: Scalars['Int'];
};

export type Tenant = {
  __typename?: 'Tenant';
  address: Scalars['String'];
  addressNumber?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  companyName: Scalars['String'];
  contractLength?: Maybe<ContractLength>;
  contractorName?: Maybe<Scalars['String']>;
  contractorsMail?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
  created: Scalars['DateTime'];
  id: Scalars['Int'];
  isActive?: Maybe<Scalars['Boolean']>;
  loginLogo?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  maxRoots: Scalars['Int'];
  maxUsers: Scalars['Int'];
  name: Scalars['String'];
  numOfDevices?: Maybe<Scalars['Int']>;
  numOfDevicesInError?: Maybe<Scalars['Int']>;
  numOfRoots?: Maybe<Scalars['Int']>;
  numOfScales?: Maybe<Scalars['Int']>;
  numOfSmartLabels?: Maybe<Scalars['Int']>;
  numOfUsers?: Maybe<Scalars['Int']>;
  postCode: Scalars['String'];
  primaryColor?: Maybe<Scalars['String']>;
  purchasedModules?: Maybe<Array<PurchasedModules>>;
  textColor?: Maybe<Scalars['String']>;
  updated: Scalars['DateTime'];
  vat: Scalars['String'];
};

export type TenantInfo = {
  __typename?: 'TenantInfo';
  devicesError?: Maybe<Scalars['Int']>;
  numOfRoots?: Maybe<Scalars['Int']>;
  numOfScales?: Maybe<Scalars['Int']>;
  numOfSensors?: Maybe<Scalars['Int']>;
  numOfSmartLabels?: Maybe<Scalars['Int']>;
  numOfUsers?: Maybe<Scalars['Int']>;
  tenant: Tenant;
};

export type TenantResponse = {
  __typename?: 'TenantResponse';
  errors?: Maybe<Array<Error>>;
  tenant?: Maybe<Tenant>;
};

export type TenantsPaginations = {
  __typename?: 'TenantsPaginations';
  nodes: Array<Tenant>;
  totalCount: Scalars['Int'];
};

export type Transaction = {
  __typename?: 'Transaction';
  device?: Maybe<Device>;
  deviceserialnumber?: Maybe<Scalars['String']>;
  reset?: Maybe<Scalars['Boolean']>;
  stage?: Maybe<TransactionStage>;
  template?: Maybe<Template>;
  templateid?: Maybe<Scalars['Int']>;
  transactionid?: Maybe<Scalars['String']>;
  ts?: Maybe<Scalars['String']>;
};

export type TransactionResponse = {
  __typename?: 'TransactionResponse';
  errors?: Maybe<Array<Error>>;
  transactions?: Maybe<Array<Transaction>>;
};

export enum TransactionStage {
  Complete = 'COMPLETE',
  Error = 'ERROR',
  Processing = 'PROCESSING'
}

export type TriggerDeviceLedInput = {
  blinkSpeed: BlinkSpeed;
  blinkTime: BlinkTime;
  color: LedColor;
  deviceId: Scalars['Int'];
};

export type TriggerSyncInput = {
  integrationId: Scalars['Int'];
};

export type UniqueColumn = {
  __typename?: 'UniqueColumn';
  name?: Maybe<Scalars['String']>;
};

export type UniqueValueEntityColumnsResponse = {
  __typename?: 'UniqueValueEntityColumnsResponse';
  nodes: Array<Maybe<UniqueColumn>>;
};

export type UploadedFileResponse = {
  __typename?: 'UploadedFileResponse';
  encoding: Scalars['String'];
  filename: Scalars['String'];
  mimetype: Scalars['String'];
  url: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  allowedIPs?: Maybe<Array<Scalars['IPv4']>>;
  color?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isTwoFactorEnabled: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  ledConfig?: Maybe<LedConfig>;
  ledConfigId?: Maybe<Scalars['Int']>;
  locationRestrictions?: Maybe<Array<LocationRestriction>>;
  roles?: Maybe<Array<Role>>;
  rootIDs?: Maybe<Array<Maybe<Scalars['Int']>>>;
  username?: Maybe<Scalars['String']>;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  errors?: Maybe<Array<Error>>;
  user?: Maybe<User>;
};

export type UsersFilter = {
  email?: InputMaybe<Scalars['String']>;
};

export type UsersInfoResponse = {
  __typename?: 'UsersInfoResponse';
  maxUsers?: Maybe<Scalars['Int']>;
  numOfUsers?: Maybe<Scalars['Int']>;
};

export type UsersPaginations = {
  __typename?: 'UsersPaginations';
  nodes: Array<User>;
  totalCount: Scalars['Int'];
};

export type Value = {
  __typename?: 'Value';
  id: Scalars['Int'];
  /** value name (only for display purposes) */
  name?: Maybe<Scalars['String']>;
  root?: Maybe<Root>;
  /** value can be assigned to a single root (e.g. the same product can have a different price in a specific root)) */
  rootId?: Maybe<Scalars['Int']>;
  /** placeholder replaced with value in template (e.g. {{PRICE}}) */
  templateKey: Scalars['String'];
  /** value type (formats value) */
  type?: Maybe<DeviceValueType>;
  /** product value */
  value: Scalars['String'];
};

export type ValueEntitiesFilter = {
  templateKey: Scalars['String'];
  value: Scalars['String'];
};

export type VirtualCategoryPoint = {
  __typename?: 'VirtualCategoryPoint';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  virtualCategoryPointId?: Maybe<Scalars['Int']>;
  virtualRacks?: Maybe<Array<Maybe<VirtualRack>>>;
};

export type VirtualCategoryPointPosition = {
  __typename?: 'VirtualCategoryPointPosition';
  id: Scalars['Int'];
  virtualCategoryPoint?: Maybe<VirtualCategoryPoint>;
  virtualCategoryPointId?: Maybe<Scalars['Int']>;
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type VirtualCategoryPointResponse = {
  __typename?: 'VirtualCategoryPointResponse';
  errors?: Maybe<Array<Maybe<Error>>>;
  virtualCategoryPoint?: Maybe<VirtualCategoryPoint>;
};

export type VirtualCategoryPointsFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type VirtualCategoryPointsPagination = {
  __typename?: 'VirtualCategoryPointsPagination';
  nodes?: Maybe<Array<VirtualCategoryPoint>>;
  totalCount: Scalars['Int'];
};

export type VirtualCategoryScreen = {
  __typename?: 'VirtualCategoryScreen';
  backgroundFile?: Maybe<File>;
  backgroundFileId?: Maybe<Scalars['Int']>;
  catalogMode?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<Channel>;
  channelId?: Maybe<Scalars['Int']>;
  file?: Maybe<File>;
  fileId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  timeout?: Maybe<Scalars['Int']>;
  virtualCategoryPointPositions?: Maybe<Array<Maybe<VirtualCategoryPointPosition>>>;
};

export type VirtualCategoryScreenResponse = {
  __typename?: 'VirtualCategoryScreenResponse';
  errors?: Maybe<Array<Maybe<Error>>>;
  virtualCategoryScreen?: Maybe<VirtualCategoryScreen>;
};

export type VirtualCategoryScreensFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type VirtualDeviceInput = {
  barId?: InputMaybe<Scalars['Int']>;
  groupId?: InputMaybe<Scalars['Int']>;
  internalId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  positionId?: InputMaybe<Scalars['Int']>;
  rootId: Scalars['Int'];
  templateVariantId?: InputMaybe<Scalars['Int']>;
};

export type VirtualMultiScreen = {
  __typename?: 'VirtualMultiScreen';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  virtualMultiScreenPositions?: Maybe<Array<Maybe<VirtualMultiScreenPosition>>>;
};

export type VirtualMultiScreenPosition = {
  __typename?: 'VirtualMultiScreenPosition';
  channel?: Maybe<Channel>;
  channelId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  sequence: Scalars['Int'];
  virtualCategoryPoint?: Maybe<VirtualCategoryPoint>;
  virtualCategoryPointId?: Maybe<Scalars['Int']>;
  virtualRack?: Maybe<VirtualRack>;
  virtualRackId?: Maybe<Scalars['Int']>;
};

export type VirtualMultiScreenResponse = {
  __typename?: 'VirtualMultiScreenResponse';
  errors?: Maybe<Array<Maybe<Error>>>;
  virtualMultiScreen?: Maybe<VirtualMultiScreen>;
};

export type VirtualMultiScreensFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type VirtualProductPosition = {
  __typename?: 'VirtualProductPosition';
  id: Scalars['Int'];
  itemCount?: Maybe<Scalars['Int']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['Int']>;
  rotation?: Maybe<Scalars['Int']>;
  sequence: Scalars['Int'];
  zoom?: Maybe<Scalars['Int']>;
};

export type VirtualRack = {
  __typename?: 'VirtualRack';
  file?: Maybe<File>;
  fileId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  virtualShelfPositions?: Maybe<Array<VirtualShelfPosition>>;
  virtualStoreDevices?: Maybe<Array<VirtualStoreDevice>>;
};

export type VirtualRackResponse = {
  __typename?: 'VirtualRackResponse';
  errors?: Maybe<Array<Maybe<Error>>>;
  virtualRack?: Maybe<VirtualRack>;
};

export type VirtualRacksFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type VirtualRacksPagination = {
  __typename?: 'VirtualRacksPagination';
  nodes?: Maybe<Array<VirtualRack>>;
  totalCount: Scalars['Int'];
};

export type VirtualShelf = {
  __typename?: 'VirtualShelf';
  file?: Maybe<File>;
  fileId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  spacing?: Maybe<Scalars['Float']>;
  variant: VirtualShelfVariant;
  virtualProductPositions?: Maybe<Array<Maybe<VirtualProductPosition>>>;
};

export type VirtualShelfPosition = {
  __typename?: 'VirtualShelfPosition';
  id: Scalars['Int'];
  sequence: Scalars['Int'];
  virtualShelf?: Maybe<VirtualShelf>;
  virtualShelfId?: Maybe<Scalars['Int']>;
};

export type VirtualShelfResponse = {
  __typename?: 'VirtualShelfResponse';
  errors?: Maybe<Array<Maybe<Error>>>;
  virtualShelf?: Maybe<VirtualShelf>;
};

export enum VirtualShelfVariant {
  Agitron = 'AGITRON',
  GlassCenter = 'GLASS_CENTER',
  GlassFull = 'GLASS_FULL',
  GlassLeft = 'GLASS_LEFT',
  GlassRight = 'GLASS_RIGHT',
  GrayCenter = 'GRAY_CENTER',
  GrayFull = 'GRAY_FULL',
  GrayLeft = 'GRAY_LEFT',
  GrayRight = 'GRAY_RIGHT',
  Normal = 'NORMAL',
  WhiteCenter = 'WHITE_CENTER',
  WhiteFull = 'WHITE_FULL',
  WhiteLeft = 'WHITE_LEFT',
  WhiteRight = 'WHITE_RIGHT',
  WoodCenter = 'WOOD_CENTER',
  WoodFull = 'WOOD_FULL',
  WoodLeft = 'WOOD_LEFT',
  WoodRight = 'WOOD_RIGHT',
  WoodWhiteCenter = 'WOOD_WHITE_CENTER',
  WoodWhiteFull = 'WOOD_WHITE_FULL',
  WoodWhiteLeft = 'WOOD_WHITE_LEFT',
  WoodWhiteRight = 'WOOD_WHITE_RIGHT'
}

export type VirtualShelvesFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type VirtualShelvesPagination = {
  __typename?: 'VirtualShelvesPagination';
  nodes?: Maybe<Array<VirtualShelf>>;
  totalCount: Scalars['Int'];
};

export type VirtualStoreDevice = {
  __typename?: 'VirtualStoreDevice';
  active?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<Channel>;
  channelId?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  ip?: Maybe<Scalars['IPv4']>;
  messageReceived?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  root?: Maybe<Root>;
  rootId?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<SignageDeviceType>;
  version?: Maybe<Scalars['String']>;
  virtualCategoryPoint?: Maybe<VirtualCategoryPoint>;
  virtualCategoryPointId?: Maybe<Scalars['Int']>;
  virtualCategoryScreen?: Maybe<VirtualCategoryScreen>;
  virtualCategoryScreenId?: Maybe<Scalars['Int']>;
  virtualMultiScreen?: Maybe<VirtualMultiScreen>;
  virtualMultiScreenId?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

export type VirtualStoreDeviceResponse = {
  __typename?: 'VirtualStoreDeviceResponse';
  errors?: Maybe<Array<Maybe<Error>>>;
  virtualStoreDevice?: Maybe<VirtualStoreDevice>;
};

export type VirtualStoreDevicesFilter = {
  active?: InputMaybe<Scalars['Boolean']>;
  channelId?: InputMaybe<Scalars['Int']>;
  messageReceived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  rootId?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type VirtualStoreDevicesPagination = {
  __typename?: 'VirtualStoreDevicesPagination';
  nodes?: Maybe<Array<VirtualStoreDevice>>;
  totalCount: Scalars['Int'];
};

export type VirutalCategoryScreensPagination = {
  __typename?: 'VirutalCategoryScreensPagination';
  nodes?: Maybe<Array<VirtualCategoryScreen>>;
  totalCount: Scalars['Int'];
};

export type VirutalMultiScreensPagination = {
  __typename?: 'VirutalMultiScreensPagination';
  nodes?: Maybe<Array<VirtualMultiScreen>>;
  totalCount: Scalars['Int'];
};

export type WarehouseAlarmsCountResponse = {
  __typename?: 'WarehouseAlarmsCountResponse';
  expiration?: Maybe<Scalars['Int']>;
  express?: Maybe<Scalars['Int']>;
  normal?: Maybe<Scalars['Int']>;
};

export type WarehouseOrderCount = {
  __typename?: 'WarehouseOrderCount';
  date: Scalars['DateTime'];
  expressCount: Scalars['Int'];
  normalCount: Scalars['Int'];
  total: Scalars['Int'];
};

export type WarehouseOrderReport = {
  __typename?: 'WarehouseOrderReport';
  allOrders: Array<WarehouseOrderCount>;
  stockValue: Array<WarehouseStockValue>;
};

export type WarehouseOrderReportInput = {
  dateFrom: Scalars['DateTime'];
  dateTo?: InputMaybe<Scalars['DateTime']>;
  rootId?: InputMaybe<Scalars['Int']>;
  supplierId?: InputMaybe<Scalars['Int']>;
};

export type WarehouseParentsWithChildrenFilter = {
  alarmType?: InputMaybe<AlarmType>;
  any?: InputMaybe<Scalars['String']>;
  rootId?: InputMaybe<Scalars['Int']>;
  supplierId?: InputMaybe<Scalars['Int']>;
};

export type WarehouseProduct = {
  __typename?: 'WarehouseProduct';
  lastChange: Scalars['DateTime'];
  mode: ModeEnum;
  product: Product;
  quantity: Scalars['Int'];
};

export type WarehouseProductValueReport = {
  __typename?: 'WarehouseProductValueReport';
  CHF: Scalars['Float'];
  EUR: Scalars['Float'];
  GBP: Scalars['Float'];
  USD: Scalars['Float'];
  date: Scalars['DateTime'];
  month: Scalars['String'];
};

export type WarehouseProductValueReportFilter = {
  rootId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  supplierId?: InputMaybe<Scalars['Int']>;
};

export type WarehouseProductValueReports = {
  __typename?: 'WarehouseProductValueReports';
  reports: Array<WarehouseProductValueReport>;
};

export type WarehouseStockValue = {
  __typename?: 'WarehouseStockValue';
  CHF: Scalars['Float'];
  EUR: Scalars['Float'];
  GBP: Scalars['Float'];
  USD: Scalars['Float'];
  date: Scalars['DateTime'];
};

export enum WeekDay {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export enum WifiMode {
  GHz_2_4 = 'GHz_2_4',
  GHz_5 = 'GHz_5'
}

export type AddEditProductsResponse = {
  __typename?: 'addEditProductsResponse';
  /** list of products added by the mutation */
  addedProducts?: Maybe<Array<Product>>;
  /** UUID for status check of updated devices */
  batchUUID: Scalars['String'];
  errors?: Maybe<Array<Error>>;
  isAssignedToShelves?: Maybe<Scalars['Boolean']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type BaysFilter = {
  aisleId?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['String']>;
  rootId?: InputMaybe<Scalars['Int']>;
};

export type BaysPaginations = {
  __typename?: 'baysPaginations';
  nodes: Array<Bay>;
  totalCount: Scalars['Int'];
};

export type ShelvesFilter = {
  aisleKey?: InputMaybe<Scalars['String']>;
  bayId?: InputMaybe<Scalars['Int']>;
  bayKey?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  rootId?: InputMaybe<Scalars['Int']>;
};

export type ShelvesPaginations = {
  __typename?: 'shelvesPaginations';
  nodes: Array<Shelf>;
  totalCount: Scalars['Int'];
};

export type AddRootMutationVariables = Exact<{
  input: AddRootInput;
}>;


export type AddRootMutation = { __typename?: 'Mutation', addRoot: { __typename?: 'RootResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string } | null> | null } };

export type DeleteRootMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteRootMutation = { __typename?: 'Mutation', deleteRoot: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type EditRootMutationVariables = Exact<{
  id: Scalars['Int'];
  input: EditRootInput;
}>;


export type EditRootMutation = { __typename?: 'Mutation', editRoot: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type RootQueryVariables = Exact<{
  rootId: Scalars['Int'];
}>;


export type RootQuery = { __typename?: 'Query', root: { __typename?: 'RootResponse', root?: { __typename?: 'Root', id: number, key?: string | null, name?: string | null, latitude?: number | null, longitude?: number | null } | null } };

export type AddRouterMutationVariables = Exact<{
  input: AddRouterInput;
}>;


export type AddRouterMutation = { __typename?: 'Mutation', addRouter?: { __typename?: 'Router', id: number } | null };

export type DeleteRouterMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteRouterMutation = { __typename?: 'Mutation', deleteRouter: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type EditRouterMutationVariables = Exact<{
  id: Scalars['Int'];
  input: EditRouterInput;
}>;


export type EditRouterMutation = { __typename?: 'Mutation', editRouter: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type RouterDetailsQueryVariables = Exact<{
  uuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
}>;


export type RouterDetailsQuery = { __typename?: 'Query', routerDetails: { __typename?: 'RouterDetailsResponse', routerDetails?: { __typename?: 'RouterDetails', cpu_temp?: number | null, cpu_usage?: number | null, memory_usage?: number | null, memory_total?: number | null, is_online?: boolean | null, is_connected_to_vpn?: boolean | null, ip_address?: string | null, storage_usage?: number | null, storage_total?: number | null, is_undervolted?: boolean | null, router?: { __typename?: 'Router', id: number, name?: string | null, dhcp?: boolean | null, token?: string | null, macAddress?: any | null, uuid?: string | null, type?: RouterType | null, version?: string | null, root?: { __typename?: 'Root', id: number, name?: string | null } | null, routerNetwork?: { __typename?: 'RouterNetwork', ip?: any | null, subnet?: any | null, gateway?: any | null, dns1?: any | null, dns2?: any | null, wifichannel?: number | null, wifimode?: WifiMode | null } | null } | null } | null } };

export type RoutersQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  where?: InputMaybe<RoutersFilter>;
}>;


export type RoutersQuery = { __typename?: 'Query', routers: { __typename?: 'RoutersPaginations', totalCount: number, nodes: Array<{ __typename?: 'Router', id: number, name?: string | null, macAddress?: any | null, uuid?: string | null, type?: RouterType | null, version?: string | null, online?: boolean | null, root?: { __typename?: 'Root', id: number, name?: string | null } | null }> } };

export type AddAisleMutationVariables = Exact<{
  input: IsleInput;
}>;


export type AddAisleMutation = { __typename?: 'Mutation', addAisle: { __typename?: 'AisleResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string } | null> | null } };

export type AisleQueryVariables = Exact<{
  aisleId: Scalars['Int'];
}>;


export type AisleQuery = { __typename?: 'Query', aisle: { __typename?: 'AisleResponse', aisle?: { __typename?: 'Aisle', id: number, key: string, root?: { __typename?: 'Root', id: number } | null } | null } };

export type AislesQueryVariables = Exact<{
  where?: InputMaybe<IslesFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type AislesQuery = { __typename?: 'Query', aisles: { __typename?: 'IslesPaginations', totalCount: number, nodes: Array<{ __typename?: 'Aisle', id: number, key: string, bays?: Array<{ __typename?: 'Bay', id: number, key: string, shelves?: Array<{ __typename?: 'Shelf', id: number, key: string, positions?: Array<{ __typename?: 'Position', id: number }> | null }> | null }> | null }> } };

export type EditAisleMutationVariables = Exact<{
  aisleId: Scalars['Int'];
  input: EditAisleInput;
}>;


export type EditAisleMutation = { __typename?: 'Mutation', editAisle: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type AssetDownloadQueryVariables = Exact<{
  fileId: Scalars['Int'];
}>;


export type AssetDownloadQuery = { __typename?: 'Query', getAsset?: { __typename?: 'AssetDownload', url?: string | null } | null };

export type CreateFolderMutationVariables = Exact<{
  input: FolderInput;
}>;


export type CreateFolderMutation = { __typename?: 'Mutation', createFolder: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type DeleteFileMutationVariables = Exact<{
  fileId: Scalars['Int'];
}>;


export type DeleteFileMutation = { __typename?: 'Mutation', deleteFile: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type EditFileMutationVariables = Exact<{
  fileId: Scalars['Int'];
  input: EditFileInput;
}>;


export type EditFileMutation = { __typename?: 'Mutation', editFile: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type FileStructureQueryVariables = Exact<{
  where?: InputMaybe<FileStructureFilter>;
}>;


export type FileStructureQuery = { __typename?: 'Query', getFileStructure?: Array<{ __typename?: 'File', id: number, duration?: number | null, originalname?: string | null, filename?: string | null, mimetype?: string | null, isFolder: boolean, thumbnail?: string | null }> | null };

export type FilesQueryVariables = Exact<{
  where?: InputMaybe<FileFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type FilesQuery = { __typename?: 'Query', files?: { __typename?: 'FilesResponsePagination', totalCount: number, nodes: Array<{ __typename?: 'File', id: number, duration?: number | null, isFolder: boolean, originalname?: string | null, filename?: string | null, mimetype?: string | null, thumbnail?: string | null, width?: number | null, height?: number | null }> } | null };

export type MultiUploadMutationVariables = Exact<{
  files: Array<Scalars['Upload']> | Scalars['Upload'];
  parentFolderId?: InputMaybe<Scalars['Int']>;
  productAssign?: InputMaybe<Scalars['Boolean']>;
}>;


export type MultiUploadMutation = { __typename?: 'Mutation', multiUpload?: Array<{ __typename?: 'MultiUploadResponse', errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null, file?: { __typename?: 'File', id: number, originalname?: string | null, filename?: string | null, mimetype?: string | null, encoding?: string | null, url?: string | null, isFolder: boolean, thumbnail?: string | null } | null }> | null };

export type AddBayMutationVariables = Exact<{
  input: BayInput;
}>;


export type AddBayMutation = { __typename?: 'Mutation', addBay: { __typename?: 'BayResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string } | null> | null } };

export type BayQueryVariables = Exact<{
  bayId: Scalars['Int'];
}>;


export type BayQuery = { __typename?: 'Query', bay: { __typename?: 'BayResponse', bay?: { __typename?: 'Bay', id: number, key: string, aisle?: { __typename?: 'Aisle', id: number } | null } | null } };

export type BaysQueryVariables = Exact<{
  where?: InputMaybe<BaysFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type BaysQuery = { __typename?: 'Query', bays: { __typename?: 'baysPaginations', totalCount: number, nodes: Array<{ __typename?: 'Bay', id: number, key: string, aisle?: { __typename?: 'Aisle', id: number, key: string } | null }> } };

export type EditBayMutationVariables = Exact<{
  bayId: Scalars['Int'];
  input: EditBayInput;
}>;


export type EditBayMutation = { __typename?: 'Mutation', editBay: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type AddChannelMutationVariables = Exact<{
  input: AddChannelInput;
}>;


export type AddChannelMutation = { __typename?: 'Mutation', addChannel: { __typename?: 'ChannelResponse', errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null, channel?: { __typename?: 'Channel', id: number } | null } };

export type ChannelQueryVariables = Exact<{
  channelId: Scalars['Int'];
}>;


export type ChannelQuery = { __typename?: 'Query', channel: { __typename?: 'ChannelResponse', channel?: { __typename?: 'Channel', id: number, name?: string | null, tags?: Array<string> | null, type: SignageDeviceType, channelItems?: Array<{ __typename?: 'ChannelItem', id: number, priority?: boolean | null, duration?: number | null, sequence: number, display?: { __typename?: 'Display', id: number } | null, file?: { __typename?: 'File', id: number, duration?: number | null, encoding?: string | null, isFolder: boolean, filename?: string | null, originalname?: string | null, mimetype?: string | null, thumbnail?: string | null } | null, playlist?: { __typename?: 'Playlist', id: number, name: string, playlistItems: Array<{ __typename?: 'PlaylistItem', duration: number, file?: { __typename?: 'File', id: number, encoding?: string | null, isFolder: boolean, filename?: string | null, originalname?: string | null, mimetype?: string | null, thumbnail?: string | null } | null }> } | null, schedule: { __typename?: 'Schedule', dateRange?: Array<any | null> | null, weekdays: Array<WeekDay>, id: number, timeFrames?: Array<string | null> | null } }> | null, displays?: Array<{ __typename?: 'Display', id: number, name?: string | null, height: number, width: number, type: DisplayType }> | null } | null } };

export type ChannelsQueryVariables = Exact<{
  where?: InputMaybe<ChannelsFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type ChannelsQuery = { __typename?: 'Query', channels: { __typename?: 'ChannelsPagination', totalCount: number, nodes?: Array<{ __typename?: 'Channel', id: number, name?: string | null, tags?: Array<string> | null, type: SignageDeviceType, displays?: Array<{ __typename?: 'Display', id: number, type: DisplayType }> | null, channelItems?: Array<{ __typename?: 'ChannelItem', id: number, schedule: { __typename?: 'Schedule', id: number, weekdays: Array<WeekDay>, timeFrames?: Array<string | null> | null, dateRange?: Array<any | null> | null } }> | null, tvDevices?: Array<{ __typename?: 'TVDevice', id: number } | null> | null, scales?: Array<{ __typename?: 'Scale', id: number } | null> | null }> | null } };

export type ChannelsSelectQueryVariables = Exact<{
  where?: InputMaybe<ChannelsFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type ChannelsSelectQuery = { __typename?: 'Query', channels: { __typename?: 'ChannelsPagination', nodes?: Array<{ __typename?: 'Channel', id: number, name?: string | null }> | null } };

export type DeleteChannelMutationVariables = Exact<{
  channelId: Scalars['Int'];
}>;


export type DeleteChannelMutation = { __typename?: 'Mutation', deleteChannel: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', message: string, path: string }> | null } };

export type EditPlaylistMutationVariables = Exact<{
  playlistId: Scalars['Int'];
  input: EditPlaylistInput;
}>;


export type EditPlaylistMutation = { __typename?: 'Mutation', editPlaylist: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type TagsQueryVariables = Exact<{
  where?: InputMaybe<TagsFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type TagsQuery = { __typename?: 'Query', tags: { __typename?: 'TagsPagination', nodes?: Array<string> | null } };

export type DashSettingsQueryVariables = Exact<{
  customer: Scalars['String'];
}>;


export type DashSettingsQuery = { __typename?: 'Query', dashSettings?: { __typename?: 'DashSettings', logo?: string | null, loginLogo?: string | null, primaryColor?: string | null, textColor?: string | null } | null };

export type AddTemplateMutationVariables = Exact<{
  input: TemplateInput;
}>;


export type AddTemplateMutation = { __typename?: 'Mutation', addTemplate: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type AddTemplateVariantMutationVariables = Exact<{
  input: TemplateVariantInput;
}>;


export type AddTemplateVariantMutation = { __typename?: 'Mutation', addTemplateVariant: { __typename?: 'TemplateVariantResponse', errors?: Array<{ __typename?: 'Error', message: string, path: string }> | null, templateVariant?: { __typename?: 'TemplateVariant', id: number } | null } };

export type ComponentVarsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  where?: InputMaybe<ComponentVarsFilter>;
}>;


export type ComponentVarsQuery = { __typename?: 'Query', componentVars: { __typename?: 'ComponentVarsPagination', nodes?: Array<{ __typename?: 'ComponentVar', id: number, name?: string | null, templateKey?: string | null, type?: DeviceValueType | null }> | null } };

export type DeleteTemplateMutationVariables = Exact<{
  input: DeleteTemplateInput;
}>;


export type DeleteTemplateMutation = { __typename?: 'Mutation', deleteTemplate: { __typename?: 'DeleteResponse', affected?: number | null, success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type DeleteTemplateVariantMutationVariables = Exact<{
  input: DeleteTemplateVariantInput;
}>;


export type DeleteTemplateVariantMutation = { __typename?: 'Mutation', deleteTemplateVariant: { __typename?: 'DeleteResponse', affected?: number | null, success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type DeleteTemplatesMutationVariables = Exact<{
  input: DeleteTemplatesInput;
}>;


export type DeleteTemplatesMutation = { __typename?: 'Mutation', deleteTemplates: { __typename?: 'DeleteResponse', affected?: number | null, success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type DisplayTemplateQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DisplayTemplateQuery = { __typename?: 'Query', template: { __typename?: 'TemplateResponse', template?: { __typename?: 'Template', id: number, deviceSize?: TemplateType | null, isCustom?: boolean | null, name?: string | null, width?: number | null, height?: number | null, templateVariant?: { __typename?: 'TemplateVariant', id: number, key: string, isDefault: boolean } | null, components?: Array<{ __typename?: 'Component', id: number, name?: string | null, w?: number | null, h?: number | null, x?: number | null, y?: number | null, htmlContent?: string | null, type?: ComponentType | null }> | null, templateVariables?: Array<{ __typename?: 'ComponentVar', id: number, name?: string | null, maxLength?: number | null, templateKey?: string | null }> | null } | null } };

export type DisplayTemplatesQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  where?: InputMaybe<TemplatesFilter>;
}>;


export type DisplayTemplatesQuery = { __typename?: 'Query', templates: { __typename?: 'TemplatesPagination', totalCount: number, nodes?: Array<{ __typename?: 'Template', id: number, deviceSize?: TemplateType | null, name?: string | null, height?: number | null, width?: number | null, templateVariant?: { __typename?: 'TemplateVariant', id: number } | null }> | null } };

export type EditTemplateMutationVariables = Exact<{
  templateId: Scalars['Int'];
  input: EditTemplateInput;
}>;


export type EditTemplateMutation = { __typename?: 'Mutation', editTemplate: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type EditTemplateVariantMutationVariables = Exact<{
  templateVariantId: Scalars['Int'];
  input: EditTemplateVariantInput;
}>;


export type EditTemplateVariantMutation = { __typename?: 'Mutation', editTemplateVariant: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type ParseTemplateQueryVariables = Exact<{
  templateId: Scalars['Int'];
  productId: Scalars['Int'];
}>;


export type ParseTemplateQuery = { __typename?: 'Query', parseTemplate: { __typename?: 'ParseTemplateResponse', template?: string | null, errors?: Array<{ __typename?: 'Error', message: string, path: string }> | null } };

export type TemplateVariantQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type TemplateVariantQuery = { __typename?: 'Query', templateVariant: { __typename?: 'TemplateVariantResponse', templateVariant?: { __typename?: 'TemplateVariant', id: number, key: string } | null } };

export type TemplateVariantsQueryVariables = Exact<{
  where?: InputMaybe<TemplateVariantsFilter>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type TemplateVariantsQuery = { __typename?: 'Query', templateVariants: { __typename?: 'TemplateVariantsPagination', totalCount: number, nodes?: Array<{ __typename?: 'TemplateVariant', id: number, key: string, isDefault: boolean, inUse?: boolean | null, templates?: Array<{ __typename?: 'Template', id: number, deviceSize?: TemplateType | null, name?: string | null, height?: number | null, width?: number | null, isCustom?: boolean | null }> | null }> | null } };

export type AddFlowMutationVariables = Exact<{
  input: AddFlowInput;
}>;


export type AddFlowMutation = { __typename?: 'Mutation', addFlow: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type EditFlowMutationVariables = Exact<{
  flowId: Scalars['Int'];
  input: EditFlowInput;
}>;


export type EditFlowMutation = { __typename?: 'Mutation', editFlow: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type FlowQueryVariables = Exact<{
  flowId: Scalars['Int'];
}>;


export type FlowQuery = { __typename?: 'Query', flow: { __typename?: 'FlowResponse', flow?: { __typename?: 'Flow', id: number, name?: string | null, flowStatements: Array<{ __typename?: 'FlowStatement', id: number, type: FlowStatementType, flowComponents: Array<{ __typename?: 'FlowComponent', id: number, type: FlowComponentType, value: string }> }> } | null } };

export type FlowsQueryVariables = Exact<{
  where?: InputMaybe<FlowsFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type FlowsQuery = { __typename?: 'Query', flows: { __typename?: 'FlowsPaginations', nodes: Array<{ __typename?: 'Flow', id: number, name?: string | null, flowStatements: Array<{ __typename?: 'FlowStatement', id: number, type: FlowStatementType, flowComponents: Array<{ __typename?: 'FlowComponent', id: number, type: FlowComponentType, value: string }> }> }> } };

export type AddFlowRunnerMutationVariables = Exact<{
  input: AddFlowRunnerInput;
}>;


export type AddFlowRunnerMutation = { __typename?: 'Mutation', addFlowRunner: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type EditFlowRunnerMutationVariables = Exact<{
  flowRunnerId: Scalars['Int'];
  input: EditFlowRunnerInput;
}>;


export type EditFlowRunnerMutation = { __typename?: 'Mutation', editFlowRunner: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type FlowRunnerQueryVariables = Exact<{
  flowRunnerId: Scalars['Int'];
}>;


export type FlowRunnerQuery = { __typename?: 'Query', flowRunner: { __typename?: 'FlowRunnerResponse', flowRunner?: { __typename?: 'FlowRunner', id: number, name?: string | null, validFrom?: any | null, validTo?: any | null, timeFrom?: any | null, timeTo?: any | null, flow: { __typename?: 'Flow', id: number, name?: string | null }, products?: Array<{ __typename?: 'Product', id: number, internalId: string, name?: string | null, valueEntities?: Array<{ __typename?: 'Value', name?: string | null, templateKey: string, value: string, type?: DeviceValueType | null }> | null } | null> | null } | null } };

export type FlowRunnersQueryVariables = Exact<{
  where?: InputMaybe<FlowRunnersFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type FlowRunnersQuery = { __typename?: 'Query', flowRunners: { __typename?: 'FlowRunnersPaginations', nodes: Array<{ __typename?: 'FlowRunner', id: number, name?: string | null, validTo?: any | null, validFrom?: any | null, timeFrom?: any | null, timeTo?: any | null, flow: { __typename?: 'Flow', id: number, name?: string | null } }> } };

export type InfoQueryVariables = Exact<{ [key: string]: never; }>;


export type InfoQuery = { __typename?: 'Query', info?: { __typename?: 'InfoResponse', tenant: string, smartLabelCount?: number | null, sensorLabelCount?: number | null, shelfBarCount?: number | null, signageDeviceCount?: number | null, industrialScaleCount?: number | null, rootCount?: number | null, routerCount?: number | null, batteryUnderCount?: number | null, deviceErrCount?: number | null, apiErrCount?: number | null, processingErrCount?: number | null } | null };

export type AddIntegrationMutationVariables = Exact<{
  input: AddIntegrationInput;
}>;


export type AddIntegrationMutation = { __typename?: 'Mutation', addIntegration: { __typename?: 'AddIntegrationResponse', integration?: { __typename?: 'Integration', id: number } | null, errors?: Array<{ __typename?: 'Error', path: string, message: string } | null> | null } };

export type DeleteIntegrationMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteIntegrationMutation = { __typename?: 'Mutation', deleteIntegration: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type EditIntegrationMutationVariables = Exact<{
  input: EditIntegrationInput;
}>;


export type EditIntegrationMutation = { __typename?: 'Mutation', editIntegration: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type IntegrationQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
  token?: InputMaybe<Scalars['String']>;
}>;


export type IntegrationQuery = { __typename?: 'Query', integration: { __typename?: 'IntegrationResponse', integration?: { __typename?: 'Integration', id: number, name?: string | null, token: string, syncTime: string, weekDays: Array<WeekDay>, triggerUrl?: string | null, cronUpdateUrl?: string | null, synchronizations?: Array<{ __typename?: 'Synchronization', id: number, status: SynchronizationStatus, time: any, updatedProducts: Array<string>, note?: string | null }> | null } | null } };

export type IntegrationsQueryVariables = Exact<{
  where?: InputMaybe<IntegrationsFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type IntegrationsQuery = { __typename?: 'Query', integrations: { __typename?: 'IntegrationsPagination', nodes?: Array<{ __typename?: 'Integration', id: number, name?: string | null }> | null } };

export type SynchronizationsQueryVariables = Exact<{
  where?: InputMaybe<SynchronizationsFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type SynchronizationsQuery = { __typename?: 'Query', synchronizations: { __typename?: 'SynchronizationsPagination', nodes?: Array<{ __typename?: 'Synchronization', id: number, status: SynchronizationStatus }> | null } };

export type TriggerSyncMutationVariables = Exact<{
  input: TriggerSyncInput;
}>;


export type TriggerSyncMutation = { __typename?: 'Mutation', triggerSync: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type EditDemoScreenMutationVariables = Exact<{
  input: EditDemoScreenInput;
}>;


export type EditDemoScreenMutation = { __typename?: 'Mutation', editDemoScreen: { __typename?: 'DemoScreenResponse', success: boolean, filename?: string | null } };

export type AddPositionMutationVariables = Exact<{
  input: PositionInput;
}>;


export type AddPositionMutation = { __typename?: 'Mutation', addPosition: { __typename?: 'PositionResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', message: string } | null> | null } };

export type DeletePositionMutationVariables = Exact<{
  positionId: Scalars['Int'];
}>;


export type DeletePositionMutation = { __typename?: 'Mutation', deletePosition: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', message: string }> | null } };

export type DeletePositionAndUntieDeviceMutationVariables = Exact<{
  positionId: Scalars['Int'];
}>;


export type DeletePositionAndUntieDeviceMutation = { __typename?: 'Mutation', deletePositionAndUntieDevice: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', message: string }> | null } };

export type EditPositionMutationVariables = Exact<{
  positionId: Scalars['Int'];
  input: EditPositionInput;
}>;


export type EditPositionMutation = { __typename?: 'Mutation', editPosition: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', message: string, path: string }> | null } };

export type EditPositionOldPositionMutationVariables = Exact<{
  positionId: Scalars['Int'];
  positionInput: EditPositionInput;
  oldPositionId: Scalars['Int'];
  oldPositionInput: EditPositionInput;
}>;


export type EditPositionOldPositionMutation = { __typename?: 'Mutation', A: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', message: string, path: string }> | null }, B: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', message: string, path: string }> | null } };

export type PlanogramPositionQueryVariables = Exact<{
  positionId: Scalars['Int'];
}>;


export type PlanogramPositionQuery = { __typename?: 'Query', position: { __typename?: 'PositionResponse', position?: { __typename?: 'Position', id: number, key?: string | null, sequence?: number | null, x?: number | null, y?: number | null, z?: number | null, shelf?: { __typename?: 'Shelf', id: number } | null, product?: { __typename?: 'Product', id: number, internalId: string, name?: string | null, parentProduct?: { __typename?: 'Product', name?: string | null } | null } | null, root?: { __typename?: 'Root', id: number } | null, device?: { __typename?: 'Device', id: number, serialNumber: string } | null, templateVariant?: { __typename?: 'TemplateVariant', id: number } | null } | null } };

export type PlanogramPositionsQueryVariables = Exact<{
  where?: InputMaybe<PositionsFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type PlanogramPositionsQuery = { __typename?: 'Query', positions: { __typename?: 'PositionsPaginations', nodes: Array<{ __typename?: 'Position', id: number, key?: string | null, sequence?: number | null, shelf?: { __typename?: 'Shelf', key: string, bay?: { __typename?: 'Bay', key: string, aisle?: { __typename?: 'Aisle', key: string } | null } | null } | null, device?: { __typename?: 'Device', id: number, serialNumber: string } | null, templateVariant?: { __typename?: 'TemplateVariant', id: number, key: string } | null, product?: { __typename?: 'Product', id: number, internalId: string, name?: string | null, parentProduct?: { __typename?: 'Product', id: number, name?: string | null } | null } | null }> } };

export type PlanogramRootQueryVariables = Exact<{
  rootId: Scalars['Int'];
}>;


export type PlanogramRootQuery = { __typename?: 'Query', root: { __typename?: 'RootResponse', root?: { __typename?: 'Root', name?: string | null } | null } };

export type StockPlanogramPositionsQueryVariables = Exact<{
  where?: InputMaybe<PositionsFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type StockPlanogramPositionsQuery = { __typename?: 'Query', positions: { __typename?: 'PositionsPaginations', nodes: Array<{ __typename?: 'Position', id: number, key?: string | null, sequence?: number | null, device?: { __typename?: 'Device', id: number, serialNumber: string } | null, templateVariant?: { __typename?: 'TemplateVariant', id: number, key: string } | null, product?: { __typename?: 'Product', id: number, internalId: string, parentProduct?: { __typename?: 'Product', id: number, name?: string | null } | null } | null }> } };

export type AddPlaylistMutationVariables = Exact<{
  input: AddPlaylistInput;
}>;


export type AddPlaylistMutation = { __typename?: 'Mutation', addPlaylist: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type EditChannelMutationVariables = Exact<{
  channelId: Scalars['Int'];
  input: EditChannelInput;
}>;


export type EditChannelMutation = { __typename?: 'Mutation', editChannel: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type PlaylistQueryVariables = Exact<{
  playlistId: Scalars['Int'];
}>;


export type PlaylistQuery = { __typename?: 'Query', playlist: { __typename?: 'PlaylistResponse', playlist?: { __typename?: 'Playlist', id: number, name: string, playlistItems: Array<{ __typename?: 'PlaylistItem', id: number, duration: number, sequence?: number | null, file?: { __typename?: 'File', id: number, duration?: number | null, encoding?: string | null, mimetype?: string | null, filename?: string | null, thumbnail?: string | null } | null }> } | null } };

export type PlaylistsQueryVariables = Exact<{
  where?: InputMaybe<PlaylistsFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type PlaylistsQuery = { __typename?: 'Query', playlists: { __typename?: 'PlaylistsPagination', nodes?: Array<{ __typename?: 'Playlist', id: number, name: string }> | null } };

export type PlaylistsWithItemsQueryVariables = Exact<{
  where?: InputMaybe<PlaylistsFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type PlaylistsWithItemsQuery = { __typename?: 'Query', playlists: { __typename?: 'PlaylistsPagination', nodes?: Array<{ __typename?: 'Playlist', id: number, name: string, playlistItems: Array<{ __typename?: 'PlaylistItem', duration: number, file?: { __typename?: 'File', mimetype?: string | null, filename?: string | null, thumbnail?: string | null } | null }> }> | null } };

export type AddEditProductsMutationVariables = Exact<{
  input: Array<ProductInput> | ProductInput;
}>;


export type AddEditProductsMutation = { __typename?: 'Mutation', addEditProducts: { __typename?: 'addEditProductsResponse', success?: boolean | null, isAssignedToShelves?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null, addedProducts?: Array<{ __typename?: 'Product', id: number, internalId: string, name?: string | null, eanCodes?: Array<string> | null, valueEntities?: Array<{ __typename?: 'Value', id: number, name?: string | null, templateKey: string, type?: DeviceValueType | null, value: string }> | null, productImages?: Array<{ __typename?: 'ProductImage', id: number, primary: boolean, file: { __typename?: 'File', id: number, originalname?: string | null, filename?: string | null, mimetype?: string | null, encoding?: string | null, url?: string | null, isFolder: boolean, width?: number | null, height?: number | null, thumbnail?: string | null } } | null> | null }> | null } };

export type AddProductMutationVariables = Exact<{
  input: ProductInput;
}>;


export type AddProductMutation = { __typename?: 'Mutation', addProduct: { __typename?: 'AddProductResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type DeleteProductMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteProductMutation = { __typename?: 'Mutation', deleteProduct: { __typename?: 'DeleteProductResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', message: string, path: string }> | null } };

export type DeleteProductsMutationVariables = Exact<{
  input: DeleteProductInput;
}>;


export type DeleteProductsMutation = { __typename?: 'Mutation', deleteProducts: { __typename?: 'DeleteResponse', affected?: number | null, success?: boolean | null, errors?: Array<{ __typename?: 'Error', message: string, path: string }> | null } };

export type DeleteValueEntitiesMutationVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type DeleteValueEntitiesMutation = { __typename?: 'Mutation', deleteValueEntities: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', message: string, path: string }> | null } };

export type EditProductMutationVariables = Exact<{
  input: EditProductInput;
}>;


export type EditProductMutation = { __typename?: 'Mutation', editProduct: { __typename?: 'EditProductResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type ProductDetailsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ProductDetailsQuery = { __typename?: 'Query', product: { __typename?: 'ProductResponse', product?: { __typename?: 'Product', id: number, internalId: string, name?: string | null, valueEntities?: Array<{ __typename?: 'Value', id: number, templateKey: string, type?: DeviceValueType | null, value: string, rootId?: number | null }> | null, parentProduct?: { __typename?: 'Product', id: number, internalId: string } | null, products?: Array<{ __typename?: 'Product', id: number, internalId: string }> | null } | null } };

export type ProductDetailsListQueryVariables = Exact<{
  where?: InputMaybe<ProductsFilter>;
}>;


export type ProductDetailsListQuery = { __typename?: 'Query', products: { __typename?: 'ProductsPaginations', nodes: Array<{ __typename?: 'Product', id: number, internalId: string, name?: string | null, valueEntities?: Array<{ __typename?: 'Value', name?: string | null, templateKey: string, type?: DeviceValueType | null, value: string }> | null, parentProduct?: { __typename?: 'Product', id: number, internalId: string } | null, products?: Array<{ __typename?: 'Product', id: number, internalId: string }> | null }> } };

export type ProductsQueryVariables = Exact<{
  where?: InputMaybe<ProductsFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type ProductsQuery = { __typename?: 'Query', products: { __typename?: 'ProductsPaginations', totalCount: number, nodes: Array<{ __typename?: 'Product', id: number, eanCodes?: Array<string> | null, internalId: string, name?: string | null, productImages?: Array<{ __typename?: 'ProductImage', id: number, file: { __typename?: 'File', id: number, thumbnail?: string | null } } | null> | null }> } };

export type SearchProductsQueryVariables = Exact<{
  where?: InputMaybe<ProductsFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type SearchProductsQuery = { __typename?: 'Query', products: { __typename?: 'ProductsPaginations', totalCount: number, nodes: Array<{ __typename?: 'Product', id: number, eanCodes?: Array<string> | null, internalId: string, name?: string | null, parentProduct?: { __typename?: 'Product', name?: string | null } | null }> } };

export type BatchTransactionsQueryVariables = Exact<{
  batchUUID: Scalars['String'];
}>;


export type BatchTransactionsQuery = { __typename?: 'Query', batchTransactions?: { __typename?: 'TransactionResponse', transactions?: Array<{ __typename?: 'Transaction', stage?: TransactionStage | null, transactionid?: string | null, ts?: string | null, device?: { __typename?: 'Device', size?: DeviceSize | null, serialNumber: string, product?: { __typename?: 'Product', name?: string | null, internalId: string } | null, template?: { __typename?: 'Template', templateVariant?: { __typename?: 'TemplateVariant', key: string } | null } | null, root?: { __typename?: 'Root', name?: string | null } | null, position?: { __typename?: 'Position', id: number, name?: string | null, key?: string | null, sequence?: number | null, shelf?: { __typename?: 'Shelf', id: number, key: string, bay?: { __typename?: 'Bay', id: number, key: string, aisle?: { __typename?: 'Aisle', id: number, key: string } | null } | null } | null } | null } | null }> | null } | null };

export type AddRoleMutationVariables = Exact<{
  input: AddRoleInput;
}>;


export type AddRoleMutation = { __typename?: 'Mutation', addRole: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type EditRolezMutationVariables = Exact<{
  roleId: Scalars['Int'];
  input: EditRoleInput;
}>;


export type EditRolezMutation = { __typename?: 'Mutation', editRole: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type DeleteRoleMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteRoleMutation = { __typename?: 'Mutation', deleteRole: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type EditRoleMutationVariables = Exact<{
  roleId: Scalars['Int'];
  input: EditRoleInput;
}>;


export type EditRoleMutation = { __typename?: 'Mutation', editRole: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type RoleQueryVariables = Exact<{
  roleId: Scalars['Int'];
}>;


export type RoleQuery = { __typename?: 'Query', role: { __typename?: 'RoleResponse', role?: { __typename?: 'Role', id: number, value?: string | null, permissions: Array<string> } | null } };

export type RolesQueryVariables = Exact<{
  where?: InputMaybe<RolesFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type RolesQuery = { __typename?: 'Query', roles: { __typename?: 'RolesPaginations', nodes: Array<{ __typename?: 'Role', id: number, value?: string | null }> } };

export type LocationsQueryVariables = Exact<{
  where?: InputMaybe<RootsFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type LocationsQuery = { __typename?: 'Query', roots?: { __typename?: 'RootsPaginations', nodes: Array<{ __typename?: 'Root', id: number, name?: string | null, latitude?: number | null, longitude?: number | null, deviceCount?: number | null }> } | null };

export type LocationsSelectQueryVariables = Exact<{
  where?: InputMaybe<RootsFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type LocationsSelectQuery = { __typename?: 'Query', roots?: { __typename?: 'RootsPaginations', nodes: Array<{ __typename?: 'Root', id: number, key?: string | null, name?: string | null }> } | null };

export type RootsQueryVariables = Exact<{
  where?: InputMaybe<RootsFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type RootsQuery = { __typename?: 'Query', roots?: { __typename?: 'RootsPaginations', nodes: Array<{ __typename?: 'Root', id: number, name?: string | null }> } | null };

export type AddShelfMutationVariables = Exact<{
  input: ShelfInput;
}>;


export type AddShelfMutation = { __typename?: 'Mutation', addShelf: { __typename?: 'ShelfResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string } | null> | null } };

export type AddShelfStructMutationVariables = Exact<{
  input: ShelfStructInput;
}>;


export type AddShelfStructMutation = { __typename?: 'Mutation', addShelfStruct: { __typename?: 'ShelfStructResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type EditShelfMutationVariables = Exact<{
  shelfId: Scalars['Int'];
  input: EditShelfInput;
}>;


export type EditShelfMutation = { __typename?: 'Mutation', editShelf: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type ShelfQueryVariables = Exact<{
  shelfId: Scalars['Int'];
}>;


export type ShelfQuery = { __typename?: 'Query', shelf: { __typename?: 'ShelfResponse', shelf?: { __typename?: 'Shelf', id: number, key: string, aisle?: { __typename?: 'Aisle', id: number } | null, bay?: { __typename?: 'Bay', id: number } | null, root?: { __typename?: 'Root', id: number } | null, positions?: Array<{ __typename?: 'Position', id: number, name?: string | null, sequence?: number | null, product?: { __typename?: 'Product', id: number, internalId: string, name?: string | null, parentProduct?: { __typename?: 'Product', name?: string | null } | null } | null, device?: { __typename?: 'Device', id: number, serialNumber: string } | null, root?: { __typename?: 'Root', id: number } | null }> | null } | null } };

export type ShelvesQueryVariables = Exact<{
  where?: InputMaybe<ShelvesFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type ShelvesQuery = { __typename?: 'Query', shelves: { __typename?: 'shelvesPaginations', totalCount: number, nodes: Array<{ __typename?: 'Shelf', id: number, key: string, bay?: { __typename?: 'Bay', key: string, aisle?: { __typename?: 'Aisle', key: string } | null } | null }> } };

export type ClearDeviceMutationVariables = Exact<{
  deviceId: Scalars['Int'];
}>;


export type ClearDeviceMutation = { __typename?: 'Mutation', clearDevice: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type DeleteDeviceMutationVariables = Exact<{
  deviceId: Scalars['Int'];
}>;


export type DeleteDeviceMutation = { __typename?: 'Mutation', deleteDevice: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type DeviceQueryVariables = Exact<{
  deviceSerialNumber: Scalars['String'];
}>;


export type DeviceQuery = { __typename?: 'Query', device: { __typename?: 'DeviceResponse', device?: { __typename?: 'Device', id: number, serialNumber: string, name?: string | null, size?: DeviceSize | null, firmwareVersion?: string | null, deviceMode?: DeviceMode | null, lastSync?: string | null, batteryLevel?: number | null, batteryCharging?: boolean | null, batteryError?: boolean | null, active?: boolean | null, rssi?: number | null, shortPressAction?: ButtonActions | null, longPressAction?: ButtonActions | null, shortPressEnabled: boolean, longPressEnabled: boolean, deviceType?: DeviceType | null, rotated?: boolean | null, config?: { __typename?: 'DeviceConfig', id: number, sleepTime: number, highPowerMode: boolean } | null, root?: { __typename?: 'Root', id: number, name?: string | null } | null, template?: { __typename?: 'Template', id: number, name?: string | null, width?: number | null, templateVariant?: { __typename?: 'TemplateVariant', id: number, key: string } | null } | null, position?: { __typename?: 'Position', id: number, name?: string | null, key?: string | null, sequence?: number | null, shelf?: { __typename?: 'Shelf', id: number, key: string, bay?: { __typename?: 'Bay', id: number, key: string, aisle?: { __typename?: 'Aisle', id: number, key: string } | null } | null } | null } | null, product?: { __typename?: 'Product', id: number, name?: string | null, internalId: string, parentProduct?: { __typename?: 'Product', name?: string | null } | null } | null, lastTransaction?: { __typename?: 'Transaction', stage?: TransactionStage | null, ts?: string | null } | null, lastConfigTransaction?: { __typename?: 'Transaction', stage?: TransactionStage | null, ts?: string | null } | null, shelf?: { __typename?: 'Shelf', id: number, key: string, bay?: { __typename?: 'Bay', id: number, key: string, aisle?: { __typename?: 'Aisle', id: number, key: string } | null } | null } | null } | null } };

export type AddDeviceConfigMutationVariables = Exact<{
  input: DeviceConfigInput;
}>;


export type AddDeviceConfigMutation = { __typename?: 'Mutation', addDeviceConfig: { __typename?: 'DeviceConfigResponse', errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null, deviceConfig?: { __typename?: 'DeviceConfig', id: number } | null } };

export type EditDeviceConfigMutationVariables = Exact<{
  input: EditDeviceConfigInput;
}>;


export type EditDeviceConfigMutation = { __typename?: 'Mutation', editDeviceConfig: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type DevicePositionsQueryVariables = Exact<{
  where?: InputMaybe<PositionsFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type DevicePositionsQuery = { __typename?: 'Query', positions: { __typename?: 'PositionsPaginations', nodes: Array<{ __typename?: 'Position', id: number, key?: string | null, device?: { __typename?: 'Device', id: number } | null }> } };

export type DevicePreviewQueryVariables = Exact<{
  deviceSerialNumber: Scalars['String'];
}>;


export type DevicePreviewQuery = { __typename?: 'Query', devicePreview: { __typename?: 'DevicePreviewResponse', current?: string | null, preview?: string | null } };

export type DeviceTransactionsQueryVariables = Exact<{
  deviceId: Scalars['Int'];
}>;


export type DeviceTransactionsQuery = { __typename?: 'Query', deviceTransactions?: { __typename?: 'TransactionResponse', transactions?: Array<{ __typename?: 'Transaction', transactionid?: string | null, ts?: string | null, stage?: TransactionStage | null, template?: { __typename?: 'Template', id: number, templateVariant?: { __typename?: 'TemplateVariant', id: number, key: string, isDefault: boolean } | null } | null }> | null } | null };

export type DevicesQueryVariables = Exact<{
  where?: InputMaybe<DevicesFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type DevicesQuery = { __typename?: 'Query', devices: { __typename?: 'DevicesPagination', totalCount: number, nodes?: Array<{ __typename?: 'Device', id: number, serialNumber: string, name?: string | null, size?: DeviceSize | null, deviceType?: DeviceType | null, lastSync?: string | null, batteryLevel?: number | null, batteryCharging?: boolean | null, batteryError?: boolean | null, active?: boolean | null, rssi?: number | null, lastTransaction?: { __typename?: 'Transaction', ts?: string | null, stage?: TransactionStage | null } | null, position?: { __typename?: 'Position', key?: string | null, sequence?: number | null, shelf?: { __typename?: 'Shelf', key: string, bay?: { __typename?: 'Bay', key: string, aisle?: { __typename?: 'Aisle', key: string } | null } | null } | null } | null, root?: { __typename?: 'Root', id: number, name?: string | null } | null, product?: { __typename?: 'Product', name?: string | null, internalId: string, parentProduct?: { __typename?: 'Product', name?: string | null } | null } | null, template?: { __typename?: 'Template', id: number, name?: string | null, width?: number | null } | null, shelf?: { __typename?: 'Shelf', key: string, bay?: { __typename?: 'Bay', key: string, aisle?: { __typename?: 'Aisle', key: string } | null } | null } | null }> | null } };

export type EditDeviceMutationVariables = Exact<{
  deviceId: Scalars['Int'];
  input: EditDeviceInput;
}>;


export type EditDeviceMutation = { __typename?: 'Mutation', editDevice: { __typename?: 'EditDeviceResponse', success: boolean, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type ForceResetDeviceMutationVariables = Exact<{
  deviceId: Scalars['Int'];
}>;


export type ForceResetDeviceMutation = { __typename?: 'Mutation', forceResetDevice: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type GroupsQueryVariables = Exact<{
  where?: InputMaybe<GroupsFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type GroupsQuery = { __typename?: 'Query', groups: { __typename?: 'GroupsPaginations', nodes: Array<{ __typename?: 'Group', id: number, name?: string | null, level?: number | null }> } };

export type TemplatesQueryVariables = Exact<{
  where?: InputMaybe<TemplatesFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type TemplatesQuery = { __typename?: 'Query', templates: { __typename?: 'TemplatesPagination', nodes?: Array<{ __typename?: 'Template', id: number, name?: string | null, width?: number | null }> | null } };

export type AddSmartSensorLabelMutationVariables = Exact<{
  input: AddSensorInput;
}>;


export type AddSmartSensorLabelMutation = { __typename?: 'Mutation', addSensor: { __typename?: 'SensorResponse', errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null, sensor?: { __typename?: 'Sensor', id: number } | null } };

export type EditSmartSensorLabelMutationVariables = Exact<{
  id: Scalars['Int'];
  input: EditSensorInput;
}>;


export type EditSmartSensorLabelMutation = { __typename?: 'Mutation', editSensor: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type SmartSensorLabelQueryVariables = Exact<{
  serialNumber: Scalars['String'];
}>;


export type SmartSensorLabelQuery = { __typename?: 'Query', sensor: { __typename?: 'SensorExtendedResponse', sensor?: { __typename?: 'SensorExtended', id: number, battery?: number | null, serialNumber: any, name?: string | null, tags?: Array<string | null> | null, root?: { __typename?: 'Root', id: number, name?: string | null } | null } | null } };

export type SmartSensorLabelMeasurementsQueryVariables = Exact<{
  serialNumber: Scalars['String'];
  measurementType: MeasurementType;
  from: Scalars['DateTime'];
  to?: InputMaybe<Scalars['DateTime']>;
}>;


export type SmartSensorLabelMeasurementsQuery = { __typename?: 'Query', sensor: { __typename?: 'SensorExtendedResponse', sensor?: { __typename?: 'SensorExtended', measurement?: Array<{ __typename?: 'Measurement', _time?: any | null, _value?: number | null } | null> | null } | null } };

export type SmartSensorLabelsQueryVariables = Exact<{
  where?: InputMaybe<SensorsFilter>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type SmartSensorLabelsQuery = { __typename?: 'Query', sensors: { __typename?: 'SensorsPagination', nodes: Array<{ __typename?: 'SensorExtended', id: number, battery?: number | null, serialNumber: any, name?: string | null, tags?: Array<string | null> | null, active?: boolean | null, root?: { __typename?: 'Root', name?: string | null } | null } | null> } };

export type EditSmartShelfBarMutationVariables = Exact<{
  barId: Scalars['Int'];
  input: EditBarInput;
}>;


export type EditSmartShelfBarMutation = { __typename?: 'Mutation', editBar: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type SmartShelfBarQueryVariables = Exact<{
  barId?: InputMaybe<Scalars['Int']>;
}>;


export type SmartShelfBarQuery = { __typename?: 'Query', bar: { __typename?: 'BarResponse', bar?: { __typename?: 'Bar', id: number, macAddress?: any | null, name?: string | null, size: DeviceSize, x?: number | null, backgroundFile?: { __typename?: 'File', id: number, filename?: string | null, mimetype?: string | null } | null, group?: { __typename?: 'Group', id: number, name?: string | null } | null, devices?: Array<{ __typename?: 'Device', id: number, serialNumber: string, x?: number | null, y?: number | null, z?: number | null, product?: { __typename?: 'Product', id: number, name?: string | null, internalId: string } | null, template?: { __typename?: 'Template', width?: number | null, templateVariant?: { __typename?: 'TemplateVariant', id: number } | null } | null, position?: { __typename?: 'Position', x?: number | null, sequence?: number | null, product?: { __typename?: 'Product', id: number } | null, templateVariant?: { __typename?: 'TemplateVariant', id: number } | null } | null }> | null, shelf?: { __typename?: 'Shelf', id: number } | null, root?: { __typename?: 'Root', id: number } | null } | null } };

export type SmartShelfBarsQueryVariables = Exact<{
  where?: InputMaybe<BarsFilter>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type SmartShelfBarsQuery = { __typename?: 'Query', bars: { __typename?: 'BarsPagination', nodes: Array<{ __typename?: 'Bar', id: number, macAddress?: any | null, name?: string | null, size: DeviceSize, root?: { __typename?: 'Root', name?: string | null } | null, shelf?: { __typename?: 'Shelf', key: string, bay?: { __typename?: 'Bay', key: string, aisle?: { __typename?: 'Aisle', key: string } | null } | null } | null } | null> } };

export type EditScaleMutationVariables = Exact<{
  scaleId: Scalars['Int'];
  input: EditScaleInput;
}>;


export type EditScaleMutation = { __typename?: 'Mutation', editScale: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type EditTvDeviceMutationVariables = Exact<{
  tvDeviceId: Scalars['Int'];
  input: EditTvDeviceInput;
}>;


export type EditTvDeviceMutation = { __typename?: 'Mutation', editTvDevice: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type RegisterScaleMutationVariables = Exact<{
  input: ScaleRegisterInput;
}>;


export type RegisterScaleMutation = { __typename?: 'Mutation', registerScale: { __typename?: 'ScaleResponse', errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null, scale?: { __typename?: 'Scale', id: number } | null } };

export type ScaleQueryVariables = Exact<{
  scaleId: Scalars['Int'];
}>;


export type ScaleQuery = { __typename?: 'Query', scale: { __typename?: 'ScaleResponse', scale?: { __typename?: 'Scale', id: number, name?: string | null, username: string, ip: any, tags?: Array<string> | null, type: DisplayType, root?: { __typename?: 'Root', id: number } | null, channel?: { __typename?: 'Channel', id: number } | null } | null } };

export type ScalesQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  where?: InputMaybe<ScalesFilter>;
}>;


export type ScalesQuery = { __typename?: 'Query', scales: { __typename?: 'ScalesPagination', totalCount: number, nodes: Array<{ __typename?: 'Scale', id: number, name?: string | null, ip: any, tags?: Array<string> | null, type: DisplayType, root?: { __typename?: 'Root', name?: string | null } | null, channel?: { __typename?: 'Channel', id: number, name?: string | null } | null } | null> } };

export type TvDevicesQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  where?: InputMaybe<TvDevicesFilter>;
}>;


export type TvDevicesQuery = { __typename?: 'Query', tvDevices: { __typename?: 'TVDevicesPagination', totalCount: number, nodes: Array<{ __typename?: 'TVDevice', id: number, token?: string | null, uuid?: string | null, name?: string | null, width?: number | null, height?: number | null, tags?: Array<string | null> | null, root?: { __typename?: 'Root', name?: string | null } | null, channel?: { __typename?: 'Channel', id: number, name?: string | null } | null } | null> } };

export type AddUserMutationVariables = Exact<{
  input: AddUserInput;
}>;


export type AddUserMutation = { __typename?: 'Mutation', addUser: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type AdminDisable2FaMutationVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type AdminDisable2FaMutation = { __typename?: 'Mutation', adminDisable2FA: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type CustomerInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomerInfoQuery = { __typename?: 'Query', customerInfo: { __typename?: 'CustomerInfoResponse', purchasedModules?: Array<PurchasedModules> | null } };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type Disable2FaMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type Disable2FaMutation = { __typename?: 'Mutation', disable2FA: { __typename?: 'Disable2FAResponse', success: boolean, delta?: number | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type EditUserMutationVariables = Exact<{
  userId: Scalars['Int'];
  input: EditUserInput;
}>;


export type EditUserMutation = { __typename?: 'Mutation', editUser: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type Enable2FaMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type Enable2FaMutation = { __typename?: 'Mutation', enable2FA: { __typename?: 'Enable2FAResponse', success: boolean, delta?: number | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type Generate2FaSecretMutationVariables = Exact<{ [key: string]: never; }>;


export type Generate2FaSecretMutation = { __typename?: 'Mutation', generate2FASecret: { __typename?: 'Generate2FASecretResponse', secret: string, uri: string } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'MeResponse', id: number, email?: string | null, fullName?: string | null, allowedIPs?: Array<any | null> | null, rootIDs?: Array<number> | null, permissions?: Array<string> | null, roles?: Array<{ __typename?: 'Role', id: number, value?: string | null }> | null, ledConfig?: { __typename?: 'LedConfig', blinkSpeed?: BlinkSpeed | null, blinkTime?: BlinkTime | null, color?: LedColor | null } | null } | null };

export type UserQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type UserQuery = { __typename?: 'Query', user: { __typename?: 'UserResponse', user?: { __typename?: 'User', id: number, email?: string | null, firstName?: string | null, lastName?: string | null, username?: string | null, rootIDs?: Array<number | null> | null, isTwoFactorEnabled: boolean, roles?: Array<{ __typename?: 'Role', value?: string | null, id: number, rank?: number | null, permissions: Array<string> }> | null, locationRestrictions?: Array<{ __typename?: 'LocationRestriction', name?: string | null }> | null } | null } };

export type UsersQueryVariables = Exact<{
  where?: InputMaybe<UsersFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type UsersQuery = { __typename?: 'Query', users: { __typename?: 'UsersPaginations', nodes: Array<{ __typename?: 'User', id: number, username?: string | null, email?: string | null, firstName?: string | null, lastName?: string | null, roles?: Array<{ __typename?: 'Role', value?: string | null }> | null }> } };

export type UsersInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersInfoQuery = { __typename?: 'Query', usersInfo: { __typename?: 'UsersInfoResponse', numOfUsers?: number | null, maxUsers?: number | null } };

export type UniqueValueEntityColumnsQueryVariables = Exact<{
  templateKey: Scalars['String'];
}>;


export type UniqueValueEntityColumnsQuery = { __typename?: 'Query', uniqueValueEntityColumns: { __typename?: 'UniqueValueEntityColumnsResponse', nodes: Array<{ __typename?: 'UniqueColumn', name?: string | null } | null> } };

export type AddVirtualMultiScreenMutationVariables = Exact<{
  input: AddVirtualMultiScreenInput;
}>;


export type AddVirtualMultiScreenMutation = { __typename?: 'Mutation', addVirtualMultiScreen: { __typename?: 'VirtualMultiScreenResponse', errors?: Array<{ __typename?: 'Error', path: string, message: string } | null> | null, virtualMultiScreen?: { __typename?: 'VirtualMultiScreen', id: number } | null } };

export type DeleteVirtualMultiScreenMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteVirtualMultiScreenMutation = { __typename?: 'Mutation', deleteVirtualMultiScreen: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type EditVirtualMultiScreenMutationVariables = Exact<{
  input: EditVirtualMultiScreenInput;
}>;


export type EditVirtualMultiScreenMutation = { __typename?: 'Mutation', editVirtualMultiScreen: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type VirtualMultiScreenQueryVariables = Exact<{
  virtualMultiScreenId: Scalars['Int'];
}>;


export type VirtualMultiScreenQuery = { __typename?: 'Query', virtualMultiScreen: { __typename?: 'VirtualMultiScreenResponse', virtualMultiScreen?: { __typename?: 'VirtualMultiScreen', id: number, name?: string | null, virtualMultiScreenPositions?: Array<{ __typename?: 'VirtualMultiScreenPosition', id: number, sequence: number, channel?: { __typename?: 'Channel', id: number, name?: string | null } | null, virtualCategoryPoint?: { __typename?: 'VirtualCategoryPoint', id: number, name?: string | null } | null, virtualRack?: { __typename?: 'VirtualRack', id: number, name?: string | null, virtualShelfPositions?: Array<{ __typename?: 'VirtualShelfPosition', id: number, virtualShelf?: { __typename?: 'VirtualShelf', id: number, spacing?: number | null, virtualProductPositions?: Array<{ __typename?: 'VirtualProductPosition', id: number, zoom?: number | null, rotation?: number | null, itemCount?: number | null, product?: { __typename?: 'Product', id: number, name?: string | null, productImages?: Array<{ __typename?: 'ProductImage', id: number, file: { __typename?: 'File', id: number, thumbnail?: string | null, filename?: string | null, width?: number | null, height?: number | null } } | null> | null } | null } | null> | null } | null }> | null } | null } | null> | null } | null } };

export type VirtualMultiScreensQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  where?: InputMaybe<VirtualMultiScreensFilter>;
}>;


export type VirtualMultiScreensQuery = { __typename?: 'Query', virtualMultiScreens: { __typename?: 'VirutalMultiScreensPagination', totalCount: number, nodes?: Array<{ __typename?: 'VirtualMultiScreen', id: number, name?: string | null }> | null } };

export type EditOrderMutationVariables = Exact<{
  input: EditOrderInput;
}>;


export type EditOrderMutation = { __typename?: 'Mutation', editOrder: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type EditOrderItemMutationVariables = Exact<{
  id: Scalars['Int'];
  input: EditOrderItemInput;
}>;


export type EditOrderItemMutation = { __typename?: 'Mutation', editOrderItem: { __typename?: 'EditOrderItemResponse', success?: boolean | null, archivedOrder?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type OrderQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type OrderQuery = { __typename?: 'Query', order: { __typename?: 'OrderResponse', order?: { __typename?: 'Order', id: number, created?: any | null, orderNumber?: string | null, mode?: OrderMode | null, costumerId?: number | null, rootId?: number | null, supplier?: { __typename?: 'Supplier', id: number, companyName: string } | null, orderItems?: Array<{ __typename?: 'OrderItem', id: number, quantity?: number | null, status?: OrderStatus | null, deliveryDate?: any | null, productName?: string | null, internalId?: string | null, partnerId?: string | null, product?: { __typename?: 'Product', id: number, internalId: string, eanCodes?: Array<string> | null, parentProduct?: { __typename?: 'Product', name?: string | null } | null, valueEntities?: Array<{ __typename?: 'Value', id: number, name?: string | null, templateKey: string, type?: DeviceValueType | null, value: string }> | null } | null }> | null } | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type OrderItemLogsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  where?: InputMaybe<OrderItemLogsFilter>;
}>;


export type OrderItemLogsQuery = { __typename?: 'Query', orderItemLogs: { __typename?: 'OrderItemLogsPagination', totalCount: number, nodes: Array<{ __typename?: 'OrderItemLog', id: number, time?: any | null, type?: LogType | null, quantity?: number | null, status?: OrderStatus | null, deliveryDate?: any | null, user?: { __typename?: 'User', firstName?: string | null, lastName?: string | null } | null } | null> } };

export type OrdersQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  where?: InputMaybe<OrderFilter>;
}>;


export type OrdersQuery = { __typename?: 'Query', orders: { __typename?: 'OrdersPagination', totalCount: number, nodes: Array<{ __typename?: 'Order', id: number, created?: any | null, orderNumber?: string | null, mode?: OrderMode | null, costumerId?: number | null, rootId?: number | null, orderItems?: Array<{ __typename?: 'OrderItem', id: number, quantity?: number | null, status?: OrderStatus | null, deliveryDate?: any | null, product?: { __typename?: 'Product', id: number, name?: string | null, internalId: string } | null }> | null, supplier?: { __typename?: 'Supplier', id: number, companyName: string, supplierId?: string | null } | null } | null> } };

export type DeleteProductImageMutationVariables = Exact<{
  productImageId: Scalars['Int'];
}>;


export type DeleteProductImageMutation = { __typename?: 'Mutation', deleteProductImage: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type ProductEditDetailsQueryVariables = Exact<{
  internalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
}>;


export type ProductEditDetailsQuery = { __typename?: 'Query', product: { __typename?: 'ProductResponse', product?: { __typename?: 'Product', id: number, internalId: string, name?: string | null, eanCodes?: Array<string> | null, active?: boolean | null, productImages?: Array<{ __typename?: 'ProductImage', id: number, primary: boolean, file: { __typename?: 'File', id: number, originalname?: string | null, filename?: string | null, mimetype?: string | null, encoding?: string | null, url?: string | null, isFolder: boolean, width?: number | null, height?: number | null, thumbnail?: string | null } } | null> | null, valueEntities?: Array<{ __typename?: 'Value', id: number, name?: string | null, templateKey: string, type?: DeviceValueType | null, value: string }> | null, products?: Array<{ __typename?: 'Product', id: number, internalId: string, name?: string | null, eanCodes?: Array<string> | null, valueEntities?: Array<{ __typename?: 'Value', id: number, name?: string | null, templateKey: string, type?: DeviceValueType | null, value: string }> | null, productImages?: Array<{ __typename?: 'ProductImage', id: number, primary: boolean, file: { __typename?: 'File', id: number, originalname?: string | null, filename?: string | null, mimetype?: string | null, encoding?: string | null, url?: string | null, isFolder: boolean, width?: number | null, height?: number | null, thumbnail?: string | null } } | null> | null }> | null } | null } };

export type UploadProductImageMutationVariables = Exact<{
  file: Scalars['Upload'];
  internalId: Scalars['String'];
  primary?: InputMaybe<Scalars['Boolean']>;
}>;


export type UploadProductImageMutation = { __typename?: 'Mutation', uploadProductImage?: { __typename?: 'ProductImage', id: number, primary: boolean, file: { __typename?: 'File', id: number, originalname?: string | null, filename?: string | null, mimetype?: string | null, encoding?: string | null, url?: string | null, isFolder: boolean, thumbnail?: string | null, width?: number | null, height?: number | null } } | null };

export type AddVirtualCategoryPointMutationVariables = Exact<{
  input: AddVirtualCategoryPointInput;
}>;


export type AddVirtualCategoryPointMutation = { __typename?: 'Mutation', addVirtualCategoryPoint: { __typename?: 'VirtualCategoryPointResponse', errors?: Array<{ __typename?: 'Error', path: string, message: string } | null> | null, virtualCategoryPoint?: { __typename?: 'VirtualCategoryPoint', id: number } | null } };

export type DeleteVirtualCategoryPointMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteVirtualCategoryPointMutation = { __typename?: 'Mutation', deleteVirtualCategoryPoint: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type EditVirtualCategoryPointMutationVariables = Exact<{
  input: EditVirtualCategoryPointInput;
}>;


export type EditVirtualCategoryPointMutation = { __typename?: 'Mutation', editVirtualCategoryPoint: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type VirtualCategoryPointQueryVariables = Exact<{
  virtualCategoryPointId: Scalars['Int'];
}>;


export type VirtualCategoryPointQuery = { __typename?: 'Query', virtualCategoryPoint: { __typename?: 'VirtualCategoryPointResponse', virtualCategoryPoint?: { __typename?: 'VirtualCategoryPoint', id: number, name?: string | null, virtualRacks?: Array<{ __typename?: 'VirtualRack', id: number, name?: string | null, virtualShelfPositions?: Array<{ __typename?: 'VirtualShelfPosition', virtualShelf?: { __typename?: 'VirtualShelf', id: number, name?: string | null, spacing?: number | null, variant: VirtualShelfVariant, virtualProductPositions?: Array<{ __typename?: 'VirtualProductPosition', zoom?: number | null, rotation?: number | null, itemCount?: number | null, product?: { __typename?: 'Product', id: number, name?: string | null, productImages?: Array<{ __typename?: 'ProductImage', file: { __typename?: 'File', filename?: string | null, thumbnail?: string | null, width?: number | null, height?: number | null } } | null> | null } | null } | null> | null } | null }> | null } | null> | null } | null } };

export type VirtualCategoryPointsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  where?: InputMaybe<VirtualCategoryPointsFilter>;
}>;


export type VirtualCategoryPointsQuery = { __typename?: 'Query', virtualCategoryPoints: { __typename?: 'VirtualCategoryPointsPagination', totalCount: number, nodes?: Array<{ __typename?: 'VirtualCategoryPoint', id: number, name?: string | null, virtualRacks?: Array<{ __typename?: 'VirtualRack', id: number, name?: string | null } | null> | null }> | null } };

export type AddVirtualCategoryScreenMutationVariables = Exact<{
  input: AddVirtualCategoryScreenInput;
}>;


export type AddVirtualCategoryScreenMutation = { __typename?: 'Mutation', addVirtualCategoryScreen: { __typename?: 'VirtualCategoryScreenResponse', errors?: Array<{ __typename?: 'Error', path: string, message: string } | null> | null, virtualCategoryScreen?: { __typename?: 'VirtualCategoryScreen', id: number } | null } };

export type DeleteCategoryScreenImageMutationVariables = Exact<{
  categoryId: Scalars['Int'];
}>;


export type DeleteCategoryScreenImageMutation = { __typename?: 'Mutation', deleteVirtualCategoryScreenImage: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type DeleteVirtualCategoryScreenMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteVirtualCategoryScreenMutation = { __typename?: 'Mutation', deleteVirtualCategoryScreen: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type EditVirtualCategoryScreenMutationVariables = Exact<{
  input: EditVirtualCategoryScreenInput;
}>;


export type EditVirtualCategoryScreenMutation = { __typename?: 'Mutation', editVirtualCategoryScreen: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type UploadVirtualStoreScreenMutationVariables = Exact<{
  file: Scalars['Upload'];
  virtualCategoryScreenId: Scalars['Int'];
}>;


export type UploadVirtualStoreScreenMutation = { __typename?: 'Mutation', uploadVirtualStoreScreen?: { __typename?: 'File', id: number, originalname?: string | null, filename?: string | null, mimetype?: string | null, encoding?: string | null, url?: string | null, isFolder: boolean, thumbnail?: string | null, width?: number | null, height?: number | null } | null };

export type VirtualCategoryScreenQueryVariables = Exact<{
  virtualCategoryScreenId: Scalars['Int'];
}>;


export type VirtualCategoryScreenQuery = { __typename?: 'Query', virtualCategoryScreen: { __typename?: 'VirtualCategoryScreenResponse', virtualCategoryScreen?: { __typename?: 'VirtualCategoryScreen', id: number, name?: string | null, timeout?: number | null, catalogMode?: boolean | null, channel?: { __typename?: 'Channel', id: number } | null, file?: { __typename?: 'File', id: number, originalname?: string | null, filename?: string | null, mimetype?: string | null, encoding?: string | null, url?: string | null, isFolder: boolean, width?: number | null, height?: number | null } | null, backgroundFile?: { __typename?: 'File', id: number, originalname?: string | null, filename?: string | null, mimetype?: string | null, encoding?: string | null, url?: string | null, isFolder: boolean, width?: number | null, height?: number | null } | null, virtualCategoryPointPositions?: Array<{ __typename?: 'VirtualCategoryPointPosition', id: number, x: number, y: number, virtualCategoryPoint?: { __typename?: 'VirtualCategoryPoint', id: number, name?: string | null } | null } | null> | null } | null } };

export type VirtualCategoryScreensQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  where?: InputMaybe<VirtualCategoryScreensFilter>;
}>;


export type VirtualCategoryScreensQuery = { __typename?: 'Query', virtualCategoryScreens: { __typename?: 'VirutalCategoryScreensPagination', totalCount: number, nodes?: Array<{ __typename?: 'VirtualCategoryScreen', id: number, name?: string | null, virtualCategoryPointPositions?: Array<{ __typename?: 'VirtualCategoryPointPosition', id: number, x: number, y: number, virtualCategoryPoint?: { __typename?: 'VirtualCategoryPoint', id: number, name?: string | null } | null } | null> | null }> | null } };

export type AddVirtualRackMutationVariables = Exact<{
  input: AddVirtualRackInput;
}>;


export type AddVirtualRackMutation = { __typename?: 'Mutation', addVirtualRack: { __typename?: 'VirtualRackResponse', errors?: Array<{ __typename?: 'Error', path: string, message: string } | null> | null, virtualRack?: { __typename?: 'VirtualRack', id: number } | null } };

export type DeleteVirtualRackMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteVirtualRackMutation = { __typename?: 'Mutation', deleteVirtualRack: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type EditVirtualRackMutationVariables = Exact<{
  input: EditVirtualRackInput;
}>;


export type EditVirtualRackMutation = { __typename?: 'Mutation', editVirtualRack: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type VirtualRackQueryVariables = Exact<{
  virtualRackId: Scalars['Int'];
}>;


export type VirtualRackQuery = { __typename?: 'Query', virtualRack: { __typename?: 'VirtualRackResponse', virtualRack?: { __typename?: 'VirtualRack', id: number, name?: string | null, file?: { __typename?: 'File', id: number, originalname?: string | null } | null, virtualShelfPositions?: Array<{ __typename?: 'VirtualShelfPosition', id: number, virtualShelf?: { __typename?: 'VirtualShelf', id: number, name?: string | null, spacing?: number | null, variant: VirtualShelfVariant, virtualProductPositions?: Array<{ __typename?: 'VirtualProductPosition', id: number, zoom?: number | null, rotation?: number | null, itemCount?: number | null, product?: { __typename?: 'Product', id: number, name?: string | null, valueEntities?: Array<{ __typename?: 'Value', value: string, templateKey: string }> | null, productImages?: Array<{ __typename?: 'ProductImage', id: number, file: { __typename?: 'File', id: number, thumbnail?: string | null, filename?: string | null, width?: number | null, height?: number | null } } | null> | null } | null } | null> | null } | null }> | null } | null } };

export type VirtualRacksQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  where?: InputMaybe<VirtualRacksFilter>;
}>;


export type VirtualRacksQuery = { __typename?: 'Query', virtualRacks: { __typename?: 'VirtualRacksPagination', totalCount: number, nodes?: Array<{ __typename?: 'VirtualRack', id: number, name?: string | null, file?: { __typename?: 'File', id: number, thumbnail?: string | null, width?: number | null, height?: number | null } | null, virtualShelfPositions?: Array<{ __typename?: 'VirtualShelfPosition', id: number, virtualShelf?: { __typename?: 'VirtualShelf', variant: VirtualShelfVariant, spacing?: number | null, virtualProductPositions?: Array<{ __typename?: 'VirtualProductPosition', zoom?: number | null, rotation?: number | null, itemCount?: number | null, product?: { __typename?: 'Product', productImages?: Array<{ __typename?: 'ProductImage', file: { __typename?: 'File', id: number, thumbnail?: string | null, width?: number | null, height?: number | null } } | null> | null } | null } | null> | null } | null }> | null }> | null } };

export type AddVirtualShelfMutationVariables = Exact<{
  input: AddVirtualShelfInput;
}>;


export type AddVirtualShelfMutation = { __typename?: 'Mutation', addVirtualShelf: { __typename?: 'VirtualShelfResponse', errors?: Array<{ __typename?: 'Error', path: string, message: string } | null> | null, virtualShelf?: { __typename?: 'VirtualShelf', id: number } | null } };

export type DeleteVirtualShelfMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteVirtualShelfMutation = { __typename?: 'Mutation', deleteVirtualShelf: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type EditVirtualShelfMutationVariables = Exact<{
  input: EditVirtualShelfInput;
}>;


export type EditVirtualShelfMutation = { __typename?: 'Mutation', editVirtualShelf: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type VirtualShelfQueryVariables = Exact<{
  virtualShelfId: Scalars['Int'];
}>;


export type VirtualShelfQuery = { __typename?: 'Query', virtualShelf: { __typename?: 'VirtualShelfResponse', virtualShelf?: { __typename?: 'VirtualShelf', id: number, name?: string | null, spacing?: number | null, variant: VirtualShelfVariant, virtualProductPositions?: Array<{ __typename?: 'VirtualProductPosition', id: number, zoom?: number | null, rotation?: number | null, itemCount?: number | null, product?: { __typename?: 'Product', id: number, name?: string | null, internalId: string, valueEntities?: Array<{ __typename?: 'Value', templateKey: string, value: string }> | null, productImages?: Array<{ __typename?: 'ProductImage', id: number, file: { __typename?: 'File', thumbnail?: string | null, filename?: string | null, width?: number | null, height?: number | null } } | null> | null } | null } | null> | null } | null } };

export type VirtualShelfProductsQueryVariables = Exact<{
  where?: InputMaybe<ProductsFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type VirtualShelfProductsQuery = { __typename?: 'Query', products: { __typename?: 'ProductsPaginations', totalCount: number, nodes: Array<{ __typename?: 'Product', id: number, name?: string | null, internalId: string, valueEntities?: Array<{ __typename?: 'Value', templateKey: string, value: string, type?: DeviceValueType | null }> | null, productImages?: Array<{ __typename?: 'ProductImage', id: number, file: { __typename?: 'File', id: number, thumbnail?: string | null, width?: number | null, height?: number | null } } | null> | null }> } };

export type VirtualShelvesQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  where?: InputMaybe<VirtualShelvesFilter>;
}>;


export type VirtualShelvesQuery = { __typename?: 'Query', virtualShelves: { __typename?: 'VirtualShelvesPagination', totalCount: number, nodes?: Array<{ __typename?: 'VirtualShelf', id: number, name?: string | null, spacing?: number | null, variant: VirtualShelfVariant, virtualProductPositions?: Array<{ __typename?: 'VirtualProductPosition', id: number, zoom?: number | null, rotation?: number | null, itemCount?: number | null, product?: { __typename?: 'Product', id: number, productImages?: Array<{ __typename?: 'ProductImage', file: { __typename?: 'File', id: number, thumbnail?: string | null, width?: number | null, height?: number | null } } | null> | null } | null } | null> | null }> | null } };

export type AddVirtualStoreDeviceMutationVariables = Exact<{
  input: AddVirtualStoreDeviceInput;
}>;


export type AddVirtualStoreDeviceMutation = { __typename?: 'Mutation', addVirtualStoreDevice: { __typename?: 'VirtualStoreDeviceResponse', virtualStoreDevice?: { __typename?: 'VirtualStoreDevice', id: number } | null, errors?: Array<{ __typename?: 'Error', path: string, message: string } | null> | null } };

export type DeleteVirtualStoreDeviceMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteVirtualStoreDeviceMutation = { __typename?: 'Mutation', deleteVirtualStoreDevice: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type EditVirtualStoreDeviceMutationVariables = Exact<{
  input: EditVirtualStoreDeviceInput;
}>;


export type EditVirtualStoreDeviceMutation = { __typename?: 'Mutation', editVirtualStoreDevice: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type VirtualStoreDeviceQueryVariables = Exact<{
  virtualStoreDeviceId: Scalars['Int'];
}>;


export type VirtualStoreDeviceQuery = { __typename?: 'Query', virtualStoreDevice: { __typename?: 'VirtualStoreDeviceResponse', virtualStoreDevice?: { __typename?: 'VirtualStoreDevice', id: number, name?: string | null, tags?: Array<string | null> | null, token?: string | null, note?: string | null, root?: { __typename?: 'Root', id: number, name?: string | null } | null, virtualCategoryScreen?: { __typename?: 'VirtualCategoryScreen', id: number, name?: string | null } | null, channel?: { __typename?: 'Channel', id: number, name?: string | null } | null, virtualMultiScreen?: { __typename?: 'VirtualMultiScreen', id: number, name?: string | null } | null } | null } };

export type VirtualStoreDevicesQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  where?: InputMaybe<VirtualStoreDevicesFilter>;
}>;


export type VirtualStoreDevicesQuery = { __typename?: 'Query', virtualStoreDevices: { __typename?: 'VirtualStoreDevicesPagination', totalCount: number, nodes?: Array<{ __typename?: 'VirtualStoreDevice', id: number, name?: string | null, tags?: Array<string | null> | null, active?: boolean | null, width?: number | null, height?: number | null, version?: string | null, messageReceived?: boolean | null, root?: { __typename?: 'Root', id: number, name?: string | null } | null, channel?: { __typename?: 'Channel', id: number, name?: string | null } | null, virtualCategoryScreen?: { __typename?: 'VirtualCategoryScreen', id: number, name?: string | null } | null, virtualMultiScreen?: { __typename?: 'VirtualMultiScreen', id: number, name?: string | null } | null, virtualCategoryPoint?: { __typename?: 'VirtualCategoryPoint', id: number, name?: string | null } | null }> | null } };

export type WarehouseParentsWithChildrenQueryVariables = Exact<{
  any?: InputMaybe<Scalars['String']>;
  rootId?: InputMaybe<Scalars['Int']>;
  supplierId?: InputMaybe<Scalars['Int']>;
  alarmType?: InputMaybe<AlarmType>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type WarehouseParentsWithChildrenQuery = { __typename?: 'Query', warehouseParentsWithChildren: { __typename?: 'ProductsPaginations', totalCount: number, nodes: Array<{ __typename?: 'Product', id: number, name?: string | null, internalId: string, valueEntities?: Array<{ __typename?: 'Value', templateKey: string, value: string }> | null, products?: Array<{ __typename?: 'Product', id: number, internalId: string, valueEntities?: Array<{ __typename?: 'Value', templateKey: string, value: string }> | null, root?: { __typename?: 'Root', id: number, name?: string | null } | null, supplier?: { __typename?: 'Supplier', id: number, companyName: string, supplierId?: string | null } | null, positions?: Array<{ __typename?: 'Position', sequence?: number | null, shelf?: { __typename?: 'Shelf', key: string, bay?: { __typename?: 'Bay', key: string, aisle?: { __typename?: 'Aisle', key: string } | null } | null } | null } | null> | null }> | null }> } };

export type OrderReportInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type OrderReportInfoQuery = { __typename?: 'Query', info?: { __typename?: 'InfoResponse', rootCount?: number | null, warehouseProductCount?: number | null, totalWarehouseOrderCount?: number | null, openWarehouseOrderCount?: number | null } | null };

export type ProductQuantityHistoryQueryVariables = Exact<{
  productId: Scalars['Int'];
  where?: InputMaybe<QuantityHistoryFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type ProductQuantityHistoryQuery = { __typename?: 'Query', quantityHistory: { __typename?: 'QuantityHistoryResponse', totalCount: number, nodes: Array<{ __typename?: 'QuantityHistory', id: number, created: any, quantity?: number | null, quantityChange?: number | null, mode: ModeEnum } | null> } };

export type SearchChildProductsQueryVariables = Exact<{
  where?: InputMaybe<ProductsFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type SearchChildProductsQuery = { __typename?: 'Query', warehouseChildren: { __typename?: 'ProductsPaginations', totalCount: number, nodes: Array<{ __typename?: 'Product', id: number, internalId: string, name?: string | null, parentProduct?: { __typename?: 'Product', id: number, name?: string | null, internalId: string } | null, devices?: Array<{ __typename?: 'Device', id: number }> | null, positions?: Array<{ __typename?: 'Position', id: number } | null> | null }> } };

export type WarehouseProductValueReportQueryVariables = Exact<{
  startDate: Scalars['DateTime'];
  rootId?: InputMaybe<Scalars['Int']>;
  supplierId?: InputMaybe<Scalars['Int']>;
}>;


export type WarehouseProductValueReportQuery = { __typename?: 'Query', warehouseProductValueReport: { __typename?: 'WarehouseProductValueReports', reports: Array<{ __typename?: 'WarehouseProductValueReport', month: string, EUR: number, USD: number, GBP: number, CHF: number, date: any }> } };

export type AddSupplierMutationVariables = Exact<{
  input: AddSupplier;
}>;


export type AddSupplierMutation = { __typename?: 'Mutation', addSupplier: { __typename?: 'AddSupplierResponse', errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null, supplier?: { __typename?: 'Supplier', id: number } | null } };

export type DeleteSupplierMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteSupplierMutation = { __typename?: 'Mutation', deleteSupplier: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type EditSupplierMutationVariables = Exact<{
  input: EditSupplier;
}>;


export type EditSupplierMutation = { __typename?: 'Mutation', editSupplier: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type SupplierQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SupplierQuery = { __typename?: 'Query', supplier: { __typename?: 'SupplierResponse', supplier?: { __typename?: 'Supplier', id: number, companyName: string, email?: string | null, supplierId?: string | null, address?: string | null, addressNumber?: string | null, country?: Country | null, postCode?: string | null, city?: string | null, vat?: string | null } | null } };

export type SuppliersQueryVariables = Exact<{
  where?: InputMaybe<SuppliersFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type SuppliersQuery = { __typename?: 'Query', suppliers: { __typename?: 'SuppliersPaginations', totalCount: number, nodes: Array<{ __typename?: 'Supplier', id: number, companyName: string, supplierId?: string | null, country?: Country | null, address?: string | null, postCode?: string | null, city?: string | null } | null> } };

export type TriggerDeviceLedMutationVariables = Exact<{
  input: TriggerDeviceLedInput;
}>;


export type TriggerDeviceLedMutation = { __typename?: 'Mutation', triggerDeviceLed: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null } };

export type WarehouseOrderReportQueryVariables = Exact<{
  input: WarehouseOrderReportInput;
}>;


export type WarehouseOrderReportQuery = { __typename?: 'Query', warehouseOrderReport: { __typename?: 'WarehouseOrderReport', allOrders: Array<{ __typename?: 'WarehouseOrderCount', date: any, normalCount: number, expressCount: number, total: number }>, stockValue: Array<{ __typename?: 'WarehouseStockValue', date: any, EUR: number, USD: number, GBP: number, CHF: number }> } };

export type WarehouseParentProductQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type WarehouseParentProductQuery = { __typename?: 'Query', product: { __typename?: 'ProductResponse', product?: { __typename?: 'Product', id: number, internalId: string, name?: string | null, valueEntities?: Array<{ __typename?: 'Value', templateKey: string, value: string }> | null, productImages?: Array<{ __typename?: 'ProductImage', id: number, primary: boolean, file: { __typename?: 'File', id: number, originalname?: string | null, filename?: string | null, mimetype?: string | null, encoding?: string | null, url?: string | null, isFolder: boolean, width?: number | null, height?: number | null, thumbnail?: string | null } } | null> | null } | null } };

export type WarehouseParentProductsQueryVariables = Exact<{
  where?: InputMaybe<ProductsFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type WarehouseParentProductsQuery = { __typename?: 'Query', products: { __typename?: 'ProductsPaginations', totalCount: number, nodes: Array<{ __typename?: 'Product', id: number, name?: string | null, internalId: string, valueEntities?: Array<{ __typename?: 'Value', templateKey: string, value: string }> | null, products?: Array<{ __typename?: 'Product', id: number, internalId: string, name?: string | null, valueEntities?: Array<{ __typename?: 'Value', templateKey: string, value: string }> | null, root?: { __typename?: 'Root', id: number, name?: string | null } | null, supplier?: { __typename?: 'Supplier', id: number, companyName: string, supplierId?: string | null } | null, positions?: Array<{ __typename?: 'Position', id: number, sequence?: number | null, shelf?: { __typename?: 'Shelf', key: string, bay?: { __typename?: 'Bay', key: string, aisle?: { __typename?: 'Aisle', key: string } | null } | null } | null } | null> | null }> | null, productImages?: Array<{ __typename?: 'ProductImage', id: number, file: { __typename?: 'File', thumbnail?: string | null } } | null> | null }> } };

export type WarehouseProductQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type WarehouseProductQuery = { __typename?: 'Query', product: { __typename?: 'ProductResponse', product?: { __typename?: 'Product', id: number, internalId: string, name?: string | null, rootId?: number | null, expirationDate?: any | null, parentProduct?: { __typename?: 'Product', id: number, name?: string | null, internalId: string, valueEntities?: Array<{ __typename?: 'Value', templateKey: string, value: string }> | null, productImages?: Array<{ __typename?: 'ProductImage', id: number, primary: boolean, file: { __typename?: 'File', id: number, originalname?: string | null, filename?: string | null, mimetype?: string | null, encoding?: string | null, url?: string | null, isFolder: boolean, width?: number | null, height?: number | null, thumbnail?: string | null } } | null> | null } | null, supplier?: { __typename?: 'Supplier', id: number, supplierId?: string | null, companyName: string } | null, valueEntities?: Array<{ __typename?: 'Value', templateKey: string, value: string }> | null, positions?: Array<{ __typename?: 'Position', id: number, sequence?: number | null, device?: { __typename?: 'Device', id: number, serialNumber: string } | null, shelf?: { __typename?: 'Shelf', key: string, bay?: { __typename?: 'Bay', key: string, aisle?: { __typename?: 'Aisle', key: string } | null } | null } | null } | null> | null, devices?: Array<{ __typename?: 'Device', id: number, serialNumber: string }> | null } | null } };

export type AddWarehouseProductsMutationVariables = Exact<{
  input: Array<ProductInput> | ProductInput;
}>;


export type AddWarehouseProductsMutation = { __typename?: 'Mutation', addEditProducts: { __typename?: 'addEditProductsResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null, addedProducts?: Array<{ __typename?: 'Product', id: number, internalId: string, name?: string | null, rootId?: number | null, valueEntities?: Array<{ __typename?: 'Value', id: number, name?: string | null, templateKey: string, type?: DeviceValueType | null, value: string }> | null }> | null } };

export type AddEditWarehouseProductsNewPositionMutationVariables = Exact<{
  input: Array<ProductInput> | ProductInput;
  positionId: Scalars['Int'];
  positionInput: EditPositionInput;
}>;


export type AddEditWarehouseProductsNewPositionMutation = { __typename?: 'Mutation', addEditProducts: { __typename?: 'addEditProductsResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null, addedProducts?: Array<{ __typename?: 'Product', id: number, internalId: string, name?: string | null, rootId?: number | null, valueEntities?: Array<{ __typename?: 'Value', id: number, name?: string | null, templateKey: string, type?: DeviceValueType | null, value: string }> | null }> | null }, editPosition: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', message: string, path: string }> | null } };

export type AddEditWarehouseProductsOldPositionMutationVariables = Exact<{
  input: Array<ProductInput> | ProductInput;
  oldPositionId: Scalars['Int'];
  oldPositionInput: EditPositionInput;
}>;


export type AddEditWarehouseProductsOldPositionMutation = { __typename?: 'Mutation', addEditProducts: { __typename?: 'addEditProductsResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null, addedProducts?: Array<{ __typename?: 'Product', id: number, internalId: string, name?: string | null, rootId?: number | null, valueEntities?: Array<{ __typename?: 'Value', id: number, name?: string | null, templateKey: string, type?: DeviceValueType | null, value: string }> | null }> | null }, editPosition: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', message: string, path: string }> | null } };

export type AddEditWarehouseProductsOldPositionNewPositionMutationVariables = Exact<{
  input: Array<ProductInput> | ProductInput;
  positionId: Scalars['Int'];
  positionInput: EditPositionInput;
  oldPositionId: Scalars['Int'];
  oldPositionInput: EditPositionInput;
}>;


export type AddEditWarehouseProductsOldPositionNewPositionMutation = { __typename?: 'Mutation', addEditProducts: { __typename?: 'addEditProductsResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null, addedProducts?: Array<{ __typename?: 'Product', id: number, internalId: string, name?: string | null, rootId?: number | null, valueEntities?: Array<{ __typename?: 'Value', id: number, name?: string | null, templateKey: string, type?: DeviceValueType | null, value: string }> | null }> | null }, A: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', message: string, path: string }> | null }, B: { __typename?: 'StandardResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', message: string, path: string }> | null } };

export type AddWarehouseProductMutationVariables = Exact<{
  input: ProductInput;
}>;


export type AddWarehouseProductMutation = { __typename?: 'Mutation', addProduct: { __typename?: 'AddProductResponse', success?: boolean | null, errors?: Array<{ __typename?: 'Error', path: string, message: string }> | null, product?: { __typename?: 'Product', id: number } | null } };

export type WarehouseProductReportQueryVariables = Exact<{
  date: Scalars['DateTime'];
  rootId?: InputMaybe<Scalars['Int']>;
  supplierId?: InputMaybe<Scalars['Int']>;
}>;


export type WarehouseProductReportQuery = { __typename?: 'Query', warehouseProductReport: Array<{ __typename?: 'WarehouseProduct', quantity: number, lastChange: any, mode: ModeEnum, product: { __typename?: 'Product', id: number, internalId: string, name?: string | null, root?: { __typename?: 'Root', name?: string | null } | null, supplier?: { __typename?: 'Supplier', companyName: string } | null, valueEntities?: Array<{ __typename?: 'Value', templateKey: string, value: string }> | null, parentProduct?: { __typename?: 'Product', name?: string | null } | null, products?: Array<{ __typename?: 'Product', id: number, internalId: string, name?: string | null, valueEntities?: Array<{ __typename?: 'Value', templateKey: string, value: string }> | null }> | null } }> };


export const AddRootDocument = gql`
    mutation addRoot($input: AddRootInput!) {
  addRoot(input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type AddRootMutationFn = Apollo.MutationFunction<AddRootMutation, AddRootMutationVariables>;

/**
 * __useAddRootMutation__
 *
 * To run a mutation, you first call `useAddRootMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRootMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRootMutation, { data, loading, error }] = useAddRootMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRootMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddRootMutation, AddRootMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddRootMutation, AddRootMutationVariables>(AddRootDocument, options);
      }
export type AddRootMutationHookResult = ReturnType<typeof useAddRootMutation>;
export type AddRootMutationResult = Apollo.MutationResult<AddRootMutation>;
export type AddRootMutationOptions = Apollo.BaseMutationOptions<AddRootMutation, AddRootMutationVariables>;
export const DeleteRootDocument = gql`
    mutation deleteRoot($id: Int!) {
  deleteRoot(id: $id) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type DeleteRootMutationFn = Apollo.MutationFunction<DeleteRootMutation, DeleteRootMutationVariables>;

/**
 * __useDeleteRootMutation__
 *
 * To run a mutation, you first call `useDeleteRootMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRootMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRootMutation, { data, loading, error }] = useDeleteRootMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRootMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRootMutation, DeleteRootMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteRootMutation, DeleteRootMutationVariables>(DeleteRootDocument, options);
      }
export type DeleteRootMutationHookResult = ReturnType<typeof useDeleteRootMutation>;
export type DeleteRootMutationResult = Apollo.MutationResult<DeleteRootMutation>;
export type DeleteRootMutationOptions = Apollo.BaseMutationOptions<DeleteRootMutation, DeleteRootMutationVariables>;
export const EditRootDocument = gql`
    mutation editRoot($id: Int!, $input: EditRootInput!) {
  editRoot(rootId: $id, input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type EditRootMutationFn = Apollo.MutationFunction<EditRootMutation, EditRootMutationVariables>;

/**
 * __useEditRootMutation__
 *
 * To run a mutation, you first call `useEditRootMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRootMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRootMutation, { data, loading, error }] = useEditRootMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditRootMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditRootMutation, EditRootMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditRootMutation, EditRootMutationVariables>(EditRootDocument, options);
      }
export type EditRootMutationHookResult = ReturnType<typeof useEditRootMutation>;
export type EditRootMutationResult = Apollo.MutationResult<EditRootMutation>;
export type EditRootMutationOptions = Apollo.BaseMutationOptions<EditRootMutation, EditRootMutationVariables>;
export const RootDocument = gql`
    query root($rootId: Int!) {
  root(rootId: $rootId) {
    root {
      id
      key
      name
      latitude
      longitude
    }
  }
}
    `;

/**
 * __useRootQuery__
 *
 * To run a query within a React component, call `useRootQuery` and pass it any options that fit your needs.
 * When your component renders, `useRootQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRootQuery({
 *   variables: {
 *      rootId: // value for 'rootId'
 *   },
 * });
 */
export function useRootQuery(baseOptions: ApolloReactHooks.QueryHookOptions<RootQuery, RootQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<RootQuery, RootQueryVariables>(RootDocument, options);
      }
export function useRootLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RootQuery, RootQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<RootQuery, RootQueryVariables>(RootDocument, options);
        }
export type RootQueryHookResult = ReturnType<typeof useRootQuery>;
export type RootLazyQueryHookResult = ReturnType<typeof useRootLazyQuery>;
export type RootQueryResult = Apollo.QueryResult<RootQuery, RootQueryVariables>;
export const AddRouterDocument = gql`
    mutation addRouter($input: AddRouterInput!) {
  addRouter(input: $input) {
    id
  }
}
    `;
export type AddRouterMutationFn = Apollo.MutationFunction<AddRouterMutation, AddRouterMutationVariables>;

/**
 * __useAddRouterMutation__
 *
 * To run a mutation, you first call `useAddRouterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRouterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRouterMutation, { data, loading, error }] = useAddRouterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRouterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddRouterMutation, AddRouterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddRouterMutation, AddRouterMutationVariables>(AddRouterDocument, options);
      }
export type AddRouterMutationHookResult = ReturnType<typeof useAddRouterMutation>;
export type AddRouterMutationResult = Apollo.MutationResult<AddRouterMutation>;
export type AddRouterMutationOptions = Apollo.BaseMutationOptions<AddRouterMutation, AddRouterMutationVariables>;
export const DeleteRouterDocument = gql`
    mutation deleteRouter($id: Int!) {
  deleteRouter(id: $id) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type DeleteRouterMutationFn = Apollo.MutationFunction<DeleteRouterMutation, DeleteRouterMutationVariables>;

/**
 * __useDeleteRouterMutation__
 *
 * To run a mutation, you first call `useDeleteRouterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRouterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRouterMutation, { data, loading, error }] = useDeleteRouterMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRouterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRouterMutation, DeleteRouterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteRouterMutation, DeleteRouterMutationVariables>(DeleteRouterDocument, options);
      }
export type DeleteRouterMutationHookResult = ReturnType<typeof useDeleteRouterMutation>;
export type DeleteRouterMutationResult = Apollo.MutationResult<DeleteRouterMutation>;
export type DeleteRouterMutationOptions = Apollo.BaseMutationOptions<DeleteRouterMutation, DeleteRouterMutationVariables>;
export const EditRouterDocument = gql`
    mutation editRouter($id: Int!, $input: EditRouterInput!) {
  editRouter(routerId: $id, input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type EditRouterMutationFn = Apollo.MutationFunction<EditRouterMutation, EditRouterMutationVariables>;

/**
 * __useEditRouterMutation__
 *
 * To run a mutation, you first call `useEditRouterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRouterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRouterMutation, { data, loading, error }] = useEditRouterMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditRouterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditRouterMutation, EditRouterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditRouterMutation, EditRouterMutationVariables>(EditRouterDocument, options);
      }
export type EditRouterMutationHookResult = ReturnType<typeof useEditRouterMutation>;
export type EditRouterMutationResult = Apollo.MutationResult<EditRouterMutation>;
export type EditRouterMutationOptions = Apollo.BaseMutationOptions<EditRouterMutation, EditRouterMutationVariables>;
export const RouterDetailsDocument = gql`
    query routerDetails($uuid: String, $id: Int) {
  routerDetails(uuid: $uuid, id: $id) {
    routerDetails {
      cpu_temp
      cpu_usage
      memory_usage
      memory_total
      is_online
      is_connected_to_vpn
      ip_address
      storage_usage
      storage_total
      is_undervolted
      router {
        id
        name
        dhcp
        token
        macAddress
        uuid
        type
        version
        root {
          id
          name
        }
        routerNetwork {
          ip
          subnet
          gateway
          dns1
          dns2
          wifichannel
          wifimode
        }
      }
    }
  }
}
    `;

/**
 * __useRouterDetailsQuery__
 *
 * To run a query within a React component, call `useRouterDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRouterDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRouterDetailsQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRouterDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RouterDetailsQuery, RouterDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<RouterDetailsQuery, RouterDetailsQueryVariables>(RouterDetailsDocument, options);
      }
export function useRouterDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RouterDetailsQuery, RouterDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<RouterDetailsQuery, RouterDetailsQueryVariables>(RouterDetailsDocument, options);
        }
export type RouterDetailsQueryHookResult = ReturnType<typeof useRouterDetailsQuery>;
export type RouterDetailsLazyQueryHookResult = ReturnType<typeof useRouterDetailsLazyQuery>;
export type RouterDetailsQueryResult = Apollo.QueryResult<RouterDetailsQuery, RouterDetailsQueryVariables>;
export const RoutersDocument = gql`
    query routers($offset: Int!, $limit: Int!, $where: RoutersFilter) {
  routers(offset: $offset, limit: $limit, where: $where) {
    nodes {
      id
      name
      macAddress
      root {
        id
        name
      }
      uuid
      type
      version
      online
    }
    totalCount
  }
}
    `;

/**
 * __useRoutersQuery__
 *
 * To run a query within a React component, call `useRoutersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoutersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoutersQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRoutersQuery(baseOptions: ApolloReactHooks.QueryHookOptions<RoutersQuery, RoutersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<RoutersQuery, RoutersQueryVariables>(RoutersDocument, options);
      }
export function useRoutersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RoutersQuery, RoutersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<RoutersQuery, RoutersQueryVariables>(RoutersDocument, options);
        }
export type RoutersQueryHookResult = ReturnType<typeof useRoutersQuery>;
export type RoutersLazyQueryHookResult = ReturnType<typeof useRoutersLazyQuery>;
export type RoutersQueryResult = Apollo.QueryResult<RoutersQuery, RoutersQueryVariables>;
export const AddAisleDocument = gql`
    mutation addAisle($input: IsleInput!) {
  addAisle(input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type AddAisleMutationFn = Apollo.MutationFunction<AddAisleMutation, AddAisleMutationVariables>;

/**
 * __useAddAisleMutation__
 *
 * To run a mutation, you first call `useAddAisleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAisleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAisleMutation, { data, loading, error }] = useAddAisleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAisleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAisleMutation, AddAisleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddAisleMutation, AddAisleMutationVariables>(AddAisleDocument, options);
      }
export type AddAisleMutationHookResult = ReturnType<typeof useAddAisleMutation>;
export type AddAisleMutationResult = Apollo.MutationResult<AddAisleMutation>;
export type AddAisleMutationOptions = Apollo.BaseMutationOptions<AddAisleMutation, AddAisleMutationVariables>;
export const AisleDocument = gql`
    query aisle($aisleId: Int!) {
  aisle(aisleId: $aisleId) {
    aisle {
      id
      key
      root {
        id
      }
    }
  }
}
    `;

/**
 * __useAisleQuery__
 *
 * To run a query within a React component, call `useAisleQuery` and pass it any options that fit your needs.
 * When your component renders, `useAisleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAisleQuery({
 *   variables: {
 *      aisleId: // value for 'aisleId'
 *   },
 * });
 */
export function useAisleQuery(baseOptions: ApolloReactHooks.QueryHookOptions<AisleQuery, AisleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AisleQuery, AisleQueryVariables>(AisleDocument, options);
      }
export function useAisleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AisleQuery, AisleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AisleQuery, AisleQueryVariables>(AisleDocument, options);
        }
export type AisleQueryHookResult = ReturnType<typeof useAisleQuery>;
export type AisleLazyQueryHookResult = ReturnType<typeof useAisleLazyQuery>;
export type AisleQueryResult = Apollo.QueryResult<AisleQuery, AisleQueryVariables>;
export const AislesDocument = gql`
    query aisles($where: IslesFilter, $offset: Int!, $limit: Int!) {
  aisles(where: $where, offset: $offset, limit: $limit) {
    nodes {
      id
      key
      bays {
        id
        key
        shelves {
          id
          key
          positions {
            id
          }
        }
      }
    }
    totalCount
  }
}
    `;

/**
 * __useAislesQuery__
 *
 * To run a query within a React component, call `useAislesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAislesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAislesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAislesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<AislesQuery, AislesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AislesQuery, AislesQueryVariables>(AislesDocument, options);
      }
export function useAislesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AislesQuery, AislesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AislesQuery, AislesQueryVariables>(AislesDocument, options);
        }
export type AislesQueryHookResult = ReturnType<typeof useAislesQuery>;
export type AislesLazyQueryHookResult = ReturnType<typeof useAislesLazyQuery>;
export type AislesQueryResult = Apollo.QueryResult<AislesQuery, AislesQueryVariables>;
export const EditAisleDocument = gql`
    mutation editAisle($aisleId: Int!, $input: EditAisleInput!) {
  editAisle(aisleId: $aisleId, input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type EditAisleMutationFn = Apollo.MutationFunction<EditAisleMutation, EditAisleMutationVariables>;

/**
 * __useEditAisleMutation__
 *
 * To run a mutation, you first call `useEditAisleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAisleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAisleMutation, { data, loading, error }] = useEditAisleMutation({
 *   variables: {
 *      aisleId: // value for 'aisleId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAisleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditAisleMutation, EditAisleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditAisleMutation, EditAisleMutationVariables>(EditAisleDocument, options);
      }
export type EditAisleMutationHookResult = ReturnType<typeof useEditAisleMutation>;
export type EditAisleMutationResult = Apollo.MutationResult<EditAisleMutation>;
export type EditAisleMutationOptions = Apollo.BaseMutationOptions<EditAisleMutation, EditAisleMutationVariables>;
export const AssetDownloadDocument = gql`
    query assetDownload($fileId: Int!) {
  getAsset(fileId: $fileId) {
    url
  }
}
    `;

/**
 * __useAssetDownloadQuery__
 *
 * To run a query within a React component, call `useAssetDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetDownloadQuery({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useAssetDownloadQuery(baseOptions: ApolloReactHooks.QueryHookOptions<AssetDownloadQuery, AssetDownloadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AssetDownloadQuery, AssetDownloadQueryVariables>(AssetDownloadDocument, options);
      }
export function useAssetDownloadLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AssetDownloadQuery, AssetDownloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AssetDownloadQuery, AssetDownloadQueryVariables>(AssetDownloadDocument, options);
        }
export type AssetDownloadQueryHookResult = ReturnType<typeof useAssetDownloadQuery>;
export type AssetDownloadLazyQueryHookResult = ReturnType<typeof useAssetDownloadLazyQuery>;
export type AssetDownloadQueryResult = Apollo.QueryResult<AssetDownloadQuery, AssetDownloadQueryVariables>;
export const CreateFolderDocument = gql`
    mutation createFolder($input: FolderInput!) {
  createFolder(input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type CreateFolderMutationFn = Apollo.MutationFunction<CreateFolderMutation, CreateFolderMutationVariables>;

/**
 * __useCreateFolderMutation__
 *
 * To run a mutation, you first call `useCreateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFolderMutation, { data, loading, error }] = useCreateFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFolderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFolderMutation, CreateFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateFolderMutation, CreateFolderMutationVariables>(CreateFolderDocument, options);
      }
export type CreateFolderMutationHookResult = ReturnType<typeof useCreateFolderMutation>;
export type CreateFolderMutationResult = Apollo.MutationResult<CreateFolderMutation>;
export type CreateFolderMutationOptions = Apollo.BaseMutationOptions<CreateFolderMutation, CreateFolderMutationVariables>;
export const DeleteFileDocument = gql`
    mutation deleteFile($fileId: Int!) {
  deleteFile(fileId: $fileId) {
    success
    errors {
      path
      message
    }
  }
}
    `;
export type DeleteFileMutationFn = Apollo.MutationFunction<DeleteFileMutation, DeleteFileMutationVariables>;

/**
 * __useDeleteFileMutation__
 *
 * To run a mutation, you first call `useDeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileMutation, { data, loading, error }] = useDeleteFileMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useDeleteFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFileMutation, DeleteFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteFileMutation, DeleteFileMutationVariables>(DeleteFileDocument, options);
      }
export type DeleteFileMutationHookResult = ReturnType<typeof useDeleteFileMutation>;
export type DeleteFileMutationResult = Apollo.MutationResult<DeleteFileMutation>;
export type DeleteFileMutationOptions = Apollo.BaseMutationOptions<DeleteFileMutation, DeleteFileMutationVariables>;
export const EditFileDocument = gql`
    mutation editFile($fileId: Int!, $input: EditFileInput!) {
  editFile(fileId: $fileId, input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type EditFileMutationFn = Apollo.MutationFunction<EditFileMutation, EditFileMutationVariables>;

/**
 * __useEditFileMutation__
 *
 * To run a mutation, you first call `useEditFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFileMutation, { data, loading, error }] = useEditFileMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditFileMutation, EditFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditFileMutation, EditFileMutationVariables>(EditFileDocument, options);
      }
export type EditFileMutationHookResult = ReturnType<typeof useEditFileMutation>;
export type EditFileMutationResult = Apollo.MutationResult<EditFileMutation>;
export type EditFileMutationOptions = Apollo.BaseMutationOptions<EditFileMutation, EditFileMutationVariables>;
export const FileStructureDocument = gql`
    query fileStructure($where: FileStructureFilter) {
  getFileStructure(where: $where) {
    id
    duration
    originalname
    filename
    mimetype
    isFolder
    thumbnail
  }
}
    `;

/**
 * __useFileStructureQuery__
 *
 * To run a query within a React component, call `useFileStructureQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileStructureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileStructureQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFileStructureQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FileStructureQuery, FileStructureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<FileStructureQuery, FileStructureQueryVariables>(FileStructureDocument, options);
      }
export function useFileStructureLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FileStructureQuery, FileStructureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<FileStructureQuery, FileStructureQueryVariables>(FileStructureDocument, options);
        }
export type FileStructureQueryHookResult = ReturnType<typeof useFileStructureQuery>;
export type FileStructureLazyQueryHookResult = ReturnType<typeof useFileStructureLazyQuery>;
export type FileStructureQueryResult = Apollo.QueryResult<FileStructureQuery, FileStructureQueryVariables>;
export const FilesDocument = gql`
    query files($where: FileFilter, $offset: Int!, $limit: Int!) {
  files(where: $where, offset: $offset, limit: $limit) {
    totalCount
    nodes {
      id
      duration
      isFolder
      originalname
      filename
      mimetype
      thumbnail
      width
      height
    }
  }
}
    `;

/**
 * __useFilesQuery__
 *
 * To run a query within a React component, call `useFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useFilesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<FilesQuery, FilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<FilesQuery, FilesQueryVariables>(FilesDocument, options);
      }
export function useFilesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FilesQuery, FilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<FilesQuery, FilesQueryVariables>(FilesDocument, options);
        }
export type FilesQueryHookResult = ReturnType<typeof useFilesQuery>;
export type FilesLazyQueryHookResult = ReturnType<typeof useFilesLazyQuery>;
export type FilesQueryResult = Apollo.QueryResult<FilesQuery, FilesQueryVariables>;
export const MultiUploadDocument = gql`
    mutation multiUpload($files: [Upload!]!, $parentFolderId: Int, $productAssign: Boolean) {
  multiUpload(
    files: $files
    parentFolderId: $parentFolderId
    productAssign: $productAssign
  ) {
    errors {
      path
      message
    }
    file {
      id
      originalname
      filename
      mimetype
      encoding
      url
      isFolder
      thumbnail
    }
  }
}
    `;
export type MultiUploadMutationFn = Apollo.MutationFunction<MultiUploadMutation, MultiUploadMutationVariables>;

/**
 * __useMultiUploadMutation__
 *
 * To run a mutation, you first call `useMultiUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMultiUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [multiUploadMutation, { data, loading, error }] = useMultiUploadMutation({
 *   variables: {
 *      files: // value for 'files'
 *      parentFolderId: // value for 'parentFolderId'
 *      productAssign: // value for 'productAssign'
 *   },
 * });
 */
export function useMultiUploadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MultiUploadMutation, MultiUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<MultiUploadMutation, MultiUploadMutationVariables>(MultiUploadDocument, options);
      }
export type MultiUploadMutationHookResult = ReturnType<typeof useMultiUploadMutation>;
export type MultiUploadMutationResult = Apollo.MutationResult<MultiUploadMutation>;
export type MultiUploadMutationOptions = Apollo.BaseMutationOptions<MultiUploadMutation, MultiUploadMutationVariables>;
export const AddBayDocument = gql`
    mutation addBay($input: BayInput!) {
  addBay(input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type AddBayMutationFn = Apollo.MutationFunction<AddBayMutation, AddBayMutationVariables>;

/**
 * __useAddBayMutation__
 *
 * To run a mutation, you first call `useAddBayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBayMutation, { data, loading, error }] = useAddBayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddBayMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddBayMutation, AddBayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddBayMutation, AddBayMutationVariables>(AddBayDocument, options);
      }
export type AddBayMutationHookResult = ReturnType<typeof useAddBayMutation>;
export type AddBayMutationResult = Apollo.MutationResult<AddBayMutation>;
export type AddBayMutationOptions = Apollo.BaseMutationOptions<AddBayMutation, AddBayMutationVariables>;
export const BayDocument = gql`
    query bay($bayId: Int!) {
  bay(bayId: $bayId) {
    bay {
      id
      key
      aisle {
        id
      }
    }
  }
}
    `;

/**
 * __useBayQuery__
 *
 * To run a query within a React component, call `useBayQuery` and pass it any options that fit your needs.
 * When your component renders, `useBayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBayQuery({
 *   variables: {
 *      bayId: // value for 'bayId'
 *   },
 * });
 */
export function useBayQuery(baseOptions: ApolloReactHooks.QueryHookOptions<BayQuery, BayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<BayQuery, BayQueryVariables>(BayDocument, options);
      }
export function useBayLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BayQuery, BayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<BayQuery, BayQueryVariables>(BayDocument, options);
        }
export type BayQueryHookResult = ReturnType<typeof useBayQuery>;
export type BayLazyQueryHookResult = ReturnType<typeof useBayLazyQuery>;
export type BayQueryResult = Apollo.QueryResult<BayQuery, BayQueryVariables>;
export const BaysDocument = gql`
    query bays($where: baysFilter, $offset: Int!, $limit: Int!) {
  bays(where: $where, offset: $offset, limit: $limit) {
    nodes {
      id
      key
      aisle {
        id
        key
      }
    }
    totalCount
  }
}
    `;

/**
 * __useBaysQuery__
 *
 * To run a query within a React component, call `useBaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useBaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBaysQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useBaysQuery(baseOptions: ApolloReactHooks.QueryHookOptions<BaysQuery, BaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<BaysQuery, BaysQueryVariables>(BaysDocument, options);
      }
export function useBaysLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BaysQuery, BaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<BaysQuery, BaysQueryVariables>(BaysDocument, options);
        }
export type BaysQueryHookResult = ReturnType<typeof useBaysQuery>;
export type BaysLazyQueryHookResult = ReturnType<typeof useBaysLazyQuery>;
export type BaysQueryResult = Apollo.QueryResult<BaysQuery, BaysQueryVariables>;
export const EditBayDocument = gql`
    mutation editBay($bayId: Int!, $input: EditBayInput!) {
  editBay(bayId: $bayId, input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type EditBayMutationFn = Apollo.MutationFunction<EditBayMutation, EditBayMutationVariables>;

/**
 * __useEditBayMutation__
 *
 * To run a mutation, you first call `useEditBayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditBayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editBayMutation, { data, loading, error }] = useEditBayMutation({
 *   variables: {
 *      bayId: // value for 'bayId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditBayMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditBayMutation, EditBayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditBayMutation, EditBayMutationVariables>(EditBayDocument, options);
      }
export type EditBayMutationHookResult = ReturnType<typeof useEditBayMutation>;
export type EditBayMutationResult = Apollo.MutationResult<EditBayMutation>;
export type EditBayMutationOptions = Apollo.BaseMutationOptions<EditBayMutation, EditBayMutationVariables>;
export const AddChannelDocument = gql`
    mutation addChannel($input: AddChannelInput!) {
  addChannel(input: $input) {
    errors {
      path
      message
    }
    channel {
      id
    }
  }
}
    `;
export type AddChannelMutationFn = Apollo.MutationFunction<AddChannelMutation, AddChannelMutationVariables>;

/**
 * __useAddChannelMutation__
 *
 * To run a mutation, you first call `useAddChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChannelMutation, { data, loading, error }] = useAddChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddChannelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddChannelMutation, AddChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddChannelMutation, AddChannelMutationVariables>(AddChannelDocument, options);
      }
export type AddChannelMutationHookResult = ReturnType<typeof useAddChannelMutation>;
export type AddChannelMutationResult = Apollo.MutationResult<AddChannelMutation>;
export type AddChannelMutationOptions = Apollo.BaseMutationOptions<AddChannelMutation, AddChannelMutationVariables>;
export const ChannelDocument = gql`
    query channel($channelId: Int!) {
  channel(id: $channelId) {
    channel {
      id
      name
      tags
      type
      channelItems {
        id
        priority
        duration
        display {
          id
        }
        sequence
        file {
          id
          duration
          encoding
          isFolder
          filename
          originalname
          mimetype
          thumbnail
        }
        playlist {
          id
          name
          playlistItems {
            duration
            file {
              id
              encoding
              isFolder
              filename
              originalname
              mimetype
              thumbnail
            }
          }
        }
        schedule {
          dateRange
          weekdays
          id
          timeFrames
        }
      }
      displays {
        id
        name
        height
        width
        type
      }
    }
  }
}
    `;

/**
 * __useChannelQuery__
 *
 * To run a query within a React component, call `useChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useChannelQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ChannelQuery, ChannelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ChannelQuery, ChannelQueryVariables>(ChannelDocument, options);
      }
export function useChannelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChannelQuery, ChannelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ChannelQuery, ChannelQueryVariables>(ChannelDocument, options);
        }
export type ChannelQueryHookResult = ReturnType<typeof useChannelQuery>;
export type ChannelLazyQueryHookResult = ReturnType<typeof useChannelLazyQuery>;
export type ChannelQueryResult = Apollo.QueryResult<ChannelQuery, ChannelQueryVariables>;
export const ChannelsDocument = gql`
    query channels($where: ChannelsFilter, $offset: Int!, $limit: Int!) {
  channels(where: $where, offset: $offset, limit: $limit) {
    nodes {
      id
      name
      tags
      type
      displays {
        id
        type
      }
      channelItems {
        id
        schedule {
          id
          weekdays
          timeFrames
          dateRange
        }
      }
      tvDevices {
        id
      }
      scales {
        id
      }
    }
    totalCount
  }
}
    `;

/**
 * __useChannelsQuery__
 *
 * To run a query within a React component, call `useChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useChannelsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ChannelsQuery, ChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ChannelsQuery, ChannelsQueryVariables>(ChannelsDocument, options);
      }
export function useChannelsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChannelsQuery, ChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ChannelsQuery, ChannelsQueryVariables>(ChannelsDocument, options);
        }
export type ChannelsQueryHookResult = ReturnType<typeof useChannelsQuery>;
export type ChannelsLazyQueryHookResult = ReturnType<typeof useChannelsLazyQuery>;
export type ChannelsQueryResult = Apollo.QueryResult<ChannelsQuery, ChannelsQueryVariables>;
export const ChannelsSelectDocument = gql`
    query channelsSelect($where: ChannelsFilter, $offset: Int!, $limit: Int!) {
  channels(where: $where, offset: $offset, limit: $limit) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useChannelsSelectQuery__
 *
 * To run a query within a React component, call `useChannelsSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelsSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelsSelectQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useChannelsSelectQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ChannelsSelectQuery, ChannelsSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ChannelsSelectQuery, ChannelsSelectQueryVariables>(ChannelsSelectDocument, options);
      }
export function useChannelsSelectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChannelsSelectQuery, ChannelsSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ChannelsSelectQuery, ChannelsSelectQueryVariables>(ChannelsSelectDocument, options);
        }
export type ChannelsSelectQueryHookResult = ReturnType<typeof useChannelsSelectQuery>;
export type ChannelsSelectLazyQueryHookResult = ReturnType<typeof useChannelsSelectLazyQuery>;
export type ChannelsSelectQueryResult = Apollo.QueryResult<ChannelsSelectQuery, ChannelsSelectQueryVariables>;
export const DeleteChannelDocument = gql`
    mutation deleteChannel($channelId: Int!) {
  deleteChannel(channelId: $channelId) {
    errors {
      message
      path
    }
    success
  }
}
    `;
export type DeleteChannelMutationFn = Apollo.MutationFunction<DeleteChannelMutation, DeleteChannelMutationVariables>;

/**
 * __useDeleteChannelMutation__
 *
 * To run a mutation, you first call `useDeleteChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChannelMutation, { data, loading, error }] = useDeleteChannelMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useDeleteChannelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteChannelMutation, DeleteChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteChannelMutation, DeleteChannelMutationVariables>(DeleteChannelDocument, options);
      }
export type DeleteChannelMutationHookResult = ReturnType<typeof useDeleteChannelMutation>;
export type DeleteChannelMutationResult = Apollo.MutationResult<DeleteChannelMutation>;
export type DeleteChannelMutationOptions = Apollo.BaseMutationOptions<DeleteChannelMutation, DeleteChannelMutationVariables>;
export const EditPlaylistDocument = gql`
    mutation editPlaylist($playlistId: Int!, $input: EditPlaylistInput!) {
  editPlaylist(playlistId: $playlistId, input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type EditPlaylistMutationFn = Apollo.MutationFunction<EditPlaylistMutation, EditPlaylistMutationVariables>;

/**
 * __useEditPlaylistMutation__
 *
 * To run a mutation, you first call `useEditPlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPlaylistMutation, { data, loading, error }] = useEditPlaylistMutation({
 *   variables: {
 *      playlistId: // value for 'playlistId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditPlaylistMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditPlaylistMutation, EditPlaylistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditPlaylistMutation, EditPlaylistMutationVariables>(EditPlaylistDocument, options);
      }
export type EditPlaylistMutationHookResult = ReturnType<typeof useEditPlaylistMutation>;
export type EditPlaylistMutationResult = Apollo.MutationResult<EditPlaylistMutation>;
export type EditPlaylistMutationOptions = Apollo.BaseMutationOptions<EditPlaylistMutation, EditPlaylistMutationVariables>;
export const TagsDocument = gql`
    query tags($where: TagsFilter, $offset: Int!, $limit: Int!) {
  tags(where: $where, offset: $offset, limit: $limit) {
    nodes
  }
}
    `;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTagsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<TagsQuery, TagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
      }
export function useTagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = Apollo.QueryResult<TagsQuery, TagsQueryVariables>;
export const DashSettingsDocument = gql`
    query dashSettings($customer: String!) {
  dashSettings(customer: $customer) {
    logo
    loginLogo
    primaryColor
    textColor
  }
}
    `;

/**
 * __useDashSettingsQuery__
 *
 * To run a query within a React component, call `useDashSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashSettingsQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useDashSettingsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<DashSettingsQuery, DashSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<DashSettingsQuery, DashSettingsQueryVariables>(DashSettingsDocument, options);
      }
export function useDashSettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DashSettingsQuery, DashSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<DashSettingsQuery, DashSettingsQueryVariables>(DashSettingsDocument, options);
        }
export type DashSettingsQueryHookResult = ReturnType<typeof useDashSettingsQuery>;
export type DashSettingsLazyQueryHookResult = ReturnType<typeof useDashSettingsLazyQuery>;
export type DashSettingsQueryResult = Apollo.QueryResult<DashSettingsQuery, DashSettingsQueryVariables>;
export const AddTemplateDocument = gql`
    mutation addTemplate($input: TemplateInput!) {
  addTemplate(input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type AddTemplateMutationFn = Apollo.MutationFunction<AddTemplateMutation, AddTemplateMutationVariables>;

/**
 * __useAddTemplateMutation__
 *
 * To run a mutation, you first call `useAddTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTemplateMutation, { data, loading, error }] = useAddTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddTemplateMutation, AddTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddTemplateMutation, AddTemplateMutationVariables>(AddTemplateDocument, options);
      }
export type AddTemplateMutationHookResult = ReturnType<typeof useAddTemplateMutation>;
export type AddTemplateMutationResult = Apollo.MutationResult<AddTemplateMutation>;
export type AddTemplateMutationOptions = Apollo.BaseMutationOptions<AddTemplateMutation, AddTemplateMutationVariables>;
export const AddTemplateVariantDocument = gql`
    mutation addTemplateVariant($input: TemplateVariantInput!) {
  addTemplateVariant(input: $input) {
    errors {
      message
      path
    }
    templateVariant {
      id
    }
  }
}
    `;
export type AddTemplateVariantMutationFn = Apollo.MutationFunction<AddTemplateVariantMutation, AddTemplateVariantMutationVariables>;

/**
 * __useAddTemplateVariantMutation__
 *
 * To run a mutation, you first call `useAddTemplateVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTemplateVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTemplateVariantMutation, { data, loading, error }] = useAddTemplateVariantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTemplateVariantMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddTemplateVariantMutation, AddTemplateVariantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddTemplateVariantMutation, AddTemplateVariantMutationVariables>(AddTemplateVariantDocument, options);
      }
export type AddTemplateVariantMutationHookResult = ReturnType<typeof useAddTemplateVariantMutation>;
export type AddTemplateVariantMutationResult = Apollo.MutationResult<AddTemplateVariantMutation>;
export type AddTemplateVariantMutationOptions = Apollo.BaseMutationOptions<AddTemplateVariantMutation, AddTemplateVariantMutationVariables>;
export const ComponentVarsDocument = gql`
    query componentVars($offset: Int!, $limit: Int!, $where: ComponentVarsFilter) {
  componentVars(offset: $offset, limit: $limit, where: $where) {
    nodes {
      id
      name
      templateKey
      type
    }
  }
}
    `;

/**
 * __useComponentVarsQuery__
 *
 * To run a query within a React component, call `useComponentVarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useComponentVarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComponentVarsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useComponentVarsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ComponentVarsQuery, ComponentVarsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ComponentVarsQuery, ComponentVarsQueryVariables>(ComponentVarsDocument, options);
      }
export function useComponentVarsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ComponentVarsQuery, ComponentVarsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ComponentVarsQuery, ComponentVarsQueryVariables>(ComponentVarsDocument, options);
        }
export type ComponentVarsQueryHookResult = ReturnType<typeof useComponentVarsQuery>;
export type ComponentVarsLazyQueryHookResult = ReturnType<typeof useComponentVarsLazyQuery>;
export type ComponentVarsQueryResult = Apollo.QueryResult<ComponentVarsQuery, ComponentVarsQueryVariables>;
export const DeleteTemplateDocument = gql`
    mutation deleteTemplate($input: DeleteTemplateInput!) {
  deleteTemplate(input: $input) {
    affected
    errors {
      path
      message
    }
    success
  }
}
    `;
export type DeleteTemplateMutationFn = Apollo.MutationFunction<DeleteTemplateMutation, DeleteTemplateMutationVariables>;

/**
 * __useDeleteTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateMutation, { data, loading, error }] = useDeleteTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTemplateMutation, DeleteTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteTemplateMutation, DeleteTemplateMutationVariables>(DeleteTemplateDocument, options);
      }
export type DeleteTemplateMutationHookResult = ReturnType<typeof useDeleteTemplateMutation>;
export type DeleteTemplateMutationResult = Apollo.MutationResult<DeleteTemplateMutation>;
export type DeleteTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteTemplateMutation, DeleteTemplateMutationVariables>;
export const DeleteTemplateVariantDocument = gql`
    mutation deleteTemplateVariant($input: DeleteTemplateVariantInput!) {
  deleteTemplateVariant(input: $input) {
    affected
    errors {
      path
      message
    }
    success
  }
}
    `;
export type DeleteTemplateVariantMutationFn = Apollo.MutationFunction<DeleteTemplateVariantMutation, DeleteTemplateVariantMutationVariables>;

/**
 * __useDeleteTemplateVariantMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateVariantMutation, { data, loading, error }] = useDeleteTemplateVariantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTemplateVariantMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTemplateVariantMutation, DeleteTemplateVariantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteTemplateVariantMutation, DeleteTemplateVariantMutationVariables>(DeleteTemplateVariantDocument, options);
      }
export type DeleteTemplateVariantMutationHookResult = ReturnType<typeof useDeleteTemplateVariantMutation>;
export type DeleteTemplateVariantMutationResult = Apollo.MutationResult<DeleteTemplateVariantMutation>;
export type DeleteTemplateVariantMutationOptions = Apollo.BaseMutationOptions<DeleteTemplateVariantMutation, DeleteTemplateVariantMutationVariables>;
export const DeleteTemplatesDocument = gql`
    mutation deleteTemplates($input: DeleteTemplatesInput!) {
  deleteTemplates(input: $input) {
    affected
    errors {
      path
      message
    }
    success
  }
}
    `;
export type DeleteTemplatesMutationFn = Apollo.MutationFunction<DeleteTemplatesMutation, DeleteTemplatesMutationVariables>;

/**
 * __useDeleteTemplatesMutation__
 *
 * To run a mutation, you first call `useDeleteTemplatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplatesMutation, { data, loading, error }] = useDeleteTemplatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTemplatesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTemplatesMutation, DeleteTemplatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteTemplatesMutation, DeleteTemplatesMutationVariables>(DeleteTemplatesDocument, options);
      }
export type DeleteTemplatesMutationHookResult = ReturnType<typeof useDeleteTemplatesMutation>;
export type DeleteTemplatesMutationResult = Apollo.MutationResult<DeleteTemplatesMutation>;
export type DeleteTemplatesMutationOptions = Apollo.BaseMutationOptions<DeleteTemplatesMutation, DeleteTemplatesMutationVariables>;
export const DisplayTemplateDocument = gql`
    query displayTemplate($id: Int!) {
  template(id: $id) {
    template {
      id
      deviceSize
      isCustom
      name
      templateVariant {
        id
        key
        isDefault
      }
      width
      height
      components {
        id
        name
        w
        h
        x
        y
        htmlContent
        type
      }
      templateVariables {
        id
        name
        maxLength
        templateKey
      }
    }
  }
}
    `;

/**
 * __useDisplayTemplateQuery__
 *
 * To run a query within a React component, call `useDisplayTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisplayTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisplayTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisplayTemplateQuery(baseOptions: ApolloReactHooks.QueryHookOptions<DisplayTemplateQuery, DisplayTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<DisplayTemplateQuery, DisplayTemplateQueryVariables>(DisplayTemplateDocument, options);
      }
export function useDisplayTemplateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DisplayTemplateQuery, DisplayTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<DisplayTemplateQuery, DisplayTemplateQueryVariables>(DisplayTemplateDocument, options);
        }
export type DisplayTemplateQueryHookResult = ReturnType<typeof useDisplayTemplateQuery>;
export type DisplayTemplateLazyQueryHookResult = ReturnType<typeof useDisplayTemplateLazyQuery>;
export type DisplayTemplateQueryResult = Apollo.QueryResult<DisplayTemplateQuery, DisplayTemplateQueryVariables>;
export const DisplayTemplatesDocument = gql`
    query displayTemplates($offset: Int!, $limit: Int!, $where: TemplatesFilter) {
  templates(offset: $offset, limit: $limit, where: $where) {
    nodes {
      id
      deviceSize
      name
      height
      width
      templateVariant {
        id
      }
    }
    totalCount
  }
}
    `;

/**
 * __useDisplayTemplatesQuery__
 *
 * To run a query within a React component, call `useDisplayTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisplayTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisplayTemplatesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDisplayTemplatesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<DisplayTemplatesQuery, DisplayTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<DisplayTemplatesQuery, DisplayTemplatesQueryVariables>(DisplayTemplatesDocument, options);
      }
export function useDisplayTemplatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DisplayTemplatesQuery, DisplayTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<DisplayTemplatesQuery, DisplayTemplatesQueryVariables>(DisplayTemplatesDocument, options);
        }
export type DisplayTemplatesQueryHookResult = ReturnType<typeof useDisplayTemplatesQuery>;
export type DisplayTemplatesLazyQueryHookResult = ReturnType<typeof useDisplayTemplatesLazyQuery>;
export type DisplayTemplatesQueryResult = Apollo.QueryResult<DisplayTemplatesQuery, DisplayTemplatesQueryVariables>;
export const EditTemplateDocument = gql`
    mutation editTemplate($templateId: Int!, $input: EditTemplateInput!) {
  editTemplate(templateId: $templateId, input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type EditTemplateMutationFn = Apollo.MutationFunction<EditTemplateMutation, EditTemplateMutationVariables>;

/**
 * __useEditTemplateMutation__
 *
 * To run a mutation, you first call `useEditTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTemplateMutation, { data, loading, error }] = useEditTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditTemplateMutation, EditTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditTemplateMutation, EditTemplateMutationVariables>(EditTemplateDocument, options);
      }
export type EditTemplateMutationHookResult = ReturnType<typeof useEditTemplateMutation>;
export type EditTemplateMutationResult = Apollo.MutationResult<EditTemplateMutation>;
export type EditTemplateMutationOptions = Apollo.BaseMutationOptions<EditTemplateMutation, EditTemplateMutationVariables>;
export const EditTemplateVariantDocument = gql`
    mutation editTemplateVariant($templateVariantId: Int!, $input: EditTemplateVariantInput!) {
  editTemplateVariant(templateVariantId: $templateVariantId, input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type EditTemplateVariantMutationFn = Apollo.MutationFunction<EditTemplateVariantMutation, EditTemplateVariantMutationVariables>;

/**
 * __useEditTemplateVariantMutation__
 *
 * To run a mutation, you first call `useEditTemplateVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTemplateVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTemplateVariantMutation, { data, loading, error }] = useEditTemplateVariantMutation({
 *   variables: {
 *      templateVariantId: // value for 'templateVariantId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTemplateVariantMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditTemplateVariantMutation, EditTemplateVariantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditTemplateVariantMutation, EditTemplateVariantMutationVariables>(EditTemplateVariantDocument, options);
      }
export type EditTemplateVariantMutationHookResult = ReturnType<typeof useEditTemplateVariantMutation>;
export type EditTemplateVariantMutationResult = Apollo.MutationResult<EditTemplateVariantMutation>;
export type EditTemplateVariantMutationOptions = Apollo.BaseMutationOptions<EditTemplateVariantMutation, EditTemplateVariantMutationVariables>;
export const ParseTemplateDocument = gql`
    query parseTemplate($templateId: Int!, $productId: Int!) {
  parseTemplate(templateId: $templateId, productId: $productId) {
    template
    errors {
      message
      path
    }
  }
}
    `;

/**
 * __useParseTemplateQuery__
 *
 * To run a query within a React component, call `useParseTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useParseTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParseTemplateQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useParseTemplateQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ParseTemplateQuery, ParseTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ParseTemplateQuery, ParseTemplateQueryVariables>(ParseTemplateDocument, options);
      }
export function useParseTemplateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ParseTemplateQuery, ParseTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ParseTemplateQuery, ParseTemplateQueryVariables>(ParseTemplateDocument, options);
        }
export type ParseTemplateQueryHookResult = ReturnType<typeof useParseTemplateQuery>;
export type ParseTemplateLazyQueryHookResult = ReturnType<typeof useParseTemplateLazyQuery>;
export type ParseTemplateQueryResult = Apollo.QueryResult<ParseTemplateQuery, ParseTemplateQueryVariables>;
export const TemplateVariantDocument = gql`
    query templateVariant($id: Int!) {
  templateVariant(id: $id) {
    templateVariant {
      id
      key
    }
  }
}
    `;

/**
 * __useTemplateVariantQuery__
 *
 * To run a query within a React component, call `useTemplateVariantQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateVariantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateVariantQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemplateVariantQuery(baseOptions: ApolloReactHooks.QueryHookOptions<TemplateVariantQuery, TemplateVariantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<TemplateVariantQuery, TemplateVariantQueryVariables>(TemplateVariantDocument, options);
      }
export function useTemplateVariantLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TemplateVariantQuery, TemplateVariantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<TemplateVariantQuery, TemplateVariantQueryVariables>(TemplateVariantDocument, options);
        }
export type TemplateVariantQueryHookResult = ReturnType<typeof useTemplateVariantQuery>;
export type TemplateVariantLazyQueryHookResult = ReturnType<typeof useTemplateVariantLazyQuery>;
export type TemplateVariantQueryResult = Apollo.QueryResult<TemplateVariantQuery, TemplateVariantQueryVariables>;
export const TemplateVariantsDocument = gql`
    query templateVariants($where: TemplateVariantsFilter, $limit: Int!, $offset: Int!) {
  templateVariants(where: $where, offset: $offset, limit: $limit) {
    nodes {
      id
      key
      isDefault
      inUse
      templates {
        id
        deviceSize
        name
        height
        width
        isCustom
      }
    }
    totalCount
  }
}
    `;

/**
 * __useTemplateVariantsQuery__
 *
 * To run a query within a React component, call `useTemplateVariantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateVariantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateVariantsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useTemplateVariantsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<TemplateVariantsQuery, TemplateVariantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<TemplateVariantsQuery, TemplateVariantsQueryVariables>(TemplateVariantsDocument, options);
      }
export function useTemplateVariantsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TemplateVariantsQuery, TemplateVariantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<TemplateVariantsQuery, TemplateVariantsQueryVariables>(TemplateVariantsDocument, options);
        }
export type TemplateVariantsQueryHookResult = ReturnType<typeof useTemplateVariantsQuery>;
export type TemplateVariantsLazyQueryHookResult = ReturnType<typeof useTemplateVariantsLazyQuery>;
export type TemplateVariantsQueryResult = Apollo.QueryResult<TemplateVariantsQuery, TemplateVariantsQueryVariables>;
export const AddFlowDocument = gql`
    mutation addFlow($input: AddFlowInput!) {
  addFlow(input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type AddFlowMutationFn = Apollo.MutationFunction<AddFlowMutation, AddFlowMutationVariables>;

/**
 * __useAddFlowMutation__
 *
 * To run a mutation, you first call `useAddFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFlowMutation, { data, loading, error }] = useAddFlowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFlowMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddFlowMutation, AddFlowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddFlowMutation, AddFlowMutationVariables>(AddFlowDocument, options);
      }
export type AddFlowMutationHookResult = ReturnType<typeof useAddFlowMutation>;
export type AddFlowMutationResult = Apollo.MutationResult<AddFlowMutation>;
export type AddFlowMutationOptions = Apollo.BaseMutationOptions<AddFlowMutation, AddFlowMutationVariables>;
export const EditFlowDocument = gql`
    mutation editFlow($flowId: Int!, $input: EditFlowInput!) {
  editFlow(flowId: $flowId, input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type EditFlowMutationFn = Apollo.MutationFunction<EditFlowMutation, EditFlowMutationVariables>;

/**
 * __useEditFlowMutation__
 *
 * To run a mutation, you first call `useEditFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFlowMutation, { data, loading, error }] = useEditFlowMutation({
 *   variables: {
 *      flowId: // value for 'flowId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditFlowMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditFlowMutation, EditFlowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditFlowMutation, EditFlowMutationVariables>(EditFlowDocument, options);
      }
export type EditFlowMutationHookResult = ReturnType<typeof useEditFlowMutation>;
export type EditFlowMutationResult = Apollo.MutationResult<EditFlowMutation>;
export type EditFlowMutationOptions = Apollo.BaseMutationOptions<EditFlowMutation, EditFlowMutationVariables>;
export const FlowDocument = gql`
    query flow($flowId: Int!) {
  flow(flowId: $flowId) {
    flow {
      id
      name
      flowStatements {
        id
        type
        flowComponents {
          id
          type
          value
        }
      }
    }
  }
}
    `;

/**
 * __useFlowQuery__
 *
 * To run a query within a React component, call `useFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlowQuery({
 *   variables: {
 *      flowId: // value for 'flowId'
 *   },
 * });
 */
export function useFlowQuery(baseOptions: ApolloReactHooks.QueryHookOptions<FlowQuery, FlowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<FlowQuery, FlowQueryVariables>(FlowDocument, options);
      }
export function useFlowLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FlowQuery, FlowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<FlowQuery, FlowQueryVariables>(FlowDocument, options);
        }
export type FlowQueryHookResult = ReturnType<typeof useFlowQuery>;
export type FlowLazyQueryHookResult = ReturnType<typeof useFlowLazyQuery>;
export type FlowQueryResult = Apollo.QueryResult<FlowQuery, FlowQueryVariables>;
export const FlowsDocument = gql`
    query flows($where: FlowsFilter, $offset: Int!, $limit: Int!) {
  flows(where: $where, offset: $offset, limit: $limit) {
    nodes {
      id
      name
      flowStatements {
        id
        flowComponents {
          id
          type
          value
        }
        type
      }
    }
  }
}
    `;

/**
 * __useFlowsQuery__
 *
 * To run a query within a React component, call `useFlowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlowsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useFlowsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<FlowsQuery, FlowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<FlowsQuery, FlowsQueryVariables>(FlowsDocument, options);
      }
export function useFlowsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FlowsQuery, FlowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<FlowsQuery, FlowsQueryVariables>(FlowsDocument, options);
        }
export type FlowsQueryHookResult = ReturnType<typeof useFlowsQuery>;
export type FlowsLazyQueryHookResult = ReturnType<typeof useFlowsLazyQuery>;
export type FlowsQueryResult = Apollo.QueryResult<FlowsQuery, FlowsQueryVariables>;
export const AddFlowRunnerDocument = gql`
    mutation addFlowRunner($input: AddFlowRunnerInput!) {
  addFlowRunner(input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type AddFlowRunnerMutationFn = Apollo.MutationFunction<AddFlowRunnerMutation, AddFlowRunnerMutationVariables>;

/**
 * __useAddFlowRunnerMutation__
 *
 * To run a mutation, you first call `useAddFlowRunnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFlowRunnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFlowRunnerMutation, { data, loading, error }] = useAddFlowRunnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFlowRunnerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddFlowRunnerMutation, AddFlowRunnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddFlowRunnerMutation, AddFlowRunnerMutationVariables>(AddFlowRunnerDocument, options);
      }
export type AddFlowRunnerMutationHookResult = ReturnType<typeof useAddFlowRunnerMutation>;
export type AddFlowRunnerMutationResult = Apollo.MutationResult<AddFlowRunnerMutation>;
export type AddFlowRunnerMutationOptions = Apollo.BaseMutationOptions<AddFlowRunnerMutation, AddFlowRunnerMutationVariables>;
export const EditFlowRunnerDocument = gql`
    mutation editFlowRunner($flowRunnerId: Int!, $input: EditFlowRunnerInput!) {
  editFlowRunner(flowRunnerId: $flowRunnerId, input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type EditFlowRunnerMutationFn = Apollo.MutationFunction<EditFlowRunnerMutation, EditFlowRunnerMutationVariables>;

/**
 * __useEditFlowRunnerMutation__
 *
 * To run a mutation, you first call `useEditFlowRunnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFlowRunnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFlowRunnerMutation, { data, loading, error }] = useEditFlowRunnerMutation({
 *   variables: {
 *      flowRunnerId: // value for 'flowRunnerId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditFlowRunnerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditFlowRunnerMutation, EditFlowRunnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditFlowRunnerMutation, EditFlowRunnerMutationVariables>(EditFlowRunnerDocument, options);
      }
export type EditFlowRunnerMutationHookResult = ReturnType<typeof useEditFlowRunnerMutation>;
export type EditFlowRunnerMutationResult = Apollo.MutationResult<EditFlowRunnerMutation>;
export type EditFlowRunnerMutationOptions = Apollo.BaseMutationOptions<EditFlowRunnerMutation, EditFlowRunnerMutationVariables>;
export const FlowRunnerDocument = gql`
    query flowRunner($flowRunnerId: Int!) {
  flowRunner(flowRunnerId: $flowRunnerId) {
    flowRunner {
      id
      name
      flow {
        id
        name
      }
      validFrom
      validTo
      timeFrom
      timeTo
      products {
        id
        internalId
        name
        valueEntities {
          name
          templateKey
          value
          type
        }
      }
    }
  }
}
    `;

/**
 * __useFlowRunnerQuery__
 *
 * To run a query within a React component, call `useFlowRunnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlowRunnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlowRunnerQuery({
 *   variables: {
 *      flowRunnerId: // value for 'flowRunnerId'
 *   },
 * });
 */
export function useFlowRunnerQuery(baseOptions: ApolloReactHooks.QueryHookOptions<FlowRunnerQuery, FlowRunnerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<FlowRunnerQuery, FlowRunnerQueryVariables>(FlowRunnerDocument, options);
      }
export function useFlowRunnerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FlowRunnerQuery, FlowRunnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<FlowRunnerQuery, FlowRunnerQueryVariables>(FlowRunnerDocument, options);
        }
export type FlowRunnerQueryHookResult = ReturnType<typeof useFlowRunnerQuery>;
export type FlowRunnerLazyQueryHookResult = ReturnType<typeof useFlowRunnerLazyQuery>;
export type FlowRunnerQueryResult = Apollo.QueryResult<FlowRunnerQuery, FlowRunnerQueryVariables>;
export const FlowRunnersDocument = gql`
    query flowRunners($where: FlowRunnersFilter, $offset: Int!, $limit: Int!) {
  flowRunners(where: $where, offset: $offset, limit: $limit) {
    nodes {
      id
      name
      flow {
        id
        name
      }
      validTo
      validFrom
      timeFrom
      timeTo
    }
  }
}
    `;

/**
 * __useFlowRunnersQuery__
 *
 * To run a query within a React component, call `useFlowRunnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlowRunnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlowRunnersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useFlowRunnersQuery(baseOptions: ApolloReactHooks.QueryHookOptions<FlowRunnersQuery, FlowRunnersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<FlowRunnersQuery, FlowRunnersQueryVariables>(FlowRunnersDocument, options);
      }
export function useFlowRunnersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FlowRunnersQuery, FlowRunnersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<FlowRunnersQuery, FlowRunnersQueryVariables>(FlowRunnersDocument, options);
        }
export type FlowRunnersQueryHookResult = ReturnType<typeof useFlowRunnersQuery>;
export type FlowRunnersLazyQueryHookResult = ReturnType<typeof useFlowRunnersLazyQuery>;
export type FlowRunnersQueryResult = Apollo.QueryResult<FlowRunnersQuery, FlowRunnersQueryVariables>;
export const InfoDocument = gql`
    query info {
  info {
    tenant
    smartLabelCount
    sensorLabelCount
    shelfBarCount
    signageDeviceCount
    industrialScaleCount
    rootCount
    routerCount
    batteryUnderCount
    deviceErrCount
    apiErrCount
    processingErrCount
  }
}
    `;

/**
 * __useInfoQuery__
 *
 * To run a query within a React component, call `useInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InfoQuery, InfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<InfoQuery, InfoQueryVariables>(InfoDocument, options);
      }
export function useInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InfoQuery, InfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<InfoQuery, InfoQueryVariables>(InfoDocument, options);
        }
export type InfoQueryHookResult = ReturnType<typeof useInfoQuery>;
export type InfoLazyQueryHookResult = ReturnType<typeof useInfoLazyQuery>;
export type InfoQueryResult = Apollo.QueryResult<InfoQuery, InfoQueryVariables>;
export const AddIntegrationDocument = gql`
    mutation addIntegration($input: AddIntegrationInput!) {
  addIntegration(input: $input) {
    integration {
      id
    }
    errors {
      path
      message
    }
  }
}
    `;
export type AddIntegrationMutationFn = Apollo.MutationFunction<AddIntegrationMutation, AddIntegrationMutationVariables>;

/**
 * __useAddIntegrationMutation__
 *
 * To run a mutation, you first call `useAddIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addIntegrationMutation, { data, loading, error }] = useAddIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddIntegrationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddIntegrationMutation, AddIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddIntegrationMutation, AddIntegrationMutationVariables>(AddIntegrationDocument, options);
      }
export type AddIntegrationMutationHookResult = ReturnType<typeof useAddIntegrationMutation>;
export type AddIntegrationMutationResult = Apollo.MutationResult<AddIntegrationMutation>;
export type AddIntegrationMutationOptions = Apollo.BaseMutationOptions<AddIntegrationMutation, AddIntegrationMutationVariables>;
export const DeleteIntegrationDocument = gql`
    mutation deleteIntegration($id: Int!) {
  deleteIntegration(id: $id) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type DeleteIntegrationMutationFn = Apollo.MutationFunction<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>;

/**
 * __useDeleteIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIntegrationMutation, { data, loading, error }] = useDeleteIntegrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteIntegrationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>(DeleteIntegrationDocument, options);
      }
export type DeleteIntegrationMutationHookResult = ReturnType<typeof useDeleteIntegrationMutation>;
export type DeleteIntegrationMutationResult = Apollo.MutationResult<DeleteIntegrationMutation>;
export type DeleteIntegrationMutationOptions = Apollo.BaseMutationOptions<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>;
export const EditIntegrationDocument = gql`
    mutation editIntegration($input: EditIntegrationInput!) {
  editIntegration(input: $input) {
    success
    errors {
      path
      message
    }
  }
}
    `;
export type EditIntegrationMutationFn = Apollo.MutationFunction<EditIntegrationMutation, EditIntegrationMutationVariables>;

/**
 * __useEditIntegrationMutation__
 *
 * To run a mutation, you first call `useEditIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editIntegrationMutation, { data, loading, error }] = useEditIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditIntegrationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditIntegrationMutation, EditIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditIntegrationMutation, EditIntegrationMutationVariables>(EditIntegrationDocument, options);
      }
export type EditIntegrationMutationHookResult = ReturnType<typeof useEditIntegrationMutation>;
export type EditIntegrationMutationResult = Apollo.MutationResult<EditIntegrationMutation>;
export type EditIntegrationMutationOptions = Apollo.BaseMutationOptions<EditIntegrationMutation, EditIntegrationMutationVariables>;
export const IntegrationDocument = gql`
    query integration($id: Int, $token: String) {
  integration(id: $id, token: $token) {
    integration {
      id
      name
      token
      syncTime
      weekDays
      triggerUrl
      cronUpdateUrl
      synchronizations {
        id
        status
        time
        updatedProducts
        note
      }
    }
  }
}
    `;

/**
 * __useIntegrationQuery__
 *
 * To run a query within a React component, call `useIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useIntegrationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IntegrationQuery, IntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<IntegrationQuery, IntegrationQueryVariables>(IntegrationDocument, options);
      }
export function useIntegrationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IntegrationQuery, IntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<IntegrationQuery, IntegrationQueryVariables>(IntegrationDocument, options);
        }
export type IntegrationQueryHookResult = ReturnType<typeof useIntegrationQuery>;
export type IntegrationLazyQueryHookResult = ReturnType<typeof useIntegrationLazyQuery>;
export type IntegrationQueryResult = Apollo.QueryResult<IntegrationQuery, IntegrationQueryVariables>;
export const IntegrationsDocument = gql`
    query integrations($where: IntegrationsFilter, $offset: Int!, $limit: Int!) {
  integrations(where: $where, offset: $offset, limit: $limit) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useIntegrationsQuery__
 *
 * To run a query within a React component, call `useIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useIntegrationsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<IntegrationsQuery, IntegrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<IntegrationsQuery, IntegrationsQueryVariables>(IntegrationsDocument, options);
      }
export function useIntegrationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IntegrationsQuery, IntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<IntegrationsQuery, IntegrationsQueryVariables>(IntegrationsDocument, options);
        }
export type IntegrationsQueryHookResult = ReturnType<typeof useIntegrationsQuery>;
export type IntegrationsLazyQueryHookResult = ReturnType<typeof useIntegrationsLazyQuery>;
export type IntegrationsQueryResult = Apollo.QueryResult<IntegrationsQuery, IntegrationsQueryVariables>;
export const SynchronizationsDocument = gql`
    query synchronizations($where: SynchronizationsFilter, $offset: Int!, $limit: Int!) {
  synchronizations(where: $where, offset: $offset, limit: $limit) {
    nodes {
      id
      status
    }
  }
}
    `;

/**
 * __useSynchronizationsQuery__
 *
 * To run a query within a React component, call `useSynchronizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSynchronizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSynchronizationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSynchronizationsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SynchronizationsQuery, SynchronizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SynchronizationsQuery, SynchronizationsQueryVariables>(SynchronizationsDocument, options);
      }
export function useSynchronizationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SynchronizationsQuery, SynchronizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SynchronizationsQuery, SynchronizationsQueryVariables>(SynchronizationsDocument, options);
        }
export type SynchronizationsQueryHookResult = ReturnType<typeof useSynchronizationsQuery>;
export type SynchronizationsLazyQueryHookResult = ReturnType<typeof useSynchronizationsLazyQuery>;
export type SynchronizationsQueryResult = Apollo.QueryResult<SynchronizationsQuery, SynchronizationsQueryVariables>;
export const TriggerSyncDocument = gql`
    mutation triggerSync($input: TriggerSyncInput!) {
  triggerSync(input: $input) {
    success
    errors {
      path
      message
    }
  }
}
    `;
export type TriggerSyncMutationFn = Apollo.MutationFunction<TriggerSyncMutation, TriggerSyncMutationVariables>;

/**
 * __useTriggerSyncMutation__
 *
 * To run a mutation, you first call `useTriggerSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerSyncMutation, { data, loading, error }] = useTriggerSyncMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTriggerSyncMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TriggerSyncMutation, TriggerSyncMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<TriggerSyncMutation, TriggerSyncMutationVariables>(TriggerSyncDocument, options);
      }
export type TriggerSyncMutationHookResult = ReturnType<typeof useTriggerSyncMutation>;
export type TriggerSyncMutationResult = Apollo.MutationResult<TriggerSyncMutation>;
export type TriggerSyncMutationOptions = Apollo.BaseMutationOptions<TriggerSyncMutation, TriggerSyncMutationVariables>;
export const EditDemoScreenDocument = gql`
    mutation editDemoScreen($input: EditDemoScreenInput!) {
  editDemoScreen(input: $input) {
    success
    filename
  }
}
    `;
export type EditDemoScreenMutationFn = Apollo.MutationFunction<EditDemoScreenMutation, EditDemoScreenMutationVariables>;

/**
 * __useEditDemoScreenMutation__
 *
 * To run a mutation, you first call `useEditDemoScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDemoScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDemoScreenMutation, { data, loading, error }] = useEditDemoScreenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditDemoScreenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditDemoScreenMutation, EditDemoScreenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditDemoScreenMutation, EditDemoScreenMutationVariables>(EditDemoScreenDocument, options);
      }
export type EditDemoScreenMutationHookResult = ReturnType<typeof useEditDemoScreenMutation>;
export type EditDemoScreenMutationResult = Apollo.MutationResult<EditDemoScreenMutation>;
export type EditDemoScreenMutationOptions = Apollo.BaseMutationOptions<EditDemoScreenMutation, EditDemoScreenMutationVariables>;
export const AddPositionDocument = gql`
    mutation addPosition($input: PositionInput!) {
  addPosition(input: $input) {
    errors {
      message
    }
    success
  }
}
    `;
export type AddPositionMutationFn = Apollo.MutationFunction<AddPositionMutation, AddPositionMutationVariables>;

/**
 * __useAddPositionMutation__
 *
 * To run a mutation, you first call `useAddPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPositionMutation, { data, loading, error }] = useAddPositionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPositionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddPositionMutation, AddPositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddPositionMutation, AddPositionMutationVariables>(AddPositionDocument, options);
      }
export type AddPositionMutationHookResult = ReturnType<typeof useAddPositionMutation>;
export type AddPositionMutationResult = Apollo.MutationResult<AddPositionMutation>;
export type AddPositionMutationOptions = Apollo.BaseMutationOptions<AddPositionMutation, AddPositionMutationVariables>;
export const DeletePositionDocument = gql`
    mutation deletePosition($positionId: Int!) {
  deletePosition(positionId: $positionId) {
    errors {
      message
    }
    success
  }
}
    `;
export type DeletePositionMutationFn = Apollo.MutationFunction<DeletePositionMutation, DeletePositionMutationVariables>;

/**
 * __useDeletePositionMutation__
 *
 * To run a mutation, you first call `useDeletePositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePositionMutation, { data, loading, error }] = useDeletePositionMutation({
 *   variables: {
 *      positionId: // value for 'positionId'
 *   },
 * });
 */
export function useDeletePositionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePositionMutation, DeletePositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeletePositionMutation, DeletePositionMutationVariables>(DeletePositionDocument, options);
      }
export type DeletePositionMutationHookResult = ReturnType<typeof useDeletePositionMutation>;
export type DeletePositionMutationResult = Apollo.MutationResult<DeletePositionMutation>;
export type DeletePositionMutationOptions = Apollo.BaseMutationOptions<DeletePositionMutation, DeletePositionMutationVariables>;
export const DeletePositionAndUntieDeviceDocument = gql`
    mutation deletePositionAndUntieDevice($positionId: Int!) {
  deletePositionAndUntieDevice(positionId: $positionId) {
    errors {
      message
    }
    success
  }
}
    `;
export type DeletePositionAndUntieDeviceMutationFn = Apollo.MutationFunction<DeletePositionAndUntieDeviceMutation, DeletePositionAndUntieDeviceMutationVariables>;

/**
 * __useDeletePositionAndUntieDeviceMutation__
 *
 * To run a mutation, you first call `useDeletePositionAndUntieDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePositionAndUntieDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePositionAndUntieDeviceMutation, { data, loading, error }] = useDeletePositionAndUntieDeviceMutation({
 *   variables: {
 *      positionId: // value for 'positionId'
 *   },
 * });
 */
export function useDeletePositionAndUntieDeviceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePositionAndUntieDeviceMutation, DeletePositionAndUntieDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeletePositionAndUntieDeviceMutation, DeletePositionAndUntieDeviceMutationVariables>(DeletePositionAndUntieDeviceDocument, options);
      }
export type DeletePositionAndUntieDeviceMutationHookResult = ReturnType<typeof useDeletePositionAndUntieDeviceMutation>;
export type DeletePositionAndUntieDeviceMutationResult = Apollo.MutationResult<DeletePositionAndUntieDeviceMutation>;
export type DeletePositionAndUntieDeviceMutationOptions = Apollo.BaseMutationOptions<DeletePositionAndUntieDeviceMutation, DeletePositionAndUntieDeviceMutationVariables>;
export const EditPositionDocument = gql`
    mutation editPosition($positionId: Int!, $input: EditPositionInput!) {
  editPosition(positionId: $positionId, input: $input) {
    errors {
      message
      path
    }
    success
  }
}
    `;
export type EditPositionMutationFn = Apollo.MutationFunction<EditPositionMutation, EditPositionMutationVariables>;

/**
 * __useEditPositionMutation__
 *
 * To run a mutation, you first call `useEditPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPositionMutation, { data, loading, error }] = useEditPositionMutation({
 *   variables: {
 *      positionId: // value for 'positionId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditPositionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditPositionMutation, EditPositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditPositionMutation, EditPositionMutationVariables>(EditPositionDocument, options);
      }
export type EditPositionMutationHookResult = ReturnType<typeof useEditPositionMutation>;
export type EditPositionMutationResult = Apollo.MutationResult<EditPositionMutation>;
export type EditPositionMutationOptions = Apollo.BaseMutationOptions<EditPositionMutation, EditPositionMutationVariables>;
export const EditPositionOldPositionDocument = gql`
    mutation editPositionOldPosition($positionId: Int!, $positionInput: EditPositionInput!, $oldPositionId: Int!, $oldPositionInput: EditPositionInput!) {
  A: editPosition(positionId: $positionId, input: $positionInput) {
    errors {
      message
      path
    }
    success
  }
  B: editPosition(positionId: $oldPositionId, input: $oldPositionInput) {
    errors {
      message
      path
    }
    success
  }
}
    `;
export type EditPositionOldPositionMutationFn = Apollo.MutationFunction<EditPositionOldPositionMutation, EditPositionOldPositionMutationVariables>;

/**
 * __useEditPositionOldPositionMutation__
 *
 * To run a mutation, you first call `useEditPositionOldPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPositionOldPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPositionOldPositionMutation, { data, loading, error }] = useEditPositionOldPositionMutation({
 *   variables: {
 *      positionId: // value for 'positionId'
 *      positionInput: // value for 'positionInput'
 *      oldPositionId: // value for 'oldPositionId'
 *      oldPositionInput: // value for 'oldPositionInput'
 *   },
 * });
 */
export function useEditPositionOldPositionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditPositionOldPositionMutation, EditPositionOldPositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditPositionOldPositionMutation, EditPositionOldPositionMutationVariables>(EditPositionOldPositionDocument, options);
      }
export type EditPositionOldPositionMutationHookResult = ReturnType<typeof useEditPositionOldPositionMutation>;
export type EditPositionOldPositionMutationResult = Apollo.MutationResult<EditPositionOldPositionMutation>;
export type EditPositionOldPositionMutationOptions = Apollo.BaseMutationOptions<EditPositionOldPositionMutation, EditPositionOldPositionMutationVariables>;
export const PlanogramPositionDocument = gql`
    query planogramPosition($positionId: Int!) {
  position(positionId: $positionId) {
    position {
      id
      key
      sequence
      x
      y
      z
      shelf {
        id
      }
      product {
        id
        internalId
        name
        parentProduct {
          name
        }
      }
      root {
        id
      }
      device {
        id
        serialNumber
      }
      templateVariant {
        id
      }
    }
  }
}
    `;

/**
 * __usePlanogramPositionQuery__
 *
 * To run a query within a React component, call `usePlanogramPositionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanogramPositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanogramPositionQuery({
 *   variables: {
 *      positionId: // value for 'positionId'
 *   },
 * });
 */
export function usePlanogramPositionQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PlanogramPositionQuery, PlanogramPositionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PlanogramPositionQuery, PlanogramPositionQueryVariables>(PlanogramPositionDocument, options);
      }
export function usePlanogramPositionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PlanogramPositionQuery, PlanogramPositionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PlanogramPositionQuery, PlanogramPositionQueryVariables>(PlanogramPositionDocument, options);
        }
export type PlanogramPositionQueryHookResult = ReturnType<typeof usePlanogramPositionQuery>;
export type PlanogramPositionLazyQueryHookResult = ReturnType<typeof usePlanogramPositionLazyQuery>;
export type PlanogramPositionQueryResult = Apollo.QueryResult<PlanogramPositionQuery, PlanogramPositionQueryVariables>;
export const PlanogramPositionsDocument = gql`
    query planogramPositions($where: PositionsFilter, $offset: Int!, $limit: Int!) {
  positions(where: $where, limit: $limit, offset: $offset) {
    nodes {
      id
      key
      sequence
      shelf {
        key
        bay {
          key
          aisle {
            key
          }
        }
      }
      device {
        id
        serialNumber
      }
      templateVariant {
        id
        key
      }
      product {
        id
        internalId
        name
        parentProduct {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __usePlanogramPositionsQuery__
 *
 * To run a query within a React component, call `usePlanogramPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanogramPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanogramPositionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePlanogramPositionsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PlanogramPositionsQuery, PlanogramPositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PlanogramPositionsQuery, PlanogramPositionsQueryVariables>(PlanogramPositionsDocument, options);
      }
export function usePlanogramPositionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PlanogramPositionsQuery, PlanogramPositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PlanogramPositionsQuery, PlanogramPositionsQueryVariables>(PlanogramPositionsDocument, options);
        }
export type PlanogramPositionsQueryHookResult = ReturnType<typeof usePlanogramPositionsQuery>;
export type PlanogramPositionsLazyQueryHookResult = ReturnType<typeof usePlanogramPositionsLazyQuery>;
export type PlanogramPositionsQueryResult = Apollo.QueryResult<PlanogramPositionsQuery, PlanogramPositionsQueryVariables>;
export const PlanogramRootDocument = gql`
    query planogramRoot($rootId: Int!) {
  root(rootId: $rootId) {
    root {
      name
    }
  }
}
    `;

/**
 * __usePlanogramRootQuery__
 *
 * To run a query within a React component, call `usePlanogramRootQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanogramRootQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanogramRootQuery({
 *   variables: {
 *      rootId: // value for 'rootId'
 *   },
 * });
 */
export function usePlanogramRootQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PlanogramRootQuery, PlanogramRootQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PlanogramRootQuery, PlanogramRootQueryVariables>(PlanogramRootDocument, options);
      }
export function usePlanogramRootLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PlanogramRootQuery, PlanogramRootQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PlanogramRootQuery, PlanogramRootQueryVariables>(PlanogramRootDocument, options);
        }
export type PlanogramRootQueryHookResult = ReturnType<typeof usePlanogramRootQuery>;
export type PlanogramRootLazyQueryHookResult = ReturnType<typeof usePlanogramRootLazyQuery>;
export type PlanogramRootQueryResult = Apollo.QueryResult<PlanogramRootQuery, PlanogramRootQueryVariables>;
export const StockPlanogramPositionsDocument = gql`
    query stockPlanogramPositions($where: PositionsFilter, $offset: Int!, $limit: Int!) {
  positions(where: $where, limit: $limit, offset: $offset) {
    nodes {
      id
      key
      sequence
      device {
        id
        serialNumber
      }
      templateVariant {
        id
        key
      }
      product {
        id
        internalId
        parentProduct {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useStockPlanogramPositionsQuery__
 *
 * To run a query within a React component, call `useStockPlanogramPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockPlanogramPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockPlanogramPositionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useStockPlanogramPositionsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<StockPlanogramPositionsQuery, StockPlanogramPositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<StockPlanogramPositionsQuery, StockPlanogramPositionsQueryVariables>(StockPlanogramPositionsDocument, options);
      }
export function useStockPlanogramPositionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StockPlanogramPositionsQuery, StockPlanogramPositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<StockPlanogramPositionsQuery, StockPlanogramPositionsQueryVariables>(StockPlanogramPositionsDocument, options);
        }
export type StockPlanogramPositionsQueryHookResult = ReturnType<typeof useStockPlanogramPositionsQuery>;
export type StockPlanogramPositionsLazyQueryHookResult = ReturnType<typeof useStockPlanogramPositionsLazyQuery>;
export type StockPlanogramPositionsQueryResult = Apollo.QueryResult<StockPlanogramPositionsQuery, StockPlanogramPositionsQueryVariables>;
export const AddPlaylistDocument = gql`
    mutation addPlaylist($input: AddPlaylistInput!) {
  addPlaylist(input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type AddPlaylistMutationFn = Apollo.MutationFunction<AddPlaylistMutation, AddPlaylistMutationVariables>;

/**
 * __useAddPlaylistMutation__
 *
 * To run a mutation, you first call `useAddPlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlaylistMutation, { data, loading, error }] = useAddPlaylistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPlaylistMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddPlaylistMutation, AddPlaylistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddPlaylistMutation, AddPlaylistMutationVariables>(AddPlaylistDocument, options);
      }
export type AddPlaylistMutationHookResult = ReturnType<typeof useAddPlaylistMutation>;
export type AddPlaylistMutationResult = Apollo.MutationResult<AddPlaylistMutation>;
export type AddPlaylistMutationOptions = Apollo.BaseMutationOptions<AddPlaylistMutation, AddPlaylistMutationVariables>;
export const EditChannelDocument = gql`
    mutation editChannel($channelId: Int!, $input: EditChannelInput!) {
  editChannel(channelId: $channelId, input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type EditChannelMutationFn = Apollo.MutationFunction<EditChannelMutation, EditChannelMutationVariables>;

/**
 * __useEditChannelMutation__
 *
 * To run a mutation, you first call `useEditChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editChannelMutation, { data, loading, error }] = useEditChannelMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditChannelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditChannelMutation, EditChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditChannelMutation, EditChannelMutationVariables>(EditChannelDocument, options);
      }
export type EditChannelMutationHookResult = ReturnType<typeof useEditChannelMutation>;
export type EditChannelMutationResult = Apollo.MutationResult<EditChannelMutation>;
export type EditChannelMutationOptions = Apollo.BaseMutationOptions<EditChannelMutation, EditChannelMutationVariables>;
export const PlaylistDocument = gql`
    query playlist($playlistId: Int!) {
  playlist(id: $playlistId) {
    playlist {
      id
      name
      playlistItems {
        id
        duration
        sequence
        file {
          id
          duration
          encoding
          mimetype
          filename
          thumbnail
        }
      }
    }
  }
}
    `;

/**
 * __usePlaylistQuery__
 *
 * To run a query within a React component, call `usePlaylistQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaylistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaylistQuery({
 *   variables: {
 *      playlistId: // value for 'playlistId'
 *   },
 * });
 */
export function usePlaylistQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PlaylistQuery, PlaylistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PlaylistQuery, PlaylistQueryVariables>(PlaylistDocument, options);
      }
export function usePlaylistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PlaylistQuery, PlaylistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PlaylistQuery, PlaylistQueryVariables>(PlaylistDocument, options);
        }
export type PlaylistQueryHookResult = ReturnType<typeof usePlaylistQuery>;
export type PlaylistLazyQueryHookResult = ReturnType<typeof usePlaylistLazyQuery>;
export type PlaylistQueryResult = Apollo.QueryResult<PlaylistQuery, PlaylistQueryVariables>;
export const PlaylistsDocument = gql`
    query playlists($where: PlaylistsFilter, $offset: Int!, $limit: Int!) {
  playlists(where: $where, offset: $offset, limit: $limit) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __usePlaylistsQuery__
 *
 * To run a query within a React component, call `usePlaylistsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaylistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaylistsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePlaylistsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PlaylistsQuery, PlaylistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PlaylistsQuery, PlaylistsQueryVariables>(PlaylistsDocument, options);
      }
export function usePlaylistsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PlaylistsQuery, PlaylistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PlaylistsQuery, PlaylistsQueryVariables>(PlaylistsDocument, options);
        }
export type PlaylistsQueryHookResult = ReturnType<typeof usePlaylistsQuery>;
export type PlaylistsLazyQueryHookResult = ReturnType<typeof usePlaylistsLazyQuery>;
export type PlaylistsQueryResult = Apollo.QueryResult<PlaylistsQuery, PlaylistsQueryVariables>;
export const PlaylistsWithItemsDocument = gql`
    query playlistsWithItems($where: PlaylistsFilter, $offset: Int!, $limit: Int!) {
  playlists(where: $where, offset: $offset, limit: $limit) {
    nodes {
      id
      name
      playlistItems {
        duration
        file {
          mimetype
          filename
          thumbnail
        }
      }
    }
  }
}
    `;

/**
 * __usePlaylistsWithItemsQuery__
 *
 * To run a query within a React component, call `usePlaylistsWithItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaylistsWithItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaylistsWithItemsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePlaylistsWithItemsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PlaylistsWithItemsQuery, PlaylistsWithItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PlaylistsWithItemsQuery, PlaylistsWithItemsQueryVariables>(PlaylistsWithItemsDocument, options);
      }
export function usePlaylistsWithItemsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PlaylistsWithItemsQuery, PlaylistsWithItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PlaylistsWithItemsQuery, PlaylistsWithItemsQueryVariables>(PlaylistsWithItemsDocument, options);
        }
export type PlaylistsWithItemsQueryHookResult = ReturnType<typeof usePlaylistsWithItemsQuery>;
export type PlaylistsWithItemsLazyQueryHookResult = ReturnType<typeof usePlaylistsWithItemsLazyQuery>;
export type PlaylistsWithItemsQueryResult = Apollo.QueryResult<PlaylistsWithItemsQuery, PlaylistsWithItemsQueryVariables>;
export const AddEditProductsDocument = gql`
    mutation addEditProducts($input: [ProductInput!]!) {
  addEditProducts(input: $input) {
    errors {
      path
      message
    }
    success
    isAssignedToShelves
    addedProducts {
      id
      internalId
      name
      eanCodes
      valueEntities {
        id
        name
        templateKey
        type
        value
      }
      productImages {
        id
        primary
        file {
          id
          originalname
          filename
          mimetype
          encoding
          url
          isFolder
          width
          height
          thumbnail
        }
      }
    }
  }
}
    `;
export type AddEditProductsMutationFn = Apollo.MutationFunction<AddEditProductsMutation, AddEditProductsMutationVariables>;

/**
 * __useAddEditProductsMutation__
 *
 * To run a mutation, you first call `useAddEditProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEditProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEditProductsMutation, { data, loading, error }] = useAddEditProductsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddEditProductsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddEditProductsMutation, AddEditProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddEditProductsMutation, AddEditProductsMutationVariables>(AddEditProductsDocument, options);
      }
export type AddEditProductsMutationHookResult = ReturnType<typeof useAddEditProductsMutation>;
export type AddEditProductsMutationResult = Apollo.MutationResult<AddEditProductsMutation>;
export type AddEditProductsMutationOptions = Apollo.BaseMutationOptions<AddEditProductsMutation, AddEditProductsMutationVariables>;
export const AddProductDocument = gql`
    mutation addProduct($input: ProductInput!) {
  addProduct(input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type AddProductMutationFn = Apollo.MutationFunction<AddProductMutation, AddProductMutationVariables>;

/**
 * __useAddProductMutation__
 *
 * To run a mutation, you first call `useAddProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductMutation, { data, loading, error }] = useAddProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddProductMutation, AddProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddProductMutation, AddProductMutationVariables>(AddProductDocument, options);
      }
export type AddProductMutationHookResult = ReturnType<typeof useAddProductMutation>;
export type AddProductMutationResult = Apollo.MutationResult<AddProductMutation>;
export type AddProductMutationOptions = Apollo.BaseMutationOptions<AddProductMutation, AddProductMutationVariables>;
export const DeleteProductDocument = gql`
    mutation deleteProduct($id: Int!) {
  deleteProduct(id: $id) {
    errors {
      message
      path
    }
    success
  }
}
    `;
export type DeleteProductMutationFn = Apollo.MutationFunction<DeleteProductMutation, DeleteProductMutationVariables>;

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProductMutation, DeleteProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, options);
      }
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>;
export type DeleteProductMutationResult = Apollo.MutationResult<DeleteProductMutation>;
export type DeleteProductMutationOptions = Apollo.BaseMutationOptions<DeleteProductMutation, DeleteProductMutationVariables>;
export const DeleteProductsDocument = gql`
    mutation deleteProducts($input: DeleteProductInput!) {
  deleteProducts(input: $input) {
    affected
    errors {
      message
      path
    }
    success
  }
}
    `;
export type DeleteProductsMutationFn = Apollo.MutationFunction<DeleteProductsMutation, DeleteProductsMutationVariables>;

/**
 * __useDeleteProductsMutation__
 *
 * To run a mutation, you first call `useDeleteProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductsMutation, { data, loading, error }] = useDeleteProductsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProductsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProductsMutation, DeleteProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteProductsMutation, DeleteProductsMutationVariables>(DeleteProductsDocument, options);
      }
export type DeleteProductsMutationHookResult = ReturnType<typeof useDeleteProductsMutation>;
export type DeleteProductsMutationResult = Apollo.MutationResult<DeleteProductsMutation>;
export type DeleteProductsMutationOptions = Apollo.BaseMutationOptions<DeleteProductsMutation, DeleteProductsMutationVariables>;
export const DeleteValueEntitiesDocument = gql`
    mutation deleteValueEntities($ids: [Int!]) {
  deleteValueEntities(ids: $ids) {
    errors {
      message
      path
    }
    success
  }
}
    `;
export type DeleteValueEntitiesMutationFn = Apollo.MutationFunction<DeleteValueEntitiesMutation, DeleteValueEntitiesMutationVariables>;

/**
 * __useDeleteValueEntitiesMutation__
 *
 * To run a mutation, you first call `useDeleteValueEntitiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteValueEntitiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteValueEntitiesMutation, { data, loading, error }] = useDeleteValueEntitiesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteValueEntitiesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteValueEntitiesMutation, DeleteValueEntitiesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteValueEntitiesMutation, DeleteValueEntitiesMutationVariables>(DeleteValueEntitiesDocument, options);
      }
export type DeleteValueEntitiesMutationHookResult = ReturnType<typeof useDeleteValueEntitiesMutation>;
export type DeleteValueEntitiesMutationResult = Apollo.MutationResult<DeleteValueEntitiesMutation>;
export type DeleteValueEntitiesMutationOptions = Apollo.BaseMutationOptions<DeleteValueEntitiesMutation, DeleteValueEntitiesMutationVariables>;
export const EditProductDocument = gql`
    mutation editProduct($input: EditProductInput!) {
  editProduct(input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type EditProductMutationFn = Apollo.MutationFunction<EditProductMutation, EditProductMutationVariables>;

/**
 * __useEditProductMutation__
 *
 * To run a mutation, you first call `useEditProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProductMutation, { data, loading, error }] = useEditProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditProductMutation, EditProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditProductMutation, EditProductMutationVariables>(EditProductDocument, options);
      }
export type EditProductMutationHookResult = ReturnType<typeof useEditProductMutation>;
export type EditProductMutationResult = Apollo.MutationResult<EditProductMutation>;
export type EditProductMutationOptions = Apollo.BaseMutationOptions<EditProductMutation, EditProductMutationVariables>;
export const ProductDetailsDocument = gql`
    query productDetails($id: Int!) {
  product(id: $id) {
    product {
      id
      internalId
      name
      valueEntities {
        id
        templateKey
        type
        value
        rootId
      }
      parentProduct {
        id
        internalId
      }
      products {
        id
        internalId
      }
    }
  }
}
    `;

/**
 * __useProductDetailsQuery__
 *
 * To run a query within a React component, call `useProductDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductDetailsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ProductDetailsQuery, ProductDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ProductDetailsQuery, ProductDetailsQueryVariables>(ProductDetailsDocument, options);
      }
export function useProductDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductDetailsQuery, ProductDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ProductDetailsQuery, ProductDetailsQueryVariables>(ProductDetailsDocument, options);
        }
export type ProductDetailsQueryHookResult = ReturnType<typeof useProductDetailsQuery>;
export type ProductDetailsLazyQueryHookResult = ReturnType<typeof useProductDetailsLazyQuery>;
export type ProductDetailsQueryResult = Apollo.QueryResult<ProductDetailsQuery, ProductDetailsQueryVariables>;
export const ProductDetailsListDocument = gql`
    query productDetailsList($where: ProductsFilter) {
  products(where: $where, limit: 100, offset: 0) {
    nodes {
      id
      internalId
      name
      valueEntities {
        name
        templateKey
        type
        value
      }
      parentProduct {
        id
        internalId
      }
      products {
        id
        internalId
      }
    }
  }
}
    `;

/**
 * __useProductDetailsListQuery__
 *
 * To run a query within a React component, call `useProductDetailsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductDetailsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductDetailsListQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useProductDetailsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProductDetailsListQuery, ProductDetailsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ProductDetailsListQuery, ProductDetailsListQueryVariables>(ProductDetailsListDocument, options);
      }
export function useProductDetailsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductDetailsListQuery, ProductDetailsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ProductDetailsListQuery, ProductDetailsListQueryVariables>(ProductDetailsListDocument, options);
        }
export type ProductDetailsListQueryHookResult = ReturnType<typeof useProductDetailsListQuery>;
export type ProductDetailsListLazyQueryHookResult = ReturnType<typeof useProductDetailsListLazyQuery>;
export type ProductDetailsListQueryResult = Apollo.QueryResult<ProductDetailsListQuery, ProductDetailsListQueryVariables>;
export const ProductsDocument = gql`
    query products($where: ProductsFilter, $offset: Int!, $limit: Int!) {
  products(where: $where, offset: $offset, limit: $limit) {
    nodes {
      id
      eanCodes
      internalId
      name
      productImages {
        id
        file {
          id
          thumbnail
        }
      }
    }
    totalCount
  }
}
    `;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useProductsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
      }
export function useProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const SearchProductsDocument = gql`
    query searchProducts($where: ProductsFilter, $offset: Int!, $limit: Int!) {
  products(where: $where, offset: $offset, limit: $limit) {
    nodes {
      id
      eanCodes
      internalId
      name
      parentProduct {
        name
      }
    }
    totalCount
  }
}
    `;

/**
 * __useSearchProductsQuery__
 *
 * To run a query within a React component, call `useSearchProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProductsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchProductsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SearchProductsQuery, SearchProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SearchProductsQuery, SearchProductsQueryVariables>(SearchProductsDocument, options);
      }
export function useSearchProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchProductsQuery, SearchProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SearchProductsQuery, SearchProductsQueryVariables>(SearchProductsDocument, options);
        }
export type SearchProductsQueryHookResult = ReturnType<typeof useSearchProductsQuery>;
export type SearchProductsLazyQueryHookResult = ReturnType<typeof useSearchProductsLazyQuery>;
export type SearchProductsQueryResult = Apollo.QueryResult<SearchProductsQuery, SearchProductsQueryVariables>;
export const BatchTransactionsDocument = gql`
    query BatchTransactions($batchUUID: String!) {
  batchTransactions(batchUUID: $batchUUID) {
    transactions {
      stage
      transactionid
      ts
      device {
        size
        serialNumber
        product {
          name
          internalId
        }
        template {
          templateVariant {
            key
          }
        }
        root {
          name
        }
        position {
          id
          name
          key
          sequence
          shelf {
            id
            key
            bay {
              id
              key
              aisle {
                id
                key
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBatchTransactionsQuery__
 *
 * To run a query within a React component, call `useBatchTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBatchTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBatchTransactionsQuery({
 *   variables: {
 *      batchUUID: // value for 'batchUUID'
 *   },
 * });
 */
export function useBatchTransactionsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<BatchTransactionsQuery, BatchTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<BatchTransactionsQuery, BatchTransactionsQueryVariables>(BatchTransactionsDocument, options);
      }
export function useBatchTransactionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BatchTransactionsQuery, BatchTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<BatchTransactionsQuery, BatchTransactionsQueryVariables>(BatchTransactionsDocument, options);
        }
export type BatchTransactionsQueryHookResult = ReturnType<typeof useBatchTransactionsQuery>;
export type BatchTransactionsLazyQueryHookResult = ReturnType<typeof useBatchTransactionsLazyQuery>;
export type BatchTransactionsQueryResult = Apollo.QueryResult<BatchTransactionsQuery, BatchTransactionsQueryVariables>;
export const AddRoleDocument = gql`
    mutation addRole($input: AddRoleInput!) {
  addRole(input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type AddRoleMutationFn = Apollo.MutationFunction<AddRoleMutation, AddRoleMutationVariables>;

/**
 * __useAddRoleMutation__
 *
 * To run a mutation, you first call `useAddRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRoleMutation, { data, loading, error }] = useAddRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddRoleMutation, AddRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddRoleMutation, AddRoleMutationVariables>(AddRoleDocument, options);
      }
export type AddRoleMutationHookResult = ReturnType<typeof useAddRoleMutation>;
export type AddRoleMutationResult = Apollo.MutationResult<AddRoleMutation>;
export type AddRoleMutationOptions = Apollo.BaseMutationOptions<AddRoleMutation, AddRoleMutationVariables>;
export const EditRolezDocument = gql`
    mutation editRolez($roleId: Int!, $input: EditRoleInput!) {
  editRole(roleId: $roleId, input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type EditRolezMutationFn = Apollo.MutationFunction<EditRolezMutation, EditRolezMutationVariables>;

/**
 * __useEditRolezMutation__
 *
 * To run a mutation, you first call `useEditRolezMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRolezMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRolezMutation, { data, loading, error }] = useEditRolezMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditRolezMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditRolezMutation, EditRolezMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditRolezMutation, EditRolezMutationVariables>(EditRolezDocument, options);
      }
export type EditRolezMutationHookResult = ReturnType<typeof useEditRolezMutation>;
export type EditRolezMutationResult = Apollo.MutationResult<EditRolezMutation>;
export type EditRolezMutationOptions = Apollo.BaseMutationOptions<EditRolezMutation, EditRolezMutationVariables>;
export const DeleteRoleDocument = gql`
    mutation deleteRole($id: Int!) {
  deleteRole(id: $id) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type DeleteRoleMutationFn = Apollo.MutationFunction<DeleteRoleMutation, DeleteRoleMutationVariables>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRoleMutation, DeleteRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(DeleteRoleDocument, options);
      }
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<DeleteRoleMutation, DeleteRoleMutationVariables>;
export const EditRoleDocument = gql`
    mutation editRole($roleId: Int!, $input: EditRoleInput!) {
  editRole(roleId: $roleId, input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type EditRoleMutationFn = Apollo.MutationFunction<EditRoleMutation, EditRoleMutationVariables>;

/**
 * __useEditRoleMutation__
 *
 * To run a mutation, you first call `useEditRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRoleMutation, { data, loading, error }] = useEditRoleMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditRoleMutation, EditRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditRoleMutation, EditRoleMutationVariables>(EditRoleDocument, options);
      }
export type EditRoleMutationHookResult = ReturnType<typeof useEditRoleMutation>;
export type EditRoleMutationResult = Apollo.MutationResult<EditRoleMutation>;
export type EditRoleMutationOptions = Apollo.BaseMutationOptions<EditRoleMutation, EditRoleMutationVariables>;
export const RoleDocument = gql`
    query role($roleId: Int!) {
  role(roleId: $roleId) {
    role {
      id
      value
      permissions
    }
  }
}
    `;

/**
 * __useRoleQuery__
 *
 * To run a query within a React component, call `useRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleQuery({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useRoleQuery(baseOptions: ApolloReactHooks.QueryHookOptions<RoleQuery, RoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<RoleQuery, RoleQueryVariables>(RoleDocument, options);
      }
export function useRoleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RoleQuery, RoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<RoleQuery, RoleQueryVariables>(RoleDocument, options);
        }
export type RoleQueryHookResult = ReturnType<typeof useRoleQuery>;
export type RoleLazyQueryHookResult = ReturnType<typeof useRoleLazyQuery>;
export type RoleQueryResult = Apollo.QueryResult<RoleQuery, RoleQueryVariables>;
export const RolesDocument = gql`
    query roles($where: RolesFilter, $offset: Int!, $limit: Int!) {
  roles(where: $where, offset: $offset, limit: $limit) {
    nodes {
      id
      value
    }
  }
}
    `;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useRolesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
      }
export function useRolesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = Apollo.QueryResult<RolesQuery, RolesQueryVariables>;
export const LocationsDocument = gql`
    query locations($where: RootsFilter, $offset: Int!, $limit: Int!) {
  roots(where: $where, offset: $offset, limit: $limit) {
    nodes {
      id
      name
      latitude
      longitude
      deviceCount
    }
  }
}
    `;

/**
 * __useLocationsQuery__
 *
 * To run a query within a React component, call `useLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useLocationsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<LocationsQuery, LocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, options);
      }
export function useLocationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationsQuery, LocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, options);
        }
export type LocationsQueryHookResult = ReturnType<typeof useLocationsQuery>;
export type LocationsLazyQueryHookResult = ReturnType<typeof useLocationsLazyQuery>;
export type LocationsQueryResult = Apollo.QueryResult<LocationsQuery, LocationsQueryVariables>;
export const LocationsSelectDocument = gql`
    query locationsSelect($where: RootsFilter, $offset: Int!, $limit: Int!) {
  roots(where: $where, offset: $offset, limit: $limit) {
    nodes {
      id
      key
      name
    }
  }
}
    `;

/**
 * __useLocationsSelectQuery__
 *
 * To run a query within a React component, call `useLocationsSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsSelectQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useLocationsSelectQuery(baseOptions: ApolloReactHooks.QueryHookOptions<LocationsSelectQuery, LocationsSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<LocationsSelectQuery, LocationsSelectQueryVariables>(LocationsSelectDocument, options);
      }
export function useLocationsSelectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationsSelectQuery, LocationsSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<LocationsSelectQuery, LocationsSelectQueryVariables>(LocationsSelectDocument, options);
        }
export type LocationsSelectQueryHookResult = ReturnType<typeof useLocationsSelectQuery>;
export type LocationsSelectLazyQueryHookResult = ReturnType<typeof useLocationsSelectLazyQuery>;
export type LocationsSelectQueryResult = Apollo.QueryResult<LocationsSelectQuery, LocationsSelectQueryVariables>;
export const RootsDocument = gql`
    query roots($where: RootsFilter, $offset: Int!, $limit: Int!) {
  roots(where: $where, offset: $offset, limit: $limit) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useRootsQuery__
 *
 * To run a query within a React component, call `useRootsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRootsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRootsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useRootsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<RootsQuery, RootsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<RootsQuery, RootsQueryVariables>(RootsDocument, options);
      }
export function useRootsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RootsQuery, RootsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<RootsQuery, RootsQueryVariables>(RootsDocument, options);
        }
export type RootsQueryHookResult = ReturnType<typeof useRootsQuery>;
export type RootsLazyQueryHookResult = ReturnType<typeof useRootsLazyQuery>;
export type RootsQueryResult = Apollo.QueryResult<RootsQuery, RootsQueryVariables>;
export const AddShelfDocument = gql`
    mutation addShelf($input: ShelfInput!) {
  addShelf(input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type AddShelfMutationFn = Apollo.MutationFunction<AddShelfMutation, AddShelfMutationVariables>;

/**
 * __useAddShelfMutation__
 *
 * To run a mutation, you first call `useAddShelfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddShelfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addShelfMutation, { data, loading, error }] = useAddShelfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddShelfMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddShelfMutation, AddShelfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddShelfMutation, AddShelfMutationVariables>(AddShelfDocument, options);
      }
export type AddShelfMutationHookResult = ReturnType<typeof useAddShelfMutation>;
export type AddShelfMutationResult = Apollo.MutationResult<AddShelfMutation>;
export type AddShelfMutationOptions = Apollo.BaseMutationOptions<AddShelfMutation, AddShelfMutationVariables>;
export const AddShelfStructDocument = gql`
    mutation addShelfStruct($input: ShelfStructInput!) {
  addShelfStruct(input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type AddShelfStructMutationFn = Apollo.MutationFunction<AddShelfStructMutation, AddShelfStructMutationVariables>;

/**
 * __useAddShelfStructMutation__
 *
 * To run a mutation, you first call `useAddShelfStructMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddShelfStructMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addShelfStructMutation, { data, loading, error }] = useAddShelfStructMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddShelfStructMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddShelfStructMutation, AddShelfStructMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddShelfStructMutation, AddShelfStructMutationVariables>(AddShelfStructDocument, options);
      }
export type AddShelfStructMutationHookResult = ReturnType<typeof useAddShelfStructMutation>;
export type AddShelfStructMutationResult = Apollo.MutationResult<AddShelfStructMutation>;
export type AddShelfStructMutationOptions = Apollo.BaseMutationOptions<AddShelfStructMutation, AddShelfStructMutationVariables>;
export const EditShelfDocument = gql`
    mutation editShelf($shelfId: Int!, $input: EditShelfInput!) {
  editShelf(shelfId: $shelfId, input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type EditShelfMutationFn = Apollo.MutationFunction<EditShelfMutation, EditShelfMutationVariables>;

/**
 * __useEditShelfMutation__
 *
 * To run a mutation, you first call `useEditShelfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditShelfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editShelfMutation, { data, loading, error }] = useEditShelfMutation({
 *   variables: {
 *      shelfId: // value for 'shelfId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditShelfMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditShelfMutation, EditShelfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditShelfMutation, EditShelfMutationVariables>(EditShelfDocument, options);
      }
export type EditShelfMutationHookResult = ReturnType<typeof useEditShelfMutation>;
export type EditShelfMutationResult = Apollo.MutationResult<EditShelfMutation>;
export type EditShelfMutationOptions = Apollo.BaseMutationOptions<EditShelfMutation, EditShelfMutationVariables>;
export const ShelfDocument = gql`
    query shelf($shelfId: Int!) {
  shelf(shelfId: $shelfId) {
    shelf {
      id
      key
      aisle {
        id
      }
      bay {
        id
      }
      root {
        id
      }
      positions {
        id
        name
        sequence
        product {
          id
          internalId
          name
          parentProduct {
            name
          }
        }
        device {
          id
          serialNumber
        }
        root {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useShelfQuery__
 *
 * To run a query within a React component, call `useShelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useShelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShelfQuery({
 *   variables: {
 *      shelfId: // value for 'shelfId'
 *   },
 * });
 */
export function useShelfQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ShelfQuery, ShelfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ShelfQuery, ShelfQueryVariables>(ShelfDocument, options);
      }
export function useShelfLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ShelfQuery, ShelfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ShelfQuery, ShelfQueryVariables>(ShelfDocument, options);
        }
export type ShelfQueryHookResult = ReturnType<typeof useShelfQuery>;
export type ShelfLazyQueryHookResult = ReturnType<typeof useShelfLazyQuery>;
export type ShelfQueryResult = Apollo.QueryResult<ShelfQuery, ShelfQueryVariables>;
export const ShelvesDocument = gql`
    query shelves($where: shelvesFilter, $offset: Int!, $limit: Int!) {
  shelves(where: $where, offset: $offset, limit: $limit) {
    nodes {
      id
      key
      bay {
        key
        aisle {
          key
        }
      }
    }
    totalCount
  }
}
    `;

/**
 * __useShelvesQuery__
 *
 * To run a query within a React component, call `useShelvesQuery` and pass it any options that fit your needs.
 * When your component renders, `useShelvesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShelvesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useShelvesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ShelvesQuery, ShelvesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ShelvesQuery, ShelvesQueryVariables>(ShelvesDocument, options);
      }
export function useShelvesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ShelvesQuery, ShelvesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ShelvesQuery, ShelvesQueryVariables>(ShelvesDocument, options);
        }
export type ShelvesQueryHookResult = ReturnType<typeof useShelvesQuery>;
export type ShelvesLazyQueryHookResult = ReturnType<typeof useShelvesLazyQuery>;
export type ShelvesQueryResult = Apollo.QueryResult<ShelvesQuery, ShelvesQueryVariables>;
export const ClearDeviceDocument = gql`
    mutation clearDevice($deviceId: Int!) {
  clearDevice(id: $deviceId) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type ClearDeviceMutationFn = Apollo.MutationFunction<ClearDeviceMutation, ClearDeviceMutationVariables>;

/**
 * __useClearDeviceMutation__
 *
 * To run a mutation, you first call `useClearDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearDeviceMutation, { data, loading, error }] = useClearDeviceMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useClearDeviceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClearDeviceMutation, ClearDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ClearDeviceMutation, ClearDeviceMutationVariables>(ClearDeviceDocument, options);
      }
export type ClearDeviceMutationHookResult = ReturnType<typeof useClearDeviceMutation>;
export type ClearDeviceMutationResult = Apollo.MutationResult<ClearDeviceMutation>;
export type ClearDeviceMutationOptions = Apollo.BaseMutationOptions<ClearDeviceMutation, ClearDeviceMutationVariables>;
export const DeleteDeviceDocument = gql`
    mutation deleteDevice($deviceId: Int!) {
  deleteDevice(id: $deviceId) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type DeleteDeviceMutationFn = Apollo.MutationFunction<DeleteDeviceMutation, DeleteDeviceMutationVariables>;

/**
 * __useDeleteDeviceMutation__
 *
 * To run a mutation, you first call `useDeleteDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeviceMutation, { data, loading, error }] = useDeleteDeviceMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useDeleteDeviceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDeviceMutation, DeleteDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteDeviceMutation, DeleteDeviceMutationVariables>(DeleteDeviceDocument, options);
      }
export type DeleteDeviceMutationHookResult = ReturnType<typeof useDeleteDeviceMutation>;
export type DeleteDeviceMutationResult = Apollo.MutationResult<DeleteDeviceMutation>;
export type DeleteDeviceMutationOptions = Apollo.BaseMutationOptions<DeleteDeviceMutation, DeleteDeviceMutationVariables>;
export const DeviceDocument = gql`
    query device($deviceSerialNumber: String!) {
  device(deviceSerialNumber: $deviceSerialNumber) {
    device {
      id
      serialNumber
      name
      size
      firmwareVersion
      deviceMode
      config {
        id
        sleepTime
        highPowerMode
      }
      root {
        id
        name
      }
      template {
        id
        name
        width
        templateVariant {
          id
          key
        }
      }
      position {
        id
        name
        key
        sequence
        shelf {
          id
          key
          bay {
            id
            key
            aisle {
              id
              key
            }
          }
        }
      }
      product {
        id
        name
        internalId
        parentProduct {
          name
        }
      }
      lastTransaction {
        stage
        ts
      }
      lastConfigTransaction {
        stage
        ts
      }
      shelf {
        id
        key
        bay {
          id
          key
          aisle {
            id
            key
          }
        }
      }
      lastSync
      batteryLevel
      batteryCharging
      batteryError
      active
      rssi
      shortPressAction
      longPressAction
      shortPressEnabled
      longPressEnabled
      deviceType
      rotated
    }
  }
}
    `;

/**
 * __useDeviceQuery__
 *
 * To run a query within a React component, call `useDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceQuery({
 *   variables: {
 *      deviceSerialNumber: // value for 'deviceSerialNumber'
 *   },
 * });
 */
export function useDeviceQuery(baseOptions: ApolloReactHooks.QueryHookOptions<DeviceQuery, DeviceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<DeviceQuery, DeviceQueryVariables>(DeviceDocument, options);
      }
export function useDeviceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DeviceQuery, DeviceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<DeviceQuery, DeviceQueryVariables>(DeviceDocument, options);
        }
export type DeviceQueryHookResult = ReturnType<typeof useDeviceQuery>;
export type DeviceLazyQueryHookResult = ReturnType<typeof useDeviceLazyQuery>;
export type DeviceQueryResult = Apollo.QueryResult<DeviceQuery, DeviceQueryVariables>;
export const AddDeviceConfigDocument = gql`
    mutation addDeviceConfig($input: DeviceConfigInput!) {
  addDeviceConfig(input: $input) {
    errors {
      path
      message
    }
    deviceConfig {
      id
    }
  }
}
    `;
export type AddDeviceConfigMutationFn = Apollo.MutationFunction<AddDeviceConfigMutation, AddDeviceConfigMutationVariables>;

/**
 * __useAddDeviceConfigMutation__
 *
 * To run a mutation, you first call `useAddDeviceConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDeviceConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDeviceConfigMutation, { data, loading, error }] = useAddDeviceConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDeviceConfigMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddDeviceConfigMutation, AddDeviceConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddDeviceConfigMutation, AddDeviceConfigMutationVariables>(AddDeviceConfigDocument, options);
      }
export type AddDeviceConfigMutationHookResult = ReturnType<typeof useAddDeviceConfigMutation>;
export type AddDeviceConfigMutationResult = Apollo.MutationResult<AddDeviceConfigMutation>;
export type AddDeviceConfigMutationOptions = Apollo.BaseMutationOptions<AddDeviceConfigMutation, AddDeviceConfigMutationVariables>;
export const EditDeviceConfigDocument = gql`
    mutation editDeviceConfig($input: EditDeviceConfigInput!) {
  editDeviceConfig(input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type EditDeviceConfigMutationFn = Apollo.MutationFunction<EditDeviceConfigMutation, EditDeviceConfigMutationVariables>;

/**
 * __useEditDeviceConfigMutation__
 *
 * To run a mutation, you first call `useEditDeviceConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDeviceConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDeviceConfigMutation, { data, loading, error }] = useEditDeviceConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditDeviceConfigMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditDeviceConfigMutation, EditDeviceConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditDeviceConfigMutation, EditDeviceConfigMutationVariables>(EditDeviceConfigDocument, options);
      }
export type EditDeviceConfigMutationHookResult = ReturnType<typeof useEditDeviceConfigMutation>;
export type EditDeviceConfigMutationResult = Apollo.MutationResult<EditDeviceConfigMutation>;
export type EditDeviceConfigMutationOptions = Apollo.BaseMutationOptions<EditDeviceConfigMutation, EditDeviceConfigMutationVariables>;
export const DevicePositionsDocument = gql`
    query devicePositions($where: PositionsFilter, $offset: Int!, $limit: Int!) {
  positions(where: $where, limit: $limit, offset: $offset) {
    nodes {
      id
      key
      device {
        id
      }
    }
  }
}
    `;

/**
 * __useDevicePositionsQuery__
 *
 * To run a query within a React component, call `useDevicePositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevicePositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevicePositionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useDevicePositionsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<DevicePositionsQuery, DevicePositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<DevicePositionsQuery, DevicePositionsQueryVariables>(DevicePositionsDocument, options);
      }
export function useDevicePositionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DevicePositionsQuery, DevicePositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<DevicePositionsQuery, DevicePositionsQueryVariables>(DevicePositionsDocument, options);
        }
export type DevicePositionsQueryHookResult = ReturnType<typeof useDevicePositionsQuery>;
export type DevicePositionsLazyQueryHookResult = ReturnType<typeof useDevicePositionsLazyQuery>;
export type DevicePositionsQueryResult = Apollo.QueryResult<DevicePositionsQuery, DevicePositionsQueryVariables>;
export const DevicePreviewDocument = gql`
    query devicePreview($deviceSerialNumber: String!) {
  devicePreview(deviceSerialNumber: $deviceSerialNumber) {
    current
    preview
  }
}
    `;

/**
 * __useDevicePreviewQuery__
 *
 * To run a query within a React component, call `useDevicePreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevicePreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevicePreviewQuery({
 *   variables: {
 *      deviceSerialNumber: // value for 'deviceSerialNumber'
 *   },
 * });
 */
export function useDevicePreviewQuery(baseOptions: ApolloReactHooks.QueryHookOptions<DevicePreviewQuery, DevicePreviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<DevicePreviewQuery, DevicePreviewQueryVariables>(DevicePreviewDocument, options);
      }
export function useDevicePreviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DevicePreviewQuery, DevicePreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<DevicePreviewQuery, DevicePreviewQueryVariables>(DevicePreviewDocument, options);
        }
export type DevicePreviewQueryHookResult = ReturnType<typeof useDevicePreviewQuery>;
export type DevicePreviewLazyQueryHookResult = ReturnType<typeof useDevicePreviewLazyQuery>;
export type DevicePreviewQueryResult = Apollo.QueryResult<DevicePreviewQuery, DevicePreviewQueryVariables>;
export const DeviceTransactionsDocument = gql`
    query DeviceTransactions($deviceId: Int!) {
  deviceTransactions(deviceId: $deviceId) {
    transactions {
      transactionid
      ts
      stage
      template {
        id
        templateVariant {
          id
          key
          isDefault
        }
      }
    }
  }
}
    `;

/**
 * __useDeviceTransactionsQuery__
 *
 * To run a query within a React component, call `useDeviceTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceTransactionsQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useDeviceTransactionsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<DeviceTransactionsQuery, DeviceTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<DeviceTransactionsQuery, DeviceTransactionsQueryVariables>(DeviceTransactionsDocument, options);
      }
export function useDeviceTransactionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DeviceTransactionsQuery, DeviceTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<DeviceTransactionsQuery, DeviceTransactionsQueryVariables>(DeviceTransactionsDocument, options);
        }
export type DeviceTransactionsQueryHookResult = ReturnType<typeof useDeviceTransactionsQuery>;
export type DeviceTransactionsLazyQueryHookResult = ReturnType<typeof useDeviceTransactionsLazyQuery>;
export type DeviceTransactionsQueryResult = Apollo.QueryResult<DeviceTransactionsQuery, DeviceTransactionsQueryVariables>;
export const DevicesDocument = gql`
    query devices($where: DevicesFilter, $offset: Int!, $limit: Int!) {
  devices(where: $where, offset: $offset, limit: $limit) {
    nodes {
      id
      serialNumber
      name
      size
      deviceType
      lastTransaction {
        ts
        stage
      }
      position {
        key
        sequence
        shelf {
          key
          bay {
            key
            aisle {
              key
            }
          }
        }
      }
      root {
        id
        name
      }
      product {
        name
        internalId
        parentProduct {
          name
        }
      }
      template {
        id
        name
        width
      }
      shelf {
        key
        bay {
          key
          aisle {
            key
          }
        }
      }
      lastSync
      batteryLevel
      batteryCharging
      batteryError
      active
      rssi
    }
    totalCount
  }
}
    `;

/**
 * __useDevicesQuery__
 *
 * To run a query within a React component, call `useDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevicesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useDevicesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<DevicesQuery, DevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<DevicesQuery, DevicesQueryVariables>(DevicesDocument, options);
      }
export function useDevicesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DevicesQuery, DevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<DevicesQuery, DevicesQueryVariables>(DevicesDocument, options);
        }
export type DevicesQueryHookResult = ReturnType<typeof useDevicesQuery>;
export type DevicesLazyQueryHookResult = ReturnType<typeof useDevicesLazyQuery>;
export type DevicesQueryResult = Apollo.QueryResult<DevicesQuery, DevicesQueryVariables>;
export const EditDeviceDocument = gql`
    mutation editDevice($deviceId: Int!, $input: EditDeviceInput!) {
  editDevice(deviceId: $deviceId, input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type EditDeviceMutationFn = Apollo.MutationFunction<EditDeviceMutation, EditDeviceMutationVariables>;

/**
 * __useEditDeviceMutation__
 *
 * To run a mutation, you first call `useEditDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDeviceMutation, { data, loading, error }] = useEditDeviceMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditDeviceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditDeviceMutation, EditDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditDeviceMutation, EditDeviceMutationVariables>(EditDeviceDocument, options);
      }
export type EditDeviceMutationHookResult = ReturnType<typeof useEditDeviceMutation>;
export type EditDeviceMutationResult = Apollo.MutationResult<EditDeviceMutation>;
export type EditDeviceMutationOptions = Apollo.BaseMutationOptions<EditDeviceMutation, EditDeviceMutationVariables>;
export const ForceResetDeviceDocument = gql`
    mutation forceResetDevice($deviceId: Int!) {
  forceResetDevice(id: $deviceId) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type ForceResetDeviceMutationFn = Apollo.MutationFunction<ForceResetDeviceMutation, ForceResetDeviceMutationVariables>;

/**
 * __useForceResetDeviceMutation__
 *
 * To run a mutation, you first call `useForceResetDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceResetDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceResetDeviceMutation, { data, loading, error }] = useForceResetDeviceMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useForceResetDeviceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForceResetDeviceMutation, ForceResetDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ForceResetDeviceMutation, ForceResetDeviceMutationVariables>(ForceResetDeviceDocument, options);
      }
export type ForceResetDeviceMutationHookResult = ReturnType<typeof useForceResetDeviceMutation>;
export type ForceResetDeviceMutationResult = Apollo.MutationResult<ForceResetDeviceMutation>;
export type ForceResetDeviceMutationOptions = Apollo.BaseMutationOptions<ForceResetDeviceMutation, ForceResetDeviceMutationVariables>;
export const GroupsDocument = gql`
    query groups($where: GroupsFilter, $offset: Int!, $limit: Int!) {
  groups(where: $where, offset: $offset, limit: $limit) {
    nodes {
      id
      name
      level
    }
  }
}
    `;

/**
 * __useGroupsQuery__
 *
 * To run a query within a React component, call `useGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGroupsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
      }
export function useGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
        }
export type GroupsQueryHookResult = ReturnType<typeof useGroupsQuery>;
export type GroupsLazyQueryHookResult = ReturnType<typeof useGroupsLazyQuery>;
export type GroupsQueryResult = Apollo.QueryResult<GroupsQuery, GroupsQueryVariables>;
export const TemplatesDocument = gql`
    query templates($where: TemplatesFilter, $offset: Int!, $limit: Int!) {
  templates(where: $where, offset: $offset, limit: $limit) {
    nodes {
      id
      name
      width
    }
  }
}
    `;

/**
 * __useTemplatesQuery__
 *
 * To run a query within a React component, call `useTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplatesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTemplatesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<TemplatesQuery, TemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<TemplatesQuery, TemplatesQueryVariables>(TemplatesDocument, options);
      }
export function useTemplatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TemplatesQuery, TemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<TemplatesQuery, TemplatesQueryVariables>(TemplatesDocument, options);
        }
export type TemplatesQueryHookResult = ReturnType<typeof useTemplatesQuery>;
export type TemplatesLazyQueryHookResult = ReturnType<typeof useTemplatesLazyQuery>;
export type TemplatesQueryResult = Apollo.QueryResult<TemplatesQuery, TemplatesQueryVariables>;
export const AddSmartSensorLabelDocument = gql`
    mutation addSmartSensorLabel($input: AddSensorInput!) {
  addSensor(input: $input) {
    errors {
      path
      message
    }
    sensor {
      id
    }
  }
}
    `;
export type AddSmartSensorLabelMutationFn = Apollo.MutationFunction<AddSmartSensorLabelMutation, AddSmartSensorLabelMutationVariables>;

/**
 * __useAddSmartSensorLabelMutation__
 *
 * To run a mutation, you first call `useAddSmartSensorLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSmartSensorLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSmartSensorLabelMutation, { data, loading, error }] = useAddSmartSensorLabelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSmartSensorLabelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddSmartSensorLabelMutation, AddSmartSensorLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddSmartSensorLabelMutation, AddSmartSensorLabelMutationVariables>(AddSmartSensorLabelDocument, options);
      }
export type AddSmartSensorLabelMutationHookResult = ReturnType<typeof useAddSmartSensorLabelMutation>;
export type AddSmartSensorLabelMutationResult = Apollo.MutationResult<AddSmartSensorLabelMutation>;
export type AddSmartSensorLabelMutationOptions = Apollo.BaseMutationOptions<AddSmartSensorLabelMutation, AddSmartSensorLabelMutationVariables>;
export const EditSmartSensorLabelDocument = gql`
    mutation editSmartSensorLabel($id: Int!, $input: EditSensorInput!) {
  editSensor(sensorId: $id, input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type EditSmartSensorLabelMutationFn = Apollo.MutationFunction<EditSmartSensorLabelMutation, EditSmartSensorLabelMutationVariables>;

/**
 * __useEditSmartSensorLabelMutation__
 *
 * To run a mutation, you first call `useEditSmartSensorLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSmartSensorLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSmartSensorLabelMutation, { data, loading, error }] = useEditSmartSensorLabelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditSmartSensorLabelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditSmartSensorLabelMutation, EditSmartSensorLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditSmartSensorLabelMutation, EditSmartSensorLabelMutationVariables>(EditSmartSensorLabelDocument, options);
      }
export type EditSmartSensorLabelMutationHookResult = ReturnType<typeof useEditSmartSensorLabelMutation>;
export type EditSmartSensorLabelMutationResult = Apollo.MutationResult<EditSmartSensorLabelMutation>;
export type EditSmartSensorLabelMutationOptions = Apollo.BaseMutationOptions<EditSmartSensorLabelMutation, EditSmartSensorLabelMutationVariables>;
export const SmartSensorLabelDocument = gql`
    query smartSensorLabel($serialNumber: String!) {
  sensor(serialNumber: $serialNumber) {
    sensor {
      id
      battery
      serialNumber
      name
      tags
      root {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useSmartSensorLabelQuery__
 *
 * To run a query within a React component, call `useSmartSensorLabelQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmartSensorLabelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmartSensorLabelQuery({
 *   variables: {
 *      serialNumber: // value for 'serialNumber'
 *   },
 * });
 */
export function useSmartSensorLabelQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SmartSensorLabelQuery, SmartSensorLabelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SmartSensorLabelQuery, SmartSensorLabelQueryVariables>(SmartSensorLabelDocument, options);
      }
export function useSmartSensorLabelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SmartSensorLabelQuery, SmartSensorLabelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SmartSensorLabelQuery, SmartSensorLabelQueryVariables>(SmartSensorLabelDocument, options);
        }
export type SmartSensorLabelQueryHookResult = ReturnType<typeof useSmartSensorLabelQuery>;
export type SmartSensorLabelLazyQueryHookResult = ReturnType<typeof useSmartSensorLabelLazyQuery>;
export type SmartSensorLabelQueryResult = Apollo.QueryResult<SmartSensorLabelQuery, SmartSensorLabelQueryVariables>;
export const SmartSensorLabelMeasurementsDocument = gql`
    query smartSensorLabelMeasurements($serialNumber: String!, $measurementType: MeasurementType!, $from: DateTime!, $to: DateTime) {
  sensor(serialNumber: $serialNumber) {
    sensor {
      measurement(type: $measurementType, from: $from, to: $to) {
        _time
        _value
      }
    }
  }
}
    `;

/**
 * __useSmartSensorLabelMeasurementsQuery__
 *
 * To run a query within a React component, call `useSmartSensorLabelMeasurementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmartSensorLabelMeasurementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmartSensorLabelMeasurementsQuery({
 *   variables: {
 *      serialNumber: // value for 'serialNumber'
 *      measurementType: // value for 'measurementType'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useSmartSensorLabelMeasurementsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SmartSensorLabelMeasurementsQuery, SmartSensorLabelMeasurementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SmartSensorLabelMeasurementsQuery, SmartSensorLabelMeasurementsQueryVariables>(SmartSensorLabelMeasurementsDocument, options);
      }
export function useSmartSensorLabelMeasurementsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SmartSensorLabelMeasurementsQuery, SmartSensorLabelMeasurementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SmartSensorLabelMeasurementsQuery, SmartSensorLabelMeasurementsQueryVariables>(SmartSensorLabelMeasurementsDocument, options);
        }
export type SmartSensorLabelMeasurementsQueryHookResult = ReturnType<typeof useSmartSensorLabelMeasurementsQuery>;
export type SmartSensorLabelMeasurementsLazyQueryHookResult = ReturnType<typeof useSmartSensorLabelMeasurementsLazyQuery>;
export type SmartSensorLabelMeasurementsQueryResult = Apollo.QueryResult<SmartSensorLabelMeasurementsQuery, SmartSensorLabelMeasurementsQueryVariables>;
export const SmartSensorLabelsDocument = gql`
    query smartSensorLabels($where: SensorsFilter, $limit: Int!, $offset: Int!) {
  sensors(where: $where, offset: $offset, limit: $limit) {
    nodes {
      id
      battery
      serialNumber
      name
      tags
      active
      root {
        name
      }
    }
  }
}
    `;

/**
 * __useSmartSensorLabelsQuery__
 *
 * To run a query within a React component, call `useSmartSensorLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmartSensorLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmartSensorLabelsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useSmartSensorLabelsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SmartSensorLabelsQuery, SmartSensorLabelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SmartSensorLabelsQuery, SmartSensorLabelsQueryVariables>(SmartSensorLabelsDocument, options);
      }
export function useSmartSensorLabelsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SmartSensorLabelsQuery, SmartSensorLabelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SmartSensorLabelsQuery, SmartSensorLabelsQueryVariables>(SmartSensorLabelsDocument, options);
        }
export type SmartSensorLabelsQueryHookResult = ReturnType<typeof useSmartSensorLabelsQuery>;
export type SmartSensorLabelsLazyQueryHookResult = ReturnType<typeof useSmartSensorLabelsLazyQuery>;
export type SmartSensorLabelsQueryResult = Apollo.QueryResult<SmartSensorLabelsQuery, SmartSensorLabelsQueryVariables>;
export const EditSmartShelfBarDocument = gql`
    mutation editSmartShelfBar($barId: Int!, $input: EditBarInput!) {
  editBar(barId: $barId, input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type EditSmartShelfBarMutationFn = Apollo.MutationFunction<EditSmartShelfBarMutation, EditSmartShelfBarMutationVariables>;

/**
 * __useEditSmartShelfBarMutation__
 *
 * To run a mutation, you first call `useEditSmartShelfBarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSmartShelfBarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSmartShelfBarMutation, { data, loading, error }] = useEditSmartShelfBarMutation({
 *   variables: {
 *      barId: // value for 'barId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditSmartShelfBarMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditSmartShelfBarMutation, EditSmartShelfBarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditSmartShelfBarMutation, EditSmartShelfBarMutationVariables>(EditSmartShelfBarDocument, options);
      }
export type EditSmartShelfBarMutationHookResult = ReturnType<typeof useEditSmartShelfBarMutation>;
export type EditSmartShelfBarMutationResult = Apollo.MutationResult<EditSmartShelfBarMutation>;
export type EditSmartShelfBarMutationOptions = Apollo.BaseMutationOptions<EditSmartShelfBarMutation, EditSmartShelfBarMutationVariables>;
export const SmartShelfBarDocument = gql`
    query smartShelfBar($barId: Int) {
  bar(barId: $barId) {
    bar {
      id
      macAddress
      name
      size
      x
      backgroundFile {
        id
        filename
        mimetype
      }
      group {
        id
        name
      }
      devices {
        id
        serialNumber
        x
        y
        z
        product {
          id
          name
          internalId
        }
        template {
          width
          templateVariant {
            id
          }
        }
        position {
          x
          sequence
          product {
            id
          }
          templateVariant {
            id
          }
        }
      }
      shelf {
        id
      }
      root {
        id
      }
    }
  }
}
    `;

/**
 * __useSmartShelfBarQuery__
 *
 * To run a query within a React component, call `useSmartShelfBarQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmartShelfBarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmartShelfBarQuery({
 *   variables: {
 *      barId: // value for 'barId'
 *   },
 * });
 */
export function useSmartShelfBarQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SmartShelfBarQuery, SmartShelfBarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SmartShelfBarQuery, SmartShelfBarQueryVariables>(SmartShelfBarDocument, options);
      }
export function useSmartShelfBarLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SmartShelfBarQuery, SmartShelfBarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SmartShelfBarQuery, SmartShelfBarQueryVariables>(SmartShelfBarDocument, options);
        }
export type SmartShelfBarQueryHookResult = ReturnType<typeof useSmartShelfBarQuery>;
export type SmartShelfBarLazyQueryHookResult = ReturnType<typeof useSmartShelfBarLazyQuery>;
export type SmartShelfBarQueryResult = Apollo.QueryResult<SmartShelfBarQuery, SmartShelfBarQueryVariables>;
export const SmartShelfBarsDocument = gql`
    query smartShelfBars($where: BarsFilter, $limit: Int!, $offset: Int!) {
  bars(where: $where, limit: $limit, offset: $offset) {
    nodes {
      id
      macAddress
      name
      size
      root {
        name
      }
      shelf {
        key
        bay {
          key
          aisle {
            key
          }
        }
      }
    }
  }
}
    `;

/**
 * __useSmartShelfBarsQuery__
 *
 * To run a query within a React component, call `useSmartShelfBarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmartShelfBarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmartShelfBarsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useSmartShelfBarsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SmartShelfBarsQuery, SmartShelfBarsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SmartShelfBarsQuery, SmartShelfBarsQueryVariables>(SmartShelfBarsDocument, options);
      }
export function useSmartShelfBarsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SmartShelfBarsQuery, SmartShelfBarsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SmartShelfBarsQuery, SmartShelfBarsQueryVariables>(SmartShelfBarsDocument, options);
        }
export type SmartShelfBarsQueryHookResult = ReturnType<typeof useSmartShelfBarsQuery>;
export type SmartShelfBarsLazyQueryHookResult = ReturnType<typeof useSmartShelfBarsLazyQuery>;
export type SmartShelfBarsQueryResult = Apollo.QueryResult<SmartShelfBarsQuery, SmartShelfBarsQueryVariables>;
export const EditScaleDocument = gql`
    mutation editScale($scaleId: Int!, $input: EditScaleInput!) {
  editScale(scaleId: $scaleId, input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type EditScaleMutationFn = Apollo.MutationFunction<EditScaleMutation, EditScaleMutationVariables>;

/**
 * __useEditScaleMutation__
 *
 * To run a mutation, you first call `useEditScaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditScaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editScaleMutation, { data, loading, error }] = useEditScaleMutation({
 *   variables: {
 *      scaleId: // value for 'scaleId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditScaleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditScaleMutation, EditScaleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditScaleMutation, EditScaleMutationVariables>(EditScaleDocument, options);
      }
export type EditScaleMutationHookResult = ReturnType<typeof useEditScaleMutation>;
export type EditScaleMutationResult = Apollo.MutationResult<EditScaleMutation>;
export type EditScaleMutationOptions = Apollo.BaseMutationOptions<EditScaleMutation, EditScaleMutationVariables>;
export const EditTvDeviceDocument = gql`
    mutation editTvDevice($tvDeviceId: Int!, $input: EditTvDeviceInput!) {
  editTvDevice(tvDeviceId: $tvDeviceId, input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type EditTvDeviceMutationFn = Apollo.MutationFunction<EditTvDeviceMutation, EditTvDeviceMutationVariables>;

/**
 * __useEditTvDeviceMutation__
 *
 * To run a mutation, you first call `useEditTvDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTvDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTvDeviceMutation, { data, loading, error }] = useEditTvDeviceMutation({
 *   variables: {
 *      tvDeviceId: // value for 'tvDeviceId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTvDeviceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditTvDeviceMutation, EditTvDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditTvDeviceMutation, EditTvDeviceMutationVariables>(EditTvDeviceDocument, options);
      }
export type EditTvDeviceMutationHookResult = ReturnType<typeof useEditTvDeviceMutation>;
export type EditTvDeviceMutationResult = Apollo.MutationResult<EditTvDeviceMutation>;
export type EditTvDeviceMutationOptions = Apollo.BaseMutationOptions<EditTvDeviceMutation, EditTvDeviceMutationVariables>;
export const RegisterScaleDocument = gql`
    mutation registerScale($input: ScaleRegisterInput!) {
  registerScale(input: $input) {
    errors {
      path
      message
    }
    scale {
      id
    }
  }
}
    `;
export type RegisterScaleMutationFn = Apollo.MutationFunction<RegisterScaleMutation, RegisterScaleMutationVariables>;

/**
 * __useRegisterScaleMutation__
 *
 * To run a mutation, you first call `useRegisterScaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterScaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerScaleMutation, { data, loading, error }] = useRegisterScaleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterScaleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterScaleMutation, RegisterScaleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RegisterScaleMutation, RegisterScaleMutationVariables>(RegisterScaleDocument, options);
      }
export type RegisterScaleMutationHookResult = ReturnType<typeof useRegisterScaleMutation>;
export type RegisterScaleMutationResult = Apollo.MutationResult<RegisterScaleMutation>;
export type RegisterScaleMutationOptions = Apollo.BaseMutationOptions<RegisterScaleMutation, RegisterScaleMutationVariables>;
export const ScaleDocument = gql`
    query scale($scaleId: Int!) {
  scale(scaleId: $scaleId) {
    scale {
      id
      name
      username
      ip
      tags
      root {
        id
      }
      channel {
        id
      }
      type
    }
  }
}
    `;

/**
 * __useScaleQuery__
 *
 * To run a query within a React component, call `useScaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useScaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScaleQuery({
 *   variables: {
 *      scaleId: // value for 'scaleId'
 *   },
 * });
 */
export function useScaleQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ScaleQuery, ScaleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ScaleQuery, ScaleQueryVariables>(ScaleDocument, options);
      }
export function useScaleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ScaleQuery, ScaleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ScaleQuery, ScaleQueryVariables>(ScaleDocument, options);
        }
export type ScaleQueryHookResult = ReturnType<typeof useScaleQuery>;
export type ScaleLazyQueryHookResult = ReturnType<typeof useScaleLazyQuery>;
export type ScaleQueryResult = Apollo.QueryResult<ScaleQuery, ScaleQueryVariables>;
export const ScalesDocument = gql`
    query scales($offset: Int!, $limit: Int!, $where: ScalesFilter) {
  scales(offset: $offset, limit: $limit, where: $where) {
    nodes {
      id
      name
      ip
      tags
      root {
        name
      }
      channel {
        id
        name
      }
      type
    }
    totalCount
  }
}
    `;

/**
 * __useScalesQuery__
 *
 * To run a query within a React component, call `useScalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useScalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScalesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useScalesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ScalesQuery, ScalesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ScalesQuery, ScalesQueryVariables>(ScalesDocument, options);
      }
export function useScalesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ScalesQuery, ScalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ScalesQuery, ScalesQueryVariables>(ScalesDocument, options);
        }
export type ScalesQueryHookResult = ReturnType<typeof useScalesQuery>;
export type ScalesLazyQueryHookResult = ReturnType<typeof useScalesLazyQuery>;
export type ScalesQueryResult = Apollo.QueryResult<ScalesQuery, ScalesQueryVariables>;
export const TvDevicesDocument = gql`
    query tvDevices($offset: Int!, $limit: Int!, $where: TVDevicesFilter) {
  tvDevices(offset: $offset, limit: $limit, where: $where) {
    nodes {
      id
      token
      uuid
      name
      width
      height
      tags
      root {
        name
      }
      channel {
        id
        name
      }
    }
    totalCount
  }
}
    `;

/**
 * __useTvDevicesQuery__
 *
 * To run a query within a React component, call `useTvDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTvDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTvDevicesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useTvDevicesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<TvDevicesQuery, TvDevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<TvDevicesQuery, TvDevicesQueryVariables>(TvDevicesDocument, options);
      }
export function useTvDevicesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TvDevicesQuery, TvDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<TvDevicesQuery, TvDevicesQueryVariables>(TvDevicesDocument, options);
        }
export type TvDevicesQueryHookResult = ReturnType<typeof useTvDevicesQuery>;
export type TvDevicesLazyQueryHookResult = ReturnType<typeof useTvDevicesLazyQuery>;
export type TvDevicesQueryResult = Apollo.QueryResult<TvDevicesQuery, TvDevicesQueryVariables>;
export const AddUserDocument = gql`
    mutation addUser($input: AddUserInput!) {
  addUser(input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type AddUserMutationFn = Apollo.MutationFunction<AddUserMutation, AddUserMutationVariables>;

/**
 * __useAddUserMutation__
 *
 * To run a mutation, you first call `useAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMutation, { data, loading, error }] = useAddUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddUserMutation, AddUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddUserMutation, AddUserMutationVariables>(AddUserDocument, options);
      }
export type AddUserMutationHookResult = ReturnType<typeof useAddUserMutation>;
export type AddUserMutationResult = Apollo.MutationResult<AddUserMutation>;
export type AddUserMutationOptions = Apollo.BaseMutationOptions<AddUserMutation, AddUserMutationVariables>;
export const AdminDisable2FaDocument = gql`
    mutation adminDisable2FA($userId: Int!) {
  adminDisable2FA(userId: $userId) {
    success
    errors {
      path
      message
    }
  }
}
    `;
export type AdminDisable2FaMutationFn = Apollo.MutationFunction<AdminDisable2FaMutation, AdminDisable2FaMutationVariables>;

/**
 * __useAdminDisable2FaMutation__
 *
 * To run a mutation, you first call `useAdminDisable2FaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDisable2FaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDisable2FaMutation, { data, loading, error }] = useAdminDisable2FaMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminDisable2FaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdminDisable2FaMutation, AdminDisable2FaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AdminDisable2FaMutation, AdminDisable2FaMutationVariables>(AdminDisable2FaDocument, options);
      }
export type AdminDisable2FaMutationHookResult = ReturnType<typeof useAdminDisable2FaMutation>;
export type AdminDisable2FaMutationResult = Apollo.MutationResult<AdminDisable2FaMutation>;
export type AdminDisable2FaMutationOptions = Apollo.BaseMutationOptions<AdminDisable2FaMutation, AdminDisable2FaMutationVariables>;
export const CustomerInfoDocument = gql`
    query customerInfo {
  customerInfo {
    purchasedModules
  }
}
    `;

/**
 * __useCustomerInfoQuery__
 *
 * To run a query within a React component, call `useCustomerInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CustomerInfoQuery, CustomerInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CustomerInfoQuery, CustomerInfoQueryVariables>(CustomerInfoDocument, options);
      }
export function useCustomerInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomerInfoQuery, CustomerInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CustomerInfoQuery, CustomerInfoQueryVariables>(CustomerInfoDocument, options);
        }
export type CustomerInfoQueryHookResult = ReturnType<typeof useCustomerInfoQuery>;
export type CustomerInfoLazyQueryHookResult = ReturnType<typeof useCustomerInfoLazyQuery>;
export type CustomerInfoQueryResult = Apollo.QueryResult<CustomerInfoQuery, CustomerInfoQueryVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($id: Int!) {
  deleteUser(id: $id) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const Disable2FaDocument = gql`
    mutation disable2FA($code: String!) {
  disable2FA(code: $code) {
    success
    delta
    errors {
      path
      message
    }
  }
}
    `;
export type Disable2FaMutationFn = Apollo.MutationFunction<Disable2FaMutation, Disable2FaMutationVariables>;

/**
 * __useDisable2FaMutation__
 *
 * To run a mutation, you first call `useDisable2FaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisable2FaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disable2FaMutation, { data, loading, error }] = useDisable2FaMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useDisable2FaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Disable2FaMutation, Disable2FaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Disable2FaMutation, Disable2FaMutationVariables>(Disable2FaDocument, options);
      }
export type Disable2FaMutationHookResult = ReturnType<typeof useDisable2FaMutation>;
export type Disable2FaMutationResult = Apollo.MutationResult<Disable2FaMutation>;
export type Disable2FaMutationOptions = Apollo.BaseMutationOptions<Disable2FaMutation, Disable2FaMutationVariables>;
export const EditUserDocument = gql`
    mutation editUser($userId: Int!, $input: EditUserInput!) {
  editUser(userId: $userId, input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type EditUserMutationFn = Apollo.MutationFunction<EditUserMutation, EditUserMutationVariables>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditUserMutation, EditUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditUserMutation, EditUserMutationVariables>(EditUserDocument, options);
      }
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = Apollo.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = Apollo.BaseMutationOptions<EditUserMutation, EditUserMutationVariables>;
export const Enable2FaDocument = gql`
    mutation enable2FA($code: String!) {
  enable2FA(code: $code) {
    success
    delta
    errors {
      path
      message
    }
  }
}
    `;
export type Enable2FaMutationFn = Apollo.MutationFunction<Enable2FaMutation, Enable2FaMutationVariables>;

/**
 * __useEnable2FaMutation__
 *
 * To run a mutation, you first call `useEnable2FaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnable2FaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enable2FaMutation, { data, loading, error }] = useEnable2FaMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useEnable2FaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Enable2FaMutation, Enable2FaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Enable2FaMutation, Enable2FaMutationVariables>(Enable2FaDocument, options);
      }
export type Enable2FaMutationHookResult = ReturnType<typeof useEnable2FaMutation>;
export type Enable2FaMutationResult = Apollo.MutationResult<Enable2FaMutation>;
export type Enable2FaMutationOptions = Apollo.BaseMutationOptions<Enable2FaMutation, Enable2FaMutationVariables>;
export const Generate2FaSecretDocument = gql`
    mutation generate2FASecret {
  generate2FASecret {
    secret
    uri
  }
}
    `;
export type Generate2FaSecretMutationFn = Apollo.MutationFunction<Generate2FaSecretMutation, Generate2FaSecretMutationVariables>;

/**
 * __useGenerate2FaSecretMutation__
 *
 * To run a mutation, you first call `useGenerate2FaSecretMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerate2FaSecretMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generate2FaSecretMutation, { data, loading, error }] = useGenerate2FaSecretMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerate2FaSecretMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Generate2FaSecretMutation, Generate2FaSecretMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Generate2FaSecretMutation, Generate2FaSecretMutationVariables>(Generate2FaSecretDocument, options);
      }
export type Generate2FaSecretMutationHookResult = ReturnType<typeof useGenerate2FaSecretMutation>;
export type Generate2FaSecretMutationResult = Apollo.MutationResult<Generate2FaSecretMutation>;
export type Generate2FaSecretMutationOptions = Apollo.BaseMutationOptions<Generate2FaSecretMutation, Generate2FaSecretMutationVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    email
    fullName
    allowedIPs
    roles {
      id
      value
    }
    rootIDs
    permissions
    ledConfig {
      blinkSpeed
      blinkTime
      color
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UserDocument = gql`
    query user($userId: Int!) {
  user(userId: $userId) {
    user {
      id
      email
      firstName
      lastName
      username
      roles {
        value
        id
        rank
        permissions
      }
      rootIDs
      locationRestrictions {
        name
      }
      isTwoFactorEnabled
    }
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
    query users($where: UsersFilter, $offset: Int!, $limit: Int!) {
  users(where: $where, offset: $offset, limit: $limit) {
    nodes {
      id
      username
      email
      firstName
      lastName
      roles {
        value
      }
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useUsersQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const UsersInfoDocument = gql`
    query usersInfo {
  usersInfo {
    numOfUsers
    maxUsers
  }
}
    `;

/**
 * __useUsersInfoQuery__
 *
 * To run a query within a React component, call `useUsersInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersInfoQuery, UsersInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UsersInfoQuery, UsersInfoQueryVariables>(UsersInfoDocument, options);
      }
export function useUsersInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersInfoQuery, UsersInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UsersInfoQuery, UsersInfoQueryVariables>(UsersInfoDocument, options);
        }
export type UsersInfoQueryHookResult = ReturnType<typeof useUsersInfoQuery>;
export type UsersInfoLazyQueryHookResult = ReturnType<typeof useUsersInfoLazyQuery>;
export type UsersInfoQueryResult = Apollo.QueryResult<UsersInfoQuery, UsersInfoQueryVariables>;
export const UniqueValueEntityColumnsDocument = gql`
    query uniqueValueEntityColumns($templateKey: String!) {
  uniqueValueEntityColumns(templateKey: $templateKey) {
    nodes {
      name
    }
  }
}
    `;

/**
 * __useUniqueValueEntityColumnsQuery__
 *
 * To run a query within a React component, call `useUniqueValueEntityColumnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUniqueValueEntityColumnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUniqueValueEntityColumnsQuery({
 *   variables: {
 *      templateKey: // value for 'templateKey'
 *   },
 * });
 */
export function useUniqueValueEntityColumnsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UniqueValueEntityColumnsQuery, UniqueValueEntityColumnsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UniqueValueEntityColumnsQuery, UniqueValueEntityColumnsQueryVariables>(UniqueValueEntityColumnsDocument, options);
      }
export function useUniqueValueEntityColumnsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UniqueValueEntityColumnsQuery, UniqueValueEntityColumnsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UniqueValueEntityColumnsQuery, UniqueValueEntityColumnsQueryVariables>(UniqueValueEntityColumnsDocument, options);
        }
export type UniqueValueEntityColumnsQueryHookResult = ReturnType<typeof useUniqueValueEntityColumnsQuery>;
export type UniqueValueEntityColumnsLazyQueryHookResult = ReturnType<typeof useUniqueValueEntityColumnsLazyQuery>;
export type UniqueValueEntityColumnsQueryResult = Apollo.QueryResult<UniqueValueEntityColumnsQuery, UniqueValueEntityColumnsQueryVariables>;
export const AddVirtualMultiScreenDocument = gql`
    mutation addVirtualMultiScreen($input: AddVirtualMultiScreenInput!) {
  addVirtualMultiScreen(input: $input) {
    errors {
      path
      message
    }
    virtualMultiScreen {
      id
    }
  }
}
    `;
export type AddVirtualMultiScreenMutationFn = Apollo.MutationFunction<AddVirtualMultiScreenMutation, AddVirtualMultiScreenMutationVariables>;

/**
 * __useAddVirtualMultiScreenMutation__
 *
 * To run a mutation, you first call `useAddVirtualMultiScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVirtualMultiScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVirtualMultiScreenMutation, { data, loading, error }] = useAddVirtualMultiScreenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddVirtualMultiScreenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddVirtualMultiScreenMutation, AddVirtualMultiScreenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddVirtualMultiScreenMutation, AddVirtualMultiScreenMutationVariables>(AddVirtualMultiScreenDocument, options);
      }
export type AddVirtualMultiScreenMutationHookResult = ReturnType<typeof useAddVirtualMultiScreenMutation>;
export type AddVirtualMultiScreenMutationResult = Apollo.MutationResult<AddVirtualMultiScreenMutation>;
export type AddVirtualMultiScreenMutationOptions = Apollo.BaseMutationOptions<AddVirtualMultiScreenMutation, AddVirtualMultiScreenMutationVariables>;
export const DeleteVirtualMultiScreenDocument = gql`
    mutation deleteVirtualMultiScreen($id: Int!) {
  deleteVirtualMultiScreen(id: $id) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type DeleteVirtualMultiScreenMutationFn = Apollo.MutationFunction<DeleteVirtualMultiScreenMutation, DeleteVirtualMultiScreenMutationVariables>;

/**
 * __useDeleteVirtualMultiScreenMutation__
 *
 * To run a mutation, you first call `useDeleteVirtualMultiScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVirtualMultiScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVirtualMultiScreenMutation, { data, loading, error }] = useDeleteVirtualMultiScreenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVirtualMultiScreenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteVirtualMultiScreenMutation, DeleteVirtualMultiScreenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteVirtualMultiScreenMutation, DeleteVirtualMultiScreenMutationVariables>(DeleteVirtualMultiScreenDocument, options);
      }
export type DeleteVirtualMultiScreenMutationHookResult = ReturnType<typeof useDeleteVirtualMultiScreenMutation>;
export type DeleteVirtualMultiScreenMutationResult = Apollo.MutationResult<DeleteVirtualMultiScreenMutation>;
export type DeleteVirtualMultiScreenMutationOptions = Apollo.BaseMutationOptions<DeleteVirtualMultiScreenMutation, DeleteVirtualMultiScreenMutationVariables>;
export const EditVirtualMultiScreenDocument = gql`
    mutation editVirtualMultiScreen($input: EditVirtualMultiScreenInput!) {
  editVirtualMultiScreen(input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type EditVirtualMultiScreenMutationFn = Apollo.MutationFunction<EditVirtualMultiScreenMutation, EditVirtualMultiScreenMutationVariables>;

/**
 * __useEditVirtualMultiScreenMutation__
 *
 * To run a mutation, you first call `useEditVirtualMultiScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVirtualMultiScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVirtualMultiScreenMutation, { data, loading, error }] = useEditVirtualMultiScreenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditVirtualMultiScreenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditVirtualMultiScreenMutation, EditVirtualMultiScreenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditVirtualMultiScreenMutation, EditVirtualMultiScreenMutationVariables>(EditVirtualMultiScreenDocument, options);
      }
export type EditVirtualMultiScreenMutationHookResult = ReturnType<typeof useEditVirtualMultiScreenMutation>;
export type EditVirtualMultiScreenMutationResult = Apollo.MutationResult<EditVirtualMultiScreenMutation>;
export type EditVirtualMultiScreenMutationOptions = Apollo.BaseMutationOptions<EditVirtualMultiScreenMutation, EditVirtualMultiScreenMutationVariables>;
export const VirtualMultiScreenDocument = gql`
    query virtualMultiScreen($virtualMultiScreenId: Int!) {
  virtualMultiScreen(virtualMultiScreenId: $virtualMultiScreenId) {
    virtualMultiScreen {
      id
      name
      virtualMultiScreenPositions {
        id
        sequence
        channel {
          id
          name
        }
        virtualCategoryPoint {
          id
          name
        }
        virtualRack {
          id
          name
          virtualShelfPositions {
            id
            virtualShelf {
              id
              spacing
              virtualProductPositions {
                id
                zoom
                rotation
                itemCount
                product {
                  id
                  name
                  productImages {
                    id
                    file {
                      id
                      thumbnail
                      filename
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useVirtualMultiScreenQuery__
 *
 * To run a query within a React component, call `useVirtualMultiScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useVirtualMultiScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVirtualMultiScreenQuery({
 *   variables: {
 *      virtualMultiScreenId: // value for 'virtualMultiScreenId'
 *   },
 * });
 */
export function useVirtualMultiScreenQuery(baseOptions: ApolloReactHooks.QueryHookOptions<VirtualMultiScreenQuery, VirtualMultiScreenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<VirtualMultiScreenQuery, VirtualMultiScreenQueryVariables>(VirtualMultiScreenDocument, options);
      }
export function useVirtualMultiScreenLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VirtualMultiScreenQuery, VirtualMultiScreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<VirtualMultiScreenQuery, VirtualMultiScreenQueryVariables>(VirtualMultiScreenDocument, options);
        }
export type VirtualMultiScreenQueryHookResult = ReturnType<typeof useVirtualMultiScreenQuery>;
export type VirtualMultiScreenLazyQueryHookResult = ReturnType<typeof useVirtualMultiScreenLazyQuery>;
export type VirtualMultiScreenQueryResult = Apollo.QueryResult<VirtualMultiScreenQuery, VirtualMultiScreenQueryVariables>;
export const VirtualMultiScreensDocument = gql`
    query virtualMultiScreens($offset: Int!, $limit: Int!, $where: VirtualMultiScreensFilter) {
  virtualMultiScreens(offset: $offset, limit: $limit, where: $where) {
    nodes {
      id
      name
    }
    totalCount
  }
}
    `;

/**
 * __useVirtualMultiScreensQuery__
 *
 * To run a query within a React component, call `useVirtualMultiScreensQuery` and pass it any options that fit your needs.
 * When your component renders, `useVirtualMultiScreensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVirtualMultiScreensQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useVirtualMultiScreensQuery(baseOptions: ApolloReactHooks.QueryHookOptions<VirtualMultiScreensQuery, VirtualMultiScreensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<VirtualMultiScreensQuery, VirtualMultiScreensQueryVariables>(VirtualMultiScreensDocument, options);
      }
export function useVirtualMultiScreensLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VirtualMultiScreensQuery, VirtualMultiScreensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<VirtualMultiScreensQuery, VirtualMultiScreensQueryVariables>(VirtualMultiScreensDocument, options);
        }
export type VirtualMultiScreensQueryHookResult = ReturnType<typeof useVirtualMultiScreensQuery>;
export type VirtualMultiScreensLazyQueryHookResult = ReturnType<typeof useVirtualMultiScreensLazyQuery>;
export type VirtualMultiScreensQueryResult = Apollo.QueryResult<VirtualMultiScreensQuery, VirtualMultiScreensQueryVariables>;
export const EditOrderDocument = gql`
    mutation editOrder($input: EditOrderInput!) {
  editOrder(input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type EditOrderMutationFn = Apollo.MutationFunction<EditOrderMutation, EditOrderMutationVariables>;

/**
 * __useEditOrderMutation__
 *
 * To run a mutation, you first call `useEditOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrderMutation, { data, loading, error }] = useEditOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditOrderMutation, EditOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditOrderMutation, EditOrderMutationVariables>(EditOrderDocument, options);
      }
export type EditOrderMutationHookResult = ReturnType<typeof useEditOrderMutation>;
export type EditOrderMutationResult = Apollo.MutationResult<EditOrderMutation>;
export type EditOrderMutationOptions = Apollo.BaseMutationOptions<EditOrderMutation, EditOrderMutationVariables>;
export const EditOrderItemDocument = gql`
    mutation editOrderItem($id: Int!, $input: EditOrderItemInput!) {
  editOrderItem(id: $id, input: $input) {
    errors {
      path
      message
    }
    success
    archivedOrder
  }
}
    `;
export type EditOrderItemMutationFn = Apollo.MutationFunction<EditOrderItemMutation, EditOrderItemMutationVariables>;

/**
 * __useEditOrderItemMutation__
 *
 * To run a mutation, you first call `useEditOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrderItemMutation, { data, loading, error }] = useEditOrderItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditOrderItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditOrderItemMutation, EditOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditOrderItemMutation, EditOrderItemMutationVariables>(EditOrderItemDocument, options);
      }
export type EditOrderItemMutationHookResult = ReturnType<typeof useEditOrderItemMutation>;
export type EditOrderItemMutationResult = Apollo.MutationResult<EditOrderItemMutation>;
export type EditOrderItemMutationOptions = Apollo.BaseMutationOptions<EditOrderItemMutation, EditOrderItemMutationVariables>;
export const OrderDocument = gql`
    query order($id: Int!) {
  order(id: $id) {
    order {
      id
      created
      orderNumber
      mode
      costumerId
      rootId
      supplier {
        id
        companyName
      }
      orderItems {
        id
        quantity
        status
        deliveryDate
        productName
        internalId
        partnerId
        product {
          id
          parentProduct {
            name
          }
          internalId
          eanCodes
          valueEntities {
            id
            name
            templateKey
            type
            value
          }
        }
      }
    }
    errors {
      path
      message
    }
  }
}
    `;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderQuery(baseOptions: ApolloReactHooks.QueryHookOptions<OrderQuery, OrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
      }
export function useOrderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
        }
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderQueryResult = Apollo.QueryResult<OrderQuery, OrderQueryVariables>;
export const OrderItemLogsDocument = gql`
    query orderItemLogs($offset: Int!, $limit: Int!, $where: OrderItemLogsFilter) {
  orderItemLogs(offset: $offset, limit: $limit, where: $where) {
    nodes {
      id
      time
      type
      user {
        firstName
        lastName
      }
      quantity
      status
      deliveryDate
    }
    totalCount
  }
}
    `;

/**
 * __useOrderItemLogsQuery__
 *
 * To run a query within a React component, call `useOrderItemLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderItemLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderItemLogsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOrderItemLogsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<OrderItemLogsQuery, OrderItemLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<OrderItemLogsQuery, OrderItemLogsQueryVariables>(OrderItemLogsDocument, options);
      }
export function useOrderItemLogsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrderItemLogsQuery, OrderItemLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<OrderItemLogsQuery, OrderItemLogsQueryVariables>(OrderItemLogsDocument, options);
        }
export type OrderItemLogsQueryHookResult = ReturnType<typeof useOrderItemLogsQuery>;
export type OrderItemLogsLazyQueryHookResult = ReturnType<typeof useOrderItemLogsLazyQuery>;
export type OrderItemLogsQueryResult = Apollo.QueryResult<OrderItemLogsQuery, OrderItemLogsQueryVariables>;
export const OrdersDocument = gql`
    query orders($offset: Int!, $limit: Int!, $where: OrderFilter) {
  orders(offset: $offset, limit: $limit, where: $where) {
    nodes {
      id
      created
      orderNumber
      mode
      costumerId
      rootId
      orderItems {
        id
        quantity
        status
        deliveryDate
        product {
          id
          name
          internalId
        }
      }
      supplier {
        id
        companyName
        supplierId
      }
    }
    totalCount
  }
}
    `;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOrdersQuery(baseOptions: ApolloReactHooks.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
      }
export function useOrdersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = Apollo.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const DeleteProductImageDocument = gql`
    mutation deleteProductImage($productImageId: Int!) {
  deleteProductImage(productImageId: $productImageId) {
    success
    errors {
      path
      message
    }
  }
}
    `;
export type DeleteProductImageMutationFn = Apollo.MutationFunction<DeleteProductImageMutation, DeleteProductImageMutationVariables>;

/**
 * __useDeleteProductImageMutation__
 *
 * To run a mutation, you first call `useDeleteProductImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductImageMutation, { data, loading, error }] = useDeleteProductImageMutation({
 *   variables: {
 *      productImageId: // value for 'productImageId'
 *   },
 * });
 */
export function useDeleteProductImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProductImageMutation, DeleteProductImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteProductImageMutation, DeleteProductImageMutationVariables>(DeleteProductImageDocument, options);
      }
export type DeleteProductImageMutationHookResult = ReturnType<typeof useDeleteProductImageMutation>;
export type DeleteProductImageMutationResult = Apollo.MutationResult<DeleteProductImageMutation>;
export type DeleteProductImageMutationOptions = Apollo.BaseMutationOptions<DeleteProductImageMutation, DeleteProductImageMutationVariables>;
export const ProductEditDetailsDocument = gql`
    query productEditDetails($internalId: String, $id: Int) {
  product(internalId: $internalId, id: $id) {
    product {
      id
      internalId
      name
      eanCodes
      active
      productImages {
        id
        primary
        file {
          id
          originalname
          filename
          mimetype
          encoding
          url
          isFolder
          width
          height
          thumbnail
        }
      }
      valueEntities {
        id
        name
        templateKey
        type
        value
      }
      products {
        id
        internalId
        name
        eanCodes
        valueEntities {
          id
          name
          templateKey
          type
          value
        }
        productImages {
          id
          primary
          file {
            id
            originalname
            filename
            mimetype
            encoding
            url
            isFolder
            width
            height
            thumbnail
          }
        }
      }
    }
  }
}
    `;

/**
 * __useProductEditDetailsQuery__
 *
 * To run a query within a React component, call `useProductEditDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductEditDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductEditDetailsQuery({
 *   variables: {
 *      internalId: // value for 'internalId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductEditDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProductEditDetailsQuery, ProductEditDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ProductEditDetailsQuery, ProductEditDetailsQueryVariables>(ProductEditDetailsDocument, options);
      }
export function useProductEditDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductEditDetailsQuery, ProductEditDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ProductEditDetailsQuery, ProductEditDetailsQueryVariables>(ProductEditDetailsDocument, options);
        }
export type ProductEditDetailsQueryHookResult = ReturnType<typeof useProductEditDetailsQuery>;
export type ProductEditDetailsLazyQueryHookResult = ReturnType<typeof useProductEditDetailsLazyQuery>;
export type ProductEditDetailsQueryResult = Apollo.QueryResult<ProductEditDetailsQuery, ProductEditDetailsQueryVariables>;
export const UploadProductImageDocument = gql`
    mutation uploadProductImage($file: Upload!, $internalId: String!, $primary: Boolean) {
  uploadProductImage(file: $file, internalId: $internalId, primary: $primary) {
    id
    primary
    file {
      id
      originalname
      filename
      mimetype
      encoding
      url
      isFolder
      thumbnail
      width
      height
    }
  }
}
    `;
export type UploadProductImageMutationFn = Apollo.MutationFunction<UploadProductImageMutation, UploadProductImageMutationVariables>;

/**
 * __useUploadProductImageMutation__
 *
 * To run a mutation, you first call `useUploadProductImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProductImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProductImageMutation, { data, loading, error }] = useUploadProductImageMutation({
 *   variables: {
 *      file: // value for 'file'
 *      internalId: // value for 'internalId'
 *      primary: // value for 'primary'
 *   },
 * });
 */
export function useUploadProductImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadProductImageMutation, UploadProductImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UploadProductImageMutation, UploadProductImageMutationVariables>(UploadProductImageDocument, options);
      }
export type UploadProductImageMutationHookResult = ReturnType<typeof useUploadProductImageMutation>;
export type UploadProductImageMutationResult = Apollo.MutationResult<UploadProductImageMutation>;
export type UploadProductImageMutationOptions = Apollo.BaseMutationOptions<UploadProductImageMutation, UploadProductImageMutationVariables>;
export const AddVirtualCategoryPointDocument = gql`
    mutation addVirtualCategoryPoint($input: AddVirtualCategoryPointInput!) {
  addVirtualCategoryPoint(input: $input) {
    errors {
      path
      message
    }
    virtualCategoryPoint {
      id
    }
  }
}
    `;
export type AddVirtualCategoryPointMutationFn = Apollo.MutationFunction<AddVirtualCategoryPointMutation, AddVirtualCategoryPointMutationVariables>;

/**
 * __useAddVirtualCategoryPointMutation__
 *
 * To run a mutation, you first call `useAddVirtualCategoryPointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVirtualCategoryPointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVirtualCategoryPointMutation, { data, loading, error }] = useAddVirtualCategoryPointMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddVirtualCategoryPointMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddVirtualCategoryPointMutation, AddVirtualCategoryPointMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddVirtualCategoryPointMutation, AddVirtualCategoryPointMutationVariables>(AddVirtualCategoryPointDocument, options);
      }
export type AddVirtualCategoryPointMutationHookResult = ReturnType<typeof useAddVirtualCategoryPointMutation>;
export type AddVirtualCategoryPointMutationResult = Apollo.MutationResult<AddVirtualCategoryPointMutation>;
export type AddVirtualCategoryPointMutationOptions = Apollo.BaseMutationOptions<AddVirtualCategoryPointMutation, AddVirtualCategoryPointMutationVariables>;
export const DeleteVirtualCategoryPointDocument = gql`
    mutation deleteVirtualCategoryPoint($id: Int!) {
  deleteVirtualCategoryPoint(id: $id) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type DeleteVirtualCategoryPointMutationFn = Apollo.MutationFunction<DeleteVirtualCategoryPointMutation, DeleteVirtualCategoryPointMutationVariables>;

/**
 * __useDeleteVirtualCategoryPointMutation__
 *
 * To run a mutation, you first call `useDeleteVirtualCategoryPointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVirtualCategoryPointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVirtualCategoryPointMutation, { data, loading, error }] = useDeleteVirtualCategoryPointMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVirtualCategoryPointMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteVirtualCategoryPointMutation, DeleteVirtualCategoryPointMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteVirtualCategoryPointMutation, DeleteVirtualCategoryPointMutationVariables>(DeleteVirtualCategoryPointDocument, options);
      }
export type DeleteVirtualCategoryPointMutationHookResult = ReturnType<typeof useDeleteVirtualCategoryPointMutation>;
export type DeleteVirtualCategoryPointMutationResult = Apollo.MutationResult<DeleteVirtualCategoryPointMutation>;
export type DeleteVirtualCategoryPointMutationOptions = Apollo.BaseMutationOptions<DeleteVirtualCategoryPointMutation, DeleteVirtualCategoryPointMutationVariables>;
export const EditVirtualCategoryPointDocument = gql`
    mutation editVirtualCategoryPoint($input: EditVirtualCategoryPointInput!) {
  editVirtualCategoryPoint(input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type EditVirtualCategoryPointMutationFn = Apollo.MutationFunction<EditVirtualCategoryPointMutation, EditVirtualCategoryPointMutationVariables>;

/**
 * __useEditVirtualCategoryPointMutation__
 *
 * To run a mutation, you first call `useEditVirtualCategoryPointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVirtualCategoryPointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVirtualCategoryPointMutation, { data, loading, error }] = useEditVirtualCategoryPointMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditVirtualCategoryPointMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditVirtualCategoryPointMutation, EditVirtualCategoryPointMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditVirtualCategoryPointMutation, EditVirtualCategoryPointMutationVariables>(EditVirtualCategoryPointDocument, options);
      }
export type EditVirtualCategoryPointMutationHookResult = ReturnType<typeof useEditVirtualCategoryPointMutation>;
export type EditVirtualCategoryPointMutationResult = Apollo.MutationResult<EditVirtualCategoryPointMutation>;
export type EditVirtualCategoryPointMutationOptions = Apollo.BaseMutationOptions<EditVirtualCategoryPointMutation, EditVirtualCategoryPointMutationVariables>;
export const VirtualCategoryPointDocument = gql`
    query virtualCategoryPoint($virtualCategoryPointId: Int!) {
  virtualCategoryPoint(virtualCategoryPointId: $virtualCategoryPointId) {
    virtualCategoryPoint {
      id
      name
      virtualRacks {
        id
        name
        virtualShelfPositions {
          virtualShelf {
            id
            name
            spacing
            variant
            virtualProductPositions {
              zoom
              rotation
              itemCount
              product {
                id
                name
                productImages {
                  file {
                    filename
                    thumbnail
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useVirtualCategoryPointQuery__
 *
 * To run a query within a React component, call `useVirtualCategoryPointQuery` and pass it any options that fit your needs.
 * When your component renders, `useVirtualCategoryPointQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVirtualCategoryPointQuery({
 *   variables: {
 *      virtualCategoryPointId: // value for 'virtualCategoryPointId'
 *   },
 * });
 */
export function useVirtualCategoryPointQuery(baseOptions: ApolloReactHooks.QueryHookOptions<VirtualCategoryPointQuery, VirtualCategoryPointQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<VirtualCategoryPointQuery, VirtualCategoryPointQueryVariables>(VirtualCategoryPointDocument, options);
      }
export function useVirtualCategoryPointLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VirtualCategoryPointQuery, VirtualCategoryPointQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<VirtualCategoryPointQuery, VirtualCategoryPointQueryVariables>(VirtualCategoryPointDocument, options);
        }
export type VirtualCategoryPointQueryHookResult = ReturnType<typeof useVirtualCategoryPointQuery>;
export type VirtualCategoryPointLazyQueryHookResult = ReturnType<typeof useVirtualCategoryPointLazyQuery>;
export type VirtualCategoryPointQueryResult = Apollo.QueryResult<VirtualCategoryPointQuery, VirtualCategoryPointQueryVariables>;
export const VirtualCategoryPointsDocument = gql`
    query virtualCategoryPoints($offset: Int!, $limit: Int!, $where: VirtualCategoryPointsFilter) {
  virtualCategoryPoints(offset: $offset, limit: $limit, where: $where) {
    nodes {
      id
      name
      virtualRacks {
        id
        name
      }
    }
    totalCount
  }
}
    `;

/**
 * __useVirtualCategoryPointsQuery__
 *
 * To run a query within a React component, call `useVirtualCategoryPointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVirtualCategoryPointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVirtualCategoryPointsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useVirtualCategoryPointsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<VirtualCategoryPointsQuery, VirtualCategoryPointsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<VirtualCategoryPointsQuery, VirtualCategoryPointsQueryVariables>(VirtualCategoryPointsDocument, options);
      }
export function useVirtualCategoryPointsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VirtualCategoryPointsQuery, VirtualCategoryPointsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<VirtualCategoryPointsQuery, VirtualCategoryPointsQueryVariables>(VirtualCategoryPointsDocument, options);
        }
export type VirtualCategoryPointsQueryHookResult = ReturnType<typeof useVirtualCategoryPointsQuery>;
export type VirtualCategoryPointsLazyQueryHookResult = ReturnType<typeof useVirtualCategoryPointsLazyQuery>;
export type VirtualCategoryPointsQueryResult = Apollo.QueryResult<VirtualCategoryPointsQuery, VirtualCategoryPointsQueryVariables>;
export const AddVirtualCategoryScreenDocument = gql`
    mutation addVirtualCategoryScreen($input: AddVirtualCategoryScreenInput!) {
  addVirtualCategoryScreen(input: $input) {
    errors {
      path
      message
    }
    virtualCategoryScreen {
      id
    }
  }
}
    `;
export type AddVirtualCategoryScreenMutationFn = Apollo.MutationFunction<AddVirtualCategoryScreenMutation, AddVirtualCategoryScreenMutationVariables>;

/**
 * __useAddVirtualCategoryScreenMutation__
 *
 * To run a mutation, you first call `useAddVirtualCategoryScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVirtualCategoryScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVirtualCategoryScreenMutation, { data, loading, error }] = useAddVirtualCategoryScreenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddVirtualCategoryScreenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddVirtualCategoryScreenMutation, AddVirtualCategoryScreenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddVirtualCategoryScreenMutation, AddVirtualCategoryScreenMutationVariables>(AddVirtualCategoryScreenDocument, options);
      }
export type AddVirtualCategoryScreenMutationHookResult = ReturnType<typeof useAddVirtualCategoryScreenMutation>;
export type AddVirtualCategoryScreenMutationResult = Apollo.MutationResult<AddVirtualCategoryScreenMutation>;
export type AddVirtualCategoryScreenMutationOptions = Apollo.BaseMutationOptions<AddVirtualCategoryScreenMutation, AddVirtualCategoryScreenMutationVariables>;
export const DeleteCategoryScreenImageDocument = gql`
    mutation deleteCategoryScreenImage($categoryId: Int!) {
  deleteVirtualCategoryScreenImage(categoryId: $categoryId) {
    success
    errors {
      path
      message
    }
  }
}
    `;
export type DeleteCategoryScreenImageMutationFn = Apollo.MutationFunction<DeleteCategoryScreenImageMutation, DeleteCategoryScreenImageMutationVariables>;

/**
 * __useDeleteCategoryScreenImageMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryScreenImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryScreenImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryScreenImageMutation, { data, loading, error }] = useDeleteCategoryScreenImageMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useDeleteCategoryScreenImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCategoryScreenImageMutation, DeleteCategoryScreenImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteCategoryScreenImageMutation, DeleteCategoryScreenImageMutationVariables>(DeleteCategoryScreenImageDocument, options);
      }
export type DeleteCategoryScreenImageMutationHookResult = ReturnType<typeof useDeleteCategoryScreenImageMutation>;
export type DeleteCategoryScreenImageMutationResult = Apollo.MutationResult<DeleteCategoryScreenImageMutation>;
export type DeleteCategoryScreenImageMutationOptions = Apollo.BaseMutationOptions<DeleteCategoryScreenImageMutation, DeleteCategoryScreenImageMutationVariables>;
export const DeleteVirtualCategoryScreenDocument = gql`
    mutation deleteVirtualCategoryScreen($id: Int!) {
  deleteVirtualCategoryScreen(id: $id) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type DeleteVirtualCategoryScreenMutationFn = Apollo.MutationFunction<DeleteVirtualCategoryScreenMutation, DeleteVirtualCategoryScreenMutationVariables>;

/**
 * __useDeleteVirtualCategoryScreenMutation__
 *
 * To run a mutation, you first call `useDeleteVirtualCategoryScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVirtualCategoryScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVirtualCategoryScreenMutation, { data, loading, error }] = useDeleteVirtualCategoryScreenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVirtualCategoryScreenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteVirtualCategoryScreenMutation, DeleteVirtualCategoryScreenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteVirtualCategoryScreenMutation, DeleteVirtualCategoryScreenMutationVariables>(DeleteVirtualCategoryScreenDocument, options);
      }
export type DeleteVirtualCategoryScreenMutationHookResult = ReturnType<typeof useDeleteVirtualCategoryScreenMutation>;
export type DeleteVirtualCategoryScreenMutationResult = Apollo.MutationResult<DeleteVirtualCategoryScreenMutation>;
export type DeleteVirtualCategoryScreenMutationOptions = Apollo.BaseMutationOptions<DeleteVirtualCategoryScreenMutation, DeleteVirtualCategoryScreenMutationVariables>;
export const EditVirtualCategoryScreenDocument = gql`
    mutation editVirtualCategoryScreen($input: EditVirtualCategoryScreenInput!) {
  editVirtualCategoryScreen(input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type EditVirtualCategoryScreenMutationFn = Apollo.MutationFunction<EditVirtualCategoryScreenMutation, EditVirtualCategoryScreenMutationVariables>;

/**
 * __useEditVirtualCategoryScreenMutation__
 *
 * To run a mutation, you first call `useEditVirtualCategoryScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVirtualCategoryScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVirtualCategoryScreenMutation, { data, loading, error }] = useEditVirtualCategoryScreenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditVirtualCategoryScreenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditVirtualCategoryScreenMutation, EditVirtualCategoryScreenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditVirtualCategoryScreenMutation, EditVirtualCategoryScreenMutationVariables>(EditVirtualCategoryScreenDocument, options);
      }
export type EditVirtualCategoryScreenMutationHookResult = ReturnType<typeof useEditVirtualCategoryScreenMutation>;
export type EditVirtualCategoryScreenMutationResult = Apollo.MutationResult<EditVirtualCategoryScreenMutation>;
export type EditVirtualCategoryScreenMutationOptions = Apollo.BaseMutationOptions<EditVirtualCategoryScreenMutation, EditVirtualCategoryScreenMutationVariables>;
export const UploadVirtualStoreScreenDocument = gql`
    mutation uploadVirtualStoreScreen($file: Upload!, $virtualCategoryScreenId: Int!) {
  uploadVirtualStoreScreen(
    file: $file
    virtualCategoryScreenId: $virtualCategoryScreenId
  ) {
    id
    originalname
    filename
    mimetype
    encoding
    url
    isFolder
    thumbnail
    width
    height
  }
}
    `;
export type UploadVirtualStoreScreenMutationFn = Apollo.MutationFunction<UploadVirtualStoreScreenMutation, UploadVirtualStoreScreenMutationVariables>;

/**
 * __useUploadVirtualStoreScreenMutation__
 *
 * To run a mutation, you first call `useUploadVirtualStoreScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadVirtualStoreScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadVirtualStoreScreenMutation, { data, loading, error }] = useUploadVirtualStoreScreenMutation({
 *   variables: {
 *      file: // value for 'file'
 *      virtualCategoryScreenId: // value for 'virtualCategoryScreenId'
 *   },
 * });
 */
export function useUploadVirtualStoreScreenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadVirtualStoreScreenMutation, UploadVirtualStoreScreenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UploadVirtualStoreScreenMutation, UploadVirtualStoreScreenMutationVariables>(UploadVirtualStoreScreenDocument, options);
      }
export type UploadVirtualStoreScreenMutationHookResult = ReturnType<typeof useUploadVirtualStoreScreenMutation>;
export type UploadVirtualStoreScreenMutationResult = Apollo.MutationResult<UploadVirtualStoreScreenMutation>;
export type UploadVirtualStoreScreenMutationOptions = Apollo.BaseMutationOptions<UploadVirtualStoreScreenMutation, UploadVirtualStoreScreenMutationVariables>;
export const VirtualCategoryScreenDocument = gql`
    query virtualCategoryScreen($virtualCategoryScreenId: Int!) {
  virtualCategoryScreen(virtualCategoryScreenId: $virtualCategoryScreenId) {
    virtualCategoryScreen {
      id
      name
      timeout
      catalogMode
      channel {
        id
      }
      file {
        id
        originalname
        filename
        mimetype
        encoding
        url
        isFolder
        width
        height
      }
      backgroundFile {
        id
        originalname
        filename
        mimetype
        encoding
        url
        isFolder
        width
        height
      }
      virtualCategoryPointPositions {
        id
        x
        y
        virtualCategoryPoint {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useVirtualCategoryScreenQuery__
 *
 * To run a query within a React component, call `useVirtualCategoryScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useVirtualCategoryScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVirtualCategoryScreenQuery({
 *   variables: {
 *      virtualCategoryScreenId: // value for 'virtualCategoryScreenId'
 *   },
 * });
 */
export function useVirtualCategoryScreenQuery(baseOptions: ApolloReactHooks.QueryHookOptions<VirtualCategoryScreenQuery, VirtualCategoryScreenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<VirtualCategoryScreenQuery, VirtualCategoryScreenQueryVariables>(VirtualCategoryScreenDocument, options);
      }
export function useVirtualCategoryScreenLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VirtualCategoryScreenQuery, VirtualCategoryScreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<VirtualCategoryScreenQuery, VirtualCategoryScreenQueryVariables>(VirtualCategoryScreenDocument, options);
        }
export type VirtualCategoryScreenQueryHookResult = ReturnType<typeof useVirtualCategoryScreenQuery>;
export type VirtualCategoryScreenLazyQueryHookResult = ReturnType<typeof useVirtualCategoryScreenLazyQuery>;
export type VirtualCategoryScreenQueryResult = Apollo.QueryResult<VirtualCategoryScreenQuery, VirtualCategoryScreenQueryVariables>;
export const VirtualCategoryScreensDocument = gql`
    query virtualCategoryScreens($offset: Int!, $limit: Int!, $where: VirtualCategoryScreensFilter) {
  virtualCategoryScreens(offset: $offset, limit: $limit, where: $where) {
    nodes {
      id
      name
      virtualCategoryPointPositions {
        id
        x
        y
        virtualCategoryPoint {
          id
          name
        }
      }
    }
    totalCount
  }
}
    `;

/**
 * __useVirtualCategoryScreensQuery__
 *
 * To run a query within a React component, call `useVirtualCategoryScreensQuery` and pass it any options that fit your needs.
 * When your component renders, `useVirtualCategoryScreensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVirtualCategoryScreensQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useVirtualCategoryScreensQuery(baseOptions: ApolloReactHooks.QueryHookOptions<VirtualCategoryScreensQuery, VirtualCategoryScreensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<VirtualCategoryScreensQuery, VirtualCategoryScreensQueryVariables>(VirtualCategoryScreensDocument, options);
      }
export function useVirtualCategoryScreensLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VirtualCategoryScreensQuery, VirtualCategoryScreensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<VirtualCategoryScreensQuery, VirtualCategoryScreensQueryVariables>(VirtualCategoryScreensDocument, options);
        }
export type VirtualCategoryScreensQueryHookResult = ReturnType<typeof useVirtualCategoryScreensQuery>;
export type VirtualCategoryScreensLazyQueryHookResult = ReturnType<typeof useVirtualCategoryScreensLazyQuery>;
export type VirtualCategoryScreensQueryResult = Apollo.QueryResult<VirtualCategoryScreensQuery, VirtualCategoryScreensQueryVariables>;
export const AddVirtualRackDocument = gql`
    mutation addVirtualRack($input: AddVirtualRackInput!) {
  addVirtualRack(input: $input) {
    errors {
      path
      message
    }
    virtualRack {
      id
    }
  }
}
    `;
export type AddVirtualRackMutationFn = Apollo.MutationFunction<AddVirtualRackMutation, AddVirtualRackMutationVariables>;

/**
 * __useAddVirtualRackMutation__
 *
 * To run a mutation, you first call `useAddVirtualRackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVirtualRackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVirtualRackMutation, { data, loading, error }] = useAddVirtualRackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddVirtualRackMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddVirtualRackMutation, AddVirtualRackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddVirtualRackMutation, AddVirtualRackMutationVariables>(AddVirtualRackDocument, options);
      }
export type AddVirtualRackMutationHookResult = ReturnType<typeof useAddVirtualRackMutation>;
export type AddVirtualRackMutationResult = Apollo.MutationResult<AddVirtualRackMutation>;
export type AddVirtualRackMutationOptions = Apollo.BaseMutationOptions<AddVirtualRackMutation, AddVirtualRackMutationVariables>;
export const DeleteVirtualRackDocument = gql`
    mutation deleteVirtualRack($id: Int!) {
  deleteVirtualRack(id: $id) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type DeleteVirtualRackMutationFn = Apollo.MutationFunction<DeleteVirtualRackMutation, DeleteVirtualRackMutationVariables>;

/**
 * __useDeleteVirtualRackMutation__
 *
 * To run a mutation, you first call `useDeleteVirtualRackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVirtualRackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVirtualRackMutation, { data, loading, error }] = useDeleteVirtualRackMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVirtualRackMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteVirtualRackMutation, DeleteVirtualRackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteVirtualRackMutation, DeleteVirtualRackMutationVariables>(DeleteVirtualRackDocument, options);
      }
export type DeleteVirtualRackMutationHookResult = ReturnType<typeof useDeleteVirtualRackMutation>;
export type DeleteVirtualRackMutationResult = Apollo.MutationResult<DeleteVirtualRackMutation>;
export type DeleteVirtualRackMutationOptions = Apollo.BaseMutationOptions<DeleteVirtualRackMutation, DeleteVirtualRackMutationVariables>;
export const EditVirtualRackDocument = gql`
    mutation editVirtualRack($input: EditVirtualRackInput!) {
  editVirtualRack(input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type EditVirtualRackMutationFn = Apollo.MutationFunction<EditVirtualRackMutation, EditVirtualRackMutationVariables>;

/**
 * __useEditVirtualRackMutation__
 *
 * To run a mutation, you first call `useEditVirtualRackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVirtualRackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVirtualRackMutation, { data, loading, error }] = useEditVirtualRackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditVirtualRackMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditVirtualRackMutation, EditVirtualRackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditVirtualRackMutation, EditVirtualRackMutationVariables>(EditVirtualRackDocument, options);
      }
export type EditVirtualRackMutationHookResult = ReturnType<typeof useEditVirtualRackMutation>;
export type EditVirtualRackMutationResult = Apollo.MutationResult<EditVirtualRackMutation>;
export type EditVirtualRackMutationOptions = Apollo.BaseMutationOptions<EditVirtualRackMutation, EditVirtualRackMutationVariables>;
export const VirtualRackDocument = gql`
    query virtualRack($virtualRackId: Int!) {
  virtualRack(virtualRackId: $virtualRackId) {
    virtualRack {
      id
      name
      file {
        id
        originalname
      }
      virtualShelfPositions {
        id
        virtualShelf {
          id
          name
          spacing
          variant
          virtualProductPositions {
            id
            zoom
            rotation
            itemCount
            product {
              id
              name
              valueEntities {
                value
                templateKey
              }
              productImages {
                id
                file {
                  id
                  thumbnail
                  filename
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useVirtualRackQuery__
 *
 * To run a query within a React component, call `useVirtualRackQuery` and pass it any options that fit your needs.
 * When your component renders, `useVirtualRackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVirtualRackQuery({
 *   variables: {
 *      virtualRackId: // value for 'virtualRackId'
 *   },
 * });
 */
export function useVirtualRackQuery(baseOptions: ApolloReactHooks.QueryHookOptions<VirtualRackQuery, VirtualRackQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<VirtualRackQuery, VirtualRackQueryVariables>(VirtualRackDocument, options);
      }
export function useVirtualRackLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VirtualRackQuery, VirtualRackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<VirtualRackQuery, VirtualRackQueryVariables>(VirtualRackDocument, options);
        }
export type VirtualRackQueryHookResult = ReturnType<typeof useVirtualRackQuery>;
export type VirtualRackLazyQueryHookResult = ReturnType<typeof useVirtualRackLazyQuery>;
export type VirtualRackQueryResult = Apollo.QueryResult<VirtualRackQuery, VirtualRackQueryVariables>;
export const VirtualRacksDocument = gql`
    query virtualRacks($offset: Int!, $limit: Int!, $where: VirtualRacksFilter) {
  virtualRacks(offset: $offset, limit: $limit, where: $where) {
    nodes {
      id
      name
      file {
        id
        thumbnail
        width
        height
      }
      virtualShelfPositions {
        id
        virtualShelf {
          variant
          spacing
          virtualProductPositions {
            zoom
            rotation
            itemCount
            product {
              productImages {
                file {
                  id
                  thumbnail
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
    totalCount
  }
}
    `;

/**
 * __useVirtualRacksQuery__
 *
 * To run a query within a React component, call `useVirtualRacksQuery` and pass it any options that fit your needs.
 * When your component renders, `useVirtualRacksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVirtualRacksQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useVirtualRacksQuery(baseOptions: ApolloReactHooks.QueryHookOptions<VirtualRacksQuery, VirtualRacksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<VirtualRacksQuery, VirtualRacksQueryVariables>(VirtualRacksDocument, options);
      }
export function useVirtualRacksLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VirtualRacksQuery, VirtualRacksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<VirtualRacksQuery, VirtualRacksQueryVariables>(VirtualRacksDocument, options);
        }
export type VirtualRacksQueryHookResult = ReturnType<typeof useVirtualRacksQuery>;
export type VirtualRacksLazyQueryHookResult = ReturnType<typeof useVirtualRacksLazyQuery>;
export type VirtualRacksQueryResult = Apollo.QueryResult<VirtualRacksQuery, VirtualRacksQueryVariables>;
export const AddVirtualShelfDocument = gql`
    mutation addVirtualShelf($input: AddVirtualShelfInput!) {
  addVirtualShelf(input: $input) {
    errors {
      path
      message
    }
    virtualShelf {
      id
    }
  }
}
    `;
export type AddVirtualShelfMutationFn = Apollo.MutationFunction<AddVirtualShelfMutation, AddVirtualShelfMutationVariables>;

/**
 * __useAddVirtualShelfMutation__
 *
 * To run a mutation, you first call `useAddVirtualShelfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVirtualShelfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVirtualShelfMutation, { data, loading, error }] = useAddVirtualShelfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddVirtualShelfMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddVirtualShelfMutation, AddVirtualShelfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddVirtualShelfMutation, AddVirtualShelfMutationVariables>(AddVirtualShelfDocument, options);
      }
export type AddVirtualShelfMutationHookResult = ReturnType<typeof useAddVirtualShelfMutation>;
export type AddVirtualShelfMutationResult = Apollo.MutationResult<AddVirtualShelfMutation>;
export type AddVirtualShelfMutationOptions = Apollo.BaseMutationOptions<AddVirtualShelfMutation, AddVirtualShelfMutationVariables>;
export const DeleteVirtualShelfDocument = gql`
    mutation deleteVirtualShelf($id: Int!) {
  deleteVirtualShelf(id: $id) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type DeleteVirtualShelfMutationFn = Apollo.MutationFunction<DeleteVirtualShelfMutation, DeleteVirtualShelfMutationVariables>;

/**
 * __useDeleteVirtualShelfMutation__
 *
 * To run a mutation, you first call `useDeleteVirtualShelfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVirtualShelfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVirtualShelfMutation, { data, loading, error }] = useDeleteVirtualShelfMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVirtualShelfMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteVirtualShelfMutation, DeleteVirtualShelfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteVirtualShelfMutation, DeleteVirtualShelfMutationVariables>(DeleteVirtualShelfDocument, options);
      }
export type DeleteVirtualShelfMutationHookResult = ReturnType<typeof useDeleteVirtualShelfMutation>;
export type DeleteVirtualShelfMutationResult = Apollo.MutationResult<DeleteVirtualShelfMutation>;
export type DeleteVirtualShelfMutationOptions = Apollo.BaseMutationOptions<DeleteVirtualShelfMutation, DeleteVirtualShelfMutationVariables>;
export const EditVirtualShelfDocument = gql`
    mutation editVirtualShelf($input: EditVirtualShelfInput!) {
  editVirtualShelf(input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type EditVirtualShelfMutationFn = Apollo.MutationFunction<EditVirtualShelfMutation, EditVirtualShelfMutationVariables>;

/**
 * __useEditVirtualShelfMutation__
 *
 * To run a mutation, you first call `useEditVirtualShelfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVirtualShelfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVirtualShelfMutation, { data, loading, error }] = useEditVirtualShelfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditVirtualShelfMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditVirtualShelfMutation, EditVirtualShelfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditVirtualShelfMutation, EditVirtualShelfMutationVariables>(EditVirtualShelfDocument, options);
      }
export type EditVirtualShelfMutationHookResult = ReturnType<typeof useEditVirtualShelfMutation>;
export type EditVirtualShelfMutationResult = Apollo.MutationResult<EditVirtualShelfMutation>;
export type EditVirtualShelfMutationOptions = Apollo.BaseMutationOptions<EditVirtualShelfMutation, EditVirtualShelfMutationVariables>;
export const VirtualShelfDocument = gql`
    query virtualShelf($virtualShelfId: Int!) {
  virtualShelf(virtualShelfId: $virtualShelfId) {
    virtualShelf {
      id
      name
      spacing
      variant
      virtualProductPositions {
        id
        zoom
        rotation
        itemCount
        product {
          id
          name
          internalId
          valueEntities {
            templateKey
            value
          }
          productImages {
            id
            file {
              thumbnail
              filename
              width
              height
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useVirtualShelfQuery__
 *
 * To run a query within a React component, call `useVirtualShelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useVirtualShelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVirtualShelfQuery({
 *   variables: {
 *      virtualShelfId: // value for 'virtualShelfId'
 *   },
 * });
 */
export function useVirtualShelfQuery(baseOptions: ApolloReactHooks.QueryHookOptions<VirtualShelfQuery, VirtualShelfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<VirtualShelfQuery, VirtualShelfQueryVariables>(VirtualShelfDocument, options);
      }
export function useVirtualShelfLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VirtualShelfQuery, VirtualShelfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<VirtualShelfQuery, VirtualShelfQueryVariables>(VirtualShelfDocument, options);
        }
export type VirtualShelfQueryHookResult = ReturnType<typeof useVirtualShelfQuery>;
export type VirtualShelfLazyQueryHookResult = ReturnType<typeof useVirtualShelfLazyQuery>;
export type VirtualShelfQueryResult = Apollo.QueryResult<VirtualShelfQuery, VirtualShelfQueryVariables>;
export const VirtualShelfProductsDocument = gql`
    query VirtualShelfProducts($where: ProductsFilter, $offset: Int!, $limit: Int!) {
  products(where: $where, offset: $offset, limit: $limit) {
    nodes {
      id
      name
      internalId
      valueEntities {
        templateKey
        value
        type
      }
      productImages {
        id
        file {
          id
          thumbnail
          width
          height
        }
      }
    }
    totalCount
  }
}
    `;

/**
 * __useVirtualShelfProductsQuery__
 *
 * To run a query within a React component, call `useVirtualShelfProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVirtualShelfProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVirtualShelfProductsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useVirtualShelfProductsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<VirtualShelfProductsQuery, VirtualShelfProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<VirtualShelfProductsQuery, VirtualShelfProductsQueryVariables>(VirtualShelfProductsDocument, options);
      }
export function useVirtualShelfProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VirtualShelfProductsQuery, VirtualShelfProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<VirtualShelfProductsQuery, VirtualShelfProductsQueryVariables>(VirtualShelfProductsDocument, options);
        }
export type VirtualShelfProductsQueryHookResult = ReturnType<typeof useVirtualShelfProductsQuery>;
export type VirtualShelfProductsLazyQueryHookResult = ReturnType<typeof useVirtualShelfProductsLazyQuery>;
export type VirtualShelfProductsQueryResult = Apollo.QueryResult<VirtualShelfProductsQuery, VirtualShelfProductsQueryVariables>;
export const VirtualShelvesDocument = gql`
    query VirtualShelves($offset: Int!, $limit: Int!, $where: VirtualShelvesFilter) {
  virtualShelves(offset: $offset, limit: $limit, where: $where) {
    nodes {
      id
      name
      spacing
      variant
      virtualProductPositions {
        id
        zoom
        rotation
        itemCount
        product {
          id
          productImages {
            file {
              id
              thumbnail
              width
              height
            }
          }
        }
      }
    }
    totalCount
  }
}
    `;

/**
 * __useVirtualShelvesQuery__
 *
 * To run a query within a React component, call `useVirtualShelvesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVirtualShelvesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVirtualShelvesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useVirtualShelvesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<VirtualShelvesQuery, VirtualShelvesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<VirtualShelvesQuery, VirtualShelvesQueryVariables>(VirtualShelvesDocument, options);
      }
export function useVirtualShelvesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VirtualShelvesQuery, VirtualShelvesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<VirtualShelvesQuery, VirtualShelvesQueryVariables>(VirtualShelvesDocument, options);
        }
export type VirtualShelvesQueryHookResult = ReturnType<typeof useVirtualShelvesQuery>;
export type VirtualShelvesLazyQueryHookResult = ReturnType<typeof useVirtualShelvesLazyQuery>;
export type VirtualShelvesQueryResult = Apollo.QueryResult<VirtualShelvesQuery, VirtualShelvesQueryVariables>;
export const AddVirtualStoreDeviceDocument = gql`
    mutation addVirtualStoreDevice($input: AddVirtualStoreDeviceInput!) {
  addVirtualStoreDevice(input: $input) {
    virtualStoreDevice {
      id
    }
    errors {
      path
      message
    }
  }
}
    `;
export type AddVirtualStoreDeviceMutationFn = Apollo.MutationFunction<AddVirtualStoreDeviceMutation, AddVirtualStoreDeviceMutationVariables>;

/**
 * __useAddVirtualStoreDeviceMutation__
 *
 * To run a mutation, you first call `useAddVirtualStoreDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVirtualStoreDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVirtualStoreDeviceMutation, { data, loading, error }] = useAddVirtualStoreDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddVirtualStoreDeviceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddVirtualStoreDeviceMutation, AddVirtualStoreDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddVirtualStoreDeviceMutation, AddVirtualStoreDeviceMutationVariables>(AddVirtualStoreDeviceDocument, options);
      }
export type AddVirtualStoreDeviceMutationHookResult = ReturnType<typeof useAddVirtualStoreDeviceMutation>;
export type AddVirtualStoreDeviceMutationResult = Apollo.MutationResult<AddVirtualStoreDeviceMutation>;
export type AddVirtualStoreDeviceMutationOptions = Apollo.BaseMutationOptions<AddVirtualStoreDeviceMutation, AddVirtualStoreDeviceMutationVariables>;
export const DeleteVirtualStoreDeviceDocument = gql`
    mutation deleteVirtualStoreDevice($id: Int!) {
  deleteVirtualStoreDevice(id: $id) {
    success
    errors {
      path
      message
    }
  }
}
    `;
export type DeleteVirtualStoreDeviceMutationFn = Apollo.MutationFunction<DeleteVirtualStoreDeviceMutation, DeleteVirtualStoreDeviceMutationVariables>;

/**
 * __useDeleteVirtualStoreDeviceMutation__
 *
 * To run a mutation, you first call `useDeleteVirtualStoreDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVirtualStoreDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVirtualStoreDeviceMutation, { data, loading, error }] = useDeleteVirtualStoreDeviceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVirtualStoreDeviceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteVirtualStoreDeviceMutation, DeleteVirtualStoreDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteVirtualStoreDeviceMutation, DeleteVirtualStoreDeviceMutationVariables>(DeleteVirtualStoreDeviceDocument, options);
      }
export type DeleteVirtualStoreDeviceMutationHookResult = ReturnType<typeof useDeleteVirtualStoreDeviceMutation>;
export type DeleteVirtualStoreDeviceMutationResult = Apollo.MutationResult<DeleteVirtualStoreDeviceMutation>;
export type DeleteVirtualStoreDeviceMutationOptions = Apollo.BaseMutationOptions<DeleteVirtualStoreDeviceMutation, DeleteVirtualStoreDeviceMutationVariables>;
export const EditVirtualStoreDeviceDocument = gql`
    mutation editVirtualStoreDevice($input: EditVirtualStoreDeviceInput!) {
  editVirtualStoreDevice(input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type EditVirtualStoreDeviceMutationFn = Apollo.MutationFunction<EditVirtualStoreDeviceMutation, EditVirtualStoreDeviceMutationVariables>;

/**
 * __useEditVirtualStoreDeviceMutation__
 *
 * To run a mutation, you first call `useEditVirtualStoreDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVirtualStoreDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVirtualStoreDeviceMutation, { data, loading, error }] = useEditVirtualStoreDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditVirtualStoreDeviceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditVirtualStoreDeviceMutation, EditVirtualStoreDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditVirtualStoreDeviceMutation, EditVirtualStoreDeviceMutationVariables>(EditVirtualStoreDeviceDocument, options);
      }
export type EditVirtualStoreDeviceMutationHookResult = ReturnType<typeof useEditVirtualStoreDeviceMutation>;
export type EditVirtualStoreDeviceMutationResult = Apollo.MutationResult<EditVirtualStoreDeviceMutation>;
export type EditVirtualStoreDeviceMutationOptions = Apollo.BaseMutationOptions<EditVirtualStoreDeviceMutation, EditVirtualStoreDeviceMutationVariables>;
export const VirtualStoreDeviceDocument = gql`
    query virtualStoreDevice($virtualStoreDeviceId: Int!) {
  virtualStoreDevice(virtualStoreDeviceId: $virtualStoreDeviceId) {
    virtualStoreDevice {
      id
      name
      tags
      token
      note
      root {
        id
        name
      }
      virtualCategoryScreen {
        id
        name
      }
      channel {
        id
        name
      }
      virtualMultiScreen {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useVirtualStoreDeviceQuery__
 *
 * To run a query within a React component, call `useVirtualStoreDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useVirtualStoreDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVirtualStoreDeviceQuery({
 *   variables: {
 *      virtualStoreDeviceId: // value for 'virtualStoreDeviceId'
 *   },
 * });
 */
export function useVirtualStoreDeviceQuery(baseOptions: ApolloReactHooks.QueryHookOptions<VirtualStoreDeviceQuery, VirtualStoreDeviceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<VirtualStoreDeviceQuery, VirtualStoreDeviceQueryVariables>(VirtualStoreDeviceDocument, options);
      }
export function useVirtualStoreDeviceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VirtualStoreDeviceQuery, VirtualStoreDeviceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<VirtualStoreDeviceQuery, VirtualStoreDeviceQueryVariables>(VirtualStoreDeviceDocument, options);
        }
export type VirtualStoreDeviceQueryHookResult = ReturnType<typeof useVirtualStoreDeviceQuery>;
export type VirtualStoreDeviceLazyQueryHookResult = ReturnType<typeof useVirtualStoreDeviceLazyQuery>;
export type VirtualStoreDeviceQueryResult = Apollo.QueryResult<VirtualStoreDeviceQuery, VirtualStoreDeviceQueryVariables>;
export const VirtualStoreDevicesDocument = gql`
    query virtualStoreDevices($offset: Int!, $limit: Int!, $where: VirtualStoreDevicesFilter) {
  virtualStoreDevices(offset: $offset, limit: $limit, where: $where) {
    nodes {
      id
      name
      root {
        id
        name
      }
      channel {
        id
        name
      }
      virtualCategoryScreen {
        id
        name
      }
      virtualMultiScreen {
        id
        name
      }
      virtualCategoryPoint {
        id
        name
      }
      tags
      active
      width
      height
      version
      messageReceived
    }
    totalCount
  }
}
    `;

/**
 * __useVirtualStoreDevicesQuery__
 *
 * To run a query within a React component, call `useVirtualStoreDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVirtualStoreDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVirtualStoreDevicesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useVirtualStoreDevicesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<VirtualStoreDevicesQuery, VirtualStoreDevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<VirtualStoreDevicesQuery, VirtualStoreDevicesQueryVariables>(VirtualStoreDevicesDocument, options);
      }
export function useVirtualStoreDevicesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VirtualStoreDevicesQuery, VirtualStoreDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<VirtualStoreDevicesQuery, VirtualStoreDevicesQueryVariables>(VirtualStoreDevicesDocument, options);
        }
export type VirtualStoreDevicesQueryHookResult = ReturnType<typeof useVirtualStoreDevicesQuery>;
export type VirtualStoreDevicesLazyQueryHookResult = ReturnType<typeof useVirtualStoreDevicesLazyQuery>;
export type VirtualStoreDevicesQueryResult = Apollo.QueryResult<VirtualStoreDevicesQuery, VirtualStoreDevicesQueryVariables>;
export const WarehouseParentsWithChildrenDocument = gql`
    query WarehouseParentsWithChildren($any: String, $rootId: Int, $supplierId: Int, $alarmType: AlarmType, $offset: Int!, $limit: Int!) {
  warehouseParentsWithChildren(
    where: {any: $any, rootId: $rootId, supplierId: $supplierId, alarmType: $alarmType}
    offset: $offset
    limit: $limit
  ) {
    nodes {
      id
      name
      internalId
      valueEntities {
        templateKey
        value
      }
      products(rootId: $rootId, supplierId: $supplierId, alarmType: $alarmType) {
        id
        internalId
        valueEntities {
          templateKey
          value
        }
        root {
          id
          name
        }
        supplier {
          id
          companyName
          supplierId
        }
        positions {
          sequence
          shelf {
            key
            bay {
              key
              aisle {
                key
              }
            }
          }
        }
      }
    }
    totalCount
  }
}
    `;

/**
 * __useWarehouseParentsWithChildrenQuery__
 *
 * To run a query within a React component, call `useWarehouseParentsWithChildrenQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehouseParentsWithChildrenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehouseParentsWithChildrenQuery({
 *   variables: {
 *      any: // value for 'any'
 *      rootId: // value for 'rootId'
 *      supplierId: // value for 'supplierId'
 *      alarmType: // value for 'alarmType'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useWarehouseParentsWithChildrenQuery(baseOptions: ApolloReactHooks.QueryHookOptions<WarehouseParentsWithChildrenQuery, WarehouseParentsWithChildrenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<WarehouseParentsWithChildrenQuery, WarehouseParentsWithChildrenQueryVariables>(WarehouseParentsWithChildrenDocument, options);
      }
export function useWarehouseParentsWithChildrenLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WarehouseParentsWithChildrenQuery, WarehouseParentsWithChildrenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<WarehouseParentsWithChildrenQuery, WarehouseParentsWithChildrenQueryVariables>(WarehouseParentsWithChildrenDocument, options);
        }
export type WarehouseParentsWithChildrenQueryHookResult = ReturnType<typeof useWarehouseParentsWithChildrenQuery>;
export type WarehouseParentsWithChildrenLazyQueryHookResult = ReturnType<typeof useWarehouseParentsWithChildrenLazyQuery>;
export type WarehouseParentsWithChildrenQueryResult = Apollo.QueryResult<WarehouseParentsWithChildrenQuery, WarehouseParentsWithChildrenQueryVariables>;
export const OrderReportInfoDocument = gql`
    query orderReportInfo {
  info {
    rootCount
    warehouseProductCount
    totalWarehouseOrderCount
    openWarehouseOrderCount
  }
}
    `;

/**
 * __useOrderReportInfoQuery__
 *
 * To run a query within a React component, call `useOrderReportInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderReportInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderReportInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrderReportInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrderReportInfoQuery, OrderReportInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<OrderReportInfoQuery, OrderReportInfoQueryVariables>(OrderReportInfoDocument, options);
      }
export function useOrderReportInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrderReportInfoQuery, OrderReportInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<OrderReportInfoQuery, OrderReportInfoQueryVariables>(OrderReportInfoDocument, options);
        }
export type OrderReportInfoQueryHookResult = ReturnType<typeof useOrderReportInfoQuery>;
export type OrderReportInfoLazyQueryHookResult = ReturnType<typeof useOrderReportInfoLazyQuery>;
export type OrderReportInfoQueryResult = Apollo.QueryResult<OrderReportInfoQuery, OrderReportInfoQueryVariables>;
export const ProductQuantityHistoryDocument = gql`
    query productQuantityHistory($productId: Int!, $where: QuantityHistoryFilter, $offset: Int!, $limit: Int!) {
  quantityHistory(
    productId: $productId
    where: $where
    offset: $offset
    limit: $limit
  ) {
    nodes {
      id
      created
      quantity
      quantityChange
      mode
    }
    totalCount
  }
}
    `;

/**
 * __useProductQuantityHistoryQuery__
 *
 * To run a query within a React component, call `useProductQuantityHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuantityHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuantityHistoryQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useProductQuantityHistoryQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ProductQuantityHistoryQuery, ProductQuantityHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ProductQuantityHistoryQuery, ProductQuantityHistoryQueryVariables>(ProductQuantityHistoryDocument, options);
      }
export function useProductQuantityHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductQuantityHistoryQuery, ProductQuantityHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ProductQuantityHistoryQuery, ProductQuantityHistoryQueryVariables>(ProductQuantityHistoryDocument, options);
        }
export type ProductQuantityHistoryQueryHookResult = ReturnType<typeof useProductQuantityHistoryQuery>;
export type ProductQuantityHistoryLazyQueryHookResult = ReturnType<typeof useProductQuantityHistoryLazyQuery>;
export type ProductQuantityHistoryQueryResult = Apollo.QueryResult<ProductQuantityHistoryQuery, ProductQuantityHistoryQueryVariables>;
export const SearchChildProductsDocument = gql`
    query searchChildProducts($where: ProductsFilter, $offset: Int!, $limit: Int!) {
  warehouseChildren(where: $where, offset: $offset, limit: $limit) {
    nodes {
      id
      internalId
      name
      parentProduct {
        id
        name
        internalId
      }
      devices {
        id
      }
      positions {
        id
      }
    }
    totalCount
  }
}
    `;

/**
 * __useSearchChildProductsQuery__
 *
 * To run a query within a React component, call `useSearchChildProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchChildProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchChildProductsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchChildProductsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SearchChildProductsQuery, SearchChildProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SearchChildProductsQuery, SearchChildProductsQueryVariables>(SearchChildProductsDocument, options);
      }
export function useSearchChildProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchChildProductsQuery, SearchChildProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SearchChildProductsQuery, SearchChildProductsQueryVariables>(SearchChildProductsDocument, options);
        }
export type SearchChildProductsQueryHookResult = ReturnType<typeof useSearchChildProductsQuery>;
export type SearchChildProductsLazyQueryHookResult = ReturnType<typeof useSearchChildProductsLazyQuery>;
export type SearchChildProductsQueryResult = Apollo.QueryResult<SearchChildProductsQuery, SearchChildProductsQueryVariables>;
export const WarehouseProductValueReportDocument = gql`
    query WarehouseProductValueReport($startDate: DateTime!, $rootId: Int, $supplierId: Int) {
  warehouseProductValueReport(
    startDate: $startDate
    rootId: $rootId
    supplierId: $supplierId
  ) {
    reports {
      month
      EUR
      USD
      GBP
      CHF
      date
    }
  }
}
    `;

/**
 * __useWarehouseProductValueReportQuery__
 *
 * To run a query within a React component, call `useWarehouseProductValueReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehouseProductValueReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehouseProductValueReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      rootId: // value for 'rootId'
 *      supplierId: // value for 'supplierId'
 *   },
 * });
 */
export function useWarehouseProductValueReportQuery(baseOptions: ApolloReactHooks.QueryHookOptions<WarehouseProductValueReportQuery, WarehouseProductValueReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<WarehouseProductValueReportQuery, WarehouseProductValueReportQueryVariables>(WarehouseProductValueReportDocument, options);
      }
export function useWarehouseProductValueReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WarehouseProductValueReportQuery, WarehouseProductValueReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<WarehouseProductValueReportQuery, WarehouseProductValueReportQueryVariables>(WarehouseProductValueReportDocument, options);
        }
export type WarehouseProductValueReportQueryHookResult = ReturnType<typeof useWarehouseProductValueReportQuery>;
export type WarehouseProductValueReportLazyQueryHookResult = ReturnType<typeof useWarehouseProductValueReportLazyQuery>;
export type WarehouseProductValueReportQueryResult = Apollo.QueryResult<WarehouseProductValueReportQuery, WarehouseProductValueReportQueryVariables>;
export const AddSupplierDocument = gql`
    mutation addSupplier($input: AddSupplier!) {
  addSupplier(input: $input) {
    errors {
      path
      message
    }
    supplier {
      id
    }
  }
}
    `;
export type AddSupplierMutationFn = Apollo.MutationFunction<AddSupplierMutation, AddSupplierMutationVariables>;

/**
 * __useAddSupplierMutation__
 *
 * To run a mutation, you first call `useAddSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSupplierMutation, { data, loading, error }] = useAddSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddSupplierMutation, AddSupplierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddSupplierMutation, AddSupplierMutationVariables>(AddSupplierDocument, options);
      }
export type AddSupplierMutationHookResult = ReturnType<typeof useAddSupplierMutation>;
export type AddSupplierMutationResult = Apollo.MutationResult<AddSupplierMutation>;
export type AddSupplierMutationOptions = Apollo.BaseMutationOptions<AddSupplierMutation, AddSupplierMutationVariables>;
export const DeleteSupplierDocument = gql`
    mutation deleteSupplier($id: Int!) {
  deleteSupplier(id: $id) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type DeleteSupplierMutationFn = Apollo.MutationFunction<DeleteSupplierMutation, DeleteSupplierMutationVariables>;

/**
 * __useDeleteSupplierMutation__
 *
 * To run a mutation, you first call `useDeleteSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSupplierMutation, { data, loading, error }] = useDeleteSupplierMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSupplierMutation, DeleteSupplierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteSupplierMutation, DeleteSupplierMutationVariables>(DeleteSupplierDocument, options);
      }
export type DeleteSupplierMutationHookResult = ReturnType<typeof useDeleteSupplierMutation>;
export type DeleteSupplierMutationResult = Apollo.MutationResult<DeleteSupplierMutation>;
export type DeleteSupplierMutationOptions = Apollo.BaseMutationOptions<DeleteSupplierMutation, DeleteSupplierMutationVariables>;
export const EditSupplierDocument = gql`
    mutation editSupplier($input: EditSupplier!) {
  editSupplier(input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type EditSupplierMutationFn = Apollo.MutationFunction<EditSupplierMutation, EditSupplierMutationVariables>;

/**
 * __useEditSupplierMutation__
 *
 * To run a mutation, you first call `useEditSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSupplierMutation, { data, loading, error }] = useEditSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditSupplierMutation, EditSupplierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditSupplierMutation, EditSupplierMutationVariables>(EditSupplierDocument, options);
      }
export type EditSupplierMutationHookResult = ReturnType<typeof useEditSupplierMutation>;
export type EditSupplierMutationResult = Apollo.MutationResult<EditSupplierMutation>;
export type EditSupplierMutationOptions = Apollo.BaseMutationOptions<EditSupplierMutation, EditSupplierMutationVariables>;
export const SupplierDocument = gql`
    query supplier($id: Int!) {
  supplier(supplierId: $id) {
    supplier {
      id
      companyName
      email
      supplierId
      address
      addressNumber
      country
      postCode
      city
      vat
      email
    }
  }
}
    `;

/**
 * __useSupplierQuery__
 *
 * To run a query within a React component, call `useSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSupplierQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SupplierQuery, SupplierQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SupplierQuery, SupplierQueryVariables>(SupplierDocument, options);
      }
export function useSupplierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SupplierQuery, SupplierQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SupplierQuery, SupplierQueryVariables>(SupplierDocument, options);
        }
export type SupplierQueryHookResult = ReturnType<typeof useSupplierQuery>;
export type SupplierLazyQueryHookResult = ReturnType<typeof useSupplierLazyQuery>;
export type SupplierQueryResult = Apollo.QueryResult<SupplierQuery, SupplierQueryVariables>;
export const SuppliersDocument = gql`
    query suppliers($where: SuppliersFilter, $offset: Int!, $limit: Int!) {
  suppliers(where: $where, offset: $offset, limit: $limit) {
    nodes {
      id
      companyName
      supplierId
      country
      address
      postCode
      city
    }
    totalCount
  }
}
    `;

/**
 * __useSuppliersQuery__
 *
 * To run a query within a React component, call `useSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuppliersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSuppliersQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SuppliersQuery, SuppliersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SuppliersQuery, SuppliersQueryVariables>(SuppliersDocument, options);
      }
export function useSuppliersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SuppliersQuery, SuppliersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SuppliersQuery, SuppliersQueryVariables>(SuppliersDocument, options);
        }
export type SuppliersQueryHookResult = ReturnType<typeof useSuppliersQuery>;
export type SuppliersLazyQueryHookResult = ReturnType<typeof useSuppliersLazyQuery>;
export type SuppliersQueryResult = Apollo.QueryResult<SuppliersQuery, SuppliersQueryVariables>;
export const TriggerDeviceLedDocument = gql`
    mutation triggerDeviceLed($input: TriggerDeviceLedInput!) {
  triggerDeviceLed(input: $input) {
    errors {
      path
      message
    }
    success
  }
}
    `;
export type TriggerDeviceLedMutationFn = Apollo.MutationFunction<TriggerDeviceLedMutation, TriggerDeviceLedMutationVariables>;

/**
 * __useTriggerDeviceLedMutation__
 *
 * To run a mutation, you first call `useTriggerDeviceLedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerDeviceLedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerDeviceLedMutation, { data, loading, error }] = useTriggerDeviceLedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTriggerDeviceLedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TriggerDeviceLedMutation, TriggerDeviceLedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<TriggerDeviceLedMutation, TriggerDeviceLedMutationVariables>(TriggerDeviceLedDocument, options);
      }
export type TriggerDeviceLedMutationHookResult = ReturnType<typeof useTriggerDeviceLedMutation>;
export type TriggerDeviceLedMutationResult = Apollo.MutationResult<TriggerDeviceLedMutation>;
export type TriggerDeviceLedMutationOptions = Apollo.BaseMutationOptions<TriggerDeviceLedMutation, TriggerDeviceLedMutationVariables>;
export const WarehouseOrderReportDocument = gql`
    query warehouseOrderReport($input: WarehouseOrderReportInput!) {
  warehouseOrderReport(input: $input) {
    allOrders {
      date
      normalCount
      expressCount
      total
    }
    stockValue {
      date
      EUR
      USD
      GBP
      CHF
    }
  }
}
    `;

/**
 * __useWarehouseOrderReportQuery__
 *
 * To run a query within a React component, call `useWarehouseOrderReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehouseOrderReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehouseOrderReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWarehouseOrderReportQuery(baseOptions: ApolloReactHooks.QueryHookOptions<WarehouseOrderReportQuery, WarehouseOrderReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<WarehouseOrderReportQuery, WarehouseOrderReportQueryVariables>(WarehouseOrderReportDocument, options);
      }
export function useWarehouseOrderReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WarehouseOrderReportQuery, WarehouseOrderReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<WarehouseOrderReportQuery, WarehouseOrderReportQueryVariables>(WarehouseOrderReportDocument, options);
        }
export type WarehouseOrderReportQueryHookResult = ReturnType<typeof useWarehouseOrderReportQuery>;
export type WarehouseOrderReportLazyQueryHookResult = ReturnType<typeof useWarehouseOrderReportLazyQuery>;
export type WarehouseOrderReportQueryResult = Apollo.QueryResult<WarehouseOrderReportQuery, WarehouseOrderReportQueryVariables>;
export const WarehouseParentProductDocument = gql`
    query warehouseParentProduct($id: Int!) {
  product(id: $id) {
    product {
      id
      internalId
      name
      valueEntities {
        templateKey
        value
      }
      productImages {
        id
        primary
        file {
          id
          originalname
          filename
          mimetype
          encoding
          url
          isFolder
          width
          height
          thumbnail
        }
      }
    }
  }
}
    `;

/**
 * __useWarehouseParentProductQuery__
 *
 * To run a query within a React component, call `useWarehouseParentProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehouseParentProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehouseParentProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWarehouseParentProductQuery(baseOptions: ApolloReactHooks.QueryHookOptions<WarehouseParentProductQuery, WarehouseParentProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<WarehouseParentProductQuery, WarehouseParentProductQueryVariables>(WarehouseParentProductDocument, options);
      }
export function useWarehouseParentProductLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WarehouseParentProductQuery, WarehouseParentProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<WarehouseParentProductQuery, WarehouseParentProductQueryVariables>(WarehouseParentProductDocument, options);
        }
export type WarehouseParentProductQueryHookResult = ReturnType<typeof useWarehouseParentProductQuery>;
export type WarehouseParentProductLazyQueryHookResult = ReturnType<typeof useWarehouseParentProductLazyQuery>;
export type WarehouseParentProductQueryResult = Apollo.QueryResult<WarehouseParentProductQuery, WarehouseParentProductQueryVariables>;
export const WarehouseParentProductsDocument = gql`
    query warehouseParentProducts($where: ProductsFilter, $offset: Int!, $limit: Int!) {
  products(where: $where, offset: $offset, limit: $limit) {
    nodes {
      id
      name
      internalId
      valueEntities {
        templateKey
        value
      }
      products {
        id
        internalId
        name
        valueEntities {
          templateKey
          value
        }
        root {
          id
          name
        }
        supplier {
          id
          companyName
          supplierId
        }
        positions {
          id
          sequence
          shelf {
            key
            bay {
              key
              aisle {
                key
              }
            }
          }
        }
      }
      productImages {
        id
        file {
          thumbnail
        }
      }
    }
    totalCount
  }
}
    `;

/**
 * __useWarehouseParentProductsQuery__
 *
 * To run a query within a React component, call `useWarehouseParentProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehouseParentProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehouseParentProductsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useWarehouseParentProductsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<WarehouseParentProductsQuery, WarehouseParentProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<WarehouseParentProductsQuery, WarehouseParentProductsQueryVariables>(WarehouseParentProductsDocument, options);
      }
export function useWarehouseParentProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WarehouseParentProductsQuery, WarehouseParentProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<WarehouseParentProductsQuery, WarehouseParentProductsQueryVariables>(WarehouseParentProductsDocument, options);
        }
export type WarehouseParentProductsQueryHookResult = ReturnType<typeof useWarehouseParentProductsQuery>;
export type WarehouseParentProductsLazyQueryHookResult = ReturnType<typeof useWarehouseParentProductsLazyQuery>;
export type WarehouseParentProductsQueryResult = Apollo.QueryResult<WarehouseParentProductsQuery, WarehouseParentProductsQueryVariables>;
export const WarehouseProductDocument = gql`
    query warehouseProduct($id: Int!) {
  product(id: $id) {
    product {
      id
      internalId
      name
      rootId
      expirationDate
      parentProduct {
        id
        name
        internalId
        valueEntities {
          templateKey
          value
        }
        productImages {
          id
          primary
          file {
            id
            originalname
            filename
            mimetype
            encoding
            url
            isFolder
            width
            height
            thumbnail
          }
        }
      }
      supplier {
        id
        supplierId
        companyName
      }
      valueEntities {
        templateKey
        value
      }
      positions {
        id
        sequence
        device {
          id
          serialNumber
        }
        shelf {
          key
          bay {
            key
            aisle {
              key
            }
          }
        }
      }
      devices {
        id
        serialNumber
      }
    }
  }
}
    `;

/**
 * __useWarehouseProductQuery__
 *
 * To run a query within a React component, call `useWarehouseProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehouseProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehouseProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWarehouseProductQuery(baseOptions: ApolloReactHooks.QueryHookOptions<WarehouseProductQuery, WarehouseProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<WarehouseProductQuery, WarehouseProductQueryVariables>(WarehouseProductDocument, options);
      }
export function useWarehouseProductLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WarehouseProductQuery, WarehouseProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<WarehouseProductQuery, WarehouseProductQueryVariables>(WarehouseProductDocument, options);
        }
export type WarehouseProductQueryHookResult = ReturnType<typeof useWarehouseProductQuery>;
export type WarehouseProductLazyQueryHookResult = ReturnType<typeof useWarehouseProductLazyQuery>;
export type WarehouseProductQueryResult = Apollo.QueryResult<WarehouseProductQuery, WarehouseProductQueryVariables>;
export const AddWarehouseProductsDocument = gql`
    mutation addWarehouseProducts($input: [ProductInput!]!) {
  addEditProducts(input: $input) {
    errors {
      path
      message
    }
    success
    addedProducts {
      id
      internalId
      name
      rootId
      valueEntities {
        id
        name
        templateKey
        type
        value
      }
    }
  }
}
    `;
export type AddWarehouseProductsMutationFn = Apollo.MutationFunction<AddWarehouseProductsMutation, AddWarehouseProductsMutationVariables>;

/**
 * __useAddWarehouseProductsMutation__
 *
 * To run a mutation, you first call `useAddWarehouseProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWarehouseProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWarehouseProductsMutation, { data, loading, error }] = useAddWarehouseProductsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddWarehouseProductsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddWarehouseProductsMutation, AddWarehouseProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddWarehouseProductsMutation, AddWarehouseProductsMutationVariables>(AddWarehouseProductsDocument, options);
      }
export type AddWarehouseProductsMutationHookResult = ReturnType<typeof useAddWarehouseProductsMutation>;
export type AddWarehouseProductsMutationResult = Apollo.MutationResult<AddWarehouseProductsMutation>;
export type AddWarehouseProductsMutationOptions = Apollo.BaseMutationOptions<AddWarehouseProductsMutation, AddWarehouseProductsMutationVariables>;
export const AddEditWarehouseProductsNewPositionDocument = gql`
    mutation addEditWarehouseProductsNewPosition($input: [ProductInput!]!, $positionId: Int!, $positionInput: EditPositionInput!) {
  addEditProducts(input: $input) {
    errors {
      path
      message
    }
    success
    addedProducts {
      id
      internalId
      name
      rootId
      valueEntities {
        id
        name
        templateKey
        type
        value
      }
    }
  }
  editPosition(positionId: $positionId, input: $positionInput) {
    errors {
      message
      path
    }
    success
  }
}
    `;
export type AddEditWarehouseProductsNewPositionMutationFn = Apollo.MutationFunction<AddEditWarehouseProductsNewPositionMutation, AddEditWarehouseProductsNewPositionMutationVariables>;

/**
 * __useAddEditWarehouseProductsNewPositionMutation__
 *
 * To run a mutation, you first call `useAddEditWarehouseProductsNewPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEditWarehouseProductsNewPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEditWarehouseProductsNewPositionMutation, { data, loading, error }] = useAddEditWarehouseProductsNewPositionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      positionId: // value for 'positionId'
 *      positionInput: // value for 'positionInput'
 *   },
 * });
 */
export function useAddEditWarehouseProductsNewPositionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddEditWarehouseProductsNewPositionMutation, AddEditWarehouseProductsNewPositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddEditWarehouseProductsNewPositionMutation, AddEditWarehouseProductsNewPositionMutationVariables>(AddEditWarehouseProductsNewPositionDocument, options);
      }
export type AddEditWarehouseProductsNewPositionMutationHookResult = ReturnType<typeof useAddEditWarehouseProductsNewPositionMutation>;
export type AddEditWarehouseProductsNewPositionMutationResult = Apollo.MutationResult<AddEditWarehouseProductsNewPositionMutation>;
export type AddEditWarehouseProductsNewPositionMutationOptions = Apollo.BaseMutationOptions<AddEditWarehouseProductsNewPositionMutation, AddEditWarehouseProductsNewPositionMutationVariables>;
export const AddEditWarehouseProductsOldPositionDocument = gql`
    mutation addEditWarehouseProductsOldPosition($input: [ProductInput!]!, $oldPositionId: Int!, $oldPositionInput: EditPositionInput!) {
  addEditProducts(input: $input) {
    errors {
      path
      message
    }
    success
    addedProducts {
      id
      internalId
      name
      rootId
      valueEntities {
        id
        name
        templateKey
        type
        value
      }
    }
  }
  editPosition(positionId: $oldPositionId, input: $oldPositionInput) {
    errors {
      message
      path
    }
    success
  }
}
    `;
export type AddEditWarehouseProductsOldPositionMutationFn = Apollo.MutationFunction<AddEditWarehouseProductsOldPositionMutation, AddEditWarehouseProductsOldPositionMutationVariables>;

/**
 * __useAddEditWarehouseProductsOldPositionMutation__
 *
 * To run a mutation, you first call `useAddEditWarehouseProductsOldPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEditWarehouseProductsOldPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEditWarehouseProductsOldPositionMutation, { data, loading, error }] = useAddEditWarehouseProductsOldPositionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      oldPositionId: // value for 'oldPositionId'
 *      oldPositionInput: // value for 'oldPositionInput'
 *   },
 * });
 */
export function useAddEditWarehouseProductsOldPositionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddEditWarehouseProductsOldPositionMutation, AddEditWarehouseProductsOldPositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddEditWarehouseProductsOldPositionMutation, AddEditWarehouseProductsOldPositionMutationVariables>(AddEditWarehouseProductsOldPositionDocument, options);
      }
export type AddEditWarehouseProductsOldPositionMutationHookResult = ReturnType<typeof useAddEditWarehouseProductsOldPositionMutation>;
export type AddEditWarehouseProductsOldPositionMutationResult = Apollo.MutationResult<AddEditWarehouseProductsOldPositionMutation>;
export type AddEditWarehouseProductsOldPositionMutationOptions = Apollo.BaseMutationOptions<AddEditWarehouseProductsOldPositionMutation, AddEditWarehouseProductsOldPositionMutationVariables>;
export const AddEditWarehouseProductsOldPositionNewPositionDocument = gql`
    mutation addEditWarehouseProductsOldPositionNewPosition($input: [ProductInput!]!, $positionId: Int!, $positionInput: EditPositionInput!, $oldPositionId: Int!, $oldPositionInput: EditPositionInput!) {
  addEditProducts(input: $input) {
    errors {
      path
      message
    }
    success
    addedProducts {
      id
      internalId
      name
      rootId
      valueEntities {
        id
        name
        templateKey
        type
        value
      }
    }
  }
  A: editPosition(positionId: $positionId, input: $positionInput) {
    errors {
      message
      path
    }
    success
  }
  B: editPosition(positionId: $oldPositionId, input: $oldPositionInput) {
    errors {
      message
      path
    }
    success
  }
}
    `;
export type AddEditWarehouseProductsOldPositionNewPositionMutationFn = Apollo.MutationFunction<AddEditWarehouseProductsOldPositionNewPositionMutation, AddEditWarehouseProductsOldPositionNewPositionMutationVariables>;

/**
 * __useAddEditWarehouseProductsOldPositionNewPositionMutation__
 *
 * To run a mutation, you first call `useAddEditWarehouseProductsOldPositionNewPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEditWarehouseProductsOldPositionNewPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEditWarehouseProductsOldPositionNewPositionMutation, { data, loading, error }] = useAddEditWarehouseProductsOldPositionNewPositionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      positionId: // value for 'positionId'
 *      positionInput: // value for 'positionInput'
 *      oldPositionId: // value for 'oldPositionId'
 *      oldPositionInput: // value for 'oldPositionInput'
 *   },
 * });
 */
export function useAddEditWarehouseProductsOldPositionNewPositionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddEditWarehouseProductsOldPositionNewPositionMutation, AddEditWarehouseProductsOldPositionNewPositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddEditWarehouseProductsOldPositionNewPositionMutation, AddEditWarehouseProductsOldPositionNewPositionMutationVariables>(AddEditWarehouseProductsOldPositionNewPositionDocument, options);
      }
export type AddEditWarehouseProductsOldPositionNewPositionMutationHookResult = ReturnType<typeof useAddEditWarehouseProductsOldPositionNewPositionMutation>;
export type AddEditWarehouseProductsOldPositionNewPositionMutationResult = Apollo.MutationResult<AddEditWarehouseProductsOldPositionNewPositionMutation>;
export type AddEditWarehouseProductsOldPositionNewPositionMutationOptions = Apollo.BaseMutationOptions<AddEditWarehouseProductsOldPositionNewPositionMutation, AddEditWarehouseProductsOldPositionNewPositionMutationVariables>;
export const AddWarehouseProductDocument = gql`
    mutation addWarehouseProduct($input: ProductInput!) {
  addProduct(input: $input) {
    errors {
      path
      message
    }
    success
    product {
      id
    }
  }
}
    `;
export type AddWarehouseProductMutationFn = Apollo.MutationFunction<AddWarehouseProductMutation, AddWarehouseProductMutationVariables>;

/**
 * __useAddWarehouseProductMutation__
 *
 * To run a mutation, you first call `useAddWarehouseProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWarehouseProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWarehouseProductMutation, { data, loading, error }] = useAddWarehouseProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddWarehouseProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddWarehouseProductMutation, AddWarehouseProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddWarehouseProductMutation, AddWarehouseProductMutationVariables>(AddWarehouseProductDocument, options);
      }
export type AddWarehouseProductMutationHookResult = ReturnType<typeof useAddWarehouseProductMutation>;
export type AddWarehouseProductMutationResult = Apollo.MutationResult<AddWarehouseProductMutation>;
export type AddWarehouseProductMutationOptions = Apollo.BaseMutationOptions<AddWarehouseProductMutation, AddWarehouseProductMutationVariables>;
export const WarehouseProductReportDocument = gql`
    query warehouseProductReport($date: DateTime!, $rootId: Int, $supplierId: Int) {
  warehouseProductReport(date: $date, rootId: $rootId, supplierId: $supplierId) {
    product {
      id
      internalId
      name
      root {
        name
      }
      supplier {
        companyName
      }
      valueEntities {
        templateKey
        value
      }
      parentProduct {
        name
      }
      products {
        id
        internalId
        name
        valueEntities {
          templateKey
          value
        }
      }
    }
    quantity
    lastChange
    mode
  }
}
    `;

/**
 * __useWarehouseProductReportQuery__
 *
 * To run a query within a React component, call `useWarehouseProductReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehouseProductReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehouseProductReportQuery({
 *   variables: {
 *      date: // value for 'date'
 *      rootId: // value for 'rootId'
 *      supplierId: // value for 'supplierId'
 *   },
 * });
 */
export function useWarehouseProductReportQuery(baseOptions: ApolloReactHooks.QueryHookOptions<WarehouseProductReportQuery, WarehouseProductReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<WarehouseProductReportQuery, WarehouseProductReportQueryVariables>(WarehouseProductReportDocument, options);
      }
export function useWarehouseProductReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WarehouseProductReportQuery, WarehouseProductReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<WarehouseProductReportQuery, WarehouseProductReportQueryVariables>(WarehouseProductReportDocument, options);
        }
export type WarehouseProductReportQueryHookResult = ReturnType<typeof useWarehouseProductReportQuery>;
export type WarehouseProductReportLazyQueryHookResult = ReturnType<typeof useWarehouseProductReportLazyQuery>;
export type WarehouseProductReportQueryResult = Apollo.QueryResult<WarehouseProductReportQuery, WarehouseProductReportQueryVariables>;