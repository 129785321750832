import { useState, useEffect, useRef, FormEvent } from 'react'
import { makeStyles } from '@mui/styles'
import {
	CssBaseline,
	Box,
	Typography,
	TextField,
	Grid,
	Button,
	Theme,
	Modal,
	Stack,
} from '@mui/material'
import { LOGIN } from '../../lib/graphql/Login/mutations'
import { gql, useMutation } from '@apollo/client'
import { themeColors } from '@app/constants'
import { createApolloClient } from '@app/lib/graphql/createApolloClient'
import { useRouter } from 'next/router'
import { TranslateMessage } from '@app/lib/lang'
import CustomAuthCode from '@app/components/CustomAuthCode'
import { useAlerts } from '@app/lib/packages'
import { CustomLoader } from '@app/components'

const useStyles = makeStyles((theme: Theme) => ({
	form: {
		height: '55%',
		marginBottom: '100px',
		maxWidth: 650,
		[theme.breakpoints.up('xs')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		},
		[theme.breakpoints.up('sm')]: {
			paddingLeft: 100,
			paddingRight: 100,
		},
	},
	title: {
		fontSize: 36,
		fontWeight: 300,
		letterSpacing: 0.5,
		paddingBottom: theme.spacing(2),
	},
	subtitle: {
		fontSize: 12,
		color: '#898989',
		fontWeight: 300,
		letterSpacing: 0.27,
		paddingTop: theme.spacing(4),
	},
	submitButton: {
		background: theme.palette.secondary.dark,
		height: 48,
		color: 'white',
		width: '100%',
		backgroundColor: themeColors.agitronDark,
	},
	error: {
		marginTop: theme.spacing(4),
		padding: theme.spacing(4),
		color: 'black',
		background: '#ffea03',
	},
	logo: {
		position: 'absolute',
		maxWidth: 300,
		maxHeight: 30,
		top: 50,
		[theme.breakpoints.up('xs')]: {
			left: theme.spacing(4),
		},
		[theme.breakpoints.up('sm')]: {
			left: 100,
		},
	},
	logoDiv: {
		height: 100,
	},
	background: {
		width: '100%',
		height: '100%',
		position: 'fixed',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		backgroundColor: '#e4eaec',
		zIndex: -1,
	},
	diagonalBackground: {
		position: 'fixed',
		width: '100%',
		height: '100%',

		left: 650,
		background: 'linear-gradient(#1e66aa,  #5f2c7f)',
		clipPath: 'polygon(0% 0%,100% 0,100% 100%,10% 100%)',
	},
}))

const useTextStyles = makeStyles((theme: Theme) => ({
	root: {
		border: '1px solid #e2e2e1',
		overflow: 'hidden',
		borderRadius: 4,
		backgroundColor: 'white',
		'&:hover': {
			backgroundColor: '#fff',
		},
		'&$focused': {
			backgroundColor: '#fff',
			borderColor: theme.palette.primary.main,
		},
	},
	focused: {},
}))

interface LoginProps {
	customer?: string
	logo?: string
	loginLogo?: string
	primaryColor?: string
	textColor?: string
}

export async function getServerSideProps(
	context: any
): Promise<{ props: LoginProps }> {
	console.log('here', context.req.headers.host)
	const host = context.req.headers.host
	const customer = host.split('.')[0]
	const client = createApolloClient(host)

	try {
		const { data } = await client.query({
			query: gql`
				query dashSettings($customer: String!) {
					dashSettings(customer: $customer) {
						customer
						logo
						loginLogo
						primaryColor
						textColor
					}
				}
			`,
			variables: { customer },
		})
		console.log(data)

		return {
			props: {
				customer: data?.dashSettings?.customer,
				logo: data?.dashSettings?.logo,
				loginLogo: data?.dashSettings?.loginLogo,
				primaryColor: data?.dashSettings?.primaryColor,
				textColor: data?.dashSettings?.textColor,
			},
		}
	} catch (error) {
		console.log('error', error)
		return {
			props: {},
		}
	}
}

const Login = ({ loginLogo, logo, primaryColor, textColor }: LoginProps) => {
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [twoFactorCode, setTwoFactorCode] = useState('')
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(false)
	const [is2FAOpen, setIs2FAOpen] = useState(false)
	const [renderCloud, setRenderCloud] = useState(false)
	const { addWarningAlert } = useAlerts()
	const router = useRouter()

	if (typeof window !== 'undefined') {
		if (logo) localStorage.setItem('logo', logo)
		else localStorage.removeItem('logo')

		if (primaryColor) localStorage.setItem('primaryColor', primaryColor)
		else localStorage.removeItem('primaryColor')

		if (textColor) localStorage.setItem('textColor', textColor)
		else localStorage.removeItem('textColor')
	}

	//TODO
	const [loginAPI] = useMutation(LOGIN, {
		variables: { username, password, otp: twoFactorCode },
	})
	const classes = useStyles()
	const textClasses = useTextStyles()

	const handleSubmit = (e: FormEvent | null) => {
		if (e) e.preventDefault()
		setLoading(true)
		loginAPI()
			.then((result) => {
				if (result.data.login.needOTP) {
					setIs2FAOpen(true)
					setLoading(false)
				} else if (result.data.login.delta === -1) {
					addWarningAlert(`twoFactor.checkTimezone`)
					setLoading(false)
				} else if (
					result.data.login?.errors?.[0]?.message?.includes(
						'Incorrect code'
					)
				) {
					addWarningAlert(`twoFactor.incorrectCode`)
					setLoading(false)
				} else if (result.data.login.tokens === null) {
					setLoading(false)
					setError(true)
				} else {
					let { token, refreshToken, storageToken } = JSON.parse(
						result.data.login.tokens
					)
					localStorage.setItem('storageToken', storageToken)
					if (process.env.NODE_ENV == 'development') {
						localStorage.setItem('token', token)
						localStorage.setItem('refreshToken', refreshToken)
					}

					setLoading(false)
					router.push('/')
				}
			})
			.catch(() => {
				setLoading(false)
				setError(true)
			})
	}
	const userField = useRef(null)
	const passField = useRef(null)

	useEffect(() => {
		setRenderCloud(window.location.href.split('/')[2].split('.').length < 3)
		if ((userField as any)?.current?.value) {
			setUsername((userField as any).current.value)
			//do the same for all autofilled fields
		}
		if ((passField as any)?.current?.value) {
			setPassword((passField as any).current.value)
			//do the same for all autofilled fields
		}
	}, [])

	useEffect(() => {
		if (twoFactorCode.length === 6) {
			console.log('handleSubmit(null)')
			handleSubmit(null)
		}
	}, [twoFactorCode])

	return (
		<>
			<CssBaseline />
			<div className={classes.diagonalBackground}></div>
			<div className={classes.logoDiv} />
			<div className={classes.background}></div>
			<div style={{ height: '150px' }}>
				<img
					src={loginLogo ? loginLogo : '/images/logo-login.svg'}
					className={classes.logo}
				/>
			</div>
			<Box
				className={classes.form}
				display="flex"
				justifyContent="center"
				alignItems="center"
			>
				<form onSubmit={handleSubmit}>
					<Grid container spacing={0} justifyContent="center">
						<Grid item xs={12}>
							<Typography className={classes.title}>
								{loginLogo
									? 'Log in to your dashboard'
									: 'Log in to your Agitron Connect dashboard'}
							</Typography>
						</Grid>
						{renderCloud ? (
							<Grid item xs={12}>
								<TextField
									style={{ marginBottom: 15 }}
									fullWidth
									inputRef={userField}
									label="Cloud instance"
									variant="filled"
									InputProps={{
										classes: textClasses,
										disableUnderline: true,
									}}
								/>
							</Grid>
						) : null}
						<Grid item xs={12}>
							<TextField
								style={{ marginBottom: 15 }}
								fullWidth
								onChange={(e) => setUsername(e.target.value)}
								inputRef={userField}
								label="Username"
								variant="filled"
								id="username"
								classes={textClasses}
								InputProps={{
									classes: textClasses,
									disableUnderline: true,
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								style={{ marginBottom: 15 }}
								fullWidth
								onChange={(e) => setPassword(e.target.value)}
								inputRef={passField}
								label="Password"
								type="password"
								variant="filled"
								id="pass"
								classes={textClasses}
								InputProps={{
									classes: textClasses,
									disableUnderline: true,
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							{loading ? (
								<Button
									className={classes.submitButton}
									variant="contained"
									color="primary"
									type="submit"
									disabled={true}
								>
									Logging in ...
								</Button>
							) : (
								<Button
									className={classes.submitButton}
									variant="contained"
									color="secondary"
									type="submit"
								>
									Log in
								</Button>
							)}
						</Grid>
						<Grid item xs={12}>
							<Typography className={classes.subtitle}>
								{loginLogo
									? `By continuing, you agree to the Terms of Use and Privacy Policy. © 2016-${new Date().getFullYear()}. Powered by Agitron IoT Engine.`
									: `By continuing, you agree to Agitron Terms of use and Privacy policy © 2016-${new Date().getFullYear()} Agitron d.o.o.`}
							</Typography>
						</Grid>
						{error && (
							<Grid xs={12} item className={classes.error}>
								<Typography>
									<img
										style={{
											paddingRight: '10px',
											position: 'relative',
											top: '2px',
										}}
										src="/images/16.svg"
									/>
									You have entered an invalid username or
									password{' '}
								</Typography>
							</Grid>
						)}
					</Grid>
				</form>

				<Modal open={is2FAOpen} onClose={() => setIs2FAOpen(false)}>
					<Stack
						sx={{
							backgroundColor: themeColors.white,
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							width: '500px',
							height: '250px',
							borderRadius: '5px',
							paddingTop: '78px',
						}}
						gap="20px"
						alignItems="center"
					>
						<CustomLoader showLoader={loading} />

						<Typography color={themeColors.darkGrey}>
							<TranslateMessage id="twoFactor.enter2FA" />
						</Typography>

						<CustomAuthCode
							onChange={(value: string) => {
								setTwoFactorCode(value)
							}}
						/>
					</Stack>
				</Modal>
			</Box>
		</>
	)
}

Login.layout = 'loginPage'

export default Login
