import {
	AppBar as MuiAppBar,
	Toolbar,
	IconButton,
	Typography,
	Box,
	Stack,
} from '@mui/material'
import { useCurrentUser } from '@app/lib/packages'
import { useRouter } from 'next/router'
import { CustomIconFromValue } from '@app/components'
import theme from '@app/lib/materialui/theme'
import { themeColors } from '@app/constants'
import { extractInitials } from '@app/lib/helpers'

interface AppBarProps {
	open: Boolean
	handleDrawerOpen: VoidFunction
}

export default function AppBar({ handleDrawerOpen }: AppBarProps) {
	const { currentUser } = useCurrentUser()
	const { push } = useRouter()

	const handleClick = () => {
		push('/user-settings')
	}

	const logo = localStorage.getItem('logo')
	const primaryColor =
		localStorage.getItem('primaryColor') || themeColors.agitronLight
	const textColor = localStorage.getItem('textColor')

	return (
		<MuiAppBar
			color="primary"
			position="fixed"
			sx={{
				background: primaryColor,
				zIndex: theme.zIndex.drawer + 1,
				transition: theme.transitions.create(['width', 'margin'], {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen,
				}),
				boxShadow: 'none',
				color: textColor || 'white',
			}}
		>
			<Toolbar>
				<Box
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					width="100%"
				>
					<Box
						display="flex"
						width={400}
						alignItems={'center'}
						color={textColor || 'white'}
					>
						<IconButton
							color="secondary"
							aria-label="open drawer"
							onClick={handleDrawerOpen}
							edge="start"
							size="large"
							sx={{
								marginRight: '15px',
								color: textColor || 'white',
							}}
						>
							<CustomIconFromValue icon="svg menu" />
						</IconButton>
						<img
							src={logo ? logo : '/images/agitron-connect.svg'}
							height="22px"
							width="auto"
						/>
					</Box>
					<Box
						display="flex"
						alignItems="center"
						onClick={handleClick}
						sx={{
							cursor: 'pointer',
						}}
						pr={2}
					>
						<Stack direction="row" alignItems="center" gap="10px">
							<div
								style={{
									width: '30px',
									height: '30px',
									borderRadius: '50%',
									backgroundColor: 'white',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									color: textColor || 'white',
								}}
							>
								<span
									style={{
										color: textColor
											? textColor
											: themeColors.agitronLight,
									}}
								>
									{extractInitials(
										currentUser?.fullName || ''
									)}
								</span>
							</div>

							<Typography color={textColor || 'white'}>
								{currentUser ? currentUser.fullName : 'User'}
							</Typography>
						</Stack>
					</Box>
				</Box>
			</Toolbar>
		</MuiAppBar>
	)
}
